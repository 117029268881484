import ScrollBoardBarChart from './ScrollBoardBarChart'
import ScrollBoardDiagram from './ScrollBoardDiagram'
import ScrollBoardPieChart from './ScrollBoardPieChart'
import ScrollBoardTreeMap from './ScrollBoardTreeMap'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import ApiStatus from 'src/api/defines/ApiStatus'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import * as dtoUtils from 'src/dto/DTOImpl'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import styled from 'styled-components'
import StepperComponent from '../StepperComponent'
import ImageNavs from './BottomImageNav'
import ControlPanel from './ControlPanel'
import ControlPanelOneClick from './ControlPanelOneClick'
import './ScrollBoardDisplayComponent.scss'
import ScrollBoardTimeDisplay from './ScrollBoardTimeDisplay'
import ScrollBoardValueChartContainer from './ScrollBoardValueChartContainer'
import { extractIds, extractPresentationIds, makeFilters, makeIdsForCharData, makeIdsForValueData, parseIdForCharData, parseString } from './ScrollBoardValueChartImpl'
import { makeArrangedData } from 'src/pages/components/dq-arrange/DQArrange'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import ModeSelector from './ModeSelector'
import { goNextPageAuto } from './ScrollBoardPageProc'
import JavaApiClient from 'src/api/JavaApiClient'
import DQLoadPanelWithProgress from 'src/components/dq-load-form/DQLoadPanelWithProgress'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

smoothscroll.polyfill()

const StyledBottomImageNavs = styled(ImageNavs)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: transparent;
    padding: 5px;
    text-align: center;
`

const StyledBottomModeSelector = styled(ModeSelector)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: transparent;
    padding: 5px;
    text-align: center;
`

const StyledBottomControlPanel = styled(ControlPanel)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: transparent;
    padding: 5px;
    text-align: center;
`

const StyledBottomControlPanelOneClick = styled(ControlPanelOneClick)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: transparent;
    padding: 5px;
    text-align: center;
`

const StyledCenterImagePage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.borderColor};
    background-color: transparent;
    padding: 5px;
    text-align: center;
    scroll-behavior: smooth;
`

const stepReferenceInit = {
    curStep: 0,
    nextStep: 0,
    curKey: '',
    totalSteps: 0,
    steps: [
        // {step : 0, selectedKey : '', dataIndex: 0, dataTotalLen: 0, data: []}
    ],
}

const items = () => [
    { value: 'manual', text: dqTranslateMsg('Page_ScrollBoard_' + 'Manual') },
    { value: 'presentation', text: dqTranslateMsg('Page_ScrollBoard_' + 'AutoSlide') },
]

const parseContent = (stepReference) => {
    const charIndex = stepReference.fields.findIndex((item) => item === '2000')
    const fields = stepReference.fields
    const targetStepData = stepReference.steps[charIndex]
    if (targetStepData) {
        const tgKey = targetStepData.key
        const filterArray = []
        if (tgKey) {
            let splitted = tgKey.split('\u0014')

            for (let ii = 0; ii < charIndex; ii++) {
                filterArray.push({ [fields[ii]]: splitted[ii] })
            }
        }
        return filterArray
    }
}

export default function ScrollBoardDisplayComponent({ pageLength, pageTypes, common, setDisableSidePanel }) {
    const { user } = useAuth()
    const { spring } = useApi()
    const { setTheme, getTheme } = useTheme()
    const borderColor = getTheme().includes('light') ? 'black' : 'white'
    const dispatch = useDispatch()
    const pageKey = 'scrollBoard'
    const timerRef = useRef(null) // Use useRef to store the timer
    const timerRefValueChart = useRef(null)

    const pages = common.pages
    const curPage = common.pages.scrollBoard
    const configPage = common.pages.configuration
    const othersPage = common.pages.others
    const sbPageSettings = common.pages.scrollBoard
    const configSettings = common.pages.configuration

    const popupMode = sbPageSettings.page.valueChartPopupMode

    const dbFilterInfo = DBUtils.getCurrentFilterData(pages, othersPage)

    const fields = pageTypes.map((item) => item.field)
    const types = pageTypes.map((item) => item.type)

    const storedStepReference = JSON.parse(localStorage.getItem('scroll-board-step-reference'))
    const [stepReference, setStepReference] = useState(storedStepReference || stepReferenceInit)
    const tempStepReference = useRef(storedStepReference || stepReferenceInit)
    const isValueChartOperating = useRef(false)

    const [mode, setMode] = useState('manual')
    const [isPlaying, setIsPlaying] = useState(false)
    const [isGraphicLoading, setIsGraphicLoading] = useState(false)

    const autoGraphicUpdateEpochTime = useRef(0)

    useEffect(() => {
        let isMounted = true

        const fetchData = async () => {
            try {
                // await getFirstStepResultData(0)
                await goHomePage(fields[0])
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching data:', error)
                }
            }
        }

        fetchData()

        return () => {
            isMounted = false
        }
    }, [curPage.page.dataUpdated, mode])

    useEffect(() => {
        setIsPlaying(false)
    }, [])

    const makeDataIndex = (stepReference, curStep, nextStep) => {
        const steps = stepReference.current.steps
        const curStepData = steps.find((item) => item.step === curStep)
        const nextStepData = steps.find((item) => item.step === nextStep)

        let updatedCurrentDataIndex = 0

        if (nextStepData) {
            if (nextStepData.field !== '1' && nextStepData.field !== '2000') {
                if (nextStepData.dataIndex < nextStepData.dataTotalLen - 1) {
                    updatedCurrentDataIndex = curStepData.dataIndex
                } else {
                    updatedCurrentDataIndex = curStepData.dataIndex + 1 < curStepData.dataTotalLen ? curStepData.dataIndex + 1 : 0
                }
            } else {
                if (nextStepData.field === '2000') {
                    updatedCurrentDataIndex = curStepData.dataIndex + 1 < curStepData.dataTotalLen ? curStepData.dataIndex + 1 : 0
                } else {
                    updatedCurrentDataIndex = 0
                }
            }
        }

        return updatedCurrentDataIndex
    }

    useEffect(() => {
        const startPresentationMode = async () => {
            if (mode === 'presentation' && isPlaying === true) {
                isValueChartOperating.current = false

                const performStep = async () => {
                    if (isValueChartOperating.current === false) {
                        if (curPage.page.autoGraphicUpdateTimeEnable && Date.now() - autoGraphicUpdateEpochTime.current > curPage.page.autoGraphicUpdateTime * 60 * 1000) {
                            setIsGraphicLoading(true)
                            const convertedStartDateTime = JavaApiClient.calcStartDateTime(
                                curPage.page.dateRangeIsDayTime,
                                curPage.page.dateRangeDayTime,
                                curPage.page.dateRangeSetting,
                                curPage.page.dateRangeDayOffset
                            )
                            const convertedEndDateTime = JavaApiClient.calcEndDateTime(curPage.page.dateRangeIsDayTime, curPage.page.dateRangeDayTime)

                            const filter = produce(curPage.filter, (draft) => {
                                if (user.user_role === 'demo') {
                                    draft.partFilter.push({
                                        group: 0,
                                        field: 1104,
                                        data: 'demo',
                                        logic: 'and',
                                        equeal: 'equeal',
                                    })
                                }
                            })

                            console.log('updateIBoardManual periodically')
                            await spring.updateIBoardManual(user, user.user_id, curPage.page, filter, convertedStartDateTime, convertedEndDateTime, configPage)
                            autoGraphicUpdateEpochTime.current = Date.now()
                            setIsGraphicLoading(false)
                        }

                        const curTotalSteps = tempStepReference.current.totalSteps
                        const curStep = tempStepReference.current.curStep
                        const nextStep = curStep === curTotalSteps - 1 ? 0 : curStep + 1
                        const steps = tempStepReference.current.steps
                        const stepsToCheck = curPage.page.autoPageRestartIndex
                        //
                        const dataCheckCurStep = steps.find((step) => step.step === curStep)

                        if ((curStep === curTotalSteps - 1 || dataCheckCurStep.data.length === 0) && stepsToCheck !== 1) {
                            for (let ii = 0; ii <= stepsToCheck - 2; ii++) {
                                const curStep = ii
                                const nextStep = curStep + 1
                                const stepsForLoop = tempStepReference.current.steps
                                const preStepItemIndex = stepsForLoop.findIndex((item) => item.step === curStep)
                                const dataIndex = makeDataIndex(tempStepReference, curStep, nextStep)

                                const updatedSteps = produce(stepsForLoop, (draft) => {
                                    draft[preStepItemIndex].dataIndex = dataIndex
                                    if (stepsForLoop[preStepItemIndex].dataIndex !== dataIndex) {
                                        // Use the splice method to remove items with step > curStep
                                        for (let i = draft.length - 1; i >= 0; i--) {
                                            if (draft[i].step > curStep) {
                                                draft.splice(i, 1)
                                            }
                                        }
                                    }
                                })
                                const info = produce(tempStepReference.current, (draft) => {
                                    draft.curStep = nextStep
                                    draft.curKey = stepsForLoop[preStepItemIndex].data[dataIndex].result_key
                                    draft.steps = updatedSteps
                                })
                                tempStepReference.current = info

                                if (ii === stepsToCheck - 2) {
                                    console.log('goNextPageAuto')
                                    goNextPageAuto(
                                        tempStepReference,
                                        user,
                                        user.user_id,
                                        spring,
                                        setStepReference,
                                        curPage,
                                        configSettings,
                                        isValueChartOperating,
                                        fields[nextStep],
                                        dbFilterInfo,
                                        handleCurrentControlFunction
                                    )
                                } else {
                                    console.log('goNextPageTempAuto')
                                    await goNextPageTempAuto(tempStepReference, user.user_id, spring, curPage, fields[nextStep])
                                }
                            }
                        } else {
                            const preStepItemIndex = steps.findIndex((item) => item.step === curStep)
                            const dataIndex = makeDataIndex(tempStepReference, curStep, nextStep)
                            const updatedSteps = produce(steps, (draft) => {
                                draft[preStepItemIndex].dataIndex = dataIndex
                            })
                            const info = produce(tempStepReference.current, (draft) => {
                                draft.curStep = nextStep
                                draft.curKey = steps[preStepItemIndex]?.data[dataIndex]?.result_key
                                draft.steps = updatedSteps
                            })
                            tempStepReference.current = info

                            goNextPageAuto(
                                tempStepReference,
                                user,
                                user.user_id,
                                spring,
                                setStepReference,
                                curPage,
                                configSettings,
                                isValueChartOperating,
                                fields[nextStep],
                                dbFilterInfo,
                                handleCurrentControlFunction
                            )
                        }
                    }

                    // Schedule the next step
                    timerRef.current = setTimeout(performStep, 1000 * curPage.page.autoPageUpdateTime)
                }

                // Initial delay before starting the performStep loop
                timerRef.current = setTimeout(performStep, 1000 * curPage.page.autoPageUpdateTime)
            }
        }

        startPresentationMode()

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [mode, isPlaying])

    const goNextPageTempAuto = async (tempStepReference, userId, springApi, setStepReference, curPage, pageField) => {
        const { curStep, nextStep, curKey, types } = tempStepReference.current
        const curType = types[curStep]
        if (curType === 'ValueChart') {
            const curStep = tempStepReference.current.curStep
            const charStep = tempStepReference.current.steps.find((item) => item.step === curStep - 1)
            const fields = tempStepReference.current.fields
            const types = tempStepReference.current.types

            const chartIDList = extractPresentationIds(charStep)
            if (chartIDList) {
                const stepFilter = {
                    part: {},
                    char: {},
                    value: {},
                }
                const idForChar = makeIdsForCharData(chartIDList)
                if (idForChar.length === 0) {
                    // handleCurrentControlFunction(null, false)
                    // alert(getErrProcMsg(procErrTable.E00002))
                    batch(() => {
                        const nextStepReference = {
                            step: curStep,
                            key: '',
                            data: [],
                            dataIndex: 0,
                            // dataIndex: dataIndex,
                            dataTotalLen: 0,
                            field: fields[curStep],
                            type: types[curStep],
                        }
                        const next = produce(tempStepReference.current, (draft) => {
                            draft.curStep = curStep
                            const existIdx = draft.steps.findIndex((step) => step.step === curStep)
                            if (existIdx !== -1) {
                                draft.steps[existIdx] = nextStepReference
                            } else {
                                draft.steps.push(nextStepReference)
                            }
                        })

                        tempStepReference.current = next
                        isValueChartOperating.current = false
                        // setStepReference(next)
                    })
                    return
                }

                const filters = makeFilters(curPage, stepFilter)
                // springApi.getCharListFilter(idForChar, filters).then((result) => {
                springApi.getCharListFilter(user, idForChar, filters).then((result) => {
                    if (!ApiStatus.isSuccess(result.status)) {
                        alert(getErrCommMsg(result.status))
                        return
                    }

                    console.log('<CharListFilter Load Success>')
                    const charAllData = result.data.flat()
                    const convertedCharDTO = dtoUtils.makeCharInfoToDTOFormat(charAllData, configSettings)
                    if (!DBUtils.checkCharValidation(convertedCharDTO)) {
                        throw new Error(`Invalid Char Data`)
                    }
                    const charTargetData = chartIDList.map((id) => {
                        const matchedData = convertedCharDTO.find((data) => data.part_id === id.part_id && data.char_id === id.char_id)
                        return matchedData || {} // Return the found object or an empty object if no match is found
                    })

                    const idsForValue = makeIdsForValueData(chartIDList)
                    const userFilters = parseContent(stepReference)
                    spring.getValueInfoScrollBoard(user, idsForValue, curPage.page, filters, userFilters).then((result) => {
                        if (!ApiStatus.isSuccess(result.status)) {
                            alert(getErrCommMsg(result.status))
                        }

                        const data = result.data
                        if (data.length !== 0) {
                            const isValid = DBUtils.checkValueValidation(data)
                            if (isValid) {
                                const filterData = dbFilterInfo.data
                                const valueDTO = dtoUtils.makeValueInfoToDTOFormat(data)
                                const arrangeOpt = filterData.others.arrangement
                                const latestOpt = filterData.others.ignoreDuplicatedValue
                                const sortOpt = filterData.common.dateTimeSort

                                charAllData.forEach((id) => valueDTO.find((char) => char.part_id === id.part_id && char.char_id === id.char_id))

                                const partIds = charAllData.map((item) => item.part_id)
                                const uniquePartIds = new Set(partIds)
                                const uniquePartObjects = Array.from(uniquePartIds).map((part_id) => ({
                                    part_id,
                                }))

                                const arrangedData = makeArrangedData(arrangeOpt, latestOpt, sortOpt, uniquePartObjects, charTargetData, valueDTO)
                                //
                                const sortedData = arrangedData.sort((a, b) => {
                                    if (a.part_id === b.part_id) {
                                        return a.char_id - b.char_id
                                    }
                                    return a.part_id - b.part_id
                                }) //
                                batch(() => {
                                    const valueChartData = {
                                        char: charTargetData,
                                        // value: arrangedData,
                                        value: sortedData,
                                    }

                                    const nextStepReference = {
                                        step: curStep,
                                        // key: refKey,
                                        key: '',
                                        data: valueChartData,
                                        dataIndex: 0,
                                        dataTotalLen: valueChartData.char.length,
                                        field: fields[curStep],
                                        type: types[curStep],
                                    }
                                    const next = produce(tempStepReference.current, (draft) => {
                                        draft.curStep = curStep
                                        const existIdx = draft.steps.findIndex((step) => step.step === curStep)
                                        if (existIdx !== -1) {
                                            draft.steps[existIdx] = nextStepReference
                                        } else {
                                            draft.steps.push(nextStepReference)
                                        }
                                    })

                                    tempStepReference.current = next
                                    // setStepReference(next)
                                })
                            }
                        } else {
                            throw new Error(dqTranslateMsg('CommunicationErrorMsg_' + `E${result.status.toString().padStart(4, '0')}`))
                        }
                    })
                })
            }
            return
        }

        const result = await spring.getIBoardResultData({
            user_id: userId,
            field_data: curStep,
            key_data: curStep === 0 ? undefined : curKey, // 조건부 속성 추가
        })
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data

        const curStepData = tempStepReference.current.steps.find((item) => item.step === curStep)

        // data 처리
        // char visible
        let filteredData
        if (pageField === '2000') {
            const isBadVisible = sbPageSettings.page.chartBadVisible
            const isWarnVisible = sbPageSettings.page.chartWarningVisible
            const isGoodVisible = sbPageSettings.page.chartGoodVisible

            filteredData = data.filter((item) => {
                const badVisibleCondition = isBadVisible ? item.result_ng_count > 0 : false
                const warnVisibleCondition = isWarnVisible ? item.result_warning_count > 0 : false
                const goodVisibleCondition = isGoodVisible ? item.result_good_count > 0 : false

                return badVisibleCondition || warnVisibleCondition || goodVisibleCondition
            })
        } else {
            filteredData = data
        }

        const chartOrder = sbPageSettings.page.barContentOrder
        if (chartOrder === 'none') {
            filteredData = filteredData
        } else if (chartOrder === 'asc') {
            filteredData.sort((a, b) => {
                return a.result_id.localeCompare(b.result_id)
            })
        } else {
            // desc
            filteredData.sort((a, b) => {
                return b.result_id.localeCompare(a.result_id)
            })
        }

        let updated
        if (curStepData) {
            updated = produce(curStepData, (draft) => {
                draft.key = curKey
                draft.data = filteredData
                draft.dataTotalLen = filteredData.length
                draft.field = tempStepReference.current.fields[curStep]
                draft.type = tempStepReference.current.types[curStep]
            })
        } else {
            updated = {
                step: curStep,
                key: curKey,
                data: filteredData,
                dataIndex: 0,
                dataTotalLen: filteredData.length,
                field: tempStepReference.current.fields[curStep],
                type: tempStepReference.current.types[curStep],
            }
        }

        const next = produce(tempStepReference.current, (draft) => {
            const existIdx = draft.steps.findIndex((item) => item.step === curStep)
            if (existIdx !== -1) {
                draft.steps[existIdx] = updated
            } else {
                draft.steps.push(updated)
            }
        })

        tempStepReference.current = next
    }

    const handleStepperBackMode = () => {
        const backStep = stepReference.curStep - 1
        goBackPage(backStep, stepReference)
    }
    const handleStepperNextMode = () => {
        const nextStep = stepReference.curStep + 1
        goNextPage(nextStep, stepReference)
    }

    const handleNextItemClicked = (nextStep, targetId, partId, charId) => {
        const nextPageType = stepReference.types[nextStep]
        goMovePageManual(stepReference, nextStep, nextPageType, targetId, fields[nextStep], partId, charId)
    }
    const handleHomePage = () => {
        goHomePage(fields[0])
    }

    const goBackPage = async (backStep, stepReference) => {
        const preStep = stepReference.steps.find((item) => item.step === backStep)

        const result = await spring.getIBoardResultData({
            user_id: user.user_id,
            field_data: backStep,
            key_data: preStep.key,
        })
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        batch(() => {
            const next = produce(stepReference, (draft) => {
                draft.curStep -= 1
            })

            setStepReference(next)
        })
    }

    const goNextPage = async (nextStep, stepReference) => {
        const preStep = stepReference.steps.find((item) => item.step === nextStep)

        const result = await spring.getIBoardResultData({
            user_id: user.user_id,
            field_data: nextStep,
            key_data: preStep.key,
        })

        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        batch(() => {
            const next = produce(stepReference, (draft) => {
                draft.curStep += 1
            })

            setStepReference(next)
        })
    }

    const handleModeChanged = async (changedMode) => {
        batch(() => {
            setStepReference(storedStepReference)
            setMode(changedMode)
        })
    }

    const handleCurrentControlFunction = async (e, buttonState) => {
        try {
            if (buttonState) {
                // await getFirstStepResultData(0)
                await goHomePage(fields[0])
                autoGraphicUpdateEpochTime.current = Date.now()
                setIsPlaying(true)
            } else {
                if (timerRef.current) {
                    clearInterval(timerRef.current) // Clear the timer when buttonState is false
                }
                if (timerRefValueChart.current) {
                    clearInterval(timerRefValueChart.current) // Clear the timer when buttonState is false
                }

                batch(() => {
                    setDisableSidePanel(false)
                    setIsPlaying(false)
                })
            }
        } catch (error) {
            alert(error)
        }
    }

    const goHomePage = async (pageField) => {
        const firstStep = 0

        // Value Chart 이외의 경우
        const result = await spring.getIBoardResultData({
            user_id: user.user_id,
            field_data: firstStep,
        })

        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data

        // data 처리
        // char visible
        let filteredData
        if (pageField === '2000') {
            const isBadVisible = curPage.page.chartBadVisible
            const isWarnVisible = curPage.page.chartWarningVisible
            const isGoodVisible = curPage.page.chartGoodVisible

            filteredData = data.filter((item) => {
                const badVisibleCondition = isBadVisible ? item.result_ng_count > 0 : false
                const warnVisibleCondition = isWarnVisible ? item.result_warning_count > 0 : false
                const goodVisibleCondition = isGoodVisible ? item.result_good_count > 0 : false

                return badVisibleCondition || warnVisibleCondition || goodVisibleCondition
            })
        } else {
            filteredData = data
        }

        const chartOrder = sbPageSettings.page.barContentOrder
        if (chartOrder === 'none') {
            filteredData = filteredData
        } else if (chartOrder === 'asc') {
            filteredData.sort((a, b) => {
                return a.result_id.localeCompare(b.result_id)
            })
        } else {
            // desc
            filteredData.sort((a, b) => {
                return b.result_id.localeCompare(a.result_id)
            })
        }

        batch(() => {
            const newStepReference = {
                step: firstStep,
                key: null,
                data: filteredData,
                dataIndex: 0,
                dataTotalLen: filteredData.length,
                field: fields[0],
                type: types[0],
            }

            const nextStep = produce(stepReferenceInit, (draft) => {
                draft.curStep = firstStep
                draft.totalSteps = fields.length
                draft.fields = fields
                draft.types = types
                draft.steps.push(newStepReference)
            })
            tempStepReference.current = nextStep
            setStepReference(nextStep)
        })
    }

    const goMovePageManual = async (stepReference, step, pageType, targetId, pageField, partId, charId) => {
        const findKey = (preStep, targetId) => {
            const preStepItem = stepReference.steps.find((item) => item.step === preStep)

            let preTargetKey = ''
            if (preStepItem.field === '2000') {
                const targetItem = preStepItem.data.find((item) => {
                    const { id, part_id, char_id } = parseString(item.result_id)
                    if (id === targetId && part_id === partId && char_id === charId) {
                        return item
                    }
                })

                if (targetItem) {
                    preTargetKey = targetItem.result_key
                }
            } else {
                preTargetKey = preStepItem.data.find((item) => item.result_id === targetId).result_key
            }

            return preTargetKey
        }

        const moveStep = step
        const moveKey = findKey(moveStep - 1, targetId)
        const movePageType = pageType

        if (movePageType === 'ValueChart') {
            let chartIDList = []
            const chartIds = extractIds(moveKey)
            chartIDList.push(chartIds)

            if (chartIds) {
                const stepFilter = []
                const idForChar = makeIdsForCharData(chartIDList)
                const filters = makeFilters(curPage, stepFilter)

                spring.getCharListFilter(user, idForChar, filters).then((result) => {
                    if (!ApiStatus.isSuccess(result.status)) {
                        alert(getErrCommMsg(result.status))
                    }

                    console.log('<CharListFilter Load Success>')
                    const charAllData = result.data.flat()
                    const convertedCharDTO = dtoUtils.makeCharInfoToDTOFormat(charAllData, configSettings)
                    if (!DBUtils.checkCharValidation(convertedCharDTO)) {
                        throw new Error(`Invalid Char Data`)
                    }
                    const charTargetData = chartIDList.map((id) => {
                        const matchedData = convertedCharDTO.find((data) => data.part_id === id.part_id && data.char_id === id.char_id)
                        return matchedData || {} // Return the found object or an empty object if no match is found
                    })

                    const idsForValue = makeIdsForValueData(chartIDList)
                    const userFilters = parseContent(stepReference)
                    spring.getValueInfoScrollBoard(user, idsForValue, curPage.page, filters, userFilters).then((result) => {
                        if (!ApiStatus.isSuccess(result.status)) {
                            alert(getErrCommMsg(result.status))
                        }

                        const data = result.data
                        if (data.length !== 0) {
                            const isValid = DBUtils.checkValueValidation(data)
                            if (isValid) {
                                const filterData = dbFilterInfo.data
                                const valueDTO = dtoUtils.makeValueInfoToDTOFormat(data)
                                const arrangeOpt = filterData.others.arrangement
                                const latestOpt = filterData.others.ignoreDuplicatedValue
                                const sortOpt = filterData.common.dateTimeSort

                                charAllData.forEach((id) => valueDTO.find((char) => char.part_id === id.part_id && char.char_id === id.char_id))

                                const partIds = charAllData.map((item) => item.part_id)
                                const uniquePartIds = new Set(partIds)
                                const uniquePartObjects = Array.from(uniquePartIds).map((part_id) => ({
                                    part_id,
                                }))

                                const arrangedData = makeArrangedData(arrangeOpt, latestOpt, sortOpt, uniquePartObjects, charTargetData, valueDTO)

                                batch(() => {
                                    const valueChartData = {
                                        char: charTargetData,
                                        value: arrangedData,
                                    }

                                    const nextStepReference = {
                                        step: moveStep,
                                        key: moveKey,
                                        data: valueChartData,
                                        dataIndex: 0,
                                        dataTotalLen: valueChartData.char.length,
                                        field: fields[moveStep],
                                        type: types[moveStep],
                                    }
                                    const next = produce(stepReference, (draft) => {
                                        draft.curStep = moveStep
                                        const existIdx = draft.steps.findIndex((step) => step.step === moveStep)
                                        if (existIdx !== -1) {
                                            draft.steps[existIdx] = nextStepReference
                                        } else {
                                            draft.steps.push(nextStepReference)
                                        }
                                    })

                                    setStepReference(next)
                                })
                            }
                        }
                    })
                })
            }
            return
        }

        if (moveStep === 0) {
            goHomePage(fields[0])
        } else {
            const result = await spring.getIBoardResultData({
                user_id: user.user_id,
                field_data: moveStep,
                key_data: moveKey, // 조건부 속성 추가
            })

            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
            }

            const data = result.data

            // data 처리
            // char visible
            let filteredData
            if (pageField === '2000') {
                const isBadVisible = sbPageSettings.page.chartBadVisible
                const isWarnVisible = sbPageSettings.page.chartWarningVisible
                const isGoodVisible = sbPageSettings.page.chartGoodVisible

                filteredData = data.filter((item) => {
                    const badVisibleCondition = isBadVisible ? item.result_ng_count > 0 : false
                    const warnVisibleCondition = isWarnVisible ? item.result_warning_count > 0 : false
                    const goodVisibleCondition = isGoodVisible ? item.result_good_count > 0 : false

                    return badVisibleCondition || warnVisibleCondition || goodVisibleCondition
                })
            } else {
                filteredData = data
            }

            const chartOrder = sbPageSettings.page.barContentOrder
            if (chartOrder === 'none') {
                filteredData = filteredData
            } else if (chartOrder === 'asc') {
                filteredData.sort((a, b) => {
                    return a.result_id.localeCompare(b.result_id)
                })
            } else {
                // desc
                filteredData.sort((a, b) => {
                    return b.result_id.localeCompare(a.result_id)
                })
            }

            const nextStepReference = {
                step: moveStep,
                key: moveKey,
                data: filteredData,
                dataIndex: 0,
                dataTotalLen: filteredData.length,
                field: fields[moveStep],
                type: types[moveStep],
            }
            const next = produce(stepReference, (draft) => {
                draft.curStep = moveStep
                const existIdx = draft.steps.findIndex((step) => step.step === moveStep)
                if (existIdx !== -1) {
                    draft.steps[existIdx] = nextStepReference
                } else {
                    draft.steps.push(nextStepReference)
                }
            })

            setStepReference(next)
        }
    }

    const renderPageComponent = (stepReference) => {
        if (stepReference.steps.length > 0) {
            const curStep = stepReference.curStep
            const tgStep = stepReference.steps.find((item) => item.step === curStep)
            const pageType = stepReference.types[curStep]
            const pageData = tgStep.data

            switch (pageType) {
                case 'BarChart':
                    return (
                        <ScrollBoardBarChart
                            id={`bd-component-${stepReference.curStep}`}
                            style={{ width: '100%', height: '100%', padding: '5px' }}
                            mode={mode}
                            stepReference={stepReference}
                            // setStepReference={setStepReference}
                            handleNextItemClicked={handleNextItemClicked}
                        />
                    )
                case 'Diagram':
                    return (
                        <ScrollBoardDiagram
                            id={`bd-component-${stepReference.curStep}`}
                            style={{ width: '100%', height: '100%' }}
                            page={currentStep}
                            data={pageData}
                            pageLength={pageLength}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            handleNextItemClicked={handleNextItemClicked}
                            mode={mode}
                        />
                    )
                case 'TreeMap':
                    return (
                        <ScrollBoardTreeMap
                            id={`bd-component-${stepReference.curStep}`}
                            style={{ width: '100%', height: '100%' }}
                            mode={mode}
                            stepReference={stepReference}
                            handleNextItemClicked={handleNextItemClicked}
                        />
                    )
                case 'Pie':
                    return (
                        <ScrollBoardPieChart
                            id={`bd-component-${stepReference.curStep}`}
                            style={{ width: '100%', height: '100%' }}
                            mode={mode}
                            page={stepReference}
                            data={pageData}
                            pageLength={pageLength}
                            currentStep={stepReference}
                            setCurrentStep={setCurrentStep}
                            handleNextItemClicked={handleNextItemClicked}
                        />
                    )
                case 'ValueChart':
                    if (mode === 'manual' && popupMode) {
                        const preStepReference = { ...stepReference, curStep: stepReference.curStep - 1 }
                        const pageType = preStepReference.types[preStepReference.curStep]

                        if (pageType === 'BarChart') {
                            return (
                                <>
                                    <ScrollBoardBarChart
                                        id={`bd-component-${preStepReference.curStep}`}
                                        style={{ width: '100%', height: '100%', padding: '5px' }}
                                        mode={mode}
                                        stepReference={preStepReference}
                                        handleNextItemClicked={handleNextItemClicked}
                                    />
                                    <ScrollBoardValueChartContainer
                                        id={`bd-component-${stepReference.curStep}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            overflowY: 'auto',
                                        }}
                                        mode={mode}
                                        isPlaying={isPlaying}
                                        isValueChartOperating={isValueChartOperating}
                                        stepReference={stepReference}
                                        setStepReference={setStepReference}
                                        handleStepperBackMode={handleStepperBackMode}
                                        sbPageSettings={sbPageSettings}
                                        configSettings={configSettings}
                                        othersPage={othersPage}
                                        timerRefValueChart={timerRefValueChart}
                                    />
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <ScrollBoardTreeMap
                                        id={`bd-component-${preStepReference.curStep}`}
                                        style={{ width: '100%', height: '100%' }}
                                        mode={mode}
                                        stepReference={preStepReference}
                                        handleNextItemClicked={handleNextItemClicked}
                                    />
                                    <ScrollBoardValueChartContainer
                                        id={`bd-component-${stepReference.curStep}`}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            overflowY: 'auto',
                                        }}
                                        mode={mode}
                                        isPlaying={isPlaying}
                                        isValueChartOperating={isValueChartOperating}
                                        stepReference={stepReference}
                                        setStepReference={setStepReference}
                                        handleStepperBackMode={handleStepperBackMode}
                                        sbPageSettings={sbPageSettings}
                                        configSettings={configSettings}
                                        othersPage={othersPage}
                                        timerRefValueChart={timerRefValueChart}
                                    />
                                </>
                            )
                        }
                    } else {
                        return (
                            <ScrollBoardValueChartContainer
                                id={`bd-component-${stepReference.curStep}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    overflowY: 'auto',
                                }}
                                mode={mode}
                                isPlaying={isPlaying}
                                isValueChartOperating={isValueChartOperating}
                                stepReference={stepReference}
                                setStepReference={setStepReference}
                                handleStepperBackMode={handleStepperBackMode}
                                sbPageSettings={sbPageSettings}
                                configSettings={configSettings}
                                othersPage={othersPage}
                                timerRefValueChart={timerRefValueChart}
                            />
                        )
                    }

                default:
                    return <div>Invalid pageType</div>
            }
        } else {
        }
    }

    return (
        <div className='scroll-board-display-component-container'>
            <DQLoadPanel
                open={isGraphicLoading}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />
            <div style={{ width: '100%', height: '10%', padding: '5px' }}>
                <StepperComponent
                    mode={mode}
                    reducerKey={'scrollBoard'}
                    stepReference={stepReference}
                    handleHomePage={handleHomePage}
                    handleStepperBackMode={handleStepperBackMode}
                    handleStepperNextMode={handleStepperNextMode}
                />
            </div>
            <div style={{ width: '100%', height: '81%', padding: '5px' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ width: '100%', height: '3%', paddingBottom: '3px' }}>
                        <div style={{ width: '100%', height: '100%', textAlign: 'center', borderRadius: '10px', backgroundColor: '#CBCBCB', color: '#000' }}>{`${
                            mode === 'manual' ? dqTranslateMsg('Page_ScrollBoard_' + 'ManualMode') : dqTranslateMsg('Page_ScrollBoard_' + 'PresentationMode')
                        } (${isPlaying ? dqTranslateMsg('Page_ScrollBoard_' + 'Operating') : dqTranslateMsg('Page_ScrollBoard_' + 'Stopped')})`}</div>
                    </div>
                    <div style={{ width: '100%', height: '95%' }}>
                        <StyledCenterImagePage theme={{ borderColor: borderColor }}>{renderPageComponent(stepReference)}</StyledCenterImagePage>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '7%', padding: '5px' }}>
                <div style={{ display: 'flex', width: '100%', height: '100%', gap: '5px' }}>
                    <StyledBottomImageNavs
                        theme={{ borderColor: borderColor }}
                        data={{
                            pageNo: stepReference.curStep + 1,
                            imageCount: pageLength,
                            imageTypes: pageTypes,
                        }}
                        configPage={common.pages.configuration}
                    />
                    <StyledBottomModeSelector
                        items={items()}
                        value={mode}
                        setValue={setMode}
                        handleModeChanged={handleModeChanged}
                        disabled={!curPage.page.orderChartListOrderValid || (mode === 'presentation' && isPlaying)}
                    />
                    <StyledBottomControlPanel
                        theme={{ borderColor: borderColor }}
                        configPage={common.pages.configuration}
                        icon={{
                            before: '/images/color/start-green.svg',
                            after: '/images/color/stop-red.svg',
                        }}
                        // text={'Auto Slide'}
                        isPlaying={isPlaying}
                        disabled={!curPage.page.orderChartListOrderValid}
                        setDisableSidePanel={setDisableSidePanel}
                        onClick={handleCurrentControlFunction}
                    />
                    <StyledBottomControlPanelOneClick
                        id={user.user_id}
                        theme={{ borderColor: borderColor }}
                        curPage={curPage}
                        configPage={common.pages.configuration}
                        icon={'refresh'}
                        // text={'Manual Update'}
                        stepReferenceInit={stepReferenceInit}
                        tempStepReference={tempStepReference}
                        setStepReference={setStepReference}
                        pageLength={pageLength}
                        pageTypes={pageTypes}
                        disabled={!curPage.page.orderChartListOrderValid}
                        handleHomePage={handleHomePage}
                    />
                </div>
            </div>
            <div style={{ width: '100%', height: '2%' }}>
                <ScrollBoardTimeDisplay />
            </div>
        </div>
    )
}
