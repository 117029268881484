import React, { useEffect } from 'react'
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import TextBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItemNew'
import { useState } from 'react'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { useApi } from 'src/contexts/api'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useAuth } from 'src/contexts/auth'
import * as rd from 'src/store/common'
import { updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'

export default function SettingsTemplateSave(props) {
    const {
        dispatch,
        pageKey,
        pageState,
        othersPage,
        selectedTemplate,
        setSelectedTemplate,
        setIsOpenCreateTemplate,
    } = props
    const { spring } = useApi()
    const { user } = useAuth()
    const [textFileName, setTextFileName] = useState('')

    const handleTextValueChanged = (e) => {
        if (e.event === undefined) return
        console.log('Filter name changed', e.value)
        setTextFileName(e.value)
    }

    const handleSaveClicked = (e) => {
        const isExist = othersPage.reportTemplates.find(
            (template) => template.name === textFileName
        )
        if (isExist) {
            alert('File exist already')
            return
        }

        const next = produce(selectedTemplate, (draft) => {
            draft.name = textFileName
        })

        setSelectedTemplate(next)

        // const nextOthers = produce(othersPage.reportTemplates, (draft) => {
        //     draft.push(next)
        // })
        const nextOthers = produce(othersPage, (draft) => {
            draft.reportTemplates.push(next)
        })
        dispatch(rd.setReportTemplates(nextOthers.reportTemplates))

        const company = user.user_company
        const plant = user.user_plant
        const dataJsonStr = JSON.stringify(nextOthers.reportTemplates)

        const updateData = {
            [companyPlantTbDescription.reportTemplates]: dataJsonStr,
        }

        updateAdminCompany(
            spring,
            makeGetAdminCompanyPrms(company, plant, updateData)
        )
        setIsOpenCreateTemplate(false)
    }

    const handleCloseClicked = (e) => {
        setIsOpenCreateTemplate(false)
    }

    useEffect(() => {
        const templateName = selectedTemplate.name
        setTextFileName(templateName)
    }, [])

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`settings-template-save-group-item`}
                colCount={2}
                caption={getCommonMsg(msgType.SidePanel, 'SaveInformation')}
            >
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <TextBoxSimpleItemNew
                            label={getCommonMsg(msgType.SidePanel, 'FileName')}
                            labelLocation='top'
                            name='fileName'
                            value={textFileName}
                            onValueChanged={handleTextValueChanged}
                        />
                    )}
                />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            type='normal'
                            text={getCommonMsg(msgType.SidePanel, 'Save')}
                            icon='check'
                            onClick={handleSaveClicked}
                        />
                    )}
                />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            type='normal'
                            text={getCommonMsg(msgType.SidePanel, 'Close')}
                            icon='close'
                            onClick={handleCloseClicked}
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}
