import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { indStyles } from '../define/IndividualReportStyle'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import IconLoader from 'src/functions/IconLoader'

export const contentSummaryList = (data, slPage, configPage, theme) => {
    // 기본 로컬 SVG 파일 경로
    const getPngPath = (iconStyle, iconType, theme, state) => {
        const iconId = IconLoader.TYPES_DEF.find(
            (type) => type.path === iconType
        ).id
        const stateId = IconLoader.STATE_DEF.find(
            (type) => type.path === state
        ).id

        const themeId = 0

        const path = IconLoader.getPngImgPath(
            iconStyle,
            iconId,
            themeId,
            stateId
        )
        return path
    }

    const getResultState = (value, slPage, configPage, theme) => {
        const iconStyle = configPage.menuIconType
        const iconType = slPage.theme.iconType.result

        if (value === 1) {
            const src = getPngPath(iconStyle, iconType, theme, 'good')
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={src}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        } else if (value === 0) {
            const src = getPngPath(iconStyle, iconType, theme, 'bad')
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={src}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        } else {
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={'/images/color/warning-exclamation.png'}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        }
    }
    const getOutOfTolState = (value, slPage, configPage, theme) => {
        const iconStyle = configPage.menuIconType
        const iconType = slPage.theme.iconType.outOfTolerances

        if (value === 1) {
            const src = getPngPath(iconStyle, iconType, theme, 'good')
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={src}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        } else if (value === 0) {
            const src = getPngPath(iconStyle, iconType, theme, 'bad')
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={src}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        } else {
            return (
                <View style={indStyles.textView}>
                    <Image
                        src={'/images/color/warning-exclamation.png'}
                        style={indStyles.listIcon}
                    />
                </View>
            )
        }
    }

    return (
        <>
            <View style={indStyles.listHeader}>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(
                            msgType.ReportPrintMultiFunction,
                            'Average'
                        )}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(
                            msgType.ReportPrintMultiFunction,
                            'StandardDeviation'
                        )}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(
                            msgType.ReportPrintMultiFunction,
                            'Minimum'
                        )}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(
                            msgType.ReportPrintMultiFunction,
                            'Maximum'
                        )}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(msgType.ReportPrintMultiFunction, 'Cp')}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(msgType.ReportPrintMultiFunction, 'Cpk')}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(msgType.ReportPrintMultiFunction, 'Result')}
                    </Text>
                </View>
                <View style={indStyles.textHeaderView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {getPageMsg(
                            msgType.ReportPrintMultiFunction,
                            'OutOfTol'
                        )}
                    </Text>
                </View>
            </View>
            <View style={indStyles.listValue}>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.average}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.stdev}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.minimum}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.maximum}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.cp}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {data.cpk}
                    </Text>
                </View>
                {getResultState(data.result, slPage, configPage, theme)}
                {getOutOfTolState(data.outOfTol, slPage, configPage, theme)}
            </View>
        </>
    )
}
