import { GroupItem, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import { ScrollView } from 'devextreme-react'
import AdvancedCommonOption from './advanced/AdvancedCommonOption'
import AdvancedDateTimeOption from './advanced/AdvancedDateTimeOption'
import AdvancedLastNFilterOption from './advanced/AdvancedLastNFilterOption'
import AdvancedETCOption from './advanced/AdvancedETCOption'
import { Form } from 'devextreme-react'
import AdvancedRealTimeFilter from './advanced/AdvancedRealTimeFilter'
import AdvancedSaveFilter from './advanced/AdvancedSaveFilter'
import { useAuth } from 'src/contexts/auth'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'

const DBFieldAdvancedCreatePopupFilter = ({
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleCreateFilterPopup,
    setIsVisibleCreateFilterPopup,
    popupCreateFilter,
    setPopupCreateFilter,
}) => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const textBoxRef = useRef(null)

    const closePopup = () => {
        setIsVisibleCreateFilterPopup(false)
        setPopupCreateFilter(null)
    }

    const title = 'Create Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-advanced-create-popup-filter-group-item`}
                colCount={2}
                caption={''}
            >
                {/* {PopupSimpleItem({
                visible: isVisibleCreateFilterPopup,
                onHiding: closePopup,
                title,
                width,
                height,
                contentRender: renderFilterContent,
            })} */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <PopupSimpleItem
                            visible={isVisibleCreateFilterPopup}
                            onHiding={closePopup}
                            title={title}
                            width={width}
                            height={height}
                            // contentRender={renderFilterContent}
                            // contentRender={() => <div>234</div>}
                        >
                            <AdvancedCommonOption
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                            />
                            <AdvancedDateTimeOption
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                            />
                            <AdvancedLastNFilterOption
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                            />
                            <AdvancedETCOption
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                            />
                            <AdvancedRealTimeFilter
                                dispatch={dispatch}
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                                pageKey={pageKey}
                                pageState={pageState}
                                configState={configState}
                                othersPage={othersPage}
                            />
                            <AdvancedSaveFilter
                                dispatch={dispatch}
                                currentFilter={popupCreateFilter}
                                setCurrentFilter={setPopupCreateFilter}
                                setIsVisibleCreateFilterPopup={
                                    setIsVisibleCreateFilterPopup
                                }
                                textBoxRef={textBoxRef}
                                pageKey={pageKey}
                                pageState={pageState}
                                configState={configState}
                                othersPage={othersPage}
                                user={user}
                            />
                        </PopupSimpleItem>
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default DBFieldAdvancedCreatePopupFilter
