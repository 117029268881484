import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import { ScrollView } from 'devextreme-react'
import AdvancedCommonOption from './advanced/AdvancedCommonOption'
import AdvancedDateTimeOption from './advanced/AdvancedDateTimeOption'
import AdvancedLastNFilterOption from './advanced/AdvancedLastNFilterOption'
import AdvancedETCOption from './advanced/AdvancedETCOption'
import { Form } from 'devextreme-react'
import AdvancedRealTimeFilter from './advanced/AdvancedRealTimeFilter'
import AdvancedModifyButton from './advanced/AdvancedModifyButton'
import { useAuth } from 'src/contexts/auth'
import AdvancedSaveFilter from './advanced/AdvancedSaveFilter'

const DBFieldAdvancedPopupFilter = ({
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleFilterPopup,
    setIsVisibleFilterPopup,
    popupDisplayFilter,
    setPopupDisplayFilter,
}) => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const textBoxRef = useRef(null)

    const closePopup = () => {
        setIsVisibleFilterPopup(false)
        setPopupDisplayFilter(null)
    }

    const title = 'Display Selected Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-advanced-popup-filter-group-item`}
                colCount={2}
                caption={''}
            >
                <PopupSimpleItem
                    visible={isVisibleFilterPopup}
                    onHiding={closePopup}
                    title={title}
                    width={width}
                    height={height}
                >
                    <AdvancedCommonOption
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                    />
                    <AdvancedDateTimeOption
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                    />
                    <AdvancedLastNFilterOption
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                    />
                    <AdvancedETCOption
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                    />
                    <AdvancedRealTimeFilter
                        dispatch={dispatch}
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                        pageKey={pageKey}
                        pageState={pageState}
                        configState={configState}
                        othersPage={othersPage}
                    />
                    <AdvancedModifyButton
                        dispatch={dispatch}
                        currentFilter={popupDisplayFilter}
                        setCurrentFilter={setPopupDisplayFilter}
                        setIsVisibleFilterPopup={setIsVisibleFilterPopup}
                        textBoxRef={textBoxRef}
                        pageKey={pageKey}
                        pageState={pageState}
                        configState={configState}
                        othersPage={othersPage}
                        user={user}
                    />
                </PopupSimpleItem>
            </GroupItem>
        </Form>
    )
}

export default DBFieldAdvancedPopupFilter
