import { Document, Image, Page, View } from '@react-pdf/renderer'
import { defineResultItems } from '../../side-panel/components/define/DefineEllementInfo'
import { filterElementFieldList } from '../../side-panel/components/define/DefineTagListIndividual'
import { getPageStyleByLang, indStyles } from '../define/IndividualReportStyle'
import { contentFields } from './ReportFieldComponent'
import { contentSummaryList } from './ReportListComponent'
import { contentBriefInfo } from './ReportResultConponent'
import {
    contentTitleDesc,
    contentTitleDescCollective,
} from './ReportTitleComponent'
import { contentCollectiveSummaryList } from './ReportCollectiveSummaryList'

const PdfContent = ({
    valuesArranged,
    template,
    chartImages,
    totalPageIndex,
    language,
    theme,
    slPage,
    configPage,
    othersPage,
}) => {
    if (
        template === null ||
        !template.elements ||
        template.elements.length === 0
    ) {
        // 예외 처리: 템플릿이 없거나, 요소가 없는 경우
        return (
            <Document>
                <Page
                    wrap={false}
                    size={template.page.size}
                    // style={indStyles.page}
                    style={getPageStyleByLang(language)}
                    orientation={template.page.orientation}
                />
            </Document>
        )
    }

    const elements = template.elements
    const elementRowHeightUnit = 2 // 2% 최소
    const briefInfoItems = defineResultItems
    let pageIndex = 0

    // 각 콘텐츠에 대해 처리하는 함수
    const renderContent = (
        contentElement,
        tgContent,
        part_id,
        char_id,
        pageIndex,
        othersPage,
        partRaw,
        charRaw
    ) => {
        const curContent = tgContent.find(
            (item) =>
                item.part_id === part_id &&
                item.char_id === char_id &&
                item.type === contentElement.value
        )

        if (!curContent) {
            return null
        }

        const curContentValue = curContent.value

        switch (contentElement.value) {
            case 'title-desc':
                return contentTitleDesc(
                    curContentValue,
                    pageIndex,
                    totalPageIndex,
                    othersPage
                )
            case 'summary-list':
                return contentSummaryList(
                    curContentValue,
                    slPage,
                    configPage,
                    theme
                )
            case 'brief-info':
                return contentBriefInfo(
                    briefInfoItems,
                    curContentValue,
                    slPage,
                    configPage
                )
            case 'value-chart':
            case 'histogram-chart':
            case 'boxplot-chart':
            case 'pareto-chart':
            case 'qcc-chart':
            case 'pt-chart':
                return (
                    <View style={indStyles.info}>
                        <Image
                            src={curContentValue}
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </View>
                )
            default:
                if (filterElementFieldList().includes(contentElement.value)) {
                    return contentFields(
                        contentElement.value,
                        curContentValue,
                        partRaw,
                        charRaw
                    )
                }
                return null
        }
    }

    // 각 콘텐츠에 대해 처리하는 함수
    const renderCollectiveContent = (
        contentElement,
        tgContent,
        columnCount,
        key,
        pageIndex,
        othersPage,
        valuesArranged
    ) => {
        switch (contentElement.value) {
            case 'title-desc':
                const titleContent = tgContent.find(
                    (item) => item.type === contentElement.value
                )

                if (!titleContent) {
                    return null
                }

                const curTitleContentValue = titleContent.value
                return contentTitleDescCollective(
                    curTitleContentValue,
                    columnCount,
                    key,
                    pageIndex,
                    totalPageIndex,
                    othersPage
                )
            case 'summary-list-collective':
                return contentCollectiveSummaryList(
                    contentElement.content ? contentElement.content : [],
                    'summary-list-collective',
                    tgContent,
                    slPage,
                    configPage,
                    theme,
                    valuesArranged
                )
            default:
                return null
        }
    }

    const PdfIndividualContent = () => {
        return valuesArranged.map((values) => {
            const { part_id, char_id, partRaw, charRaw } = values

            if (elements.length === 0 || chartImages.content.length === 0) {
                return (
                    <Page
                        size={template.page.size}
                        // style={indStyles.page}
                        style={getPageStyleByLang(language)}
                        orientation={template.page.orientation}
                    />
                )
            }

            pageIndex++

            return (
                <Page
                    size={template.page.size}
                    // style={indStyles.page}
                    style={getPageStyleByLang(language)}
                    orientation={template.page.orientation}
                >
                    {elements.map((element) => {
                        const columnContent = element.columnContent
                        const columnHeight =
                            elementRowHeightUnit * element.heightUnit
                        const columnCount = columnContent.length
                        const tgContent = chartImages.content
                        if (tgContent.length < 1) {
                            return null
                        }

                        return (
                            <View
                                key={element.index}
                                style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    height: `${columnHeight}%`,
                                }}
                            >
                                {columnContent.map((contentElement, idx) => (
                                    <View
                                        key={element.index + idx}
                                        style={{
                                            ...indStyles.section,
                                            width: `${100 / columnCount}%`,
                                        }}
                                    >
                                        {renderContent(
                                            contentElement,
                                            tgContent,
                                            part_id,
                                            char_id,
                                            pageIndex,
                                            othersPage,
                                            partRaw,
                                            charRaw
                                        )}
                                    </View>
                                ))}
                            </View>
                        )
                    })}
                </Page>
            )
        })
    }

    const PdfCollectiveContent = () => {
        if (
            elements.length === 0 ||
            !chartImages ||
            valuesArranged.length === 0
        ) {
            return null
        }

        pageIndex++

        return (
            <Page
                size={template.page.size}
                style={getPageStyleByLang(language)}
                orientation={template.page.orientation}
            >
                {elements.map((element) => {
                    const columnContent = element.columnContent
                    const columnHeight =
                        columnContent[0]?.value === 'summary-list-collective'
                            ? elementRowHeightUnit *
                                  element.heightUnit *
                                  valuesArranged.length +
                              1
                            : elementRowHeightUnit * element.heightUnit
                    const columnCount = columnContent.length
                    const tgContent = chartImages.content

                    if (tgContent.length < 1) {
                        return null
                    }

                    return (
                        <View
                            key={element.index}
                            style={{
                                flexDirection: 'row',
                                width: '100%',
                                height: `${columnHeight}%`,
                            }}
                            // debug
                        >
                            {columnContent.map(
                                (contentElement, idx) => (
                                    <View
                                        key={element.index + idx}
                                        style={{
                                            ...indStyles.section,
                                            width: `${100 / columnCount}%`,
                                            height: 'auto',
                                        }}
                                    >
                                        {renderCollectiveContent(
                                            contentElement,
                                            tgContent,
                                            element.index + idx,
                                            columnCount,
                                            pageIndex,
                                            othersPage,
                                            valuesArranged
                                        )}
                                    </View>
                                )
                                // renderCollectiveContent(
                                //     contentElement,
                                //     tgContent,
                                //     element.index + idx,
                                //     columnCount,
                                //     pageIndex,
                                //     othersPage,
                                //     valuesArranged
                                // )
                            )}
                        </View>
                    )
                })}
            </Page>
        )
    }

    return (
        <Document>
            {template.type === 'individual'
                ? PdfIndividualContent()
                : PdfCollectiveContent()}
        </Document>
    )
}
export default PdfContent
