export const reportElementTagListForIndividual = () => [
    { type: 'text', value: 'title-desc', display: 'Title' },
    { type: 'text', value: 'author-desc', display: 'Author' },
    { type: 'info', value: 'brief-info', display: 'Result' },
    { type: 'chart', value: 'pt-chart', display: 'Positional Tolerances' },
    { type: 'chart', value: 'value-chart', display: 'Value Chart' },
    { type: 'chart', value: 'histogram-chart', display: 'Histogram' },
    { type: 'chart', value: 'boxplot-chart', display: 'BoxPlot' },
    { type: 'chart', value: 'pareto-chart', display: 'Pareto' },
    { type: 'chart', value: 'qcc-chart', display: 'Quality Control Chart' },
    { type: 'list', value: 'summary-list', display: 'Summary List' },
    { type: 'field', value: 'p1001', display: 'P1001' },
    { type: 'field', value: 'p1002', display: 'P1002' },
    { type: 'field', value: 'p1003', display: 'P1003' },
    { type: 'field', value: 'p1005', display: 'P1005' },
    { type: 'field', value: 'p1007', display: 'P1007' },
    { type: 'field', value: 'p1008', display: 'P1008' },
    { type: 'field', value: 'p1009', display: 'P1009' },
    { type: 'field', value: 'p1011', display: 'P1011' },
    { type: 'field', value: 'p1012', display: 'P1012' },
    { type: 'field', value: 'p1013', display: 'P1013' },
    { type: 'field', value: 'p1014', display: 'P1014' },
    { type: 'field', value: 'p1015', display: 'P1015' },
    { type: 'field', value: 'p1022', display: 'P1022' },
    { type: 'field', value: 'p1032', display: 'P1032' },
    { type: 'field', value: 'p1046', display: 'P1046' },
    { type: 'field', value: 'p1052', display: 'P1052' },
    { type: 'field', value: 'p1053', display: 'P1053' },
    { type: 'field', value: 'p1062', display: 'P1062' },
    { type: 'field', value: 'p1072', display: 'P1072' },
    { type: 'field', value: 'p1081', display: 'P1081' },
    { type: 'field', value: 'p1082', display: 'P1082' },
    { type: 'field', value: 'p1085', display: 'P1085' },
    { type: 'field', value: 'p1086', display: 'P1086' },
    { type: 'field', value: 'p1087', display: 'P1087' },
    { type: 'field', value: 'p1091', display: 'P1091' },
    { type: 'field', value: 'p1092', display: 'P1092' },
    { type: 'field', value: 'p1100', display: 'P1100' },
    { type: 'field', value: 'p1101', display: 'P1101' },
    { type: 'field', value: 'p1102', display: 'P1102' },
    { type: 'field', value: 'p1103', display: 'P1103' },
    { type: 'field', value: 'p1104', display: 'P1104' },
    { type: 'field', value: 'p1203', display: 'P1203' },
    { type: 'field', value: 'p1206', display: 'P1206' },
    { type: 'field', value: 'p1303', display: 'P1303' },
    { type: 'field', value: 'p1802', display: 'P1802' },
    { type: 'field', value: 'p1812', display: 'P1812' },
    { type: 'field', value: 'p1822', display: 'P1822' },
    { type: 'field', value: 'p1832', display: 'P1832' },
    { type: 'field', value: 'p1842', display: 'P1842' },
    { type: 'field', value: 'p1852', display: 'P1852' },
    { type: 'field', value: 'p1862', display: 'P1862' },
    { type: 'field', value: 'p1872', display: 'P1872' },
    { type: 'field', value: 'p1882', display: 'P1882' },
    { type: 'field', value: 'p1892', display: 'P1892' },
    { type: 'field', value: 'p1900', display: 'P1900' },
    { type: 'field', value: 'c2001', display: 'C2001' },
    { type: 'field', value: 'c2002', display: 'C2002' },
    { type: 'field', value: 'c2003', display: 'C2003' },
    { type: 'field', value: 'c2004', display: 'C2004' },
    { type: 'field', value: 'c2005', display: 'C2005' },
    { type: 'field', value: 'c2006', display: 'C2006' },
    { type: 'field', value: 'c2007', display: 'C2007' },
    { type: 'field', value: 'c2008', display: 'C2008' },
    { type: 'field', value: 'c2009', display: 'C2009' },
    { type: 'field', value: 'c2011', display: 'C2011' },
    { type: 'field', value: 'c2015', display: 'C2015' },
    { type: 'field', value: 'c2016', display: 'C2016' },
    { type: 'field', value: 'c2021', display: 'C2021' },
    { type: 'field', value: 'c2022', display: 'C2022' },
    { type: 'field', value: 'c2035', display: 'C2035' },
    { type: 'field', value: 'c2041', display: 'C2041' },
    { type: 'field', value: 'c2042', display: 'C2042' },
    { type: 'field', value: 'c2043', display: 'C2043' },
    { type: 'field', value: 'c2051', display: 'C2051' },
    { type: 'field', value: 'c2091', display: 'C2091' },
    { type: 'field', value: 'c2092', display: 'C2092' },
    { type: 'field', value: 'c2093', display: 'C2093' },
    { type: 'field', value: 'c2100', display: 'C2100' },
    { type: 'field', value: 'c2101', display: 'C2101' },
    { type: 'field', value: 'c2110', display: 'C2110' },
    { type: 'field', value: 'c2111', display: 'C2111' },
    { type: 'field', value: 'c2112', display: 'C2112' },
    { type: 'field', value: 'c2113', display: 'C2113' },
    { type: 'field', value: 'c2114', display: 'C2114' },
    { type: 'field', value: 'c2115', display: 'C2115' },
    { type: 'field', value: 'c2116', display: 'C2116' },
    { type: 'field', value: 'c2117', display: 'C2117' },
    { type: 'field', value: 'c2120', display: 'C2120' },
    { type: 'field', value: 'c2121', display: 'C2121' },
    { type: 'field', value: 'c2130', display: 'C2130' },
    { type: 'field', value: 'c2131', display: 'C2131' },
    { type: 'field', value: 'c2142', display: 'C2142' },
    { type: 'field', value: 'c2161', display: 'C2161' },
    { type: 'field', value: 'c2162', display: 'C2162' },
    { type: 'field', value: 'c2163', display: 'C2163' },
    { type: 'field', value: 'c2202', display: 'C2202' },
    { type: 'field', value: 'c2205', display: 'C2205' },
    { type: 'field', value: 'c2213', display: 'C2213' },
    { type: 'field', value: 'c2220', display: 'C2220' },
    { type: 'field', value: 'c2221', display: 'C2221' },
    { type: 'field', value: 'c2222', display: 'C2222' },
    { type: 'field', value: 'c2225', display: 'C2225' },
    { type: 'field', value: 'c2226', display: 'C2226' },
    { type: 'field', value: 'c2227', display: 'C2227' },
    { type: 'field', value: 'c2243', display: 'C2243' },
    { type: 'field', value: 'c2244', display: 'C2244' },
    { type: 'field', value: 'c2245', display: 'C2245' },
    { type: 'field', value: 'c2246', display: 'C2246' },
    { type: 'field', value: 'c2302', display: 'C2302' },
    { type: 'field', value: 'c2402', display: 'C2402' },
    { type: 'field', value: 'c2410', display: 'C2410' },
    { type: 'field', value: 'c2413', display: 'C2413' },
    { type: 'field', value: 'c2415', display: 'C2415' },
    { type: 'field', value: 'c2802', display: 'C2802' },
    { type: 'field', value: 'c2812', display: 'C2812' },
    { type: 'field', value: 'c2822', display: 'C2822' },
    { type: 'field', value: 'c2832', display: 'C2832' },
    { type: 'field', value: 'c2842', display: 'C2842' },
    { type: 'field', value: 'c2852', display: 'C2852' },
    { type: 'field', value: 'c2862', display: 'C2862' },
    { type: 'field', value: 'c2872', display: 'C2872' },
    { type: 'field', value: 'c2882', display: 'C2882' },
    { type: 'field', value: 'c2892', display: 'C2892' },
    { type: 'field', value: 'c2900', display: 'C2900' },
    { type: 'field', value: 'c2999', display: 'C2999' },
    { type: 'field', value: 'c8006', display: 'C8006' },
    { type: 'field', value: 'c8007', display: 'C8007' },
    { type: 'field', value: 'c8011', display: 'C8011' },
    { type: 'field', value: 'c8012', display: 'C8012' },
    { type: 'field', value: 'c8013', display: 'C8013' },
    { type: 'field', value: 'c8014', display: 'C8014' },
    { type: 'field', value: 'c8015', display: 'C8015' },
    { type: 'field', value: 'c8106', display: 'C8106' },
    { type: 'field', value: 'c8107', display: 'C8107' },
    { type: 'field', value: 'c8111', display: 'C8111' },
    { type: 'field', value: 'c8112', display: 'C8112' },
    { type: 'field', value: 'c8113', display: 'C8113' },
    { type: 'field', value: 'c8114', display: 'C8114' },
    { type: 'field', value: 'c8115', display: 'C8115' },
    { type: 'field', value: 'c8500', display: 'C8500' },
    { type: 'field', value: 'c8501', display: 'C8501' },
    { type: 'field', value: 'c8531', display: 'C8531' },
    { type: 'field', value: 'c8532', display: 'C8532' },
    { type: 'field', value: 'c8540', display: 'C8540' },
    // Title을 제외한 나머지 display 키는 다국어모듈의 키로 사용됨
]

export const findElementTagTypeByContent = (content) => {
    const targetType = reportElementTagListForIndividual().find(
        (element) => element.content === content
    )
    return targetType
}

export const filterIndividualTags = (type) => {
    switch (type) {
        case 'all':
            return reportElementTagListForIndividual()
        case 'text':
        case 'chart':
        case 'list':
        case 'field':
            return reportElementTagListForIndividual().filter(
                (element) => element.type === type
            )
        default:
            console.error('not match element type')
            break
    }
}

export const filterElementFieldList = () => {
    const fieldItems = reportElementTagListForIndividual().filter(
        (element) => element.type === 'field'
    )

    const fieldList = fieldItems.map((item) => item.value)
    return fieldList
}

export const makeLookUpGroupedDataSource = () => {
    const inputArray = reportElementTagListForIndividual()
    const groupedData = {}

    // 주어진 배열을 순회하며 그룹화
    inputArray.forEach((item) => {
        const { type, value, display } = item

        // type이 이미 그룹화된 데이터에 존재하지 않으면 새로 생성
        if (!groupedData[type]) {
            groupedData[type] = { key: type, items: [] }
        }

        // 해당 그룹에 value와 display 객체를 추가
        groupedData[type].items.push({ value, display })
    })

    // 결과를 배열로 변환
    return Object.values(groupedData)
}

// export const getAllElementIndividualType = () => {
//     return [
//         ...new Set(reportElementTagListForIndividual().map((el) => el.type)),
//     ]
// }

export const getAllElementIndividualValue = () => {
    return [
        ...new Set(reportElementTagListForIndividual().map((el) => el.value)),
    ]
}
