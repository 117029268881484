import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { indStyles } from '../define/IndividualReportStyle'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import IconLoader from 'src/functions/IconLoader'

export const contentTitleDesc = (data) => {
    const logoData = data.logo
    const titleData = data.title
    const dateData = data.date
    const pageData = data.pageIndex

    return (
        <View style={indStyles.title}>
            <View style={indStyles.logoView}>
                <Image
                    // src={logoData}
                    source={{ uri: `data:image/png;base64,${logoData}` }}
                    style={indStyles.logo}
                />
            </View>
            <View style={indStyles.titleView}>
                <Text
                    wrap={false}
                    style={indStyles.titleText}
                >
                    {titleData}
                </Text>
            </View>
            <View style={indStyles.dateView}>
                <Text
                    wrap={false}
                    style={indStyles.centeredText}
                >
                    {dateData}
                </Text>
            </View>
            <View style={indStyles.pageView}>
                <Text
                    wrap={false}
                    style={indStyles.centeredText}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                ></Text>
            </View>
        </View>
    )
}

export const contentTitleDescCollective = (data, columnCount, key) => {
    const logoData = data.logo
    const titleData = data.title
    const dateData = data.date
    const pageData = data.pageIndex

    return (
        <View style={indStyles.title}>
            <View style={indStyles.logoView}>
                <Image
                    // src={logoData}
                    source={{ uri: `data:image/png;base64,${logoData}` }}
                    style={indStyles.logo}
                />
            </View>
            <View style={indStyles.titleView}>
                <Text
                    wrap={false}
                    style={indStyles.titleText}
                >
                    {titleData}
                </Text>
            </View>
            <View style={indStyles.dateView}>
                <Text
                    wrap={false}
                    style={indStyles.centeredText}
                >
                    {dateData}
                </Text>
            </View>
            <View style={indStyles.pageView}>
                <Text
                    wrap={false}
                    style={indStyles.centeredText}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                ></Text>
            </View>
        </View>
    )
}
