import { TextBox } from 'devextreme-react'
import React, { forwardRef } from 'react'
import { Label } from 'devextreme-react/form'
import './TextBoxNoValueSimpleItemNew.scss' // Import your custom CSS

const TextBoxNoValueSimpleItemNew = forwardRef(
    ({ colSpan, label, labelLocation, unit, disabled = false }, ref) => {
        return (
            <>
                {/* Label rendering if exists */}
                {label && (
                    <Label
                        text={label}
                        location={labelLocation}
                    />
                )}

                {/* DateBox Component */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TextBox
                        ref={ref}
                        width={'100%'}
                        /*label={label} labelMode={labelLocation} */ disabled={
                            disabled
                        }
                    />

                    {/* Unit rendering */}
                    {unit && <span>{dqTranslateMsg('SidePanel_' + unit)}</span>}
                </div>
            </>
        )
    }
)

export default TextBoxNoValueSimpleItemNew
