import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GroupItem, SimpleItem, Form } from 'devextreme-react/form'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import { msgType } from 'src/functions/table/MessageTypeTable'
import {
    getCommonMsg,
    getErrCommMsg,
    getPageMsg,
} from 'src/functions/CommonMassageUtils'
import { useDropzone } from 'react-dropzone'
import { useApi } from 'src/contexts/api'
import { makeUploadCompanyLogo } from 'src/api/admin/AdminCompanyApiHelper'
import { useAuth } from 'src/contexts/auth'
import { uploadCompanyLogo } from 'src/api/admin/AdminCompanyApi'
import * as rd from 'src/store/common'
import { useDispatch } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DQToast from 'src/functions/DQToast'

function extractBase64Data(url) {
    // "data:image/png;base64," 부분을 제거하고 Base64 데이터만 반환
    const base64Data = url.split(',')[1]
    return base64Data
}

export default function SettingsLogoUploader(props) {
    const { mode, othersPage } = props
    const toastRef = useRef(null)

    const [logoFile, setLogoFile] = useState(null)
    const [newLogoFile, setNewLogoFile] = useState(null)

    const { user } = useAuth()
    const { spring } = useApi()
    const dispatch = useDispatch()

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length > 0 && acceptedFiles.length < 2) {
                // Generate unique IDs (hashes) for each file asynchronously
                const filePromises = acceptedFiles.map((file) => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onloadend = () => {
                            // Base64 데이터와 함께 파일 정보를 객체로 반환
                            const base64Data = reader.result
                            const fileInfo = {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                base64Data: base64Data, // Base64로 변환된 파일 데이터
                                originalFileObj: file,
                            }
                            resolve(fileInfo) // 파일 정보와 Base64 데이터를 함께 반환
                        }
                        reader.onerror = (error) => {
                            reject(error) // 에러 처리
                        }
                        reader.readAsDataURL(file) // 파일을 Base64로 읽음
                    })
                })

                // 모든 파일을 처리하고, 첫 번째 파일을 setNewLogoFile에 설정
                Promise.all(filePromises)
                    .then((results) => {
                        console.log('All files processed', results) // 모든 파일 정보와 Base64 데이터를 출력
                        setNewLogoFile(results[0]) // 첫 번째 파일의 정보와 Base64 데이터 저장
                    })
                    .catch((error) => {
                        console.error('Error reading files', error)
                    })
            } else {
                alert('Only One file can be uploaded')
            }
        },
        [logoFile]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.png'],
            // 'image/*': ['.jpeg', '.jpg', '.png'],
        },
        // accept: '.csv, .dfq',
    })

    useEffect(() => {
        if (!othersPage.reportLogo) {
            setLogoFile(null)
        } else {
            setLogoFile(othersPage.reportLogo)
        }
    }, [logoFile])

    return (
        <>
            <DQToast ref={toastRef} />
            <Form
                colCount={1}
                labelMode='outside'
                // visible={mode === 'editor'}
                visible={mode}
            >
                <GroupItem
                    key='report-settings-create-template-group-item'
                    colCount={2}
                    // caption={getCommonMsg(msgType.SidePanel, 'CreateTemplate')}
                    caption={'Upload Logo'}
                >
                    <SimpleItem
                        colSpan={2}
                        render={() => (
                            <div
                                {...getRootProps()}
                                style={{
                                    width: '100%',
                                    height: '100px',
                                    border: `2px dashed ${
                                        isDragActive ? 'red' : '#ccc'
                                    }`,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center', // 세로 중앙 정렬
                                    alignItems: 'center', // 세로 중앙 정렬
                                }}
                            >
                                <input {...getInputProps()} />

                                {!logoFile && !newLogoFile ? (
                                    <p style={{ margin: 0 }}>
                                        {dqTranslateMsg(
                                            'Common_' +
                                                'ReportLogoFileNotRegistered'
                                        )}
                                    </p>
                                ) : logoFile && !newLogoFile ? (
                                    <p style={{ margin: 0 }}>
                                        {dqTranslateMsg(
                                            'Common_' +
                                                'ReportLogoFileRegistered'
                                        )}
                                    </p>
                                ) : (
                                    <p style={{ margin: 0 }}>
                                        {newLogoFile.name}
                                    </p>
                                )}
                            </div>
                        )}
                    />
                    <SimpleItem
                        colSpan={2}
                        render={() => (
                            <ButtonSimpleItemNew
                                colSpan={1}
                                type='normal'
                                text={getCommonMsg(msgType.SidePanel, 'Upload')}
                                icon='upload'
                                width='100%'
                                height='100%'
                                onClick={async () => {
                                    const { status, data } =
                                        await uploadCompanyLogo(
                                            spring,
                                            makeUploadCompanyLogo(
                                                user.user_company,
                                                user.user_plant,
                                                newLogoFile.originalFileObj
                                            )
                                        )

                                    if (status !== 0) {
                                        alert(getErrCommMsg(status))
                                        return
                                    }

                                    setLogoFile(newLogoFile.base64Data)
                                    dispatch(
                                        rd.setReportLogoData(
                                            extractBase64Data(
                                                newLogoFile.base64Data
                                            )
                                        )
                                    )
                                    toastRef.current.handleToastDisplay(
                                        'success',
                                        getCommonMsg(
                                            msgType.SidePanel,
                                            'SavedSettings'
                                        )
                                    )
                                }}
                            />
                        )}
                    ></SimpleItem>
                </GroupItem>
            </Form>
        </>
    )
}
