import React, { useState } from 'react'
import { Button } from 'devextreme-react/button'
import { connect, useSelector } from 'react-redux'
import useStepper from './useStepper' // Import the custom hook
import { defaultIntegrations } from '@sentry/react'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { Step, StepLabel, Stepper, Typography } from '@mui/material'

function updateListOrderChartList(fields, types) {
    const areLengthsEqual = (fieldData, typeData) => {
        return fieldData.length === typeData.length
    }

    if (fields === '' || types === '') {
        return []
    }

    const fieldData = fields.split('|')
    const typeData = types.split('|')

    if (!areLengthsEqual(fieldData, typeData)) {
        return []
    }

    let orderChartList = fieldData.map((field, index) => ({
        id: index,
        field: field,
        caption: field.charAt(0).toUpperCase() + field.slice(1),
        type: typeData[index],
    }))

    // orderChartList.pop()
    return orderChartList
}

const parseContent = (stepReference, index, mode) => {
    if (stepReference.steps.length > 0) {
        if (stepReference.curStep === 0 && index === 0) {
            return '*'
        } else if (stepReference.fields[index] === '2000' && mode === 'presentation') {
            return 'Characteristics List'
        } else {
            const nextStep = stepReference.steps.find((item) => item.step === index + 1)
            if (nextStep) {
                const tgKey = nextStep.key
                if (typeof tgKey !== 'string') {
                    return 'Characteristics List'
                }
                if (tgKey) {
                    let splitted = tgKey.split('\u0014')

                    const displayStr = splitted[index]
                    if (displayStr === '\u0010') {
                        return 'unknown'
                    } else if (displayStr === '\u0011') {
                        return ''
                    } else {
                        return splitted[index].replace(/\u000f/g, '')
                    }

                    // return splitted[index] ? splitted[index] : '-'
                } else {
                    return '-'
                }
            }
        }
    }
}

const convertTranslation = (field) => {
    let keyPrefix = ''
    let fieldPrefix = ''
    let numberField = Number(field)
    if (numberField >= 1000 && numberField < 2000) {
        fieldPrefix = 'P'
    } else if (numberField >= 2000 && numberField < 9000) {
        fieldPrefix = 'C'
    } else {
        fieldPrefix = 'V'
    }

    switch (fieldPrefix) {
        case 'P':
            keyPrefix = 'Db_field_Part_'
            break
        case 'C':
            keyPrefix = 'Db_field_Characteristics_'
            break
        case 'V':
            keyPrefix = 'Db_field_Value_'
            break
        default:
            // 기본값이 필요한 경우 처리
            keyPrefix = 'Db_field_Default_'
            break
    }

    const formatted = field.toString().padStart(4, '0')

    const translated = dqTranslateMsg(keyPrefix + fieldPrefix + formatted)
    return translated
}

const StepperComponent = ({ mode, reducerKey, stepReference, handleHomePage, handleStepperBackMode, handleStepperNextMode }) => {
    const { activeStep, handleHome, handleBack, handleNext, isFirstStep, isLastStep } = useStepper(stepReference, handleHomePage, handleStepperBackMode, handleStepperNextMode)

    const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
    const fields = sbPageSettings.page.orderChartField
    const types = sbPageSettings.page.orderChartType
    const steps = updateListOrderChartList(fields, types)

    const topOrderTextSize = sbPageSettings.theme.topOrderTextFontSize
    const topOrderTextCustomColor = sbPageSettings.theme.topOrderTextFontCustomColor
    const topOrderTextColor = sbPageSettings.theme.topOrderTextFontColor

    const isHaveNextStep = () => {
        if (stepReference.steps.find((item) => item.step === stepReference.curStep + 1)) {
            return true
        }
        return false
    }
    const isHaveBackStep = () => {
        if (stepReference.steps.find((item) => item.step === stepReference.curStep - 1)) {
            return true
        }
        return false
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <div style={{ width: '85%' }}>
                <Stepper
                    nonLinear
                    activeStep={activeStep}
                    alternativeLabel
                >
                    {steps.map((step, index) => (
                        <Step key={`scroll-board-step-${index}`}>
                            <StepLabel>
                                <div
                                    style={{
                                        whiteSpace: 'pre-line',
                                        fontSize: topOrderTextSize,
                                        color: topOrderTextCustomColor ? topOrderTextColor : '#767676',
                                    }}
                                >
                                    {convertTranslation(step.field)}
                                    {step.field !== '1' && (
                                        <>
                                            <br />
                                            {parseContent(stepReference, index, mode)}
                                        </>
                                    )}
                                </div>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div style={{ width: '15%', display: 'flex', flexDirection: 'column', padding: '0 0px', gap: '3px' }}>
                <div style={{ flexGrow: 1 }}>
                    <Button
                        key={'stepper-btn-home'}
                        icon='home'
                        text={dqTranslateMsg('Page_ScrollBoard_' + 'Home')}
                        disabled={isFirstStep}
                        onClick={handleHome}
                        style={{ width: '100%' }} // Home 버튼이 flex-grow로 확장되도록 설정
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '3px' }}>
                    <Button
                        key={'stepper-btn-prev'}
                        icon='arrowleft'
                        text={dqTranslateMsg('Page_ScrollBoard_' + 'Back')}
                        disabled={isFirstStep || !isHaveBackStep()}
                        onClick={handleBack}
                        style={{ flexBasis: '50%' }} // arrowleft와 arrowright 버튼이 같은 너비를 가지도록 설정
                    />
                    <Button
                        key={'stepper-btn-next'}
                        icon='arrowright'
                        text={dqTranslateMsg('Page_ScrollBoard_' + 'Next')}
                        disabled={isLastStep || !isHaveNextStep()}
                        onClick={handleNext}
                        style={{ flexBasis: '50%' }} // arrowleft와 arrowright 버튼이 같은 너비를 가지도록 설정
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    fieldData: state.common.pages[ownProps.reducerKey].page.orderChartField,
    typeData: state.common.pages[ownProps.reducerKey].page.orderChartType,
})

export default connect(mapStateToProps)(StepperComponent)
