import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as xBarImpl from './QualityControlXBarChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import {
    getLocationChartSubData,
    getQccChartSubData,
} from 'src/functions/QccProcessUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const formatLimitInfoByAxis = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number'
        ? `${label} : ${DNUtils.axisValueDisplay(
              value,
              char,
              configSettings
          )}<br>`
        : ''
}
const formatLimitInfoByCalc = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number'
        ? `${label} : ${DNUtils.calcValueDisplay(value, configSettings)}<br>`
        : ''
}

const toolTipBalloonCallback = (args, settings, configSettings, char) => {
    let displayStr = ''

    // **Prediction Content 생성**
    let predictionContent = ''

    if (args[0].seriesName) {
        predictionContent += `<b>${args[0].seriesName}</b><br>`
    }

    if (args[0].data) {
        predictionContent += `${dqTranslateMsg(
            'Page_QualityControlChart_' + 'Number'
        )} : ${args[0].data[0]}<br>`
        predictionContent += `${dqTranslateMsg(
            'Page_QualityControlChart_' + 'Value'
        )} : ${DNUtils.valueDisplay(args[0].data[1], char, configSettings)}<br>`
    }

    // **Limits Content 생성**
    let limitsContent = ''

    const useSpecLimits = settings.page.specificationLimitEnable
    const useWarnLimits = settings.page.warningLimitEnable
    const useControlLimits = settings.page.controlLimitEnable
    const useAverageLimits = settings.page.averageLimitEnable
    const useNominalLimits = settings.page.nominalLimitEnable
    const usePlausibilityLimits = settings.page.plausibilityLimitEnable
    const useScrapLimits = settings.page.scrapLimitEnable
    const useAcceptanceLimits = settings.page.acceptanceLimitEnable

    if (
        useSpecLimits ||
        useWarnLimits ||
        useControlLimits ||
        useAverageLimits ||
        useNominalLimits
    ) {
        const limitsHeader = dqTranslateMsg(
            'Page_QualityControlChart_' + 'Limits'
        )
        limitsContent += `<b>${limitsHeader}</b><br>`

        if (useSpecLimits) {
            limitsContent += formatLimitInfoByAxis(
                'USL',
                settings.usl,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByAxis(
                'LSL',
                settings.lsl,
                char,
                configSettings
            )
        }

        if (useWarnLimits) {
            limitsContent += formatLimitInfoByCalc(
                'UWL',
                settings.uwl,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByCalc(
                'LWL',
                settings.lwl,
                char,
                configSettings
            )
        }

        if (useControlLimits) {
            limitsContent += formatLimitInfoByCalc(
                'UCL',
                settings.ucl,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByCalc(
                'LCL',
                settings.lcl,
                char,
                configSettings
            )
        }

        if (useAverageLimits) {
            limitsContent += formatLimitInfoByCalc(
                'X\u0304',
                settings.average,
                char,
                configSettings
            )
        }

        if (useNominalLimits) {
            limitsContent += formatLimitInfoByCalc(
                'Nominal',
                settings.nominal,
                char,
                configSettings
            )
        }

        if (usePlausibilityLimits) {
            limitsContent += formatLimitInfoByCalc(
                'UPL',
                settings.upl,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByCalc(
                'LPL',
                settings.lpl,
                char,
                configSettings
            )
        }

        if (useScrapLimits) {
            limitsContent += formatLimitInfoByCalc(
                'URL',
                settings.url,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByCalc(
                'LRL',
                settings.lrl,
                char,
                configSettings
            )
        }

        if (useAcceptanceLimits) {
            limitsContent += formatLimitInfoByCalc(
                'UAcL',
                settings.uacl,
                char,
                configSettings
            )
            limitsContent += formatLimitInfoByCalc(
                'LAcL',
                settings.lacl,
                char,
                configSettings
            )
        }
    }

    // **HTML Table로 Prediction과 Limits 배치**
    displayStr += `
        <table style="border-collapse: collapse; width: 100%;">
            <tr>
                <td style="vertical-align: top; padding-right: 20px;">${predictionContent}</td>
                <td style="vertical-align: top;">${limitsContent}</td>
            </tr>
        </table>
    `

    return displayStr
}

export const getXBarChartOptions = (
    settings,
    configSettings,
    targetChar,
    targetValue
) => {
    const titleText = `${
        DBUtils.getFieldData(targetChar, 'c2002') ?? 'empty'
    } P:${DBUtils.getFieldData(targetChar, 'part_id') ?? '?'} / C:${
        DBUtils.getFieldData(targetChar, 'char_id') ?? '?'
    } / X-Bar / R Chart`

    const {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    }
    const filteredValue = outlierFilterValues(
        limits,
        targetChar,
        targetValue,
        configSettings
    )

    const xBarCalcCallback = xBarImpl.makeQccUpperSeriesData
    const { lcl, ucl, average, seriesData, values } = getQccChartSubData(
        'xBar',
        settings,
        configSettings,
        targetChar,
        filteredValue,
        xBarCalcCallback
    )

    const maxValue = xBarImpl.findLargestNumber(values)
    const minValue = xBarImpl.findSmallestNumber(values)
    const xAxisRight = seriesData.length + 1
    const xAxisLeft = 0

    const topBottomCalcArray = {
        settings,
        usl,
        lsl,
        uwl,
        lwl,
        ucl,
        lcl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        maxValue,
        minValue,
    }

    const yAxisTop = xBarImpl.makeYAxisTop(topBottomCalcArray)
    const yAxisBottom = xBarImpl.makeYAxisBottom(topBottomCalcArray)
    const decimalPlaces =
        DBUtils.getFieldData(targetChar, 'c2022') ??
        configSettings.commonSettings.displayDecimalPlaces

    // chart setting reorganization
    const addedSettings = {
        ...settings,
        ...configSettings,
        titleText,
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        lacl,
        uacl,
        lcl,
        ucl,
        center: average,
        average,
        nominal,
        // warnOffset,
        xAxisRight,
        xAxisLeft,
        yAxisTop,
        yAxisBottom,
        decimalPlaces,
        char: targetChar,
    }

    return {
        title: {
            text: addedSettings.titleText,
            left: 'center',
            textStyle: {
                fontFamily: 'Arial',
                fontSize:
                    (18 /
                        (settings.page.layout.row +
                            settings.page.layout.column)) *
                    4,
            },
        },
        animationDuration: 300,
        grid: {
            top: '15%',
            bottom: '7%',
            left: '0%',
            right: '5%',
            containLabel: true,
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        toolbox: {
            feature: {
                saveAsImage: {},
            },
            itemSize:
                (15 /
                    (settings.page.layout.row + settings.page.layout.column)) *
                4,
        },
        tooltip: {
            trigger: 'axis',
            appendToBody: true,
            position: function (pos, params, dom, rect, size) {
                const obj = {}
                obj.top = 10
                obj.left = pos[0] + 30

                return obj
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            extraCssText: 'overflow: visible; position: fixed;',
            formatter: function (args) {
                return toolTipBalloonCallback(
                    args,
                    addedSettings,
                    configSettings,
                    targetChar
                )
            },
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        xAxis: {
            show: true,
            type: 'value',
            min: xAxisLeft,
            max: xAxisRight,
            minInterval: 1,
            maxInterval: addedSettings.page.xAxisDenseLabel
                ? 2
                : xAxisRight < 10
                ? 2
                : Math.abs(Math.floor(addedSettings.xAxisRight / 5)),
            axisLabel: {
                rotate: 90,
                fontFamily: 'Arial',
                hideOverlap: true,
                showMinLabel: false,
                showMaxLabel: false,
            },
            splitLine: {
                show: false,
            },
            axisLine: {
                onZero: false,
                hideOverlap: true,
            },
            axisTick: {
                interval: 1,
            },
        },
        yAxis: {
            show: true,
            min: addedSettings.yAxisBottom,
            max: addedSettings.yAxisTop,
            type: 'value',
            axisLabel: {
                fontFamily: 'Arial',
                showMinLabel: false,
                showMaxLabel: false,
                formatter: function (value, index) {
                    let axisMax = addedSettings.yAxisTop
                    let axisMin = addedSettings.yAxisBottom

                    if (value === axisMin || value === axisMax) {
                        return ''
                    }
                    return value
                },
            },
            nameTextStyle: {
                fontFamily: 'Arial',
            },
            splitLine: {
                show: true,
            },
            minorTick: {
                show: false,
            },
            axisLine: {
                onZero: false,
            },
        },
        legend: {
            show: false,
            textStyle: {
                fontFamily: 'Arial',
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                xAxisIndex: 0,
                height: `3%`,
                bottom: '3%',
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'slider',
                orient: 'vertical',
                width: `3%`,
                brushSelect: true,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                yAxisIndex: 0,
                filterMode: 'none',
                zoomLock: false, // Allow zooming
                zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
            },
        ],
        series: [
            {
                type: 'line',
                name: 'X\u0304',
                data: seriesData,
                symbol: (value, params) =>
                    xBarImpl.makeSymbol(value, params, addedSettings),
                symbolSize: (value, params) =>
                    xBarImpl.makeSymbolSize(value, params, addedSettings),
                itemStyle: {
                    color: (params) =>
                        xBarImpl.makeSymbolColor(params, addedSettings),
                },
                lineStyle: xBarImpl.convertToChartLine(settings),
                markLine: {
                    animation: false,
                    symbol: 'none',
                    precision: 10,
                    emphasis: {
                        disabled: true,
                    },
                    // precision: settings.lineChart.markLines.precision,
                    data: xBarImpl.convertToMarkLine(addedSettings),
                },
                markArea: {
                    type: 'line',
                    name: 'Areas',
                    emphasis: {
                        disabled: true,
                    },
                    data: xBarImpl.convertToMarkArea(addedSettings),
                },
            },
        ],
    }
}
////
