// import { CheckBox } from 'devextreme-react'
// import { Label, SimpleItem } from 'devextreme-react/form'

// const CheckBoxSimpleItem = ({ ref, colSpan, label, labelLocation, name, value, disabled, onValueChanged }) => {
//   return (
//     <SimpleItem colSpan={colSpan} disabled={disabled}>
//       <Label text={label} location={labelLocation} />
//       <CheckBox ref={ref} name={name} value={value} onValueChanged={onValueChanged} />
//     </SimpleItem>
//   )
// }

// export default CheckBoxSimpleItem

import { CheckBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const CheckBoxSimpleItemNew = ({
    colSpan,
    label,
    labelLocation = 'right', // 기본 값 설정
    name,
    value,
    disabled = false,
    visible = true, // visible props 추가
    onValueChanged,
    width = '100%', // width를 props로 받도록 수정
}) => {
    if (!visible) return null // visible이 false일 경우 렌더링하지 않음

    return (
        <div style={{ gridColumn: `span ${colSpan || 1}`, width: width }}>
            {/* Label rendering if exists */}
            {/* {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />	
            )} */}

            {/* CheckBox Component */}
            {/* <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            > */}
            <CheckBox
                name={name}
                value={value}
                onValueChanged={onValueChanged}
                disabled={disabled}
            >
                {/* {label} */}
            </CheckBox>

            {/* </div> */}
        </div>
    )
}

export default CheckBoxSimpleItemNew
