import Form, { GroupItem, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import { getDatabaseInfo } from 'src/api/db'
import { databaseSettingsPrm } from '../../../settings/DatabaseSettings'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'

const DBFieldCreateButton = ({
    pageKey,
    pageState,
    configState,
    isVisibleCreateFilterPopup,
    setIsVisibleCreateFilterPopup,
    popupCreateFilter,
    setPopupCreateFilter,
    currentFilterMode,
}) => {
    const onCreateButtonClick = () => {
        console.log('create')
        const makeInitialDatabase = (currentFilterMode) => {
            if (currentFilterMode === 'quick') {
                return {
                    common: databaseSettingsPrm.filters.quick.common,
                    others: databaseSettingsPrm.filters.quick.others,
                    data: databaseSettingsPrm.filters.quick.data,
                }
            } else {
                return {
                    common: databaseSettingsPrm.filters.advanced.common,
                    others: databaseSettingsPrm.filters.advanced.others,
                    data: databaseSettingsPrm.filters.advanced.data,
                }
            }
        }
        setIsVisibleCreateFilterPopup(true)
        setPopupCreateFilter(makeInitialDatabase(currentFilterMode))
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-quick-create-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'MakeFilter')}
            >
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <ButtonSimpleItemNew
                            // colSpan={2}
                            type='normal'
                            text={dqTranslateMsg('SidePanel_' + 'New')}
                            icon='add'
                            width='100%'
                            height='100%'
                            onClick={onCreateButtonClick}
                        />
                    )}
                />
                {/* {ButtonSimpleItem({
                    colSpan: 2,
                    type: 'normal',
                    text: dqTranslateMsg('SidePanel_' + 'New'),
                    icon: 'add',
                    width: '100%',
                    height: '100%',
                    onClick: onCreateButtonClick,
                })} */}
            </GroupItem>
        </Form>
    )
}

export default DBFieldCreateButton
