import React from 'react'
import { GroupItem, SimpleItem, Form } from 'devextreme-react/form'
import ListSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItemNew'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import TextBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItemNew'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import * as rd from 'src/store/common'
import { updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { batch } from 'react-redux'
import { validateTemplates } from '../func/ReportUtils'

const handleExportAll = (e, templates) => {
    const data = templates // 가져올 데이터
    const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json',
    })
    saveAs(blob, 'reportTemplates.json')
}

const handleImportAll = (e, spring, dispatch, othersPage, user) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.json'
    input.onchange = (e) => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = (event) => {
                try {
                    const importedData = JSON.parse(event.target.result)
                    const originData = othersPage.reportTemplates

                    // Validation
                    const isValid = validateTemplates(importedData, originData)
                    if (!isValid) {
                        alert('import data is not valid')
                        return
                    }

                    const next = produce(othersPage, (draft) => {
                        draft.reportTemplates = [
                            ...draft.reportTemplates,
                            ...importedData,
                        ]
                    })
                    dispatch(rd.setReportTemplates(next.reportTemplates))

                    const company = user.user_company
                    const plant = user.user_plant
                    const dataJsonStr = JSON.stringify(next.reportTemplates)

                    const updateData = {
                        [companyPlantTbDescription.reportTemplates]:
                            dataJsonStr,
                    }

                    updateAdminCompany(
                        spring,
                        makeGetAdminCompanyPrms(company, plant, updateData)
                    )

                    console.log('Imported Data:', importedData)
                } catch (error) {
                    console.error('Error reading or parsing the file:', error)
                }
            }
            reader.readAsText(file)
        }
    }
    input.click() // 파일 선택 창을 띄운다.
}

const SettingsSelectTemplate = (props) => {
    const {
        dispatch,
        pageKey,
        curPage,
        othersPage,
        savedTemplate,
        selectedTemplateNo,
        mode,
        setSelectedTemplate,
        setIsOpenSelectedTemplate,
    } = props

    const { spring } = useApi()
    const { user } = useAuth()

    const selectedFilterNo = [curPage.page.selectedTemplateNo]

    const handleTemplateClicked = (e) => {
        // if (mode === 'editor') {
        if (mode) {
            const itemData = e.itemData
            batch(() => {
                setSelectedTemplate(itemData)
                setIsOpenSelectedTemplate(true)
            })
        }
    }

    const handleTemplateDeleting = async (e) => {
        // if (mode === 'editor') {
        if (mode) {
            let result = confirm(
                getCommonMsg(msgType.SidePanel, 'TemplateDeletedConfirmMsg')
            )

            if (!result) {
                e.cancel = true
                return
            }

            const deleteTemplate = e.itemData

            const nextOthers = produce(othersPage, (draft) => {
                draft.reportTemplates = draft.reportTemplates.filter(
                    (item) => item.name !== deleteTemplate.name
                )
            })
            dispatch(rd.setReportTemplates(nextOthers.reportTemplates))

            const company = user.user_company
            const plant = user.user_plant
            const dataJsonStr = JSON.stringify(nextOthers.reportTemplates)

            const updateData = {
                [companyPlantTbDescription.reportTemplates]: dataJsonStr,
            }

            updateAdminCompany(
                spring,
                makeGetAdminCompanyPrms(company, plant, updateData)
            )
        }
    }

    const onSelectionChanged = (e) => {
        if (e.addedItems.length > 0) {
            const selectedItem = e.addedItems[0]
            // setSelectedItemKeys(value)
            const next = produce(curPage, (draft) => {
                draft.page.selectedTemplateNo = selectedItem.id
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const makeSavedTemplateDataSource = (data) => {
        const dataSource = data.map((template, index) => {
            return {
                ...template,
                id: index,
            }
        })

        return dataSource
    }

    const renderElementList = (itemData) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    width: '100%',
                    paddingTop: 1,
                    paddingBottom: 1,
                }}
            >
                <TextBoxSimpleItemNew
                    label='File Name'
                    labelLocation='top'
                    name='file-name'
                    value={itemData.name}
                    readOnly={true}
                />
                <TextBoxSimpleItemNew
                    label='Orientation'
                    labelLocation='top'
                    name='orientation'
                    value={itemData.page.orientation}
                    readOnly={true}
                />
                <TextBoxSimpleItemNew
                    label='Report Type'
                    labelLocation='top'
                    name='report-type'
                    value={itemData.type}
                    readOnly={true}
                />
            </div>
        )
    }
    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key='settings-select-template-group-item'
                colCount={2}
                caption='Template Settings'
            >
                {/* SimpleItem을 사용해 render로 ListSimpleItem 렌더링 */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <ListSimpleItemNew
                            colSpan={2}
                            label=''
                            labelLocation='top'
                            dataSource={makeSavedTemplateDataSource(
                                savedTemplate
                            )}
                            itemRender={renderElementList}
                            // allowItemDeleting={mode === 'editor' ? true : false}
                            allowItemDeleting={mode}
                            itemDeleteMode='static'
                            selectionMode='single'
                            showSelectionControls={true}
                            selectedItemKeys={selectedFilterNo}
                            onItemDeleting={handleTemplateDeleting}
                            onItemClick={handleTemplateClicked}
                            keyExpr='id'
                            displayExpr='name'
                            onReorder={null}
                            backgroundCondition={true}
                            onSelectionChanged={onSelectionChanged}
                        />
                    )}
                />

                {/* 버튼들 추가 */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <div style={{ width: '100%', display: 'flex' }}>
                            <ButtonSimpleItemNew
                                colSpan={1}
                                type='normal'
                                text={getCommonMsg(
                                    msgType.SidePanel,
                                    'ExportAll'
                                )}
                                icon='export'
                                width='50%'
                                height='100%'
                                onClick={(e) =>
                                    handleExportAll(
                                        e,
                                        othersPage.reportTemplates
                                    )
                                }
                            />
                            <ButtonSimpleItemNew
                                colSpan={1}
                                type='normal'
                                text={getCommonMsg(
                                    msgType.SidePanel,
                                    'ImportAll'
                                )}
                                icon='import'
                                width='50%'
                                height='100%'
                                onClick={(e) =>
                                    handleImportAll(
                                        e,
                                        spring,
                                        dispatch,
                                        othersPage,
                                        user
                                    )
                                }
                            />
                        </div>
                    )}
                ></SimpleItem>
            </GroupItem>
        </Form>
    )
}

export default SettingsSelectTemplate
