import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { indStyles } from '../define/IndividualReportStyle'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import IconLoader from 'src/functions/IconLoader'
import { reportTagsCollectiveSummaryList } from '../../side-panel/components/define/DefineTagListCollectiveSummaryList'

const getPngPath = (iconStyle, iconType, theme, state) => {
    const iconId = IconLoader.TYPES_DEF.find(
        (type) => type.path === iconType
    ).id
    const stateId = IconLoader.STATE_DEF.find((type) => type.path === state).id

    const themeId = 0

    const path = IconLoader.getPngImgPath(iconStyle, iconId, themeId, stateId)
    return path
}

const IconRenderer = ({ iconStyle, iconType, theme, state, debug }) => {
    const src = getPngPath(iconStyle, iconType, theme, state)
    return (
        <View style={indStyles.textView}>
            <Image
                src={src}
                style={indStyles.listIcon}
                debug={debug}
            />
        </View>
    )
}

const WarningIcon = ({ debug }) => (
    <View
        style={indStyles.textView}
        // debug
    >
        <Image
            src={'/images/color/warning-exclamation.png'}
            style={indStyles.listIcon}
            debug={debug}
        />
    </View>
)

const TextColumn = ({ dis, text, debug }) =>
    dis === 'trend' ? (
        <View style={indStyles.trendHeaderView}>
            <Text
                style={indStyles.centeredText}
                debug={debug}
                wrap={false}
            >
                {text.split('')}
            </Text>
        </View>
    ) : (
        <View style={indStyles.textHeaderView}>
            <Text
                style={indStyles.centeredText}
                debug={debug}
                wrap={false}
            >
                {text.split('')}
            </Text>
        </View>
    )

const DataColumn = ({ data, debug }) => (
    <View style={indStyles.textView}>
        <Text
            style={indStyles.centeredText}
            debug={debug}
            wrap={false}
        >
            {data}
        </Text>
    </View>
)

function extractBase64Data(url) {
    // "data:image/png;base64," 부분을 제거하고 Base64 데이터만 반환
    const base64Data = url.split(',')[1]
    return base64Data
}

const SvgColumn = ({ data, debug }) => (
    <View style={indStyles.trendView}>
        <Image
            // src={extractBase64Data(data)}
            src={data}
            // src={'/images/color/warning-exclamation.png'}
            // style={indStyles.listIcon}
            style={{ width: '100%', height: 'auto' }}
            debug={debug}
        />
    </View>
)

export const contentCollectiveSummaryList = (
    content,
    contentValue,
    // data,
    tgContent,
    slPage,
    configPage,
    theme,
    valuesArranged
) => {
    const iconStyle = configPage.menuIconType
    const debug = false

    const getResultState = (value) => {
        if (value === 1) {
            return (
                <IconRenderer
                    iconStyle={iconStyle}
                    iconType={slPage.theme.iconType.result}
                    theme={theme}
                    state='good'
                    debug={debug}
                />
            )
        } else if (value === 0) {
            return (
                <IconRenderer
                    iconStyle={iconStyle}
                    iconType={slPage.theme.iconType.result}
                    theme={theme}
                    state='bad'
                    debug={debug}
                />
            )
        } else {
            return <WarningIcon debug={debug} />
        }
    }

    const getOutOfTolState = (value) => {
        if (value === 1) {
            return (
                <IconRenderer
                    iconStyle={iconStyle}
                    iconType={slPage.theme.iconType.outOfTolerances}
                    theme={theme}
                    state='good'
                    debug={debug}
                />
            )
        } else if (value === 0) {
            return (
                <IconRenderer
                    iconStyle={iconStyle}
                    iconType={slPage.theme.iconType.outOfTolerances}
                    theme={theme}
                    state='bad'
                    debug={debug}
                />
            )
        } else {
            return <WarningIcon />
        }
    }

    return (
        <>
            <View
                style={indStyles.listHeader}
                // debug
            >
                {content.map((key) => {
                    const display = reportTagsCollectiveSummaryList().find(
                        (tag) => tag.value === key
                    )?.display

                    return (
                        <TextColumn
                            key={key}
                            dis={key}
                            text={display ? display : ''}
                            debug={debug}
                        />
                    )
                })}
            </View>
            {valuesArranged.map((valueArray) => {
                const part_id = valueArray.part_id
                const char_id = valueArray.char_id
                const curSummaryContent = tgContent.find(
                    (item) =>
                        item.part_id === part_id &&
                        item.char_id === char_id &&
                        item.type === contentValue
                )

                if (!curSummaryContent) {
                    return null
                }

                const curSummaryContentValue = curSummaryContent.value
                const data = curSummaryContentValue

                return (
                    <View
                        style={indStyles.listValue}
                        // debug
                    >
                        {content.map((item, index) => {
                            if (item === 'result') {
                                return getResultState(data['result'])
                            } else if (item === 'outOfTol') {
                                return getOutOfTolState(data['outOfTol'])
                            } else if (item === 'trend') {
                                return (
                                    <SvgColumn
                                        key={index}
                                        data={data[item] ? data[item] : ''}
                                        debug={debug}
                                    />
                                )
                            } else {
                                return (
                                    <DataColumn
                                        key={index}
                                        data={data[item] ? data[item] : ''}
                                        debug={debug}
                                    />
                                )
                            }
                        })}
                    </View>
                )
            })}
        </>
    )
}
