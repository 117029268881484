export const reportPrintMultiFunctionSettingsPrm = {
    page: {
        layout: {
            column: 1,
            row: 1,
        },
        reportMode: 'user', // editor, user
        debugMode: true,
        selectedTemplateNo: 0,
    },
    theme: {},
}
