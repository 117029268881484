import { Button, Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useApi } from 'src/contexts/api'
import { useAuth } from 'src/contexts/auth'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { reportPrintMultiFunctionSettingsPrm } from '../settings/ReportPrintMultiFunctionSettings'
import SettingsCreateReportTemplate from './components/settings/SettingsCreateTemplate'
import SettingsCreateTemplatePopup from './components/settings/SettingsCreateTemplatePopup'
import SettingsSelectTemplate from './components/settings/SettingsSelectTemplate'
import SettingsSelectedTemplatePopup from './components/settings/SettingsSelectedTemplatePopup'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { sampleStyles, templateData } from './components/define/SampleData'
import { reportEmptyTemplateFormat } from './components/define/DefineEmptyTemplate'
import SettingsLogoUploader from './components/settings/SettingsLogoUploader'

export default function SidePanelReportPrintMultiFunctionSettings(props) {
    const { pageKey } = props
    const dispatch = useDispatch()
    const { user } = useAuth()
    const { spring } = useApi()

    const parentRef = useRef(null)
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const othersPage = common.pages.others
    const curPage = common.pages.reportPrintMultiFunction
    const configPage = common.pages.configuration
    const curReportMode = curPage.page.reportMode
    const selectedTemplateNo = curPage.page.selectedTemplateNo

    // report authorization
    const isAdmin = user.user_group === 'admin'

    // test data
    const sampleTemplate = templateData

    const [isOpenCreateTemplate, setIsOpenCreateTemplate] = useState(false)
    const [createTemplate, setCreateTemplate] = useState(
        reportEmptyTemplateFormat
    )
    const [isOpenSelectedTemplate, setIsOpenSelectedTemplate] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(sampleTemplate)

    const handleCurPageSettingsSave = (updatePageData) => {
        dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
    }

    const handleSaveButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.keys(curPage).forEach((key) => {
                draft[key] = curPage[key]
            })
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay(
            'success',
            getCommonMsg(msgType.SidePanel, 'SavedSettings')
        )
    }

    const handleResetButtonClicked = () => {
        const next = produce(curPage, (draft) => {
            Object.entries(reportPrintMultiFunctionSettingsPrm.page).forEach(
                ([key, value]) => {
                    draft.page[key] = value
                }
            )
        })

        handleCurPageSettingsSave(next)
        toastRef.current.handleToastDisplay(
            'warning',
            getCommonMsg(msgType.SidePanel, 'ResetSettings')
        )
    }

    useEffect(() => {
        if (othersPage.reportTemplates.length > 0) {
            setSelectedTemplate(othersPage.reportTemplates[selectedTemplateNo])
        }
    }, [])

    return (
        <div
            ref={parentRef}
            style={{ padding: '5px', width: '100%', height: '100%' }}
        >
            <DQToast ref={toastRef} />
            {/* Function Buttons */}
            <div style={{ width: '100%', height: '4%' }}>
                <DQSaveReset
                    saveBtnText={dqTranslateMsg('SidePanel_Save')}
                    resetBtnText={dqTranslateMsg('SidePanel_Reset')}
                    saveCallback={handleSaveButtonClicked}
                    resetCallback={handleResetButtonClicked}
                />
            </div>

            {/* Main Content */}
            <div style={{ width: '100%', height: '92%' }}>
                <ScrollView
                    className='side-panel-tab-container'
                    style={{ padding: 0 }}
                    showScrollbar='onHover'
                    useNative={false}
                >
                    <SettingsLogoUploader
                        othersPage={othersPage}
                        // mode={curReportMode}
                        mode={isAdmin}
                    />

                    {/* Create Report Template */}
                    <SettingsCreateReportTemplate
                        key='create-report'
                        // mode={curReportMode}
                        mode={isAdmin}
                        setIsOpenCreateTemplate={setIsOpenCreateTemplate}
                    />

                    <SettingsSelectTemplate
                        dispatch={dispatch}
                        pageKey={pageKey}
                        curPage={curPage}
                        othersPage={othersPage}
                        // savedTemplate={curPage.page.templates}
                        savedTemplate={othersPage.reportTemplates}
                        selectedTemplateNo={selectedTemplateNo}
                        mode={isAdmin}
                        setSelectedTemplate={setSelectedTemplate}
                        setIsOpenSelectedTemplate={setIsOpenSelectedTemplate}
                    />

                    {/* Create Template Popup */}
                    <SettingsCreateTemplatePopup
                        key='create-template-popup'
                        dispatch={dispatch}
                        // mode={curReportMode}
                        mode={isAdmin}
                        pageKey={pageKey}
                        pageState={curPage}
                        configState={configPage}
                        othersPage={othersPage}
                        isOpenCreateTemplate={isOpenCreateTemplate}
                        setIsOpenCreateTemplate={setIsOpenCreateTemplate}
                        createTemplate={createTemplate}
                        setCreateTemplate={setCreateTemplate}
                    />

                    {/* Selected Template Popup */}
                    <SettingsSelectedTemplatePopup
                        key='selected-template-popup'
                        dispatch={dispatch}
                        // mode={curReportMode}
                        mode={isAdmin}
                        pageKey={pageKey}
                        pageState={curPage}
                        configState={configPage}
                        othersPage={othersPage}
                        selectedTemplate={selectedTemplate}
                        selectedTemplateNo={selectedTemplateNo}
                        setSelectedTemplate={setSelectedTemplate}
                        isOpenSelectedTemplate={isOpenSelectedTemplate}
                        setIsOpenSelectedTemplate={setIsOpenSelectedTemplate}
                    />
                </ScrollView>
            </div>
        </div>
    )
}
