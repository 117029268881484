import { useEffect } from 'react'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import SettingsRectElementsLayout from './SettingsRectElementsLayout'
import SettingsTemplateSave from './SettingsTemplateSave'
import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { sampleStyles } from '../define/SampleData'
import SettingsReportLayout from './SettingsReportLayout'
import SettingsReportType from './SettingsReportType'

const SettingsCreateTemplatePopup = (props) => {
    const {
        isOpenCreateTemplate,
        setIsOpenCreateTemplate,
        createTemplate,
        setCreateTemplate,
        templates,
        dispatch,
        pageKey,
        pageState,
        othersPage,
        mode,
    } = props

    const styles = sampleStyles

    const closePopup = () => {
        setIsOpenCreateTemplate(false)
        // setCreateTemplate(null)
    }

    // PDF Content
    const PdfLayoutContent = ({ template }) => {
        const elements = template.elements
        const elementRowHeightUnit = 2 // 2% 최소

        return (
            <Document pageMode={'fullScreen'}>
                <Page
                    size={template.page.size}
                    style={styles.page}
                    orientation={template.page.orientation}
                >
                    {elements.map((element) => {
                        const columnContent = element.columnContent
                        // const rowCount = elements.length
                        const columnHeight =
                            elementRowHeightUnit * element.heightUnit
                        const columnCount = element.columnCount

                        return (
                            <View
                                key={element.index}
                                style={{
                                    flexDirection: 'row',
                                    // marginBottom: 20,
                                    width: '100%',
                                    height: `${columnHeight}%`,
                                }}
                            >
                                {columnContent
                                    ? columnContent.map((content, idx) => (
                                          <View
                                              key={element.index + idx}
                                              style={{
                                                  ...styles.section,
                                                  width: `${
                                                      100 / columnCount
                                                  }%`,
                                                  // width: `auto`,
                                              }}
                                          >
                                              <View style={styles.info}>
                                                  <Text style={styles.leftText}>
                                                      {content.value}
                                                  </Text>
                                              </View>
                                          </View>
                                      ))
                                    : null}
                            </View>
                        )
                    })}
                </Page>
            </Document>
        )
    }

    const renderFilterContent = () => {
        if (!createTemplate) {
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    gap: '10px',
                }}
            >
                <div style={{ width: '50%', height: '100%' }}>
                    <SettingsReportType
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={createTemplate}
                        setSelectedTemplate={setCreateTemplate}
                        disabled={false}
                    />
                    <SettingsReportLayout
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={createTemplate}
                        setSelectedTemplate={setCreateTemplate}
                    />
                    <SettingsRectElementsLayout
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={createTemplate}
                        setSelectedTemplate={setCreateTemplate}
                    />
                    <SettingsTemplateSave
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={createTemplate}
                        setSelectedTemplate={setCreateTemplate}
                        setIsOpenCreateTemplate={setIsOpenCreateTemplate}
                    />
                </div>
                <div
                    style={{
                        width: '50%',
                        height: '100%',
                        border: '1px dotted gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', // 세로 중앙 정렬
                    }}
                >
                    <div
                        style={{
                            marginBottom: '5px',
                            width: '100%',
                            height: '100%',
                            fontWeight: 'bold',
                        }}
                    >
                        <PDFViewer
                            showToolbar={false}
                            width='100%'
                            height='100%'
                        >
                            {createTemplate ? (
                                <PdfLayoutContent
                                    template={createTemplate}
                                    // theme={theme}
                                />
                            ) : null}
                        </PDFViewer>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <PopupSimpleItem
            visible={isOpenCreateTemplate}
            onHiding={closePopup}
            title='Create Filter'
            width='60%'
            height='90%'
        >
            {renderFilterContent()}
        </PopupSimpleItem>
    )
}

export default SettingsCreateTemplatePopup
