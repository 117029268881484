import { Form } from 'devextreme-react'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import produce from 'immer'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getSummary } from 'src/api/summary-list'
import { useTheme } from 'src/contexts/theme'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { MONO_BLUE, MONO_RED, COLOR } from 'src/functions/constants/iconPathDefine'
import { getErrCommMsg, getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'

function getMenuIconPath(type, theme) {
    // const path = type === 'mono' ? '/images/mono' : '/images/color'

    let path
    if (type === MONO_BLUE) {
        path = '/images/monoBlue'
    } else if (type === MONO_RED) {
        path = '/images/monoRed'
    } else {
        path = '/images/color'
    }

    let iconPath
    if (type === 'monoBlue' || type === 'monoRed') {
        const themePath = theme.includes('light') ? '/light' : '/dark'
        iconPath = path + themePath
    } else {
        iconPath = path
    }
    return iconPath
}

const processCapItems = () => ({
    groupTitle: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'ProcessCapability'),
    },
    groupTitleSub1: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'ProcessCapabilityIndex'),
    },
    groupTitleSub2: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'OtherInformation'),
    },
    cp: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Cp'),
    },
    cpk: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Cpk'),
    },
    stdDev: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'StdDev'),
    },
    dateTime: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'DateTime'),
    },
    sampleCount: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'SampleCount'),
    },
    usl: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Usl'),
    },
    lsl: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Lsl'),
    },
    average: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Average'),
    },
    median: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Median'),
    },
    min: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Min'),
    },
    max: {
        text: dqTranslateMsg('Page_ScrollBoard_' + 'Max'),
    },
})

const pcItemFormat = {
    cp: 0.0, // response
    cpk: 0.0, // response
    standardDeviation: 0, // response
    min: 0.0, // response
    max: 0.0, // response
    average: 0.0, // response
    dateTime: '',
    sampleCount: 0,
    usl: 0.0,
    lsl: 0.0,
    median: 0.0,
}

function ScrollBoardProcessCapability(props) {
    const { value, char } = props
    const { getTheme } = useTheme()
    const theme = getTheme()

    const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
    const configSettings = useSelector((state) => state.common.pages.configuration)

    const [pcItemData, setPCItemData] = useState({ ...pcItemFormat })

    const makePCItemData = (pcItemData, char, value, responseData) => {
        const res = responseData
        const valueArray = value.map((item) => item.v0001)

        const next = produce(pcItemData, (draft) => {
            draft.cp = DNUtils.calcValueDisplay(res.cp, configSettings)
            draft.cpk = DNUtils.calcValueDisplay(res.cpk, configSettings)
            draft.standardDeviation = DNUtils.calcValueDisplay(res.stdev, configSettings)
            draft.min = DNUtils.calcValueDisplay(res.minimum, configSettings)
            draft.max = DNUtils.calcValueDisplay(res.maximum, configSettings)
            draft.average = DNUtils.calcValueDisplay(res.average, configSettings)
            // draft.dateTime =
            draft.sampleCount = value.length
            draft.usl = DNUtils.calcValueDisplay(PCUtils.getUsl(char), configSettings)
            draft.lsl = DNUtils.calcValueDisplay(PCUtils.getLsl(char), configSettings)
            draft.median = DNUtils.calcValueDisplay(PCUtils.getMedian(valueArray), configSettings)
        })

        return next
    }

    useEffect(() => {
        try {
            if (value.length < 2) {
                alert(getErrProcMsg(procErrTable.E00003))
                return
            }

            getSummary(char, value, sbPageSettings, configSettings).then((result) => {
                if (!ApiStatus.isSuccess(result.status)) {
                    alert(getErrCommMsg(result.status))
                    return
                }

                // Success
                setPCItemData(makePCItemData(pcItemData, char, value, result.data))
            })
        } catch (error) {
            throw error
        }
    })

    const values = processCapItems()
    const pcCriteria = 1.33

    return (
        <div style={{ padding: '5px' }}>
            <Form
                colCount={4}
                id='form'
                formData={pcItemData}
            >
                <GroupItem
                    key={`scroll-board-process-capability-total-group-item`}
                    colCount={4}
                    colSpan={4}
                    caption={values.groupTitle.text}
                >
                    <GroupItem
                        key={`scroll-board-process-capability-left-group-item`}
                        colCount={2}
                        colSpan={2}
                        caption={values.groupTitleSub1.text}
                    >
                        <SimpleItem
                            colSpan={1}
                            render={() => {
                                if (isNaN(pcItemData.cp) || pcItemData.cp === null || pcItemData.cp === undefined) {
                                    return (
                                        <div style={{ textAlign: 'center', padding: '10px 0' }}>
                                            <p style={{ fontSize: '20px' }}>{values.cp.text}</p>
                                            <p style={{ fontSize: '20px', color: pcItemData.cp > pcCriteria ? 'green' : 'red' }}>{dqTranslateMsg('Page_ScrollBoard_' + 'Empty')}</p>
                                            <img
                                                src={'/images/color/warning-exclamation.svg'}
                                                width='60'
                                                height='60'
                                            />
                                        </div>
                                    )
                                }

                                return (
                                    <div style={{ textAlign: 'center', padding: '10px 0' }}>
                                        <p style={{ fontSize: '20px' }}>{values.cp.text}</p>
                                        <p style={{ fontSize: '20px', color: pcItemData.cp > pcCriteria ? 'green' : 'red' }}>{pcItemData.cp}</p>
                                        <img
                                            src={
                                                pcItemData.cp > pcCriteria
                                                    ? `${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-good.svg`
                                                    : `${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-bad.svg`
                                            }
                                            width='60'
                                            height='60'
                                        />
                                    </div>
                                )
                            }}
                            disabled={true}
                        />
                        {/* <SimpleItem dataField="cp" label={{ text: values.cp.text }} disabled={true} /> */}
                        <SimpleItem
                            colSpan={1}
                            render={() => {
                                if (isNaN(pcItemData.cpk) || pcItemData.cpk === null || pcItemData.cpk === undefined) {
                                    return (
                                        <div style={{ textAlign: 'center', padding: '10px 0' }}>
                                            <p style={{ fontSize: '20px' }}>{values.cpk.text}</p>
                                            <p style={{ fontSize: '20px', color: pcItemData.cpk > pcCriteria ? 'green' : 'red' }}>{dqTranslateMsg('Page_ScrollBoard_' + 'Empty')}</p>
                                            <img
                                                src={'/images/color/warning-exclamation.svg'}
                                                width='60'
                                                height='60'
                                            />
                                        </div>
                                    )
                                }

                                return (
                                    <div style={{ textAlign: 'center', padding: '10px 0' }}>
                                        <p style={{ fontSize: '20px' }}>{values.cpk.text}</p>
                                        <p style={{ fontSize: '20px', color: pcItemData.cpk > pcCriteria ? 'green' : 'red' }}>{pcItemData.cpk}</p>
                                        <img
                                            src={
                                                pcItemData.cpk > pcCriteria
                                                    ? `${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-good.svg`
                                                    : `${getMenuIconPath(configSettings.menuIconType, theme)}/thumbs-bad.svg`
                                            }
                                            width='60'
                                            height='60'
                                        />
                                    </div>
                                )
                            }}
                            disabled={true}
                        />
                        {/* <SimpleItem dataField="cpk" label={{ text: values.cpk.text }} disabled={true} /> */}
                    </GroupItem>
                    <GroupItem
                        key={'scroll-board-process-capability-right-group-item'}
                        colSpan={2}
                        caption={values.groupTitleSub2.text}
                    >
                        <SimpleItem
                            dataField='standardDeviation'
                            label={{ text: values.stdDev.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='min'
                            label={{ text: values.min.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='max'
                            label={{ text: values.max.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='average'
                            label={{ text: values.average.text }}
                            disabled={true}
                        />
                        {/* <SimpleItem
              dataField="dateTime"
              label={{ text: values.dateTime.text }}
              disabled={true}
            /> */}
                        <SimpleItem
                            dataField='sampleCount'
                            label={{ text: values.sampleCount.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='usl'
                            label={{ text: values.usl.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='lsl'
                            label={{ text: values.lsl.text }}
                            disabled={true}
                        />
                        <SimpleItem
                            dataField='median'
                            label={{ text: values.median.text }}
                            disabled={true}
                        />
                    </GroupItem>
                </GroupItem>
            </Form>
        </div>
    )
}

export default ScrollBoardProcessCapability
