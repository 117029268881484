import {
    Column,
    DataGrid,
    Editing,
    Item,
    Lookup,
    Scrolling,
    Toolbar,
    ValidationRule,
} from 'devextreme-react/data-grid'
import { Label, SimpleItem } from 'devextreme-react/form'
import TextTitle from 'src/functions/TextTitle'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'
import { useSelector } from 'react-redux'
import { SelectBox } from 'devextreme-react'
import CatalogUtils from 'src/functions/CatalogUtils'
import { forwardRef } from 'react'

const DataGridSimpleItem = forwardRef(
    ({ colSpan, type, dataSource, onSave, configState, othersPage }, ref) => {
        const mapFilterField = (type, useField) => {
            if (!['part', 'char', 'value'].includes(type)) {
                throw new Error('Invalid type')
            }

            const translationPrefix = {
                part: 'Db_field_Part_',
                char: 'Db_field_Characteristics_',
                value: 'Db_field_Value_',
            }

            return useField[type].map((item) => {
                let captionCondition = ''
                if (configState.commonSettings.displayFieldNameWithNumber) {
                    captionCondition += `[ ${item.text} ] `
                }
                captionCondition += dqTranslateMsg(
                    translationPrefix[type] + item.text
                )
                return { value: item.value, text: captionCondition }
            })
        }

        const useField = othersPage.useFieldList
        // const useField = ouseFieldList
        const useFilterField = mapFilterField(type, useField)

        const onEditorPreparing = (e) => {
            if (e.parentType === 'dataRow' && e.dataField === 'value') {
                const currentFieldId = e.row?.data?.field_id

                if (currentFieldId === 'v0005') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0005,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else if (currentFieldId === 'v0007') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0007,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else if (currentFieldId === 'v0008') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0008,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else if (currentFieldId === 'v0010') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0010,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else if (currentFieldId === 'v0011') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0011,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else if (currentFieldId === 'v0012') {
                    e.dataType = 'string'
                    e.editorName = 'dxSelectBox'
                    e.editorOptions = {
                        dataSource: othersPage.catalogs.v0012,
                        valueExpr: 'no',
                        displayExpr: 'desc',
                        value: e.row?.data?.value || '', // 초기값 설정
                        onValueChanged: (args) => {
                            // Update the value in the row data when editor value changes
                            e.row.data.value = args.value
                            e.component.cellValue(
                                e.row.rowIndex,
                                'value',
                                args.value
                            ) // Update grid cell
                        },
                    }
                } else {
                    e.dataType = 'string'
                    e.editorName = 'dxTextBox'
                    e.editorOptions.lookup = null
                }
            }
        }

        function cellRender(e) {
            const currentFieldId = e.row?.data?.field_id

            if (CatalogUtils.isCatalogField(currentFieldId)) {
                const value = CatalogUtils.convertByCatalog(
                    currentFieldId,
                    e.value,
                    othersPage.catalogs
                )
                return value
            }

            return e.value
        }

        return (
            // <SimpleItem colSpan={colSpan}>
            <DataGrid
                ref={ref}
                style={{ width: '100%', height: '150px', padding: '3px' }}
                showRowLines={true}
                showBorders={true}
                dataSource={dataSource}
                onSaved={onSave}
                noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
                scrolling={{
                    mode: 'standard',
                    showScrollbar: 'onHover',
                    useNative: false,
                }}
                onEditorPreparing={onEditorPreparing} // Handle dynamic lookup
            >
                <Editing
                    mode='batch'
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                />
                <Scrolling mode='standard' />

                <Column
                    dataField='field_id'
                    width='50%'
                    caption={dqTranslateMsg('Page_Database_' + 'FieldID')}
                    alignment='center'
                >
                    <Lookup
                        dataSource={useFilterField}
                        width={'auto'}
                        valueExpr='value'
                        displayExpr='text'
                    />
                    <ValidationRule type='required' />
                </Column>
                <Column
                    dataField='value'
                    width='50%'
                    caption={dqTranslateMsg(
                        'Page_Database_' + 'ConditionValue'
                    )}
                    alignment='center'
                    cellRender={cellRender}
                >
                    <ValidationRule type='required' />
                </Column>
            </DataGrid>
            // </SimpleItem>
        )
    }
)

export default DataGridSimpleItem
