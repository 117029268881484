import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { indStyles } from '../define/IndividualReportStyle'
import { getDBFieldMsg, getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import IconLoader from 'src/functions/IconLoader'

export const getReportFieldValue = (field, partRaw, charRaw) => {
    let value
    if (field.startsWith('p') && partRaw.hasOwnProperty(field)) {
        value = partRaw[field]
    } else if (field.startsWith('c') && charRaw.hasOwnProperty(field)) {
        value = charRaw[field]
    } else {
        value = 'not found'
    }
    return value
}

export const contentFields = (field, value, partRaw, charRaw) => {
    let type
    let desc
    if (partRaw.hasOwnProperty(field)) {
        type = 'Part'
        desc = getDBFieldMsg(
            type,
            field.charAt(0).toUpperCase() + field.slice(1)
        )
    } else if (charRaw.hasOwnProperty(field)) {
        type = 'Characteristics'
        desc = getDBFieldMsg(
            type,
            field.charAt(0).toUpperCase() + field.slice(1)
        )
    } else {
        desc = 'not found'
    }
    return (
        <View style={indStyles.info}>
            <View style={indStyles.textView}>
                <Text
                    wrap={false}
                    style={indStyles.leftText}
                >
                    {`${desc} : ${value}`}
                </Text>
            </View>
        </View>
    )
}
