import React, { useEffect, useRef } from 'react'
import Form, {
    EmptyItem,
    GroupItem,
    Label,
    SimpleItem,
} from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DateBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DateBoxSimpleItem'
import { format, parseISO } from 'date-fns'
import DateBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/DateBoxSimpleItemNew'
import CheckBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItemNew'

function formatDateBoxValue(dateString) {
    const year = parseInt(dateString.substring(0, 4), 10)
    const month = parseInt(dateString.substring(4, 6), 10) - 1 // Month is 0-indexed
    const day = parseInt(dateString.substring(6, 8), 10)
    const hour = parseInt(dateString.substring(8, 10), 10)
    const minute = parseInt(dateString.substring(10, 12), 10)
    const second = parseInt(dateString.substring(12, 14), 10)

    return new Date(year, month, day, hour, minute, second)
}

const QuickDateTimeOption = ({ currentFilter, setCurrentFilter }) => {
    if (currentFilter === null) {
        return null
    }
    const onCheckBoxEnableDateTimeValueChanged = (e, dataField) => {
        if (e.event === undefined) return

        try {
            const value = e.value
            const next = produce(currentFilter, (draft) => {
                draft.others[dataField] = value
                // Ensure useEasyLoad is set to false only if value is true
                if (value) {
                    draft.others.useEasyLoad = false
                }
            })
            setCurrentFilter(next)
            // dispatch(ps[pageKey].setPageSettingsToRedux(next))
        } catch (error) {
            console.error(
                'Error in onCheckBoxEnableDateTimeValueChanged:',
                error
            )
        }
    }

    const onDateBoxStartDateTimeValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const selectedDate = new Date(value)
        const converted = format(selectedDate, 'yyyyMMddHHmmss')
        const endTime = parseInt(currentFilter.common.endDateTime)

        if (parseInt(converted) > endTime) {
            // Reset to previous value and show an error
            const oldValue = e.previousValue
            e.component.option('value', oldValue)
            alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
        } else {
            const next = produce(currentFilter, (draft) => {
                draft.common[dataField] = converted
            })
            setCurrentFilter(next)
            // dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    const onDateBoxEndDateTimeValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const selectedDate = new Date(value)
        const converted = format(selectedDate, 'yyyyMMddHHmmss')
        const startTime = parseInt(currentFilter.common.startDateTime)

        if (startTime > parseInt(converted)) {
            // Show an error if end time is less than start time
            alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
            // Optionally reset to previous value if needed
            const oldValue = e.previousValue
            e.component.option('value', oldValue)
        } else {
            const next = produce(currentFilter, (draft) => {
                draft.common[dataField] = converted
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`date-time-option-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'DateTime')}
            >
                {/* Enable CheckBox */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <CheckBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg('SidePanel_' + 'Enable')}
                            labelLocation='right'
                            name='useDateTime'
                            value={currentFilter.others.useDateTime}
                            onValueChanged={(e) =>
                                onCheckBoxEnableDateTimeValueChanged(
                                    e,
                                    'useDateTime'
                                )
                            }
                        />
                    )}
                >
                    <Label
                        text={dqTranslateMsg('SidePanel_' + 'Enable')}
                        location={'top'}
                        alignment={'left'}
                        showColon={false}
                    />
                </SimpleItem>

                {/* EmptyItem for layout */}
                <EmptyItem />

                {/* Start DateBox */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <DateBoxSimpleItemNew
                            label={dqTranslateMsg(
                                'SidePanel_' + 'StartDateTime'
                            )}
                            labelLocation='top'
                            name='startDateTime'
                            pickerType='calendar'
                            displayFormat='yyyy-MM-dd HH:mm:ss'
                            value={formatDateBoxValue(
                                currentFilter.common.startDateTime
                            )}
                            disabled={
                                currentFilter.others.useDateTime === false
                            }
                            onValueChanged={(e) =>
                                onDateBoxStartDateTimeValueChanged(
                                    e,
                                    'startDateTime'
                                )
                            }
                        />
                    )}
                />

                {/* End DateBox */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <DateBoxSimpleItemNew
                            label={dqTranslateMsg('SidePanel_' + 'EndDateTime')}
                            labelLocation='top'
                            name='endDateTime'
                            pickerType='calendar'
                            displayFormat='yyyy-MM-dd HH:mm:ss'
                            value={formatDateBoxValue(
                                currentFilter.common.endDateTime
                            )}
                            disabled={
                                currentFilter.others.useDateTime === false
                            }
                            onValueChanged={(e) =>
                                onDateBoxEndDateTimeValueChanged(
                                    e,
                                    'endDateTime'
                                )
                            }
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default QuickDateTimeOption
