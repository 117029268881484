// SettingsCreateTemplate
import React, { useEffect } from 'react'
import { GroupItem, SimpleItem, Form } from 'devextreme-react/form'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'

const SettingsCreateTemplate = ({ mode, setIsOpenCreateTemplate }) => {
    const handleCreateTemplateClick = () => {
        setIsOpenCreateTemplate(true)
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
            visible={mode}
        >
            <GroupItem
                key='report-settings-create-template-group-item'
                colCount={2}
                caption={getCommonMsg(msgType.SidePanel, 'CreateTemplate')}
            >
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <ButtonSimpleItemNew
                            colSpan={2}
                            type='normal'
                            text={getCommonMsg(msgType.SidePanel, 'New')}
                            icon='add'
                            width='100%'
                            height='100%'
                            onClick={handleCreateTemplateClick}
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default SettingsCreateTemplate
