import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import './SidePanelForm.scss'
import SidePanelDatabaseForm from './component/side-panel-database-form/SidePanelDatabaseForm'
import SidePanelDQSettingsForm from './component/side-panel-dq-settings-form/SidePanelDQSettingsForm'
import SidePanelFilterForm from './component/side-panel-filter-form/SidePanelFilterForm'
import SidePanelThemeForm from './component/side-panel-theme-form/SidePanelThemeForm'

export default function SidePanelForm(props) {
    const {
        pageKey,
        disable,
        setSidePanelState,
        // selectedTabIndex,
        // setSelectedTabIndex,
    } = props
    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const common = useSelector((state) => state.common)
    const chartRawData = useSelector((state) => state.chartRawData)
    const panelRef = useRef(null)

    const notUseDatabaseFormList = ['database', 'monitoring', 'scrollBoard']
    const notUseSettingsFormList = ['database', 'reportPrint']
    const notUseThemeFormList = [
        'database',
        'decisionTreeChart',
        'valueList',
        'reportPrint',
        'linearRegressionChart',
        'monitoring',
        'reportPrintMultiFunction',
    ]
    const useFilterFormList = ['scrollBoard', 'database']
    const isDatabaseNotIncluded = notUseDatabaseFormList.includes(pageKey)
    const isSettingsNotIncluded = notUseSettingsFormList.includes(pageKey)
    const isThemeNotIncluded = notUseThemeFormList.includes(pageKey)
    const isFilterNotIncluded = !useFilterFormList.includes(pageKey)

    const getCountTabCount = (pageKey) => {
        let count = 3
        if (notUseDatabaseFormList.includes(pageKey)) {
            count -= 1
        }
        if (notUseThemeFormList.includes(pageKey)) {
            count -= 1
        }
        return count
    }

    const tabCount = getCountTabCount(pageKey)
    let tabClassName = ''
    if (tabCount === 3) {
        tabClassName = 'side-panel-tab-container side-panel-tab-container-three'
    } else if (tabCount === 2) {
        tabClassName = 'side-panel-tab-container side-panel-tab-container-two'
    } else if (tabCount === 1) {
        tabClassName = 'side-panel-tab-container side-panel-tab-container-one'
    } else {
        tabClassName = 'side-panel-tab-container side-panel-tab-container-three'
    }

    return (
        <div className='dx-theme-background-color'>
            <TabPanel
                className={'tabClassName'}
                ref={panelRef}
                animationEnabled={true}
                swipeEnabled={false}
                focusStateEnabled={false}
                deferRendering={false}
                width={'100%'}
                height={'100%'}
                selectedIndex={selectedTabIndex}
                onSelectionChanged={(e) =>
                    setSelectedTabIndex(e.component.option('selectedIndex'))
                }
            >
                {!isDatabaseNotIncluded ? (
                    <Item
                        icon='activefolder'
                        disabled={disable}
                    >
                        <SidePanelDatabaseForm
                            pageKey={pageKey}
                            common={common}
                            chartRawData={chartRawData}
                        />
                    </Item>
                ) : null}
                {!isSettingsNotIncluded ? (
                    <Item
                        icon='preferences'
                        disabled={disable}
                    >
                        <SidePanelDQSettingsForm pageKey={pageKey} />
                    </Item>
                ) : null}
                {!isThemeNotIncluded ? (
                    <Item
                        icon='palette'
                        disabled={disable}
                    >
                        <SidePanelThemeForm pageKey={pageKey} />
                    </Item>
                ) : null}
                {!isFilterNotIncluded ? (
                    <Item
                        icon='filter'
                        disabled={disable}
                    >
                        <SidePanelFilterForm pageKey={pageKey} />
                    </Item>
                ) : null}
            </TabPanel>
        </div>
    )
}
