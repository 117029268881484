export const companyPlantTbDescription = {
    license: 'company_admin_config_1',
    language: 'company_admin_config_2',
    reserved_3: 'company_admin_config_3',
    reserved_4: 'company_admin_config_4',
    useFields: 'company_admin_config_5',
    catalogs: 'company_admin_config_6',
    reportTemplates: 'company_admin_config_7',
    reserved_8: 'company_admin_config_8',
    quickFilters: 'company_admin_config_9',
    advancedFilters: 'company_admin_config_10',
    reportLogo: 'company_admin_config_11',
}
