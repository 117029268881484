import { DateBox } from 'devextreme-react'
import { Label, SimpleItem } from 'devextreme-react/form'

const DateBoxSimpleItem = ({
    colSpan = 2,
    label,
    labelLocation,
    name,
    pickerType,
    displayFormat,
    value,
    disabled,
    onValueChanged,
}) => {
    let calendarOptions = {}
    if (displayFormat === 'HH:mm') {
        calendarOptions = {
            visible: false,
        }
    } else {
        calendarOptions = {
            visible: true,
        }
    }

    return (
        <SimpleItem colSpan={colSpan}>
            <Label
                text={label}
                location={labelLocation}
            />
            <DateBox
                name={name}
                // label={label}
                // labelMode={labelLocation}
                displayFormat={displayFormat}
                pickerType={pickerType}
                value={value}
                useNative={true}
                useMaskBehavior={true}
                interval={1}
                type='datetime'
                disabled={disabled}
                onValueChanged={onValueChanged}
                calendarOptions={calendarOptions}
            />
        </SimpleItem>
    )
}

export default DateBoxSimpleItem

// import React from 'react'
// import { DateBox } from 'devextreme-react'
// import { Label, SimpleItem } from 'devextreme-react/form'

// const DateBoxSimpleItemNew = ({
//     colSpan = 2,
//     label,
//     labelLocation = 'top', // 기본 값 설정
//     name,
//     pickerType,
//     displayFormat,
//     value,
//     disabled = false,
//     onValueChanged,
// }) => {
//     let calendarOptions = {}

//     if (displayFormat === 'HH:mm') {
//         calendarOptions = {
//             visible: false,
//         }
//     } else {
//         calendarOptions = {
//             visible: true,
//         }
//     }

//     return (
//         <SimpleItem
//             colSpan={colSpan}
//             disabled={disabled}
//         >
//             {/* Label rendering if exists */}
//             {label && (
//                 <Label
//                     text={label}
//                     location={labelLocation}
//                 />
//             )}

//             {/* DateBox Component */}
//             <div
//                 style={{
//                     width: '100%',
//                     display: 'flex',
//                     justifyContent: 'center',
//                 }}
//             >
//                 <DateBox
//                     name={name}
//                     displayFormat={displayFormat}
//                     pickerType={pickerType}
//                     value={value}
//                     useNative={true}
//                     useMaskBehavior={true}
//                     interval={1}
//                     type='datetime'
//                     disabled={disabled}
//                     onValueChanged={onValueChanged}
//                     calendarOptions={calendarOptions}
//                 />
//             </div>
//         </SimpleItem>
//     )
// }

// export default DateBoxSimpleItemNew
