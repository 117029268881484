import Form, {
    EmptyItem,
    GroupItem,
    Label,
    SimpleItem,
} from 'devextreme-react/form'
import produce from 'immer'
import { batch } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItemNew'
import NumberBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItemNew'
import SelectBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItemNew'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'

const AdvancedLastNFilterOption = ({ currentFilter, setCurrentFilter }) => {
    if (currentFilter === null) {
        return null
    }

    const isNFilter = currentFilter.others.useEasyLoad
    const nFilterCount = currentFilter.common.easyLoadValue
    const nFilterType = currentFilter.others.easyLoadValueType
    const isSaved = true

    const handleNumberBoxNFilterCountChange = (e) => {
        let count = 0
        if (nFilterType === 'week' && e.value > 10000) {
            count = 10000
        } else if (nFilterType === 'day' && e.value > 10000 * 7) {
            count = 10000 * 7
        } else if (nFilterType === 'hour' && e.value > 10000 * 7 * 24) {
            count = 10000 * 7 * 24
        } else {
            count = e.value
        }
        const minOption = e.component.option('min')

        const next = produce(currentFilter, (draft) => {
            draft.common.easyLoadValue = count === null ? minOption : count
        })
        setCurrentFilter(next)
        // setNFilterCount(count === null ? minOption : count)
    }

    const handleSelectBoxNFilterTypeChange = (e) => {
        const type = e.itemData.value
        let count = 0
        if (type === 'week' && nFilterCount > 10000) {
            count = 10000
        } else if (type === 'day' && nFilterCount > 10000 * 7) {
            count = 10000 * 7
        } else if (type === 'hour' && nFilterCount > 10000 * 7 * 24) {
            count = 10000 * 7 * 24
        } else {
            count = nFilterCount
        }

        batch(() => {
            const next = produce(currentFilter, (draft) => {
                draft.common.easyLoadValue = count
                draft.others.easyLoadValueType = type
            })
            setCurrentFilter(next)
        })
    }

    const handleCheckBoxEnableLastNFilterChange = (e, dataField) => {
        if (e.event === undefined) return

        const value = e.value

        batch(() => {
            const next = produce(currentFilter, (draft) => {
                draft.others[dataField] = value
                if (value) {
                    draft.others.useDateTime = false
                }
            })
            setCurrentFilter(next)
        })
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`last-n-filter-option-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'LastNFilter')}
            >
                {/* CheckBox to enable/disable easy load */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <CheckBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg('SidePanel_' + 'Enable')}
                            labelLocation='right'
                            name='useEasyLoad'
                            value={isNFilter}
                            disable={false}
                            onValueChanged={(e) =>
                                handleCheckBoxEnableLastNFilterChange(
                                    e,
                                    'useEasyLoad'
                                )
                            }
                        />
                    )}
                >
                    <Label
                        text={dqTranslateMsg('SidePanel_' + 'Enable')}
                        location={'top'}
                        alignment={'left'}
                        showColon={false}
                    />
                </SimpleItem>
                <EmptyItem />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <NumberBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg('SidePanel_' + 'Count')}
                            labelLocation='top'
                            name='easyLoadValue'
                            min={1}
                            max={100000000}
                            step={1}
                            value={nFilterCount}
                            disabled={!isNFilter}
                            onValueChanged={handleNumberBoxNFilterCountChange}
                        />
                    )}
                ></SimpleItem>
                {/* SelectBox for N Filter Type */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <SelectBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg('SidePanel_' + 'Type')}
                            labelLocation='top'
                            name='easyLoadValueType'
                            dataSource={sd.easyLoadTypeDefine()}
                            valueExpr='value'
                            displayExpr='text'
                            value={nFilterType}
                            disabled={!isNFilter}
                            onItemClick={handleSelectBoxNFilterTypeChange}
                        />
                    )}
                ></SimpleItem>
            </GroupItem>
        </Form>
    )
}

export default AdvancedLastNFilterOption
