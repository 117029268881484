const bodyFormat = {
    requestMsg: {},
}

export const makeDeleteAdminCompanyPrms = (company, plant) => {
    let body = { ...bodyFormat }
    body.requestMsg = {
        company: company,
        plant: plant,
    }
    return body
}

export const makeGetAdminCompanyPrms = (company, plant, updateData) => {
    let body = { ...bodyFormat }
    body.requestMsg = {
        company: company,
        plant: plant,
        ...updateData,
    }
    return body
}

export const makeGetAdminCompanyListPrms = () => {
    let body = { ...bodyFormat }
    body.requestMsg = {}
    return body
}

export const makeGetAdminCompanyPlantListPrms = (company, plant) => {
    let body = { ...bodyFormat }
    body.requestMsg = {
        company: company,
        plant: plant,
    }
    return body
}

export const makeUpdateAdminCompanyPrms = (company, plant, updateData) => {
    let body = { ...bodyFormat }
    body.requestMsg = {
        company: company,
        plant: plant,
        ...updateData,
    }
    return body
}

export const makeUploadCompanyLogo = (company, plant, file) => {
    const formData = new FormData()
    formData.append('company', company)
    formData.append('plant', plant)
    formData.append('file', file)
    formData.append('codepage', 932)

    return formData
}
