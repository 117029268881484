import React from 'react'
import { RadioGroup } from 'devextreme-react'
import { Label } from 'devextreme-react/form'

const RadioGroupSimpleItemNew = ({ colSpan, label, labelLocation, name, value, items, itemRender, layout, valueExpr, displayExpr, disabled = false, onValueChanged = null, visible = true }) => {
    if (!visible) return null

    return (
        <div style={{ width: '100%' }}>
            {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RadioGroup
                    name={name}
                    layout={layout}
                    disabled={disabled}
                    value={value}
                    items={items}
                    itemRender={itemRender}
                    onValueChanged={onValueChanged}
                    valueExpr={valueExpr}
                    displayExpr={displayExpr}
                    width='100%'
                />
            </div>
        </div>
    )
}

export default RadioGroupSimpleItemNew
