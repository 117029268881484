import { useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import './ReportPrintMultiFunctionForm.scss'
import ReportPrintPage from './components/ReportPrintPage'

export default connect()(ReportPrintMultiFunctionForm)

function ReportPrintMultiFunctionForm({ pageKey }) {
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)

    return (
        <div className={'dx-theme-background-color'}>
            <DQLoadPanel open={isLoadingPanel} />
            <div style={{ width: '100%', height: '100%' }}>
                <ReportPrintPage pageKey={pageKey} />
            </div>
        </div>
    )
}
