import { CheckBox, SelectBox } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import { convertUserRoleToString, userRoleLevel } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminRibbon.scss'
import AdminRibbonDataGrid from './AdminRibbonDataGrid'
import AdminRibbonPreview from './AdminRibbonPreview'
import AdminRibbonTreeList from './AdminRibbonTreeList'
import DQToast from '../../../../../functions/DQToast'
import { useAuth } from 'src/contexts/auth'
import { defaultRibbon } from '../../settings/AdminSettings'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import produce from 'immer'
import { getAdminGroup, updateAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms, makeUpdateAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { groupTbDescription as groupTbDesc } from 'src/functions/constants/groupDBFieldDescription'
import * as rd from 'src/store/common'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useApi } from 'src/contexts/api'
import { makeAdminGroupToDTOFormat } from 'src/dto/DTOImpl'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(AdminRibbon)

function AdminRibbon({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const dispatch = useDispatch()
    const { spring } = useApi()
    const { user } = useAuth()
    const toastRef = useRef(null)

    const othersPage = common.pages.others

    const [isDefaultSettings, setIsDefaultSettings] = useState(true)
    const [savedItems, setSavedItems] = useState([])

    useEffect(() => {
        setSavedItems(othersPage.ribbon)
    }, [othersPage])

    const entireItems = Object.entries(ps).reduce((acc, [key, value], currentIndex) => {
        const iconType = common.pages.configuration.menuIconType
        const theme = getTheme()
        const menuItem = {
            name: key,
            isDataNeed: value.showWithData,
            icon: value.getPageIcon(iconType, theme), // 메뉴 아이템의 아이콘을 가져옵니다. 이 메서드가 정의되어 있어야 합니다.
            parent: '',
            type: value.type,
        }

        // if (menuItem.name !== 'scrollBoard') {
        acc.push(menuItem) // 계산된 메뉴 아이템을 누산기 배열에 추가
        // }

        return acc
    }, [])

    const handleSaveButton = async () => {
        const updateGroupSettings = async (element, field, pageValue) => {
            const company = element.company
            const plant = element.plant
            const group = element.group
            const dataJsonStr = JSON.stringify(pageValue)

            const updateData = {
                [field]: dataJsonStr,
            }

            await updateAdminGroup(spring, makeUpdateAdminGroupPrms(company, plant, group, updateData))
        }

        isDefaultSettings ? console.log('setting from default group settings') : console.log('setting from individual settings')

        const next = produce(othersPage, (draft) => {
            draft.ribbon = savedItems
        })

        if (isDefaultSettings) {
            const element = {
                company: user.user_company,
                plant: user.user_plant,
                group: user.user_group,
            }
            await updateGroupSettings(element, groupTbDesc.ribbon, next.ribbon)
        }

        dispatch(rd.setRibbon(next.ribbon))
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
    }

    const handleResetButton = async () => {
        const company = user?.user_company
        const plant = user?.user_plant
        const group = user?.user_group

        if (!company || !plant || !group) {
            console.error('company plant group info is invalid')
            return
        }
        const targetGroupInfo = await getAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
        const adminGroupDTO = makeAdminGroupToDTOFormat(targetGroupInfo)
        const ribbonDataStr = adminGroupDTO[groupTbDesc.ribbon]

        let ribbonData = null
        if (ribbonDataStr === '' || ribbonDataStr === null) {
            console.error('group ribbon data is empty')
            ribbonData = defaultRibbon
        } else {
            ribbonData = JSON.parse(ribbonDataStr)
        }
        console.log(`group ribbon :  <${ribbonData}>`)

        const next = produce(othersPage, (draft) => {
            draft.ribbon = ribbonData
        })

        dispatch(rd.setRibbon(next.ribbon))
        toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_AdminSettings_' + 'ResetSettings'))
    }

    const handleCheckboxChange = (e) => {
        setIsDefaultSettings(e.value)
    }

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />
            <div className='admin-language-control-container'>
                <div className='button-container'>
                    <CheckBox
                        key={'ribbon-save-check-box'} // key 추가
                        text={dqTranslateMsg('Page_Configuration_' + 'Apply') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Group') + ' ' + dqTranslateMsg('Page_Configuration_' + 'Save')}
                        value={isDefaultSettings} // 권한 상태에 따라 체크 여부 결정
                        width={'100%'}
                        height={'100%'}
                        disabled={true}
                        // onValueChanged={handleCheckboxChange} // 상태 변경 핸들러 추가
                    />
                </div>
                <div className='button-container'>
                    <Button
                        icon='save'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Save')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleSaveButton}
                    />
                </div>

                <div className='button-container'>
                    <Button
                        icon='undo'
                        text={dqTranslateMsg('Page_AdminSettings_' + 'Reset')}
                        width={'100%'}
                        height={'100%'}
                        onClick={handleResetButton}
                    />
                </div>
            </div>
            <div style={{ width: '35%', height: '96%', padding: '10px' }}>
                <AdminRibbonDataGrid
                    title={dqTranslateMsg('Page_AdminSettings_' + 'SelectionMenu')}
                    userRole={user.user_group}
                    savedItems={savedItems}
                    entireItems={entireItems}
                    setSavedItems={setSavedItems}
                />
            </div>
            <div style={{ width: '35%', height: '96%', padding: '10px' }}>
                <AdminRibbonTreeList
                    title={dqTranslateMsg('Page_AdminSettings_' + 'MakeHierarchy')}
                    userRole={user.user_group}
                    savedItems={savedItems}
                    entireItems={entireItems}
                    setSavedItems={setSavedItems}
                />
            </div>
            <div
                style={{
                    width: '30%',
                    height: '96%',
                    padding: '10px',
                }}
            >
                <div style={{ display: 'flex', width: '100%', height: '96%' }}>
                    <div style={{ width: '100%', height: '100%' }}>
                        <AdminRibbonPreview
                            title={dqTranslateMsg('Page_AdminSettings_' + 'PreviewDataLoaded')}
                            dataLoaded={true}
                            userRole={user.user_group}
                            savedItems={savedItems}
                            entireItems={entireItems}
                            ribbon={savedItems}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
