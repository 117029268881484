import { defValueField } from 'src/default/field/value'
import CatalogUtils from 'src/functions/CatalogUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    DataGrid,
    Editing,
    Export,
    Item,
    Lookup,
    Pager,
    Paging,
    Position,
    Scrolling,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CPUtils from 'src/functions/CommonPageUtils'
import { TagBox } from 'devextreme-react'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'

// function isValidYyyyMmDdHhMmSs(value) {
//     const parse = require('date-fns/parse')
//     const isValid = require('date-fns/isValid')
//     try {
//         const date = parse(value, 'yyyyMMddHHmmss', new Date())
//         return isValid(date)
//     } catch (error) {
//         return false
//     }
// }

function isValidYyyyMmDdHhMmSs(value) {
    if (!/^\d{14}$/.test(value)) return false // 정규식으로 형식 검사
    const year = parseInt(value.substr(0, 4), 10)
    const month = parseInt(value.substr(4, 2), 10) - 1 // 월은 0-11 범위
    const day = parseInt(value.substr(6, 2), 10)
    const hour = parseInt(value.substr(8, 2), 10)
    const minute = parseInt(value.substr(10, 2), 10)
    const second = parseInt(value.substr(12, 2), 10)
    const date = new Date(year, month, day, hour, minute, second)

    return (
        date.getFullYear() === year &&
        date.getMonth() === month &&
        date.getDate() === day &&
        date.getHours() === hour &&
        date.getMinutes() === minute &&
        date.getSeconds() === second
    )
}

function isValidDateTimeFormate(value) {
    // 기본 정규식 확인 (14자리 숫자)
    if (!/^\d{14}$/.test(value)) {
        return false
    }
    // 각 값 추출
    const year = parseInt(value.substring(0, 4), 10)
    const month = parseInt(value.substring(4, 6), 10)
    const day = parseInt(value.substring(6, 8), 10)
    const hour = parseInt(value.substring(8, 10), 10)
    const minute = parseInt(value.substring(10, 12), 10)
    const second = parseInt(value.substring(12, 14), 10)

    // 월 범위 검사 (1 ~ 12)
    if (month < 1 || month > 12) {
        return false
    }

    // 일 범위 검사 (월별 일수 확인)
    const daysInMonth = new Date(year, month, 0).getDate() // 해당 월의 마지막 날
    if (day < 1 || day > daysInMonth) {
        return false
    }

    // 시간 범위 검사 (0 ~ 23)
    if (hour < 0 || hour > 23) {
        return false
    }

    // 분, 초 범위 검사 (0 ~ 59)
    if (minute < 0 || minute > 59 || second < 0 || second > 59) {
        return false
    }

    // 유효한 값
    return true
}

function renderWarning(numericValue, char, configSettings) {
    return (
        <div className='value-list-cp-cpk-container'>
            <div style={{ color: 'red' }}>
                {DNUtils.valueDisplay(numericValue, char, configSettings)}
            </div>
            <img
                src={'/images/color/warning-exclamation.svg'}
                width='15'
                height='15'
                alt='Warning'
            />
        </div>
    )
}

function renderValue(numericValue, char, configSettings) {
    return (
        <div className='value-list-cp-cpk-container'>
            <div style={{ fontSize: '12px' }}>
                {DNUtils.valueDisplay(numericValue, char, configSettings)}
            </div>
        </div>
    )
}

function handleValueRender(
    e,
    dataField,
    lsl,
    usl,
    char,
    settings,
    configSettings,
    catalogs
) {
    const value = e.data?.[dataField] // Use optional chaining to safely access the data field

    if (value === undefined) return

    // if (!dataField.includes('v0001')) {
    if (dataField.includes('v0001')) {
        //2024-12-10 hodo null 처리
        const numericValue = value !== null ? Number(value) : null // Convert the value to a number once

        if (numericValue === null || isNaN(numericValue)) {
            // Return an empty container if the value is NaN
            return (
                <div className='value-list-cp-cpk-container'>
                    <div style={{ fontSize: '12px' }}></div>
                </div>
            )
        }

        if (lsl === null && usl === null) {
            return renderValue(numericValue, char, configSettings)
        }

        if (lsl !== null && usl !== null) {
            if (numericValue < lsl || numericValue > usl) {
                return renderWarning(numericValue, char, configSettings)
            }
            return renderValue(numericValue, char, configSettings)
        }

        if (lsl !== null && usl === null) {
            if (numericValue < lsl) {
                return renderWarning(numericValue, char, configSettings)
            }
            return renderValue(numericValue, char, configSettings)
        }

        if (usl !== null && numericValue > usl) {
            return renderWarning(numericValue, char, configSettings)
        }

        return renderValue(numericValue, char, configSettings)
        // } else if (dataField.includes('v0005')) {
    } else {
        let convertedValue = value

        if (dataField.includes('v0004')) {
            // Use the utility function to convert the value to a date-time format
            convertedValue = CPUtils.getDisplayDateTimeSec(
                settings.page.dateTimeExtraFieldDisplayFormat,
                value
            )
            // } else if (dataField.includes('v0005')) {
            //     console.log(value)
        } else {
            const splitted = dataField.split('_')
            const field = splitted[splitted.length - 1]

            if (CatalogUtils.isCatalogField(field)) {
                // Use the utility function to convert the value based on the catalog
                convertedValue = CatalogUtils.convertByCatalog(
                    field,
                    value,
                    catalogs
                )
                console.log('true')
            } else {
                console.log('false')
            }
        }

        return (
            <div className='value-list-cp-cpk-container'>
                <div style={{ fontSize: '12px' }}>
                    {convertedValue !== 'NaN' ? convertedValue : null}
                </div>
            </div>
        )
    }
}

const handleLookupMultipleValueChanged = (e, dataField) => {
    // Ensure the display value is correctly set after a value change
    e.setValue(args.values.join(','))
}
const handleLookupValueChanged = (e, dataField) => {
    // Ensure the display value is correctly set after a value change
    const { component, value } = e
    const gridInstance = component._options.editing.changes // Get the instance of the grid
    const rowIndex = e.row.rowIndex
    const columnIndex = component.columnIndex

    const displayValue = othersPage.catalogs[dataField].find(
        (item) => item.no === value
    )?.desc

    if (gridInstance) {
        gridInstance[rowIndex][columnIndex] = displayValue
    }
}
const renderCatalogSelector = (othersPage, targetField) => {
    const isCatalogField = CatalogUtils.isCatalogField(targetField)

    if (targetField === 'v0005') {
        return (
            <TagBox
                dataSource={othersPage.catalogs[targetField]}
                valueExpr='no'
                displayExpr='desc'
                searchEnabled={true} // 검색 기능 활성화
                showSelectionControls={true} // 체크박스 활성화
                onValueChanged={(e) =>
                    handleLookupMultipleValueChanged(e, dataField)
                }
                multiline={true} // 다중 선택 표시를 줄바꿈으로 처리
            />
        )
    } else if (isCatalogField) {
        return (
            <Lookup
                dataSource={othersPage.catalogs[targetField]}
                valueExpr='no'
                displayExpr='desc'
                onValueChanged={(e) => handleLookupValueChanged(e, dataField)}
            />
        )
    } else {
        return null
    }
}

const makeCharColumn = (
    groupIndex,
    char,
    values,
    settings,
    configSettings,
    othersPage,
    viewData
) => {
    if (values.valueRaw.length > 0) {
        // values 배열이 비어있지 않은 경우에만 접근
        const mainBandKey = `part_id-${char.part_id},char_id-${char.char_id}` // Band를 구분할 고유 키

        return (
            <Column
                key={`group-main-header-${groupIndex}`}
                name={mainBandKey}
                cssClass={groupIndex % 2 === 0 ? 'even-column' : 'odd-column'}
                dataField={char.c2002}
                caption={char.c2002}
                minWidth={100}
                allowResizing={true}
                width={'auto'}
                alignment='center'
                isBand={true}
            >
                {viewData.map((view, index) => {
                    const upperFirstLetter =
                        view.charAt(0).toUpperCase() + view.slice(1)

                    const dataField =
                        values.valueRaw[0].part_id +
                        '_' +
                        values.valueRaw[0].char_id +
                        '_' +
                        view

                    const keySplitted = dataField.split('_')
                    const targetField = keySplitted[keySplitted.length - 1]
                    const targetInfo = defValueField().find(
                        (item) => item.value === targetField
                    )
                    return (
                        <Column
                            key={`group-sub-header-${groupIndex}-${index}`}
                            name={`group-sub-name-${groupIndex}-${index}`}
                            cssClass={
                                groupIndex % 2 === 0
                                    ? 'even-column'
                                    : 'odd-column'
                            }
                            dataField={dataField}
                            caption={dqTranslateMsg(
                                'Db_field_' + 'Value_' + upperFirstLetter
                            )}
                            dataType={targetInfo.dataType}
                            visibleIndex={index}
                            allowResizing={true}
                            minWidth={100}
                            width={'auto'}
                            allowReordering={false}
                            renderAsync={false}
                            ownerBand={mainBandKey}
                            // cellRender={(cellData) => handleValueRender(cellData, dataField, char.c2110, char.c2111, char, settings, configSettings, othersPage.catalogs)}
                            cellRender={(cellData) =>
                                handleValueRender(
                                    cellData,
                                    dataField,
                                    char.c2110,
                                    char.c2111,
                                    char,
                                    settings,
                                    configSettings,
                                    othersPage.catalogs
                                )
                            }
                        >
                            {/* {CatalogUtils.isCatalogField(targetField) ? (
                                <Lookup
                                    dataSource={othersPage.catalogs[targetField]}
                                    valueExpr='no'
                                    displayExpr='desc'
                                    onValueChanged={(e) => handleLookupValueChanged(e, dataField)}
                                />
                            ) : null} */}
                            {/* {renderCatalogSelector(othersPage, targetField)} */}
                        </Column>
                    )
                })}
            </Column>
        )
    } else {
        return null
    }
}

export const columns = (
    charArray,
    valuesArranged,
    curPage,
    configPage,
    othersPage,
    viewData
) => {
    return charArray
        .map((char, index) => {
            const targetValue = valuesArranged.find(
                (valueArray) =>
                    valueArray.part_id === char.part_id &&
                    valueArray.char_id === char.char_id
            )

            if (targetValue) {
                return makeCharColumn(
                    index,
                    char,
                    targetValue,
                    curPage,
                    configPage,
                    othersPage,
                    viewData
                )
            }
            return null // Explicitly return null for clarity
        })
        .filter((item) => item !== null)
}
export const parseTargetValueItems = (
    configSettings,
    char,
    values,
    viewData
) => {
    let charValues = []
    let decimalPlaces = char.c2022

    if (decimalPlaces === null) {
        decimalPlaces = configSettings.commonSettings.displayDecimalPlaces
    } else if (decimalPlaces < 0) {
        decimalPlaces = 0
    } else if (decimalPlaces > 15) {
        decimalPlaces = 15
    }

    charValues = values.map((value) => {
        let extractedValues = {}

        for (const property of viewData) {
            if (property === 'v0001') {
                extractedValues[
                    value.part_id + '_' + value.char_id + '_' + property
                ] = value[property]
            } else {
                if (CatalogUtils.isCatalogField(property)) {
                    extractedValues[
                        value.part_id + '_' + value.char_id + '_' + property
                    ] = value[property]
                } else {
                    extractedValues[
                        value.part_id + '_' + value.char_id + '_' + property
                    ] = value[property]
                }
            }
        }

        return extractedValues
    })

    return charValues
}

export const onExporting = (e, valuesArranged) => {
    const arranged = valuesArranged.sort((a, b) => {
        // Ensure both part_id values exist and are numbers
        const partIdA = Number(a.part_id)
        const partIdB = Number(b.part_id)

        if (!Number.isFinite(partIdA) || !Number.isFinite(partIdB)) {
            throw new Error('part_id must be a finite number')
        }

        return partIdA - partIdB
    })

    if (arranged) {
        const fileName =
            arranged[0].partRaw.p1001 + '_' + arranged[0].partRaw.p1002

        if (e.format === 'xlsx') {
            const workbook = new Workbook()
            const worksheet = workbook.addWorksheet('Main sheet')
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], {
                            type: 'application/octet-stream',
                        }),
                        `${fileName}.xlsx`
                    )
                })
            })
        }
    }
}

export const handleCellPrepared = (e) => {
    if (e && e.rowType === 'header') {
        e.cellElement.style.textAlign = 'center'
    }
}

export const handleSaveState = (datagridRef, saveState) => {
    if (datagridRef.current) {
        const state = datagridRef.current.instance.state()
        saveState(state)
    }
}

export const handleLoadState = (datagridRef, loadState) => {
    if (datagridRef.current) {
        const state = loadState()
        if (state) {
            datagridRef.current.instance.state(state)
        }
    }
}

export const handleClearState = (datagridRef, clearState) => {
    clearState()
    if (datagridRef.current) {
        const emptyState = {
            columns: [],
            sorting: [],
            grouping: [],
            selectionFilter: [],
            pageIndex: 0,
            pageSize: 10,
        }
        datagridRef.current.instance.state(emptyState)
    }
}
export const handleOptionChanged = async (
    e,
    selectedChar,
    pageKey,
    datagridRef
) => {
    // 컬럼 리사이즈가 발생했을 때만 처리
    if (e.name !== 'hoveredElement') {
        // 'columns' 속성의 'width'가 변경된 경우만 처리
        if (e.name === 'columns') {
            const grid = e.component
            // const columns = grid.getVisibleColumns() // 모든 컬럼 가져오기
            const columns = grid.getController('columns').getColumns()
            const syncWidth = e.value
            const filteredArr = columns.filter(
                (item) => item.name !== 'no' && item.name !== 'arrangement'
            )
            syncGroupColumnWidths(datagridRef, syncWidth, filteredArr)
        }
    }
}

// 그룹 컬럼들의 너비 동기화 함수
const syncGroupColumnWidths = (datagridRef, syncWidth, othersColumns) => {
    // 그룹 컬럼만 선택
    if (othersColumns.length > 1) {
        // 첫 번째 그룹 컬럼의 너비를 기준으로 나머지 그룹 컬럼의 너비를 설정
        datagridRef.current.instance.beginUpdate()
        othersColumns.forEach((col) => {
            if (col.width !== syncWidth) {
                datagridRef.current.instance.columnOption(
                    col.name,
                    'width',
                    syncWidth
                )
            }
        })
        datagridRef.current.instance.endUpdate()
    }
}

export const onEditorPreparing = (e, othersPage) => {
    // if (e.parentType === 'dataRow' && e.dataField === 'value') {
    if (e.parentType === 'dataRow') {
        if (e.dataField) {
            if (e.dataField.includes('v0005')) {
                e.dataType = 'string'
                e.editorName = 'dxTagBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0005,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    showSelectionControls: true,
                    value:
                        CatalogUtils.parseEventValueToCatalogEditInValueList(
                            e.value
                        ) || [], // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        // e.row.data.value = args.value
                        // e.component.cellValue(e.row.rowIndex, 'value', args.value) // Update grid cell
                        e.setValue(args.value.join(','))
                    },
                }
            } else if (e.dataField.includes('v0007')) {
                e.dataType = 'string'
                e.editorName = 'dxSelectBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0007,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    value: e.row?.data?.value || '', // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        e.row.data.value = args.value
                        e.component.cellValue(
                            e.row.rowIndex,
                            e.dataField,
                            args.value
                        ) // Update grid cell
                        // e.setValue(args.values.join(','))
                    },
                }
            } else if (e.dataField.includes('v0008')) {
                e.dataType = 'string'
                e.editorName = 'dxSelectBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0008,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    value: e.row?.data?.value || '', // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        e.row.data.value = args.value
                        e.component.cellValue(
                            e.row.rowIndex,
                            e.dataField,
                            args.value
                        ) // Update grid cell
                    },
                }
            } else if (e.dataField.includes('v0010')) {
                e.dataType = 'string'
                e.editorName = 'dxSelectBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0010,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    value: e.row?.data?.value || '', // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        e.row.data.value = args.value
                        e.component.cellValue(
                            e.row.rowIndex,
                            e.dataField,
                            args.value
                        ) // Update grid cell
                    },
                }
            } else if (e.dataField.includes('v0011')) {
                e.dataType = 'string'
                e.editorName = 'dxSelectBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0011,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    value: e.row?.data?.value || '', // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        e.row.data.value = args.value
                        e.component.cellValue(
                            e.row.rowIndex,
                            e.dataField,
                            args.value
                        ) // Update grid cell
                    },
                }
            } else if (e.dataField.includes('v0012')) {
                e.dataType = 'string'
                e.editorName = 'dxSelectBox'
                e.editorOptions = {
                    dataSource: othersPage.catalogs.v0012,
                    valueExpr: 'no',
                    displayExpr: 'desc',
                    value: e.row?.data?.value || '', // 초기값 설정
                    onValueChanged: (args) => {
                        // Update the value in the row data when editor value changes
                        e.row.data.value = args.value
                        e.component.cellValue(
                            e.row.rowIndex,
                            e.dataField,
                            args.value
                        ) // Update grid cell
                    },
                }
            } else if (e.dataField.includes('v0004')) {
                const rowIndex = e.row.rowIndex
                const previousValue = e.component.cellValue(
                    rowIndex,
                    e.dataField
                ) // 이전 값 가져오기
                //2024-12-10 hodo 날짜 형식에 맞게 입력 되도록 변경
                e.dataType = 'string' // 문자열 형식
                e.editorName = 'dxTextBox' // 텍스트 박스 사용
                e.editorOptions = {
                    value: previousValue || '', // 초기값으로 이전 값 설정 (없으면 빈 문자열)
                    mask: '0000-00-00 00:00:00', // yyyy-MM-dd HH:mm:ss 형식
                    maskRules: {
                        0: /[0-9]/, // 숫자만 허용
                    },
                    maskInvalidMessage: getErrProcMsg(procErrTable.E00004), // 잘못된 입력 시 메시지
                    onValueChanged: (args) => {
                        let value = args.value // 사용자가 입력한 값
                        const rowIndex = e.row.rowIndex
                        const previousValue = e.component.cellValue(
                            rowIndex,
                            e.dataField
                        ) // 이전 값 가져오기
                        // 유효성 검사
                        if (
                            !/^\d{4}\d{2}\d{2}\d{2}\d{2}\d{2}$/.test(value) ||
                            isValidDateTimeFormate(value) === false
                        ) {
                            alert(getErrProcMsg(procErrTable.E00004)) // 오류 메시지
                            e.component.cellValue(
                                rowIndex,
                                e.dataField,
                                previousValue
                            ) // 이전 값 복원
                        } else {
                            // 유효한 값 저장
                            e.row.data.value = value
                            e.component.cellValue(rowIndex, e.dataField, value)
                        }
                    },
                }
            } else if (e.dataField.includes('v0002')) {
                e.dataType = 'string'
                e.editorName = 'dxTextBox'
                e.editorOptions.lookup = null
                e.editorOptions.onValueChanged = (args) => {
                    const value = parseInt(args.value, 10) // 숫자 변환
                    const rowIndex = e.row.rowIndex
                    const previousValue = e.component.cellValue(
                        rowIndex,
                        e.dataField
                    ) // 이전 값 가져오기

                    if (isNaN(value) || value < 0 || value > 999) {
                        // 유효하지 않은 값 처리
                        alert(getErrProcMsg(procErrTable.E00005))
                        e.component.cellValue(
                            rowIndex,
                            e.dataField,
                            previousValue
                        ) // 이전 값으로 되돌림
                    } else {
                        // 유효한 값 저장
                        e.row.data.value = value
                        e.component.cellValue(rowIndex, e.dataField, value)
                    }
                }
            } else {
                e.dataType = 'string'
                e.editorName = 'dxTextBox'
                e.editorOptions.lookup = null
            }
        }
    }
}
