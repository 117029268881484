import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import SidePanelTemplate from 'src/template/side-panel/SidePanelTemplate'
import {
    handleSaveButtonClicked,
    handleResetButtonClicked,
    handleSwitchFilterMode,
    filterButtonDescription,
} from 'src/template/side-panel/SidePanelTemplateHelper'
import CommonOption from './components/settings/CommonOption'
import DateTimeOption from './components/settings/DateTimeOption'
import LastNFilterOption from './components/settings/LastNFilterOption'
import ETCOption from './components/settings/ETCOption'
import DBFieldOption from './components/settings/DBFieldOption'
import produce from 'immer'

import { Button } from 'devextreme-react'
import { useEffect, useRef, useState } from 'react'
import { DBFieldAdvancedOption } from './components/settings/DBFieldAdvancedOption'
// import DBFieldAdvancedRealTimeFilter from './components/settings/DBFieldAdvancedRealTimeFilter'
// import DBFieldAdvancedSaveFilter from './components/settings/DBFieldAdvancedSaveFilter'
// import DBFieldAdvancedSavedFilter from './components/settings/DBFieldAdvancedSavedFilter'
// import DBFieldAdvancedPreviewFilter from './components/settings/DBFieldAdvancedPreviewFilter'
import { databaseSettingsPrm } from '../settings/DatabaseSettings'
// import DBFieldAdvancedPopupFilter from './components/settings/DBFieldAdvancedPopupFilter'
import DBFieldSaveFilter from './components/settings/DBFieldSaveFilter'
import DBFieldSavedFilter from './components/settings/DBFieldSavedFilter'
import DBFieldPopupFilter from './components/settings/DBFieldPopupFilter'

// import DBFieldAdvancedCreatePopupFilter from './components/settings/DBFieldAdvancedCreatePopupFilter'
import DBFieldAdvancedCreate from './components/settings/DBFieldAdvancedCreateButton'
// import DBFieldAdvancedCreateButton from './components/settings/DBFieldAdvancedCreateButton'
import DBFieldCreateButton from './components/settings/DBFieldCreateButton'
import DBFieldCreatePopupFilter from './components/settings/DBFieldCreatePopupFilter'
import { useTheme } from 'src/contexts/theme'
import DBFieldAdvancedRealTimeFilter from './components/settings/DBFieldAdvancedRealTimeFilter'
import DBFieldAdvancedSavedFilter from './components/settings/DBFieldAdvancedSavedFilter'
import DBFieldAdvancedCreateButton from './components/settings/DBFieldAdvancedCreateButton'
import DBFieldAdvancedCreatePopupFilter from './components/settings/DBFieldAdvancedCreatePopupFilter'
import DBFieldAdvancedPopupFilter from './components/settings/DBFieldAdvancedPopupFilter'

export default function SidePanelDatabaseFilter({ pageKey }) {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const { getTheme } = useTheme()
    const theme = getTheme()
    const toastRef = useRef(null)

    const common = useSelector((state) => state.common)
    const curPage = common.pages[pageKey]
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentFilterMode = curPage.page.filterMode

    const partRef = useRef(null)
    const charRef = useRef(null)
    const valueRef = useRef(null)
    const partRefPopup = useRef(null)
    const charRefPopup = useRef(null)
    const valueRefPopup = useRef(null)

    const [filterUI, setFilterUI] = useState(null)

    const [isVisibleFilterPopup, setIsVisibleFilterPopup] = useState(false)
    const [popupDisplayFilter, setPopupDisplayFilter] = useState(null)
    const [isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup] =
        useState(false)
    const [popupCreateFilter, setPopupCreateFilter] = useState(null)

    const saveCallback = () =>
        handleSaveButtonClicked(
            dispatch,
            user,
            pageKey,
            curPage,
            curPage,
            toastRef
        )
    const resetCallback = () => {
        let next
        if (currentFilterMode === 'quick') {
            next = produce(curPage, (draft) => {
                Object.entries(databaseSettingsPrm.filters.quick).forEach(
                    ([key, value]) => {
                        draft.filters.quick[key] = value
                    }
                )
            })
        } else {
            next = produce(curPage, (draft) => {
                Object.entries(databaseSettingsPrm.filters.advanced).forEach(
                    ([key, value]) => {
                        draft.filters.advanced[key] = value
                    }
                )
            })
        }
        handleResetButtonClicked(dispatch, user, pageKey, next, {}, toastRef)
    }

    const switchFilterModeButton = (
        <Button
            style={{ width: '100%' }}
            text={filterButtonDescription(currentFilterMode)}
            onClick={() =>
                handleSwitchFilterMode(
                    curPage,
                    dispatch,
                    pageKey,
                    currentFilterMode
                )
            }
        />
    )

    const dbFieldOptionJSX = () => {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <CommonOption currentFilterMode={currentFilterMode} />
                <DateTimeOption currentFilterMode={currentFilterMode} />
                <LastNFilterOption currentFilterMode={currentFilterMode} />
                <ETCOption currentFilterMode={currentFilterMode} />
                <DBFieldOption
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    partRef={partRef}
                    charRef={charRef}
                    valueRef={valueRef}
                    theme={theme}
                />
                <DBFieldCreateButton
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    isVisibleCreateFilterPopup={isVisibleCreateFilterPopup}
                    setIsVisibleCreateFilterPopup={
                        setIsVisibleCreateFilterPopup
                    }
                    popupCreateFilter={popupCreateFilter}
                    setPopupCreateFilter={setPopupCreateFilter}
                    currentFilterMode={currentFilterMode}
                />
                <DBFieldSavedFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    setIsVisibleFilterPopup={setIsVisibleFilterPopup}
                    setPopupDisplayFilter={setPopupDisplayFilter}
                />
                <DBFieldCreatePopupFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    isVisibleCreateFilterPopup={isVisibleCreateFilterPopup}
                    setIsVisibleCreateFilterPopup={
                        setIsVisibleCreateFilterPopup
                    }
                    popupCreateFilter={popupCreateFilter}
                    setPopupCreateFilter={setPopupCreateFilter}
                    partRefPopup={partRefPopup}
                    charRefPopup={charRefPopup}
                    valueRefPopup={valueRefPopup}
                />
                <DBFieldPopupFilter
                    dispatch={dispatch}
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    isVisibleFilterPopup={isVisibleFilterPopup}
                    setIsVisibleFilterPopup={setIsVisibleFilterPopup}
                    popupDisplayFilter={popupDisplayFilter}
                    setPopupDisplayFilter={setPopupDisplayFilter}
                    partRefPopup={partRefPopup}
                    charRefPopup={charRefPopup}
                    valueRefPopup={valueRefPopup}
                    theme={theme}
                    user={user}
                />
            </div>
        )
    }

    const dbFieldAdvancedOptionJSX = () => {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <CommonOption currentFilterMode={currentFilterMode} />
                <DateTimeOption currentFilterMode={currentFilterMode} />
                <LastNFilterOption currentFilterMode={currentFilterMode} />
                <ETCOption currentFilterMode={currentFilterMode} />
                <DBFieldAdvancedRealTimeFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                />
                <DBFieldAdvancedCreateButton
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    isVisibleCreateFilterPopup={isVisibleCreateFilterPopup}
                    setIsVisibleCreateFilterPopup={
                        setIsVisibleCreateFilterPopup
                    }
                    popupCreateFilter={popupCreateFilter}
                    setPopupCreateFilter={setPopupCreateFilter}
                    currentFilterMode={currentFilterMode}
                />
                <DBFieldAdvancedSavedFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    setIsVisibleFilterPopup={setIsVisibleFilterPopup}
                    setPopupDisplayFilter={setPopupDisplayFilter}
                />
                <DBFieldAdvancedCreatePopupFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    isVisibleCreateFilterPopup={isVisibleCreateFilterPopup}
                    setIsVisibleCreateFilterPopup={
                        setIsVisibleCreateFilterPopup
                    }
                    popupCreateFilter={popupCreateFilter}
                    setPopupCreateFilter={setPopupCreateFilter}
                />
                <DBFieldAdvancedPopupFilter
                    pageKey={pageKey}
                    pageState={curPage}
                    configState={configPage}
                    othersPage={othersPage}
                    isVisibleFilterPopup={isVisibleFilterPopup}
                    setIsVisibleFilterPopup={setIsVisibleFilterPopup}
                    popupDisplayFilter={popupDisplayFilter}
                    setPopupDisplayFilter={setPopupDisplayFilter}
                />
            </div>
        )
    }

    let menuItems = null
    if (currentFilterMode === 'advanced') {
        menuItems = dbFieldAdvancedOptionJSX()
    } else {
        menuItems = dbFieldOptionJSX()
    }
    return (
        <SidePanelTemplate
            pageKey={pageKey}
            // formComponents={formComponents()}
            formComponents={menuItems}
            saveCallback={saveCallback}
            resetCallback={resetCallback}
            extraButtons={switchFilterModeButton}
            toastRef={toastRef}
        />
    )
}
