export const reportEmptyTemplateFormat = {
    name: '',
    type: 'individual',
    page: {
        size: 'A4',
        orientation: 'portrait',
        wrap: false,
        debug: false,
        dpi: 72,
    },
    elements: [],
}
