import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import RadioGroupSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItemNew'
import produce from 'immer'

export const reportLayout = () => [
    { value: 'portrait', text: getCommonMsg(msgType.SidePanel, 'Portrait') },
    { value: 'landscape', text: getCommonMsg(msgType.SidePanel, 'Landscape') },
]

export default function SettingsReportLayout(props) {
    const {
        dispatch,
        pageKey,
        pageState,
        othersPage,
        selectedTemplate,
        setSelectedTemplate,
    } = props
    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`settings-report-layout-group-item`}
                colCount={6}
                caption={getCommonMsg(msgType.SidePanel, 'ReportLayout')}
            >
                <SimpleItem
                    colSpan={6}
                    render={() => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <RadioGroupSimpleItemNew
                                ref={null}
                                colSpan={6}
                                label={''}
                                labelLocation={'top'}
                                name={'reportLayout'}
                                items={reportLayout()}
                                layout={'horizontal'}
                                valueExpr={'value'}
                                displayExpr={'text'}
                                value={selectedTemplate.page.orientation}
                                onValueChanged={(e) => {
                                    const next = produce(
                                        selectedTemplate,
                                        (draft) => {
                                            draft.page.orientation = e.value
                                        }
                                    )
                                    setSelectedTemplate(next)
                                }}
                            />
                        </div>
                    )}
                />
            </GroupItem>
        </Form>
    )
}
