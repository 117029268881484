import { Form } from 'devextreme-react'
import { GroupItem, SimpleItem } from 'devextreme-react/form'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import QuickCommonOption from './quick/QuickCommonOption'
import QuickDateTimeOption from './quick/QuickDateTimeOption'
import QuickLastNFilterOption from './quick/QuickLastNFilterOption'
import QuickETCOption from './quick/QuickETCOption'
import QuickOption from './quick/QuickOption'
import QuickSaveFilter from './quick/QuickSaveFilter'
import { useTheme } from 'src/contexts/theme'
import { useAuth } from 'src/contexts/auth'

const DBFieldCreatePopupFilter = ({
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleCreateFilterPopup,
    setIsVisibleCreateFilterPopup,
    popupCreateFilter,
    setPopupCreateFilter,
    partRefPopup,
    charRefPopup,
    valueRefPopup,
}) => {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const { getTheme } = useTheme()
    const theme = getTheme()
    const textBoxRef = useRef(null)

    const closePopup = () => {
        setIsVisibleCreateFilterPopup(false)
        setPopupCreateFilter(null)
    }

    const title = 'Create Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-advanced-create-popup-filter-group-item`}
                colCount={2}
                caption={''}
            >
                {/* {PopupSimpleItem({
                visible: isVisibleCreateFilterPopup,
                onHiding: closePopup,
                title,
                width,
                height,
                contentRender: renderFilterContent,
            })} */}
                <PopupSimpleItem
                    visible={isVisibleCreateFilterPopup}
                    onHiding={closePopup}
                    title={title}
                    width={width}
                    height={height}
                    // contentRender={renderFilterContent}
                >
                    <QuickCommonOption
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                    />
                    <QuickDateTimeOption
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                    />
                    <QuickLastNFilterOption
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                    />
                    <QuickETCOption
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                    />
                    <QuickOption
                        dispatch={dispatch}
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                        isVisibleCreateFilterPopup={isVisibleCreateFilterPopup}
                        setIsVisibleCreateFilterPopup={
                            setIsVisibleCreateFilterPopup
                        }
                        pageKey={pageKey}
                        pageState={pageState}
                        configState={configState}
                        othersPage={othersPage}
                        partRefPopup={partRefPopup}
                        charRefPopup={charRefPopup}
                        valueRefPopup={valueRefPopup}
                        theme={theme}
                    />
                    <QuickSaveFilter
                        dispatch={dispatch}
                        currentFilter={popupCreateFilter}
                        setCurrentFilter={setPopupCreateFilter}
                        setIsVisibleCreateFilterPopup={
                            setIsVisibleCreateFilterPopup
                        }
                        textBoxRef={textBoxRef}
                        pageKey={pageKey}
                        pageState={pageState}
                        configState={configState}
                        othersPage={othersPage}
                        user={user}
                    />
                </PopupSimpleItem>
            </GroupItem>
        </Form>
    )
}

export default DBFieldCreatePopupFilter
