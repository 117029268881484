import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'

export function isNullOrUndefined(data) {
    return data === null || data === undefined
}

export const calculateAverage = (data) => {
    let sum = 0
    for (const value of data) {
        sum += value
    }
    return sum / data.length
}

// Calculate the median
export const calculateMedian = (data) => {
    const sortedData = data.slice().sort((a, b) => a - b)
    const mid = Math.floor(sortedData.length / 2)

    if (sortedData.length % 2 === 0) {
        return (sortedData[mid - 1] + sortedData[mid]) / 2
    } else {
        return sortedData[mid]
    }
}

// Calculate the standard deviation
export const calculateStandardDeviation = (data) => {
    const average = calculateAverage(data)
    const squaredDifferences = data.map((value) => Math.pow(value - average, 2))
    // const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0)
    let sumSquaredDifferences = 0
    for (const value of squaredDifferences) {
        sumSquaredDifferences += value
    }
    const variance = sumSquaredDifferences / data.length
    const standardDeviation = Math.sqrt(variance)

    return standardDeviation
}

export const makeQccUpperSeriesData = (chartType, optionType, optionCount, handlingIncompleteSubgroup, valueData) => {
    const averages = []

    if (isNullOrUndefined(valueData) || valueData.length == 0) {
        return []
    }

    const valueArray = valueData
    if (chartType === 'raw') {
        for (let i = 0; i <= valueArray.length - 1; i++) {
            const values = valueArray.map((item) => item.v0001)
            const format = [i + 1, values[i]]
            averages.push(format)
        }
    } else {
        const value_len = valueArray.length - optionCount;
        if (optionType === 'moving' || optionType === 1) {
            for (let i = 0; i <= valueArray.length - optionCount; i++) {
                // 'delete' case: skip incomplete subgroups
                //사전에 종료 되는 현상 수정
                if (handlingIncompleteSubgroup === 'delete' && i + optionCount > valueArray.length) {
                    break
                }

                // 'calculate' case: handle incomplete subgroups
                let values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
                if (handlingIncompleteSubgroup === 'calculate' && i + optionCount > value_len) {
                    values = valueArray.slice(i).map((item) => item.v0001) // Use remaining values
                }

                // const values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
                let sum = 0
                for (const value of values) {
                    sum += value
                }
                const average = values.length > 0 ? sum / values.length : 0
                const format = [i + 1, average]
                averages.push(format)
            }
        } else {
            let xAxis = 1
            for (let i = 0; i <= value_len; i = i + optionCount) {
                // 'delete' case: skip incomplete subgroups
                //사전에 종료 되는 현상 수정
                if (handlingIncompleteSubgroup === 'delete' && i + optionCount > valueArray.length) {
                    break
                }

                // 'calculate' case: handle incomplete subgroups
                let values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
                if (handlingIncompleteSubgroup === 'calculate' && i + optionCount > value_len) {
                    values = valueArray.slice(i).map((item) => item.v0001) // Use remaining values
                }

                // const values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
                let sum = 0
                for (const value of values) {
                    sum += value
                }
                const average = values.length > 0 ? sum / values.length : 0
                const format = [xAxis, average]
                averages.push(format)
                xAxis++
            }
        }
    }
    return averages
}

const calculateYAxisSpace = (props) => {
    const { settings, usl, lsl, uwl, lwl, ucl, lcl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
    const {
        page: { specificationLimitEnable, warningLimitEnable, controlLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable },
    } = settings

    const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
    const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

    const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
    const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

    const t_ucl = controlLimitEnable ? ucl ?? maxValue : maxValue
    const t_lcl = controlLimitEnable ? lcl ?? minValue : minValue

    const t_upl = plausibilityLimitEnable ? upl ?? maxValue : maxValue
    const t_lpl = plausibilityLimitEnable ? lpl ?? minValue : minValue

    const t_url = scrapLimitEnable ? url ?? maxValue : maxValue
    const t_lrl = scrapLimitEnable ? lrl ?? minValue : minValue

    const t_uacl = acceptanceLimitEnable ? uacl ?? maxValue : maxValue
    const t_lacl = acceptanceLimitEnable ? lacl ?? minValue : minValue

    const max = Math.max(t_usl, t_uwl, t_ucl, t_upl, t_url, t_uacl, maxValue)
    const min = Math.min(t_lsl, t_lwl, t_lcl, t_lpl, t_lrl, t_lacl, minValue)

    let space = Math.abs(max - min)
    if (space === 0) space = 1

    const additionalSpace = space * 0.1

    return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
    const { max, additionalSpace } = calculateYAxisSpace(props)
    return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
    const { min, additionalSpace } = calculateYAxisSpace(props)
    return min - additionalSpace
}

export const findLargestNumber = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return undefined // Return undefined for invalid or empty arrays
    }

    return CCUtils.getMax(arr)
}

export const findSmallestNumber = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return undefined // Return undefined for invalid or empty arrays
    }

    return CCUtils.getMin(arr)
}
export const makeSymbol = (value, params, settings) => {
    const val = params.data[1]

    const { lineChart, usl, uwl, ucl, lwl, lsl, lcl } = settings
    const symbols = lineChart.symbols

    const { locationChartType } = settings.page
    // const natMode = settings.commonSettings.useNaturalBoundary
    const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2

    // if (locationChartType === 'average') {
    if (ucl === null && lcl === null) {
        return symbols.good.shape
    } else if (ucl === null && lcl !== null) {
        if (val >= lcl) {
            return symbols.good.shape
        } else {
            return symbols.error.lower.shape
        }
    } else if (ucl !== null && lcl === null) {
        if (val >= ucl) {
            return symbols.error.upper.shape
        } else {
            return symbols.good.shape
        }
    } else {
        if (val > ucl) {
            return symbols.error.upper.shape
        } else if (val >= lcl) {
            return symbols.good.shape
        } else {
            return symbols.error.lower.shape
        }
    }
    // } else {
    //     if (natMode) {
    //         if (usl === null && lsl === null) {
    //             return lineChart.symbols.good.shape
    //         }

    //         if (usl !== null && lsl !== null) {
    //             if (uwl === null) {
    //                 // if (val > usl) return symbols.error.upper.shape
    //                 // if (val > uwl) return symbols.warn.upper.shape
    //                 if (val >= lwl) return symbols.good.shape
    //                 if (val >= lsl) return symbols.warn.lower.shape
    //                 return symbols.error.lower.shape
    //             } else if (lwl === null) {
    //                 if (val > usl) return symbols.error.upper.shape
    //                 if (val > uwl) return symbols.warn.upper.shape
    //                 // if (val >= lwl) return symbols.good.shape
    //                 // if (val >= lsl) return symbols.warn.lower.shape
    //                 // return symbols.error.lower.shape
    //                 return symbols.good.shape
    //             } else {
    //                 if (val > usl) return symbols.error.upper.shape
    //                 if (val > uwl) return symbols.warn.upper.shape
    //                 if (val >= lwl) return symbols.good.shape
    //                 if (val >= lsl) return symbols.warn.lower.shape
    //                 return symbols.error.lower.shape
    //             }
    //         }

    //         if (usl === null) {
    //             return lwl !== null && val >= lwl ? symbols.good.shape : val > lsl ? symbols.warn.lower.shape : symbols.error.lower.shape
    //         }

    //         // lsl === null case
    //         if (val > usl) return symbols.error.upper.shape
    //         if (uwl !== null && val > uwl) return symbols.warn.upper.shape
    //         return symbols.good.shape
    //     } else {
    //         if (usl === null && lsl === null) {
    //             return lineChart.symbols.good.shape
    //         }

    //         if (usl !== null && lsl !== null) {
    //             if (val > usl) return symbols.error.upper.shape
    //             if (val > uwl) return symbols.warn.upper.shape
    //             if (val >= lwl) return symbols.good.shape
    //             if (val >= lsl) return symbols.warn.lower.shape
    //             return symbols.error.lower.shape
    //         }

    //         if (usl === null) {
    //             return lwl !== null && val >= lwl ? symbols.good.shape : val > lsl ? symbols.warn.lower.shape : symbols.error.lower.shape
    //         }

    //         // lsl === null case
    //         if (val > usl) return symbols.error.upper.shape
    //         if (uwl !== null && val > uwl) return symbols.warn.upper.shape
    //         return symbols.good.shape
    //     }
    // }
}

export const makeSymbolSize = (value, params, settings) => {
    // const val = params.data.v0001
    const val = params.data[1]
    const { lineChart, usl, uwl, ucl, lwl, lsl, lcl } = settings
    const symbols = lineChart.symbols

    const { locationChartType } = settings.page

    // if (locationChartType === 'average') {
    if (ucl === null && lcl === null) {
        return symbols.good.size
    }

    if (val > ucl) {
        return symbols.error.upper.size
    } else if (val >= lcl) {
        return symbols.good.size
    } else {
        return symbols.error.lower.size
    }
    // } else {
    //     if (usl === null && lsl === null) return symbols.good.size

    //     if (usl !== null && lsl !== null) {
    //         if (val > usl) return symbols.error.upper.size
    //         if (val > uwl) return symbols.warn.upper.size
    //         if (val >= lwl) return symbols.good.size
    //         if (val >= lsl) return symbols.warn.lower.size
    //         return symbols.error.lower.size
    //     }

    //     if (usl === null) {
    //         return lwl !== null && val >= lwl ? symbols.good.size : val > lsl ? symbols.warn.lower.size : symbols.error.lower.size
    //     }

    //     // lsl === null case
    //     if (val > usl) return symbols.error.upper.size
    //     if (uwl !== null && val > uwl) return symbols.warn.upper.size
    //     return symbols.good.size
    // }
}

export const makeSymbolColor = (params, settings) => {
    // const val = params.data.v0001
    const val = params.data[1]
    const { lineChart, usl, uwl, ucl, lwl, lsl, lcl } = settings
    const symbols = lineChart.symbols

    const { locationChartType } = settings.page

    // if (locationChartType === 'average') {
    if (ucl === null && lcl === null) {
        return symbols.good.color
    }

    if (val > ucl) {
        return symbols.error.upper.color
    } else if (val >= lcl) {
        return symbols.good.color
    } else {
        return symbols.error.lower.color
    }
    // } else {
    //     // const natMode = settings.commonSettings.useNaturalBoundary
    //     const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
    //     const prmProps = { val, usl, lsl, uwl, lwl, natMode }
    //     const valueState = CDSUtils.getValueState(prmProps)

    //     if (valueState === CDSUtils.GOOD_STATE) {
    //         return symbols.good.color
    //     } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
    //         return symbols.warn.upper.color
    //     } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
    //         return symbols.warn.lower.color
    //     } else if (valueState === CDSUtils.NG_UPPER_STATE) {
    //         return symbols.error.upper.color
    //     } else {
    //         //if (valueState === CDSUtils.NG_LOWER_STATE) {
    //         return symbols.error.lower.color
    //     }
    // }
}

export const makeQccLowerSeriesData = (optionType, valueData) => {
    const differences = []

    if (isNullOrUndefined(valueData) || valueData.length == 0) {
        return []
    }

    const valueArray = valueData.data

    if (optionType === 'fixed') {
        for (let i = 0; i <= valueArray.length - 5; i++) {
            const values = valueArray.slice(i, i + 5).map((item) => item.v0001)
            const max = CCUtils.getMax(values)
            const min = CCUtils.getMin(values)
            const difference = max - min
            const format = [i, difference]
            differences.push(format)
        }
    } else {
        for (let i = 0; i <= valueArray.length - 2; i++) {
            const values = valueArray.slice(i, i + 2).map((item) => item.v0001)
            const max = CCUtils.getMax(values)
            const min = CCUtils.getMin(values)
            const difference = max - min
            const format = [i, difference]
            differences.push(format)
        }
    }

    return differences
}

export const convertToChartLine = (settings) => {
    const rtnChartLine = {
        type: settings.lineChart.chartLines.type,
        width: settings.lineChart.chartLines.width,
        color: settings.lineChart.chartLines.color,
    }

    return rtnChartLine
}

export const convertToMarkLine = (settings, pageKey) => {
    const createMarkLine = (enabled, labelEnabled, labelName, yAxisValue, lineSettings, natState) => {
        const position = 'insideEndTop'
        const fontFamily = 'Arial'
        const fontSize = pageKey === 'reportPrint' ? 7 : (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3

        return {
            show: yAxisValue !== null && enabled,
            name: labelEnabled ? labelName : '',
            yAxis: yAxisValue,
            label: {
                position: position,
                formatter: (args) => args.name,
                fontFamily: fontFamily,
                fontSize: fontSize,
            },
            lineStyle: {
                type: natState ? 'dotted' : lineSettings?.type,
                color: lineSettings?.color,
                width: lineSettings?.width,
            },
            labelLayout: { moveOverlap: 'shiftY' },
        }
    }

    const { specificationLimitEnable, warningLimitEnable, nominalLimitEnable, averageLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable, controlLimitEnable } = settings.page
    const {
        specificationLimitLabelEnable,
        warningLimitLabelEnable,
        nominalLimitLabelEnable,
        averageLimitLabelEnable,
        plausibilityLimitLabelEnable,
        scrapLimitLabelEnable,
        acceptanceLimitLabelEnable,
        controlLimitLabelEnable,
    } = settings.page

    const { specLimit, warnLimit, nominal, average, plausibilityLimit, scrapLimit, acceptanceLimit, controlLimit } = settings.lineChart.markLines

    // locationChartType
    const typeLocChart = settings.page.locationChartType
    // const useNat = settings.commonSettings.useNaturalBoundary
    const isUpperNat = PCUtils.getTypeUpperWarningLimit(settings.char) === 2
    const isLowerNat = PCUtils.getTypeLowerWarningLimit(settings.char) === 2

    const targetML = [
        {
            limitEnable: specificationLimitEnable,
            labelEnable: specificationLimitLabelEnable,
            name: 'LSL',
            value: settings.lsl,
            config: specLimit,
            // natState: useNat && isLowerNat
            natState: isLowerNat,
        },
        {
            limitEnable: specificationLimitEnable,
            labelEnable: specificationLimitLabelEnable,
            name: 'USL',
            value: settings.usl,
            config: specLimit,
            //  natState: useNat && isUpperNat
            natState: isUpperNat,
        },
        { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'LWL', value: settings.lwl, config: warnLimit },
        { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'UWL', value: settings.uwl, config: warnLimit },
        { limitEnable: controlLimitEnable, labelEnable: controlLimitLabelEnable, name: 'LCL', value: settings.lcl, config: controlLimit },
        { limitEnable: controlLimitEnable, labelEnable: controlLimitLabelEnable, name: 'UCL', value: settings.ucl, config: controlLimit },
        { limitEnable: nominalLimitEnable, labelEnable: nominalLimitLabelEnable, name: 'Nominal', value: settings.nominal, config: nominal },
        { limitEnable: averageLimitEnable, labelEnable: averageLimitLabelEnable, name: typeLocChart === 'average' ? 'X\u0304' : 'Average', value: settings.average, config: average },
        { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'UPL', value: settings.upl, config: plausibilityLimit },
        { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'LPL', value: settings.lpl, config: plausibilityLimit },
        { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'URL', value: settings.url, config: scrapLimit },
        { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'LRL', value: settings.lrl, config: scrapLimit },
        { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'UAcl', value: settings.uacl, config: acceptanceLimit },
        { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'LAcl', value: settings.lacl, config: acceptanceLimit },
    ]

    // Create markLines using map
    const markLines = targetML.map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config, item.natState))

    // Filter out mark lines where show is false, and remove 'show' property
    return markLines.filter((line) => line.show).map(({ show, ...rest }) => rest)
}
export const convertToMarkArea = (settings) => {
    const { locationChartType } = settings.page
    // const natMode = settings.commonSettings.useNaturalBoundary
    const rtnMarkArea = []

    const addMarkArea = (show, yAxisStart, yAxisEnd, color) => {
        rtnMarkArea.push([
            { show },
            { yAxis: yAxisStart },
            {
                yAxis: yAxisEnd,
                itemStyle: { color },
            },
        ])
    }

    if (locationChartType === 'average') {
        addMarkArea(true, settings.ucl, settings.yAxisTop, settings.lineChart.markAreas.outOfControl?.color)
        addMarkArea(true, settings.ucl, settings.lcl, settings.lineChart.markAreas.good?.color)
        addMarkArea(true, settings.lcl, settings.yAxisBottom, settings.lineChart.markAreas.outOfControl?.color)

        const validAreas = rtnMarkArea.filter((array) => array.some((item) => item.show === true))
        const removeShowMarkAreas = validAreas.map((innerArray) => innerArray.filter((item) => !item.show))
        return removeShowMarkAreas
    } else {
        addMarkArea(true, settings.ucl, settings.yAxisTop, settings.lineChart.markAreas.outOfControl?.color)
        addMarkArea(true, settings.ucl, settings.lcl, settings.lineChart.markAreas.good?.color)
        addMarkArea(true, settings.lcl, settings.yAxisBottom, settings.lineChart.markAreas.outOfControl?.color)

        const validAreas = rtnMarkArea.filter((array) => array.some((item) => item.show === true))
        const removeShowMarkAreas = validAreas.map((innerArray) => innerArray.filter((item) => !item.show))
        return removeShowMarkAreas
    }
}
