import React, { useState } from 'react'
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import ListSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItemNew'
import NumberBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItemNew'
import SelectBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItemNew'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import produce from 'immer'
import { TagBox } from 'devextreme-react'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import * as rd from 'src/store/common'
import TextBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItemNew'
import RadioGroupSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItemNew'
import { reportTagsCollectiveSummaryList } from '../define/DefineTagListCollectiveSummaryList'
import {
    filterIndividualTags,
    reportElementTagListForIndividual,
} from '../define/DefineTagListIndividual'
import {
    filterCollectiveTags,
    reportElementTagListForCollective,
} from '../define/DefineTagListCollective'
import SettingsSelectedTemplateSave from './SettingsSelectedTemplateSave'

const SettingsRectElementsLayout = (props) => {
    const {
        dispatch,
        pageKey,
        pageState,
        othersPage,
        selectedTemplate,
        setSelectedTemplate,
        setIsOpenSelectedTemplate,
    } = props

    const [addItemColumn, setAddItemColumn] = useState(1)
    const [addItemRow, setAddItemRow] = useState(1)
    const [addItemContent, setAddItemContent] = useState(null)

    const individualTags = filterIndividualTags('all')
    const collectiveTags = filterCollectiveTags('all')
    // 상태 관리: 각 TagBox에 대해 열린/닫힌 상태 관리
    const [openedTagBoxes, setOpenedTagBoxes] = useState({})

    // const reportType = selectedTemplate.type

    const makeElementListDataSource = (template) => {
        if (template && template.elements.length > 0) {
            return template.elements.map((item, index) => {
                return {
                    ...item,
                    id: index,
                }
            })
        } else {
            return []
        }
    }

    const handleExtraFieldDelete = (e) => {
        const extraFieldList = makeElementListDataSource(selectedTemplate)
        extraFieldList.splice(e.itemIndex, 1)
        updateOrderList(extraFieldList)
    }
    const handleReorder = (e) => {
        const { fromIndex, toIndex } = e

        // 현재 elements 배열 복사
        const reorderedList = [...selectedTemplate.elements]

        // 항목을 기존 위치에서 제거하고 새 위치로 삽입
        const [movedItem] = reorderedList.splice(fromIndex, 1) // 기존 위치에서 항목 제거
        reorderedList.splice(toIndex, 0, movedItem) // 새 위치에 항목 삽입

        // 새로운 리스트로 상태 업데이트
        const nextState = produce(selectedTemplate, (draft) => {
            draft.elements = reorderedList // elements 배열에 reorderedList 적용
        })

        setSelectedTemplate(nextState) // 상태 업데이트

        // 페이지 상태 업데이트
        const changedIndex = othersPage.reportTemplates.findIndex(
            (template) => template.name === nextState.name
        )
        const nextOthers = produce(othersPage, (draft) => {
            draft.reportTemplates[changedIndex].elements = reorderedList
        })
        dispatch(rd.setReportTemplates(nextOthers.reportTemplates))
    }

    const updateOrderList = (list) => {
        const nextState = produce(selectedTemplate, (draft) => {
            draft.elements = list
        })
        setSelectedTemplate(nextState)
        const changedIndex = pageState.page.templates.findIndex(
            (template) => template.name === nextState.name
        )
        const next = produce(pageState, (draft) => {
            draft.page.templates[changedIndex] = nextState
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const renderElementList = (itemData) => {
        const columnValues = itemData.columnContent.map(
            (column) => column.value
        )
        const columnHeight = itemData.heightUnit
        const rowIndex = itemData.index

        const getTitleValue = () => {
            const targetElIdx = selectedTemplate.elements.findIndex(
                (el) => el.index === rowIndex
            )

            if (targetElIdx !== -1) {
                const targetTitleIdx = selectedTemplate.elements[
                    targetElIdx
                ].columnContent.findIndex((el) => el.value === 'title-desc')

                return selectedTemplate.elements[targetElIdx].columnContent[
                    targetTitleIdx
                ].content
            }
        }

        const getCollectiveSummaryListTags = () => {
            const targetElIdx = selectedTemplate.elements.findIndex(
                (el) => el.index === rowIndex
            )

            if (targetElIdx !== -1) {
                const targetTitleIdx = selectedTemplate.elements[
                    targetElIdx
                ].columnContent.findIndex(
                    (el) => el.value === 'summary-list-collective'
                )

                return selectedTemplate.elements[targetElIdx].columnContent[
                    targetTitleIdx
                ].content
            }
        }

        const handleDropdownOpened = (e, rowIndex) => {
            // 드롭다운 상태를 업데이트하여, 해당 rowIndex의 TagBox가 열린 상태로 유지되도록 설정
            setOpenedTagBoxes((prev) => ({
                ...prev,
                [rowIndex]: e, // 열린 상태를 업데이트
            }))
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    gap: '5px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '10%',
                        gap: '5px',
                        paddingTop: 1,
                        paddingBottom: 1,
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                        }}
                    >
                        ▶️
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                        }}
                    >
                        {itemData.index}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                        }}
                    >
                        :
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        width: '90%',
                        paddingTop: 1,
                        paddingBottom: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        <NumberBoxSimpleItemNew
                            label={getCommonMsg(msgType.SidePanel, 'RowHeight')}
                            labelLocation={'top'}
                            width='20%'
                            showSpinButtons={true}
                            min={1}
                            max={50}
                            step={1}
                            value={columnHeight}
                            onValueChanged={(e) => {
                                const next = produce(
                                    selectedTemplate,
                                    (draft) => {
                                        draft.elements[itemData.id].heightUnit =
                                            e.value
                                    }
                                )
                                setSelectedTemplate(next)
                            }}
                        />

                        <TagBox
                            dataSource={
                                selectedTemplate.type === 'individual'
                                    ? individualTags
                                    : collectiveTags
                            }
                            value={columnValues}
                            valueExpr='value'
                            displayExpr='display'
                            multiline={false}
                            showSelectionControls={
                                selectedTemplate.type === 'individual'
                                    ? true
                                    : false
                            }
                            width='80%'
                            onValueChanged={(e) => {
                                const items = e.value // 선택된 값
                                const targetElIdx =
                                    selectedTemplate.elements.findIndex(
                                        (el) => el.index === rowIndex
                                    )

                                if (targetElIdx !== -1) {
                                    // selectedTemplate 구조와 columnContent를 업데이트
                                    const next = produce(
                                        selectedTemplate,
                                        (draft) => {
                                            draft.elements[
                                                targetElIdx
                                            ].columnContent = items.map(
                                                (item) => ({
                                                    value: item,
                                                    content: null,
                                                })
                                            )
                                        }
                                    )
                                    setSelectedTemplate(next) // 상태 업데이트
                                }
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        {columnValues.includes('title-desc') && (
                            <TextBoxSimpleItemNew
                                label={getCommonMsg(
                                    msgType.SidePanel,
                                    'TitleContent'
                                )}
                                labelLocation='top'
                                name='title'
                                value={getTitleValue()}
                                onValueChanged={(e) => {
                                    const titleText = e.value // 선택된 값
                                    const targetElIdx =
                                        selectedTemplate.elements.findIndex(
                                            (el) => el.index === rowIndex
                                        )
                                    const targetTitleIdx =
                                        selectedTemplate.elements[
                                            targetElIdx
                                        ].columnContent.findIndex(
                                            (el) => el.value === 'title-desc'
                                        )
                                    if (
                                        targetElIdx !== -1 &&
                                        targetTitleIdx !== -1
                                    ) {
                                        // selectedTemplate 구조와 columnContent를 업데이트
                                        const next = produce(
                                            selectedTemplate,
                                            (draft) => {
                                                draft.elements[
                                                    targetElIdx
                                                ].columnContent[
                                                    targetTitleIdx
                                                ] = {
                                                    value: 'title-desc',
                                                    content: titleText,
                                                }
                                            }
                                        )
                                        setSelectedTemplate(next) // 상태 업데이트
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            paddingTop: 1,
                            paddingBottom: 1,
                        }}
                    >
                        {columnValues.includes('summary-list-collective') && (
                            <TagBox
                                dataSource={reportTagsCollectiveSummaryList()}
                                value={getCollectiveSummaryListTags() || []}
                                valueExpr='value'
                                displayExpr='display'
                                multiline={false}
                                showSelectionControls={true}
                                opened={openedTagBoxes[rowIndex] || false} // 첫 번째 TagBox의 열린 상태
                                width='100%'
                                onOpenedChange={(e) =>
                                    handleDropdownOpened(e, rowIndex)
                                } // 열린 상태 변경
                                onValueChanged={(e) => {
                                    const items = e.value // 선택된 값
                                    const targetElIdx =
                                        selectedTemplate.elements.findIndex(
                                            (el) => el.index === rowIndex
                                        )

                                    const targetSummaryListCollectiveIdx =
                                        selectedTemplate.elements[
                                            targetElIdx
                                        ].columnContent.findIndex(
                                            (el) =>
                                                el.value ===
                                                'summary-list-collective'
                                        )

                                    if (
                                        targetElIdx !== -1 &&
                                        targetSummaryListCollectiveIdx !== -1
                                    ) {
                                        // selectedTemplate 구조와 columnContent를 업데이트
                                        const next = produce(
                                            selectedTemplate,
                                            (draft) => {
                                                draft.elements[
                                                    targetElIdx
                                                ].columnContent[
                                                    targetSummaryListCollectiveIdx
                                                ] = {
                                                    value: 'summary-list-collective',
                                                    content: items,
                                                }
                                            }
                                        )
                                        setSelectedTemplate(next) // 상태 업데이트
                                        // 상태 업데이트 후 드롭다운을 유지
                                        setOpenedTagBoxes((prev) => ({
                                            ...prev,
                                            [rowIndex]: true, // 선택된 TagBox만 열어둠
                                        }))
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Form
                colCount={1}
                labelMode='outside'
            >
                <GroupItem
                    key='settings-rect-elements-layout-group-item'
                    colSpan={1}
                    colCount={6}
                    caption={getCommonMsg(msgType.SidePanel, 'ElementsLayout')}
                >
                    <SimpleItem
                        colSpan={6}
                        render={() => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '5px',
                                        width: '100%',
                                        // height: '100%',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                    }}
                                >
                                    <NumberBoxSimpleItemNew
                                        label={getCommonMsg(
                                            msgType.SidePanel,
                                            'RowHeight'
                                        )}
                                        labelLocation={'outside'}
                                        width='20%'
                                        showSpinButtons={true}
                                        min={1}
                                        max={50}
                                        step={1}
                                        value={addItemRow}
                                        onValueChanged={(e) => {
                                            setAddItemRow(e.value)
                                        }}
                                    />
                                    <TagBox
                                        dataSource={
                                            selectedTemplate.type ===
                                            'individual'
                                                ? individualTags
                                                : collectiveTags
                                        }
                                        value={addItemContent}
                                        valueExpr='value'
                                        displayExpr='display'
                                        multiline={false}
                                        showSelectionControls={true}
                                        width='100%'
                                        onValueChanged={(e) => {
                                            const item = e.value
                                            setAddItemContent(item)
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    />
                    <SimpleItem
                        colSpan={6}
                        render={() => (
                            <ButtonSimpleItemNew
                                colSpan={1}
                                type='normal'
                                text={getCommonMsg(msgType.SidePanel, 'Add')}
                                icon='add'
                                width='100%'
                                height='100%'
                                onClick={(e) => {
                                    console.log('Add clicked')

                                    let contents = []

                                    selectedTemplate.type === 'individual'
                                        ? (contents = addItemContent.map(
                                              (added) =>
                                                  reportElementTagListForIndividual().find(
                                                      (defItem) =>
                                                          defItem.value ===
                                                          added
                                                  )
                                          ))
                                        : (contents = addItemContent.map(
                                              (added) =>
                                                  reportElementTagListForCollective().find(
                                                      (defItem) =>
                                                          defItem.value ===
                                                          added
                                                  )
                                          ))

                                    const next = produce(
                                        selectedTemplate,
                                        (draft) => {
                                            draft.elements.push({
                                                index: draft.elements.length,
                                                heightUnit: addItemRow,
                                                columnCount: addItemColumn,
                                                columnContent: contents,
                                            })
                                        }
                                    )

                                    setSelectedTemplate(next)
                                }}
                            />
                        )}
                    />
                </GroupItem>
                <GroupItem
                    key='registered-group-item'
                    colSpan={1}
                    colCount={6}
                    caption={getCommonMsg(msgType.SidePanel, 'RegisteredItem')}
                >
                    <SimpleItem
                        colSpan={6}
                        render={() => (
                            <ListSimpleItemNew
                                label={getCommonMsg(
                                    msgType.SidePanel,
                                    'RegisteredItem'
                                )}
                                labelLocation={'top'}
                                dataSource={makeElementListDataSource(
                                    selectedTemplate
                                )}
                                itemRender={renderElementList}
                                allowItemDeleting={true}
                                itemDeleteMode='static'
                                noDataText={getCommonMsg(
                                    msgType.SidePanel,
                                    'NoData'
                                )}
                                onReorder={handleReorder}
                                onItemDeleted={handleExtraFieldDelete}
                            />
                        )}
                    />
                </GroupItem>
            </Form>
        </>
    )
}

export default SettingsRectElementsLayout
