import React from 'react'
import { Button } from 'devextreme-react'
import { Label } from 'devextreme-react/form'

const ButtonSimpleItemNew = ({ colSpan, type = 'normal', text, icon, disabled = false, onClick = null, label, labelLocation }) => {
    return (
        <>
            {/* Label rendering if exists */}
            {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )}

            {/* Button Component */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                    text={text}
                    icon={icon}
                    type={type}
                    stylingMode={'contained'}
                    onClick={onClick}
                    width='100%'
                    height='100%'
                    disabled={disabled}
                />
            </div>
        </>
    )
}

export default ButtonSimpleItemNew
