import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import { FilterBuilder } from 'devextreme-react'
import { CustomOperation } from 'devextreme-react/filter-builder'
import { EditorComponent } from 'src/pages/components/dq-wrapper-ui-devexpress/EditorComponent'

const TAB_SIZE = 4

function prepareItem(item, spaces) {
    return Array.isArray(item[0])
        ? formatValue(item, spaces + TAB_SIZE)
        : JSON.stringify(item)
}
function formatValue(value, spaces = TAB_SIZE) {
    if (value && Array.isArray(value[0])) {
        const formattedValue = value
            .map((item) => prepareItem(item, spaces))
            .join(`,${getLineBreak(spaces)}`)
        return `[${getLineBreak(spaces)}${formattedValue}${getLineBreak(
            spaces - TAB_SIZE
        )}]`
    }
    return JSON.stringify(value)
}
function getLineBreak(spaces) {
    return `\r\n${new Array(spaces + 1).join(' ')}`
}

function makeFieldOpt(isCatalog, field) {
    let fieldOpt

    if (!isCatalog) {
        if (field === 'v0004') {
            fieldOpt = ['<=', '>=']
        } else {
            fieldOpt = ['contains', '=']
        }
    } else {
        fieldOpt = ['=']
    }

    return fieldOpt
}
function makeDataType(isCatalog, field) {
    let dataType

    if (!isCatalog) {
        if (field === 'v0004') {
            dataType = 'datetime'
        } else {
            dataType = 'string'
        }
    } else {
        dataType = 'string'
    }

    return dataType
}
function makeFieldsByFieldList(useFieldListAll, othersPage) {
    const fields = useFieldListAll.map((fieldInfo) => {
        const isCatalogField = CatalogUtils.isCatalogField(fieldInfo.value)
        const fieldOperations = makeFieldOpt(isCatalogField, fieldInfo.value)
        const dataType = makeDataType(isCatalogField, fieldInfo.value)
        const tgCatalog = getTargetCatalogData(othersPage, fieldInfo.value)

        const field = {
            dataField: fieldInfo.numValue,
            caption: fieldInfo.text + ` (${fieldInfo.numValue})`,
            dataType: dataType,
            filterOperations: fieldOperations,
            lookup: isCatalogField
                ? {
                      dataSource: tgCatalog,
                      valueExpr: function (item) {
                          // "item" can be null
                          return item?.no
                      },
                      displayExpr: function (item) {
                          // "item" can be null
                          return item?.desc
                      },
                  }
                : undefined,
        }

        return field
    })

    return fields
}

function getTargetCatalogData(othersPage, field) {
    let data
    const catalogs = othersPage.catalogs

    if (field === 'v0005') {
        data = catalogs.v0005
    } else if (field === 'v0007') {
        data = catalogs.v0007
    } else if (field === 'v0008') {
        data = catalogs.v0008
    } else if (field === 'v0010') {
        data = catalogs.v0010
    } else if (field === 'v0012') {
        data = catalogs.v0012
    } else {
        data = null
    }
    return data
}

const DBFieldAdvancedRealTimeFilter = ({
    pageKey,
    pageState,
    configState,
    othersPage,
}) => {
    const dispatch = useDispatch()
    const configSettings = configState
    const filterNameTbRef = useRef(null)

    const [filters, setFilters] = useState(pageState.filters.advanced)

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty =
        Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }
    const usePartFilterField = CPUtils.getConfigItemListFromUseField(
        'Part',
        false,
        null,
        useFieldList.part,
        configState
    )
    const useCharFilterField = CPUtils.getConfigItemListFromUseField(
        'Characteristics',
        false,
        null,
        useFieldList.char,
        configState
    )
    const useValueFilterField = CPUtils.getConfigItemListFromUseField(
        'Value',
        false,
        null,
        useFieldList.value,
        configState
    )

    useFieldList = [
        ...usePartFilterField,
        ...useCharFilterField,
        ...useValueFilterField,
    ]
    const fields = makeFieldsByFieldList(useFieldList, othersPage)

    const onValueChanged = (e) => {
        console.log(e)
        const next = produce(filters, (draft) => {
            draft.data = e.value
        })
        setFilters(next)
    }
    const isCompositeCondition = (condition) => {
        return Array.isArray(condition) && Array.isArray(condition[0])
    }

    const isValidFieldRange = (beforeField, integerField) => {
        if (beforeField >= 0 && beforeField < 1000) {
            return integerField < 1000
        } else if (beforeField >= 1000 && beforeField < 2000) {
            return integerField >= 1000 && integerField < 2000
        } else if (
            (beforeField >= 2000 && beforeField < 3000) ||
            (beforeField >= 8000 && beforeField < 9000)
        ) {
            return (
                (integerField >= 2000 && integerField < 3000) ||
                (integerField >= 8000 && integerField < 9000)
            )
        }
        return false
    }

    const validateFilters = (filters) => {
        if (filters === null) {
            return true
        }

        for (const element of filters) {
            if (isCompositeCondition(element)) {
                let beforeField = -1

                for (const el of element) {
                    if (Array.isArray(el)) {
                        const [field] = el
                        const integerField = parseInt(field)

                        if (beforeField === -1) {
                            beforeField = integerField
                        } else {
                            if (!isValidFieldRange(beforeField, integerField)) {
                                return false
                            }
                        }
                    }
                }
            }
        }
        return true
    }

    const onApplyButtonClick = () => {
        // if (!validateFilters(filters.current)) {
        if (!validateFilters(filters.data)) {
            alert(getErrProcMsg(procErrTable.E00001))
            return
        }
        console.log('applied')
        const next = produce(pageState, (draft) => {
            // draft.filters.advanced.data = filters.current
            draft.filters.advanced.data = filters.data
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    const onClearButtonClick = () => {
        console.log('clear')
        const next = produce(pageState, (draft) => {
            draft.filters.advanced.data = null
        })
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
        setFilters(next.filters.advanced)
    }

    const onFilterNameClearButtonClick = () => {
        console.log('Filter Name Clear')
        if (filterNameTbRef.current) {
            filterNameTbRef.current.instance.option('value', '')
        }
    }

    useEffect(() => {
        setFilters(pageState.filters.advanced)
    }, [])

    const filterOperationDescriptions = {
        contains: dqTranslateMsg('SidePanel_' + 'Contains'), // 포함
        equal: dqTranslateMsg('SidePanel_' + 'Equal'), // 같음
        // lessThanOrEqual: dqTranslateMsg('SidePanel_' + 'LessThanOrEqual'), // 작거나 같음
        // greaterThanOrEqual: dqTranslateMsg('SidePanel_' + 'GreaterThanOrEqual'), // 크거나 같음
        // anyof: dqTranslateMsg('SidePanel_' + 'AnyOf'), // 여러 값 중 하나
        // noneof: dqTranslateMsg('SidePanel_' + 'NoneOf'), // 여러 값 중 어떤 것도 아님
        // notEqual: dqTranslateMsg('SidePanel_' + 'NotEqual'), // 같지 않음
    }

    const calculateFilterExpression = (filterValue, field) => {
        return (
            filterValue &&
            filterValue.length &&
            Array.prototype.concat
                .apply(
                    [],
                    filterValue.map((i) => [[field.dataField, '=', i], 'or'])
                )
                .slice(0, -1)
        )
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-advanced-real-time-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'DBFieldAdvancedFilter')}
            >
                {/* AdvancedFilterItem 컴포넌트 */}
                {/* <AdvancedFilterItem
                    colSpan={2}
                    fields={fields}
                    value={filters.data}
                    maxGroupLevel={1}
                    onValueChanged={onValueChanged}
                /> */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <FilterBuilder
                            fields={fields}
                            value={filters.data}
                            maxGroupLevel={1}
                            groupOperations={['and', 'or']}
                            filterOperationDescriptions={
                                filterOperationDescriptions
                            }
                            onValueChanged={onValueChanged}
                            width={'100%'}
                            // onContentReady={onContentReady}
                        >
                            <CustomOperation
                                name='anyof'
                                caption={dqTranslateMsg('SidePanel_' + 'AnyOf')}
                                icon='check'
                                editorComponent={EditorComponent}
                                calculateFilterExpression={
                                    calculateFilterExpression
                                }
                            />
                        </FilterBuilder>
                    )}
                />

                {/* Apply Button */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            colSpan={1}
                            type='normal'
                            text={dqTranslateMsg('SidePanel_' + 'Apply')}
                            icon='check'
                            width='100%'
                            height='100%'
                            onClick={onApplyButtonClick}
                        />
                    )}
                />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            colSpan={1}
                            type='normal'
                            text={dqTranslateMsg('SidePanel_' + 'ClearAll')}
                            icon='clear'
                            width='100%'
                            height='100%'
                            onClick={onClearButtonClick}
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default DBFieldAdvancedRealTimeFilter
