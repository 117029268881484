import { Toast } from 'devextreme-react/toast'
import ReactECharts from 'echarts-for-react'
import produce from 'immer'
import { useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getOverviewMainBarChartOption, getOverviewSubBarChartOption } from 'src/pages/overview/components/main-view/chart-option/OverviewBarChartOption'
import { getOverviewPieChartOption } from 'src/pages/overview/components/main-view/chart-option/OverviewPieChartOption'
import './OverviewForm.scss'
import * as impl from './OverviewFormImpl'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import { useLanguage } from 'src/contexts/languages'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import DQToast from 'src/functions/DQToast'

function OverviewForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const { user } = useAuth()
    const { language } = useLanguage()
    // For initialRendering
    const isInitialRender = useRef(true)
    const piChartRef = useRef(null)
    const allMainChartRef = useRef(null)
    const allSubChartRef = useRef(null)
    const singleChartRef = useRef(null)

    const toastRef = useRef(null)

    const [subChart, setSubChart] = useState({
        show: false,
        partId: null,
    })
    const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

    const { charArray, curPage, configPage } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
    const othersPage = common.pages.others

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty = Object.keys(othersPage.useFieldList).length === 0

    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const piResult = impl.makeStateInfoAll(configPage, valuesArranged)

    // pie single and all
    const pieOption = getOverviewPieChartOption(curPage, piResult.errCnt, piResult.warnCnt, piResult.goodCnt, piResult.noneCnt)

    let barMainOption
    let barSubOption
    if (curPage.page.display.type === 'all') {
        const mainResult = impl.makeState(curPage.page.display.main, subChart, curPage, configPage, othersPage, parts, charArray, valuesArranged)
        barMainOption = getOverviewMainBarChartOption(useFieldList, curPage, mainResult.name, mainResult.errCnt, mainResult.warnCnt, mainResult.goodCnt, mainResult.noneCnt, mainResult.totalCnt)

        const subResult = impl.makeState(curPage.page.display.sub, subChart, curPage, configPage, othersPage, parts, charArray, valuesArranged)
        barSubOption = getOverviewSubBarChartOption(useFieldList, curPage, subResult.name, subResult.errCnt, subResult.warnCnt, subResult.goodCnt, subResult.noneCnt, subResult.totalCnt)
    } else {
        const mainResult = impl.makeState(curPage.page.display.main, subChart, curPage, configPage, othersPage, parts, charArray, valuesArranged)
        // 'single'
        barMainOption = getOverviewMainBarChartOption(useFieldList, curPage, mainResult.name, mainResult.errCnt, mainResult.warnCnt, mainResult.goodCnt, mainResult.noneCnt, mainResult.totalCnt)
    }

    // events settings
    const onChartClick = (e) => {
        const targetPart = parts.find((part) => part.p1002 === e.name)

        const next = produce(subChart, (draft) => {
            draft.show = true
            draft.partId = targetPart.part_id
        })
        // handleCurPageSettingsSave(next)
        setSubChart(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_Overview_' + 'SelectedPart') + ' : ' + e.name)
    }

    const onContextMenu = () => {
        const next = produce(subChart, (draft) => {
            draft.show = false
            draft.partId = null
        })
        setSubChart(next)
        toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_Overview_' + 'NoneSelectedPart'))
    }

    useEChartsResize(piChartRef)
    useEChartsResize(allMainChartRef)
    useEChartsResize(allSubChartRef)
    useEChartsResize(singleChartRef)

    return (
        <div className='dx-theme-background-color'>
            <DQToast ref={toastRef} />

            {charArray.length !== 0 ? (
                <>
                    <div
                        className='overview-pie-chart-container'
                        style={{
                            padding: '3px',
                        }}
                    >
                        <ReactECharts
                            ref={piChartRef}
                            style={{
                                width: '100%',
                                height: '100%',
                                padding: '10px',
                                borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                            }}
                            option={pieOption}
                            theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                            opts={{
                                renderer: 'svg',
                                locale: ETCUtils.checkLangForECharts(language),
                            }}
                        />
                    </div>
                    <div
                        className='overview-bar-chart-container'
                        style={{
                            padding: '3px',
                        }}
                    >
                        {curPage.page.display.type === 'all' && (
                            <>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50%',
                                        padding: '3px',
                                    }}
                                >
                                    <ReactECharts
                                        ref={allMainChartRef}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            padding: '10px',
                                            borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                                        }}
                                        option={barMainOption}
                                        theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                        opts={{
                                            renderer: 'svg',
                                            locale: ETCUtils.checkLangForECharts(language),
                                        }}
                                        onEvents={{
                                            click: onChartClick,
                                            contextmenu: onContextMenu,
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '50%',
                                        padding: '3px',
                                    }}
                                >
                                    <ReactECharts
                                        ref={allSubChartRef}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            padding: '10px',
                                            borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                                        }}
                                        option={barSubOption}
                                        theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                        opts={{
                                            renderer: 'svg',
                                            locale: ETCUtils.checkLangForECharts(language),
                                        }}
                                        // onEvents={{ click: onChartClick, contextmenu: onContextMenu }}
                                    />
                                </div>
                            </>
                        )}
                        {curPage.page.display.type === 'single' && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    padding: '10px',
                                    borderRadius: '10px', // 둥근 테두리를 위한 설정입니다.
                                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <ReactECharts
                                    ref={singleChartRef}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    option={barMainOption}
                                    theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
                                    opts={{
                                        renderer: 'svg',
                                        locale: ETCUtils.checkLangForECharts(language),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <img
                        src={'/images/color/warning-exclamation.svg'}
                        width='30'
                        height='30'
                    />
                    <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_Overview_' + 'DataIsNotSelected')}</div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

const mapDispatchToProps = (dispatch) => ({
    openControlSidePanel: (openStatus) => dispatch(setStateControlSidePanel(openStatus)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewForm)
