import Form, { GroupItem, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import * as rd from 'src/store/common'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useAuth } from 'src/contexts/auth'
import { useApi } from 'src/contexts/api'
import { updateUserInfoForSettings } from 'src/api/user'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import TextBoxNoValueSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItemNew'

const AdvancedSaveFilter = ({
    dispatch,
    currentFilter,
    setCurrentFilter,
    setIsVisibleCreateFilterPopup,
    // textBoxRef,
    pageKey,
    pageState,
    configState,
    othersPage,
    user,
}) => {
    const textBoxRef = useRef(null) // textBoxRef를 useRef로 초기화

    const onFilterNameSaveButtonClick = async () => {
        console.log('applied')

        if (textBoxRef.current) {
            const filterName = textBoxRef.current.instance.option('value')

            if (filterName === '') {
                alert('Please Enter Filter Name')
                return
            }
            const filterFormat = {
                filterName,
                ...currentFilter,
            }

            const filters = [...othersPage.databaseSavedAdvancedFilterList]
            filters.push(filterFormat)

            await updateUserInfoForSettings(
                user.user_id,
                'chart_config_30',
                JSON.stringify(filters)
            )
            batch(() => {
                // dispatch(ps[pageKey].setPageSettingsToRedux(next))
                dispatch(rd.setDatabaseSavedAdvancedFilterList(filters))
                setIsVisibleCreateFilterPopup(false)
            })
        }
    }

    const onFilterPopupCloseButtonClick = () => {
        setIsVisibleCreateFilterPopup(false)
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-advanced-save-filter-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'SaveFilter')}
            >
                {/* TextBox for Filter Name */}
                <SimpleItem
                    colSpan={2}
                    render={() => (
                        <TextBoxNoValueSimpleItemNew
                            colSpan={2}
                            label={dqTranslateMsg('SidePanel_' + 'FilterName')}
                            labelLocation='top'
                            ref={textBoxRef}
                        />
                    )}
                ></SimpleItem>

                {/* Save Button */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            colSpan={1}
                            type='normal'
                            text={dqTranslateMsg('SidePanel_' + 'Save')}
                            icon='check'
                            width='100%'
                            height='100%'
                            onClick={onFilterNameSaveButtonClick}
                        />
                    )}
                ></SimpleItem>

                {/* Close Button */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            colSpan={1}
                            type='normal'
                            text={dqTranslateMsg('SidePanel_' + 'Close')}
                            icon='close'
                            width='100%'
                            height='100%'
                            onClick={onFilterPopupCloseButtonClick}
                        />
                    )}
                ></SimpleItem>
            </GroupItem>
        </Form>
    )
}

export default AdvancedSaveFilter
