import React, { useCallback } from 'react'
import TagBox from 'devextreme-react/tag-box'
// import { categories, categoryLabel } from './data.js'
export const EditorComponent = (props) => {
    const onValueChanged = useCallback(
        (e) => {
            props.data.setValue(e.value && e.value.length ? e.value : null)
        },
        [props.data]
    )
    return (
        <TagBox
            // inputAttr={categoryLabel}
            defaultValue={props.data.value}
            items={props.data.field.lookup.dataSource}
            onValueChanged={onValueChanged}
            minWidth='100px'
            maxWidth='200px'
        />
    )
}
