import React from 'react'
import { NumberBox } from 'devextreme-react'
import { Label } from 'devextreme-react/form'
import './NumberBoxSimpleItem.scss' // Import your custom CSS
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const NumberBoxSimpleItemNew = ({
    colSpan = 1,
    label,
    width,
    labelLocation,
    name,
    min,
    max,
    step,
    format,
    defaultValue = 1,
    value,
    unit,
    disabled = false,
    onValueChanged = null,
}) => {
    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: width,
    }

    return (
        <>
            {/* Label rendering if exists */}
            {/* {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )} */}

            {/* NumberBox and Unit Container */}
            <div
                className='numberbox-container'
                style={containerStyle}
            >
                <NumberBox
                    name={name}
                    label={label}
                    labelMode={labelLocation}
                    min={min}
                    max={max}
                    step={step}
                    format={format}
                    value={value}
                    disabled={disabled}
                    showSpinButtons={true}
                    onValueChanged={onValueChanged}
                    width={'100%'}
                />
                {unit && <span>{dqTranslateMsg('SidePanel_' + unit)}</span>}
            </div>
        </>
    )
}

export default NumberBoxSimpleItemNew
