import { GroupItem, SimpleItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import { ScrollView } from 'devextreme-react'
import AdvancedCommonOption from './advanced/AdvancedCommonOption'
import AdvancedDateTimeOption from './advanced/AdvancedDateTimeOption'
import AdvancedLastNFilterOption from './advanced/AdvancedLastNFilterOption'
import AdvancedETCOption from './advanced/AdvancedETCOption'
import { Form } from 'devextreme-react'
import AdvancedRealTimeFilter from './advanced/AdvancedRealTimeFilter'
import QuickCommonOption from './quick/QuickCommonOption'
import QuickDateTimeOption from './quick/QuickDateTimeOption'
import QuickLastNFilterOption from './quick/QuickLastNFilterOption'
import QuickETCOption from './quick/QuickETCOption'
import QuickOption from './quick/QuickOption'
import QuickModifyButton from './quick/QuickModifyButton'
import { useTheme } from 'src/contexts/theme'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'

const DBFieldPopupFilter = ({
    dispatch,
    pageKey,
    pageState,
    configState,
    othersPage,
    isVisibleFilterPopup,
    setIsVisibleFilterPopup,
    popupDisplayFilter,
    setPopupDisplayFilter,
    partRefPopup,
    charRefPopup,
    valueRefPopup,
    theme,
    user,
}) => {
    const closePopup = () => {
        setIsVisibleFilterPopup(false)
        setPopupDisplayFilter(null)
    }

    const title = 'Display Selected Filter'
    const width = '30%'
    const height = 'auto'

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-popup-filter-group-item`}
                colCount={2}
                caption={''}
            >
                {/* {PopupSimpleItem({
                visible: isVisibleFilterPopup,
                onHiding: closePopup,
                title,
                width,
                height,
                contentRender: renderFilterContent,
            })} */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <PopupSimpleItem
                            visible={isVisibleFilterPopup}
                            onHiding={closePopup}
                            title={title}
                            width={width}
                            height={height}
                            // contentRender={renderFilterContent}
                            // contentRender={() => <div>234</div>}
                        >
                            <QuickCommonOption
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                            />
                            <QuickDateTimeOption
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                            />
                            <QuickLastNFilterOption
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                            />
                            <QuickETCOption
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                            />
                            <QuickOption
                                dispatch={dispatch}
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                                isVisibleFilterPopup={isVisibleFilterPopup}
                                setIsVisibleFilterPopup={
                                    setIsVisibleFilterPopup
                                }
                                pageKey={pageKey}
                                pageState={pageState}
                                configState={configState}
                                othersPage={othersPage}
                                partRefPopup={partRefPopup}
                                charRefPopup={charRefPopup}
                                valueRefPopup={valueRefPopup}
                                theme={theme}
                            />
                            <QuickModifyButton
                                dispatch={dispatch}
                                currentFilter={popupDisplayFilter}
                                setCurrentFilter={setPopupDisplayFilter}
                                isVisibleFilterPopup={isVisibleFilterPopup}
                                setIsVisibleFilterPopup={
                                    setIsVisibleFilterPopup
                                }
                                pageKey={pageKey}
                                pageState={pageState}
                                configState={configState}
                                othersPage={othersPage}
                                user={user}
                            />
                        </PopupSimpleItem>
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default DBFieldPopupFilter
