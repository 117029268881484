import React from 'react'
import { Lookup } from 'devextreme-react'
import { Label } from 'devextreme-react/form'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'

const SelectBoxSimpleItemNew = (props) => {
    const {
        ref,
        colSpan,
        label,
        labelLocation,
        name,
        dataSource,
        valueExpr,
        displayExpr,
        value,
        disabled = false,
        visible = true,
        onItemClick = null,
        width = '100%',
    } = props

    if (!visible) return null

    return (
        <div style={{ gridColumn: `span ${colSpan || 1}`, width: width }}>
            {/* {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )} */}
            <Lookup
                ref={ref}
                name={name}
                label={label}
                labelMode={labelLocation}
                dataSource={dataSource}
                showDropDownButton={true}
                dropDownOptions={{
                    showTitle: false,
                    hideOnOutsideClick: true,
                    showCloseButton: false,
                    maxHeight: 300,
                }}
                value={value}
                width={'auto'}
                valueExpr={valueExpr}
                displayExpr={displayExpr}
                stylingMode='outlined'
                disabled={disabled}
                useNativeScrolling={false}
                onItemClick={onItemClick}
            />
        </div>
    )
}

export default SelectBoxSimpleItemNew
