import React from 'react'
import { TextBox } from 'devextreme-react'
import { Label } from 'devextreme-react/form'

const TextBoxSimpleItemNew = ({
    colSpan,
    ref,
    label,
    labelLocation,
    name,
    value,
    disabled = false,
    onValueChanged = null,
    visible = true,
    readOnly = false,
}) => {
    if (!visible) return null

    return (
        <div style={{ width: '100%' }}>
            {/* {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )} */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextBox
                    ref={ref}
                    name={name}
                    label={label}
                    value={value}
                    labelMode={labelLocation}
                    disabled={disabled}
                    onValueChanged={onValueChanged}
                    width='100%'
                    readOnly={readOnly}
                />
            </div>
        </div>
    )
}

export default TextBoxSimpleItemNew
