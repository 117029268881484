import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { outlierFilterValues } from './ValueProcessUtils'

// class HistogramChartUtils {
export function makeChartData(configSettings, char) {
    const titleText = `${char.c2002 ?? 'empty'} P${char.part_id ?? '?'}/C${
        char.char_id ?? '?'
    }`
    const decimalPlaces = DNUtils.getValueDecimalPlaces(char, configSettings)

    return {
        titleText,
        decimalPlaces,
    }
}
export function makePCData(configSettings, char, value) {
    const {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    } = PCUtils.getAllLimits(char, configSettings)
    const limits = {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    }
    const filteredValue = outlierFilterValues(
        limits,
        char,
        value,
        configSettings
    )

    const { lcl, ucl, average, minValue, maxValue, v0001s } =
        PCUtils.getCalculatedPrms(filteredValue, configSettings)

    return {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        lacl,
        uacl,
        ucl,
        lcl,
        valuesReference: filteredValue,
        v0001s,
        average,
        minValue,
        maxValue,
    }
}

export function makeChartOption(
    isFromReportPage,
    pageSet,
    configSettings,
    char,
    values
) {
    const pcSet = makePCData(configSettings, char, values)
    const chartSet = makeChartData(configSettings, char)

    const settings = {
        ...pageSet,
        ...configSettings,
        ...pcSet,
        ...chartSet,
    }

    return {
        title: {
            text: !isFromReportPage
                ? {
                      text: settings.titleText,
                  }
                : null,
            left: 'center',
            textStyle: {
                fontFamily: 'Arial',
                fontSize:
                    (settings.page.fontSize /
                        (settings.page.layout.row +
                            settings.page.layout.column)) *
                    5,
            },
        },
        animationDuration: 300,
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            top: '15%',
            bottom: '5%',
            left: '1%',
            right: '1%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                nameTextStyle: {
                    fontFamily: 'Arial',
                },
                axisLabel: {
                    show: true,
                    fontFamily: 'Arial',
                    rotate: 90,
                    fontSize: !isFromReportPage
                        ? ((settings.page.fontSize - 2) /
                              (settings.page.layout.row +
                                  settings.page.layout.column)) *
                          3
                        : 10,
                    formatter: function (value) {
                        return DNUtils.calcValueDisplay(
                            Number(value),
                            configSettings
                        )
                    },
                },
                axisLine: {
                    onZero: false,
                },
            },
            {
                type: 'value',
                axisLabel: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                type: 'value',
                name: 'count',
                // alignTicks: false,
                nameTextStyle: {
                    fontFamily: 'Arial',
                    fontSize: !isFromReportPage
                        ? ((settings.page.fontSize - 2) /
                              (settings.page.layout.row +
                                  settings.page.layout.column)) *
                          3
                        : 10,
                },
                axisLabel: {
                    fontFamily: 'Arial',
                    fontSize: !isFromReportPage
                        ? ((settings.page.fontSize - 2) /
                              (settings.page.layout.row +
                                  settings.page.layout.column)) *
                          3
                        : 10,
                    formatter: function (value, index) {
                        return value
                    },
                },
                axisLine: {
                    onZero: false,
                },
            },
            {
                type: 'value',
                name: 'curve',
                // alignTicks: true,
                nameTextStyle: {
                    fontFamily: 'Arial',
                    fontSize: !isFromReportPage
                        ? ((settings.page.fontSize - 2) /
                              (settings.page.layout.row +
                                  settings.page.layout.column)) *
                          3
                        : 10,
                },
                axisLabel: {
                    fontFamily: 'Arial',
                    fontSize: !isFromReportPage
                        ? ((settings.page.fontSize - 2) /
                              (settings.page.layout.row +
                                  settings.page.layout.column)) *
                          3
                        : 10,
                    formatter: function (value, index) {
                        return value
                    },
                },
                axisLine: {
                    onZero: false,
                },
                splitLine: {
                    show: false,
                },
            },
        ],
        toolbox: !isFromReportPage
            ? {
                  feature: {
                      saveAsImage: {},
                  },
                  itemSize:
                      (15 /
                          (settings.page.layout.row +
                              settings.page.layout.column)) *
                      4,
              }
            : null,
        tooltip: {
            trigger: 'item',
            appendToBody: true,
            textStyle: {
                fontFamily: 'Arial',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            position: function (pos, params, dom, rect, size) {
                let obj = { top: 60 }
                obj['left'] =
                    pos[0] < size.viewSize[0] / 2
                        ? pos[0] + 50
                        : pos[0] - size.contentSize[0] - 50
                return obj
            },
            formatter: null,
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                orient: 'horizontal',
                height: `3%`,
                bottom: '1%',
                brushSelect: false,
                showDataShadow: true,
                showDetail: false,
                filterMode: 'none',
            },
            {
                show: true,
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none',
            },
        ],
        series: [
            {
                type: 'line',
                // xAxisIndex: 1,
                symbol: 'none',
                markLine: {
                    show: true,
                    label: '123',
                    precision: 10,
                    animation: false,
                    symbol: 'none',
                },
            },
        ],
    }
}

export function makeChartOptionArray(
    pageKey,
    chars,
    values,
    pageSet,
    configSettings
) {
    return values.map((value, index) => {
        const char = chars.find(
            (c) => c.part_id === value.part_id && c.char_id === value.char_id
        )
        const info = {
            part_id: char.part_id,
            char_id: char.char_id,
        }

        const option = makeChartOption(
            pageKey,
            pageSet,
            configSettings,
            char,
            value
        )
        return { option, info }
    })
}
