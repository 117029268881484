import * as dqConvert from 'src/pages/components/dq-convert/DQConvert'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'

export function makeNumberOfEvent(configPage, othersPage, char, value) {
    const uniqueTypeList = new Set()

    // const flatValues = value.flat()
    const flatValues = value.filter((data) => data.v0002 === 0)
    flatValues.forEach((item) => {
        const evtField = item.v0005
        const values = evtField !== null ? evtField.split(',') : [] // 쉼표로 분리
        values.forEach((value) => {
            if (value.trim() !== '' && value !== null) {
                uniqueTypeList.add(value.trim()) // 중복 제거 (trim을 사용하여 공백 제거)
            }
        })
    })

    const uniqueTypeArray = Array.from(uniqueTypeList)

    const uniqueTypeListCnt = []
    uniqueTypeArray.forEach((uniqueValue) => {
        const count = flatValues.filter((item) => {
            const evtField = item.v0005
            const values =
                evtField !== null
                    ? evtField.split(',').map((v) => v.trim())
                    : []
            return values.includes(uniqueValue)
        }).length
        uniqueTypeListCnt.push(count)
    })

    const convertedUniqueTypeArray = uniqueTypeArray.map((uniqueType) =>
        dqConvert.convertByCatalog('v0005', uniqueType, othersPage.catalogs)
    )

    return {
        uniqueType: convertedUniqueTypeArray,
        uniqueTypeCnt: uniqueTypeListCnt,
    }
}

export function makeDataByLslUsl(
    settings,
    configSettings,
    char,
    value,
    limits
) {
    let errCnt = 0
    let warnCnt = 0
    let goodCnt = 0
    let noneCnt = 0

    // const lsl = char.c2110
    // const usl = char.c2111

    // const nominal = char.c2101 ?? (usl + lsl) / 2
    // const gap = usl - nominal
    // const warnOffset = (gap * configSettings.commonSettings.warningLimitPercentage) / 100
    // const lwl = lsl === null ? null : lsl + warnOffset
    // const uwl = usl === null ? null : usl - warnOffset

    const lsl = limits.lsl
    const usl = limits.usl
    const lwl = limits.lwl
    const uwl = limits.uwl

    value
        .filter((data) => data.v0002 === 0)
        .forEach((valueObj) => {
            // if (lsl === null && usl === null) {
            //     goodCnt++
            // } else if (lsl !== null && usl !== null) {
            //     if (lsl > value.v0001 || usl < value.v0001) {
            //         errCnt++
            //     } else if ((value.v0001 < usl && value.v0001 > uwl) || (value.v0001 > lsl && value.v0001 < lwl)) {
            //         warnCnt++
            //     } else {
            //         goodCnt++
            //     }
            // } else if (lsl !== null && usl === null) {
            //     if (lsl > value.v0001) {
            //         errCnt++
            //     } else if (value.v0001 >= lsl && value.v0001 < lwl) {
            //         warnCnt++
            //     } else {
            //         goodCnt++
            //     }
            // } else {
            //     // if (lsl === null && usl !== null) {
            //     if (usl < value.v0001) {
            //         errCnt++
            //     } else if (value.v0001 <= usl && value.v0001 > uwl) {
            //         warnCnt++
            //     } else {
            //         goodCnt++
            //     }
            // }
            const val = valueObj.v0001

            const prmProps = { val, usl, lsl, uwl, lwl }
            const valueState = CDSUtils.getOverviewValueState(prmProps)
            if (valueState === CDSUtils.GOOD_STATE) {
                goodCnt++
            } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
                warnCnt++
            } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
                warnCnt++
            } else if (valueState === CDSUtils.NG_UPPER_STATE) {
                errCnt++
            } else if (valueState === CDSUtils.NG_LOWER_STATE) {
                errCnt++
            } else {
                // NONE_STATE
                return noneCnt++
            }
        })

    return {
        uniqueType: [char.c2002],
        uniqueTypeCnt: {
            totalCnt: [errCnt + goodCnt],
            ngCnt: [errCnt],
            warnCnt: [warnCnt],
            goodCnt: [goodCnt],
        },
    }
}

export function makeSeriesSummaryData(displayType, targetDataArray) {
    let seriesData = []

    if (displayType === 'event') {
    } else {
        const ngCntArray = targetDataArray.ngCntArray
        const goodCntArray = targetDataArray.goodCntArray
        seriesData.push({
            type: 'bar',
            name: 'ng cnt',
            stack: 'Total',
            data: ngCntArray,
            colorBy: 'data',
        })
        seriesData.push({
            type: 'bar',
            name: 'good cnt',
            stack: 'Total',
            data: goodCntArray,
            colorBy: 'data',
        })
    }

    return seriesData
}

// Event
export function makeSeriesStackData(settings, configSettings, targetData) {
    let seriesData = []

    const lineData = []
    const placeHolerData = [0]
    let sum = 0

    for (let i = 0; i < targetData.uniqueTypeCnt.length; i++) {
        sum += targetData.uniqueTypeCnt[i]
        lineData.push(sum)
        placeHolerData.push(sum)
    }

    const totalCnt = targetData.uniqueTypeCnt.reduce(
        (acc, current) => acc + current,
        0
    )
    const percentageArray = targetData.uniqueTypeCnt.map(
        (cnt) => (cnt / totalCnt) * 100
    )

    seriesData.push({
        name: 'PlaceHolder',
        type: 'bar',
        stack: 'Total',
        silent: true,
        itemStyle: {
            borderColor: 'transparent',
            color: 'transparent',
        },
        emphasis: {
            borderColor: 'transparent',
            color: 'transparent',
        },
        data: placeHolerData,
    })
    seriesData.push({
        type: 'line',
        name: 'event',
        data: lineData,
        symbol: settings.lineChart.symbols.shape,
        symbolSize: settings.lineChart.symbols.size,
        animation: true,
        itemStyle: {
            color: settings.lineChart.symbols.color,
        },
        lineStyle: {
            type: settings.lineChart.chartLines.type,
            width: settings.lineChart.chartLines.width,
            color: settings.lineChart.chartLines.color,
        },
    })
    seriesData.push({
        type: 'bar',
        name: 'event',
        stack: 'Total',
        label: {
            show: true,
            fontSize:
                (settings.page.fontSize /
                    (settings.page.layout.row + settings.page.layout.column)) *
                4,
            position: 'top',
            formatter: function (params) {
                if (percentageArray[params.dataIndex] === 0) {
                    return ''
                } else {
                    return (
                        DNUtils.calcValueDisplay(
                            percentageArray[params.dataIndex],
                            configSettings
                        ) + '%'
                    )
                }
            },
        },

        data: targetData.uniqueTypeCnt,
        colorBy: 'data',
    })
    return seriesData
}

export function makeSeriesNotStackData(settings, configSettings, targetData) {
    let seriesData = []

    const totalCnt = targetData.uniqueTypeCnt.reduce(
        (acc, current) => acc + current,
        0
    )
    const percentageArray = targetData.uniqueTypeCnt.map(
        (cnt) => (cnt / totalCnt) * 100
    )

    seriesData.push({
        type: 'line',
        name: 'event',
        data: targetData.uniqueTypeCnt,
        symbol: settings.lineChart.symbols.shape,
        symbolSize: settings.lineChart.symbols.size,
        animation: true,
        itemStyle: {
            color: settings.lineChart.symbols.color,
        },
        lineStyle: {
            type: settings.lineChart.chartLines.type,
            width: settings.lineChart.chartLines.width,
            color: settings.lineChart.chartLines.color,
        },
    })
    seriesData.push({
        type: 'bar',
        name: 'event',
        data: targetData.uniqueTypeCnt,
        label: {
            show: true,
            position: 'top',
            fontSize:
                (settings.page.fontSize /
                    (settings.page.layout.row + settings.page.layout.column)) *
                4,
            formatter: function (params) {
                if (percentageArray[params.dataIndex] === 0) {
                    return ''
                } else {
                    return (
                        DNUtils.calcValueDisplay(
                            percentageArray[params.dataIndex],
                            configSettings
                        ) + '%'
                    )
                }
            },
        },

        colorBy: 'data',
    })

    return seriesData
}

const makeSeriesData = (settings, configSettings, targetData) => {
    const seriesData = []
    if (settings.page.displayDefectUnit.ng === true) {
        seriesData.push({
            value: targetData.uniqueTypeCnt.ngCnt[0],
            itemStyle: {
                color: settings.barChart.error.color,
            },
        })
    }
    if (settings.page.displayDefectUnit.warn === true) {
        seriesData.push({
            value: targetData.uniqueTypeCnt.warnCnt[0],
            itemStyle: {
                color: settings.barChart.warn.color,
            },
        })
    }
    if (settings.page.displayDefectUnit.good === true) {
        seriesData.push({
            value: targetData.uniqueTypeCnt.goodCnt[0],
            itemStyle: {
                color: settings.barChart.good.color,
            },
        })
    }

    return seriesData
}
// LSL USL
export function makeSeriesLslUslNotStackData(
    settings,
    configSettings,
    targetData
) {
    const calcDecimalPlaces =
        configSettings.commonSettings.calcDisplayDecimalPlaces
    let seriesData = []

    seriesData.push({
        type: 'bar',
        data: makeSeriesData(settings, configSettings, targetData),
        label: {
            show: true,
            position: 'top',
            fontSize:
                (settings.page.fontSize /
                    (settings.page.layout.row + settings.page.layout.column)) *
                4,
            formatter: function (args) {
                const percentage =
                    (args.data.value / targetData.uniqueTypeCnt.totalCnt) * 100
                if (percentage === 0) {
                    return ''
                } else {
                    return (
                        DNUtils.calcValueDisplay(percentage, configSettings) +
                        '%'
                    )
                }
            },
        },
    })

    return seriesData
}
