import { DateBox } from 'devextreme-react'

const DateBoxSimpleItemNew = ({
    ref,
    colSpan = 2,
    label,
    labelLocation = 'top', // 기본 값 설정
    name,
    pickerType,
    displayFormat,
    value,
    disabled = false,
    visible = true,
    onValueChanged,
    width = '100%',
}) => {
    if (!visible) return null

    let calendarOptions = {}

    if (displayFormat === 'HH:mm') {
        calendarOptions = {
            visible: false,
        }
    } else {
        calendarOptions = {
            visible: true,
        }
    }

    return (
        <div style={{ gridColumn: `span ${colSpan || 1}`, width: width }}>
            {/* Label rendering if exists */}
            {/* {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )} */}

            {/* DateBox Component */}
            {/* <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            > */}
            <DateBox
                ref={ref}
                name={name}
                displayFormat={displayFormat}
                pickerType={pickerType}
                value={value}
                useNative={true}
                useMaskBehavior={true}
                interval={1}
                type='datetime'
                disabled={disabled}
                onValueChanged={onValueChanged}
                calendarOptions={calendarOptions}
            />
            {/* </div> */}
        </div>
    )
}

export default DateBoxSimpleItemNew
