import {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    DataGrid,
    Editing,
    Export,
    Item,
    Lookup,
    Pager,
    Paging,
    Position,
    Scrolling,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import produce from 'immer'
import 'jspdf-autotable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { batch, connect, useDispatch } from 'react-redux'
import * as dbValueApi from 'src/api/value'
import { useAuth } from 'src/contexts/auth'
import { defValueField } from 'src/default/field/value'
import CatalogUtils from 'src/functions/CatalogUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import { setValueInfo } from 'src/store/charts-raw-data'
import './ValueListForm.scss'
import { useCallback } from 'react'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import * as vlHelper from './ValueListFormImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'
import { getCurrentFilterData } from 'src/functions/DatabaseUtils'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ValueListForm)

function ValueListForm({ pageKey, common, chartRawData }) {
    const { user } = useAuth()
    // For initialRendering
    const isInitialRender = useRef(true)
    const [highlightApplied, setHighlightApplied] = useState(true)
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [addedDbIdDataSource, setAddedDbIdDataSource] = useState([])
    const [columns, setColumns] = useState([])
    const datagridRef = useRef(null)
    const { saveState, loadState, clearState } = useCustomStateStore({
        stateKey: 'value-list-datagrid',
        excludedFields: ['dataField'],
    }) // Pass your custom state key here

    const dispatch = useDispatch()
    const databasePage = common.pages.database
    const pages = common.pages
    const curPage = common.pages.valueList
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentSel = common.current_sel
    const viewData = curPage.page.extraFieldList
    const selectedChar = common.loaded_all[pageKey]
    const hasWriteAuth = common.pages.others.permissions.includes('write')
    const hasReadAuth = common.pages.others.permissions.includes('read')
    const hasDemoAuth = common.pages.others.permissions.includes('demo')
    const dbFilterInfo = getCurrentFilterData(pages, othersPage)

    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const valuesArranged = CPUtils.checkMatchCharsAndValues(charArray, values)
    const memoizedValuesArranged = valuesArranged
    const memoizedCharArray = charArray

    const value_count = valuesArranged.length
    useEffect(() => {
        // Memoizing data to prevent unnecessary recalculations
        const makeValueListDataSource = async () => {
            console.log('1')
            setIsLoadingPanel(true)
            try {
                let dataSource = []
                chars.forEach((char) => {
                    const targetValue = memoizedValuesArranged.find(
                        (valueArray) =>
                            valueArray.part_id === char.part_id &&
                            valueArray.char_id === char.char_id
                    )
                    if (targetValue) {
                        const {
                            lsl,
                            usl,
                            nominal,
                            lwl,
                            uwl,
                            lpl,
                            upl,
                            lrl,
                            url,
                            lacl,
                            uacl,
                            gap,
                            warnOffset,
                        } = PCUtils.getAllLimits(char, configPage)
                        const limits = {
                            lsl,
                            usl,
                            nominal,
                            lwl,
                            uwl,
                            lpl,
                            upl,
                            lrl,
                            url,
                            lacl,
                            uacl,
                            gap,
                            warnOffset,
                        }
                        const filteredValue = outlierFilterValues(
                            limits,
                            char,
                            targetValue.valueRaw,
                            configPage
                        )
                        // const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

                        const targetData = vlHelper.parseTargetValueItems(
                            configPage,
                            char,
                            filteredValue,
                            viewData
                        )
                        const maxLength = Math.max(
                            dataSource.length,
                            targetData.length
                        )
                        let combinedArray = []
                        for (let i = 0; i < maxLength; i++) {
                            const obj = {}
                            if (i < dataSource.length)
                                Object.assign(obj, dataSource[i])
                            if (i < targetData.length)
                                Object.assign(obj, targetData[i])
                            combinedArray.push(obj)
                        }
                        dataSource = combinedArray
                    }
                })

                let tempAddedDbIdDataSource = []
                const filterData = dbFilterInfo.data
                if (filterData.others.arrangement === 'None') {
                    tempAddedDbIdDataSource = dataSource.map((obj, index) => ({
                        ...obj,
                        no: index + 1,
                    }))
                } else {
                    tempAddedDbIdDataSource = dataSource.map((obj, index) => ({
                        ...obj,
                        arrangement: values[0].fields[index],
                        no: index + 1,
                    }))
                }

                const generatedColumns = vlHelper.columns(
                    memoizedCharArray,
                    memoizedValuesArranged,
                    curPage,
                    configPage,
                    othersPage,
                    viewData
                )

                if (tempAddedDbIdDataSource.length == 0) {
                    tempAddedDbIdDataSource = null
                }
                batch(() => {
                    setAddedDbIdDataSource(tempAddedDbIdDataSource)
                    setColumns(generatedColumns)
                    setIsLoadingPanel(false)
                })
                console.log('2')
            } catch (error) {
                console.error(
                    'Error Making Value List DataSource or Columns:',
                    error
                )
                setIsLoadingPanel(false)
            }
        }

        makeValueListDataSource()
    }, [selectedChar, curPage, configPage])

    const handleRowUpdating = (e) => {
        const newRowData = e.newData // 한 Row에 수정된 여러 column데이터들이 들어올수있다.
        const rowIndex = e.key - 1

        const newArray = Object.entries(newRowData).map(([key, value]) => ({
            key,
            value,
        }))

        const updateInfos = []

        // Modify innerData (Redux)
        newArray.forEach((newData) => {
            const modiKey = newData.key
            const modiValue = newData.value
            const modiMode = modiValue === '' ? 'delete' : 'update' // 'delete' mode will be change attribute to 256

            if (!modiKey) {
                // value could be null
                e.cancel = true
                console.error(
                    `Error -> modiKey : ${modiKey}, modiValue : ${modiValue}`
                )
                return
            }

            /**
             * key => [part_id, char_id, filed]
             */
            const splitted = modiKey.split('_')
            const part_id = parseInt(splitted[0])
            const char_id = parseInt(splitted[1])
            const field = splitted[2]
            let value_id

            if (!(part_id && char_id && field)) {
                e.cancel = true
                console.error(
                    `Error -> part_id : ${part_id}, char_id : ${char_id}, field : ${field}`
                )
                return
            }

            const targetArray = valuesArranged.find(
                (values) =>
                    values.part_id === part_id && values.char_id === char_id
            )

            if (!targetArray) {
                e.cancel = true
                console.error(`Error -> targetArray is not exist`)
                return
            }
            value_id = targetArray.valueRaw[rowIndex].value_id

            const updatedInfo = {}
            updatedInfo['part_id'] = part_id
            updatedInfo['char_id'] = char_id
            updatedInfo['value_id'] = value_id

            if (modiMode === 'update') {
                updatedInfo[field] = modiValue
            } else {
                // 'delete' mode
                updatedInfo['dt_field'] = []
                updatedInfo['dt_field'].push(field)
                updatedInfo['v0002'] = 256
            }

            dbValueApi.setValueInfo(user, updatedInfo)

            const updateForParams = {
                part_id: part_id,
                char_id: char_id,
                value_id: value_id,
                field: field,
                value: modiValue,
                mode: modiMode,
            }

            updateInfos.push(updateForParams)
        })

        // Modify innerData (Redux)
        const tempAllValues = [...values]
        updateInfos.forEach((info) => {
            const { part_id, char_id, value_id, field, value, mode } = info

            if (mode === 'update') {
                const tgValueIdx = tempAllValues.findIndex(
                    (item) =>
                        item.part_id === part_id && item.char_id === char_id
                )

                if (tgValueIdx !== -1) {
                    const tempValues = tempAllValues[tgValueIdx]

                    const tgValueNext = produce(tempValues, (draft) => {
                        const modiValueIndex = draft.valueRaw.findIndex(
                            (item) => item.value_id === value_id
                        )
                        if (modiValueIndex !== -1) {
                            // draft.valueRaw[modiValueIndex][field] = parseFloat(value)
                            draft.valueRaw[modiValueIndex][field] = value
                        }
                    })

                    tempAllValues[tgValueIdx] = tgValueNext
                }
            } else {
                const tgValueIdx = tempAllValues.findIndex(
                    (item) =>
                        item.part_id === part_id && item.char_id === char_id
                )

                if (tgValueIdx !== -1) {
                    const tempValues = tempAllValues[tgValueIdx]

                    const tgValueNext = produce(tempValues, (draft) => {
                        const modiValueIndex = draft.valueRaw.findIndex(
                            (item) => item.value_id === value_id
                        )
                        if (modiValueIndex !== -1) {
                            draft.valueRaw[modiValueIndex][field] = null
                            draft.valueRaw[modiValueIndex]['v0002'] = 256
                        }
                    })

                    tempAllValues[tgValueIdx] = tgValueNext
                }
            }
        })
        dispatch(setValueInfo(tempAllValues))
        e.cancel = false
    }

    const handleInit = (e) => {
        setIsLoadingPanel(false)
        console.log('handleInit')
    }
    const handleRowUpdated = (e) => {
        console.log('handleRowUpdated')
    }
    const handleRowRemoving = (e) => {
        console.log('handleRowRemoving')
        const valueIndex = e.key - 1
        const deleteKeyList = Object.keys(e.data)
        const deleteListObj = deleteKeyList.map((key) => {
            if (key != null) {
                const splittedKey = key.split('_')
                const part_id = parseInt(splittedKey[0])
                const char_id = parseInt(splittedKey[1])
                const field = splittedKey[2]

                return { part_id: part_id, char_id: char_id, field: field }
            }
        })

        if (deleteListObj) {
            deleteListObj.forEach((item) => {
                const filteredArrays = valuesArranged.map((innerArray) => {
                    return innerArray.valueRaw.filter(
                        (element) =>
                            element.part_id === item.part_id &&
                            element.char_id === item.char_id
                    )
                })
                const find_items = filteredArrays.filter(
                    (element) => element.length > 0
                )

                if (find_items !== null && find_items.length > 0) {
                    if (find_items[0].length > valueIndex) {
                        const value_id = find_items[0][valueIndex].value_id

                        const updatedData = {}
                        updatedData['part_id'] = item.part_id
                        updatedData['char_id'] = item.char_id
                        updatedData['value_id'] = value_id

                        dbValueApi.deleteValue(user, updatedData)
                    }
                }
            })
        }

        console.log('handleRowRemoving')
    }
    const handleRowRemoved = (e) => {
        console.log('handleRowRemoved')
    }
    const handleRowSaved = (e) => {
        console.log('handleRowSaved')
    }

    const handleAddMenuItems = (e) => {
        const contextMenu = [
            {
                text: dqTranslateMsg('Page_ValueList_' + 'DeleteCellFromDB'),
                icon: 'clearformat',
                onItemClick: () => {
                    console.log(e)

                    const data = e.row.cells[e.columnIndex].data

                    let dataArray = []
                    for (let key in data) {
                        if (data.hasOwnProperty(key)) {
                            // Check if the key is a direct property of the object
                            dataArray.push([key, data[key]])
                        }
                    }
                    const [key, value] =
                        Array.from(dataArray)[e.columnIndex - 1]
                    const value_index = e.row.key - 1

                    console.log(key)
                    console.log(value)

                    if (key != null && key.length > 0) {
                        const part_char_id = key
                        const idInfos = part_char_id.split('_')

                        if (idInfos !== null && idInfos.length > 2) {
                            let part_id = parseInt(idInfos[0])
                            let char_id = parseInt(idInfos[1])
                            let field_name = idInfos[2]
                            let new_value = value

                            const filteredArrays = valuesArranged.map(
                                (innerArray) => {
                                    return innerArray.valueRaw.filter(
                                        (element) =>
                                            element.part_id === part_id &&
                                            element.char_id === char_id
                                    )
                                }
                            )

                            if (filteredArrays !== null) {
                                const find_items = filteredArrays.filter(
                                    (element) => element.length > 0
                                )
                                if (
                                    find_items !== null &&
                                    find_items.length > 0
                                ) {
                                    if (find_items[0].length > value_index) {
                                        const value_id =
                                            find_items[0][value_index].value_id

                                        const deletedData = {}
                                        deletedData['part_id'] = part_id
                                        deletedData['char_id'] = char_id
                                        deletedData['value_id'] = value_id

                                        dbValueApi.deleteValue(
                                            user,
                                            deletedData
                                        )

                                        const targetValuesIndex =
                                            values.findIndex(
                                                (item) =>
                                                    item.part_id === part_id &&
                                                    item.char_id === char_id
                                            )

                                        if (targetValuesIndex !== -1) {
                                            const tempValues =
                                                values[targetValuesIndex]
                                            const targetValuesNext = produce(
                                                tempValues,
                                                (draft) => {
                                                    const removedValueRaw =
                                                        draft.valueRaw.filter(
                                                            (item) =>
                                                                item.value_id !==
                                                                value_id
                                                        )
                                                    draft.valueRaw =
                                                        removedValueRaw
                                                }
                                            )

                                            const valuesNext = produce(
                                                values,
                                                (draft) => {
                                                    draft[targetValuesIndex] =
                                                        targetValuesNext
                                                }
                                            )

                                            console.log(valuesNext)
                                            dispatch(setValueInfo(valuesNext))
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // process
                },
            },
            {
                text: dqTranslateMsg('Page_ValueList_' + 'DeleteRowFromDB'),
                icon: 'clearformat',
                onItemClick: () => {
                    console.log('handleRowRemoving')
                    const valueIndex = e.row.key - 1
                    const deleteKeyList = Object.keys(e.row.data)
                    const deleteListObj = deleteKeyList.map((key) => {
                        if (key != null) {
                            const splittedKey = key.split('_')
                            const part_id = parseInt(splittedKey[0])
                            const char_id = parseInt(splittedKey[1])
                            const field = splittedKey[2]

                            return {
                                part_id: part_id,
                                char_id: char_id,
                                field: field,
                            }
                        }
                    })

                    if (deleteListObj) {
                        const deletedValueInfo = []
                        deleteListObj.forEach((item) => {
                            const filteredArrays = valuesArranged.map(
                                (innerArray) => {
                                    return innerArray.valueRaw.filter(
                                        (element) =>
                                            element.part_id === item.part_id &&
                                            element.char_id === item.char_id
                                    )
                                }
                            )
                            const find_items = filteredArrays.filter(
                                (element) => element.length > 0
                            )

                            if (find_items !== null && find_items.length > 0) {
                                if (find_items[0].length > valueIndex) {
                                    const part_id = item.part_id
                                    const char_id = item.char_id
                                    const value_id =
                                        find_items[0][valueIndex].value_id

                                    const updatedData = {}
                                    updatedData['part_id'] = part_id
                                    updatedData['char_id'] = char_id
                                    updatedData['value_id'] = value_id

                                    deletedValueInfo.push({
                                        part_id,
                                        char_id,
                                        value_id,
                                    })
                                    dbValueApi.deleteValue(user, updatedData)
                                }
                            }
                        })

                        if (deletedValueInfo.length > 0) {
                            const tempAllValues = [...values]

                            deletedValueInfo.forEach((info) => {
                                const targetValuesIndex = values.findIndex(
                                    (item) =>
                                        item.part_id === info.part_id &&
                                        item.char_id === info.char_id
                                )

                                if (targetValuesIndex !== -1) {
                                    const tempValues = values[targetValuesIndex]

                                    const removedValuesRaw =
                                        tempValues.valueRaw.filter(
                                            (value) =>
                                                value.value_id !== info.value_id
                                        )

                                    const targetValuesNext = produce(
                                        tempValues,
                                        (draft) => {
                                            draft.valueRaw = removedValuesRaw
                                        }
                                    )

                                    tempAllValues[targetValuesIndex] =
                                        targetValuesNext
                                }
                            })

                            dispatch(setValueInfo(tempAllValues))
                        }
                    }
                },
            },
        ]
        if (e.target === 'content' && e.row && e.row.rowType === 'data') {
            if (!e.items) e.items = contextMenu
        }
    }

    useEffect(() => {
        const highlightColumnByName = async (columnName) => {
            setIsLoadingPanel(true)
            if (ps[pageKey].func.sidePanel.target === true)
                if (datagridRef && datagridRef.current) {
                    const dataGridInstance = datagridRef.current.instance
                    const columnsExist = dataGridInstance.getVisibleColumns(0)
                    const columnsForDataField =
                        dataGridInstance.getVisibleColumns()
                    const columnIndex = columnsExist.findIndex(
                        (column) => column.name === columnName
                    )
                    if (columnIndex !== -1) {
                        const moveToName = columnsExist[columnIndex].name
                        const isArranged =
                            dbFilterInfo.data.others.arrangement === 'None'
                                ? false
                                : true
                        const addColumnNum = isArranged ? 2 : 1
                        const targetIndex =
                            addColumnNum +
                            1 +
                            (columnIndex - addColumnNum) * viewData.length -
                            1
                        const moveToDataField =
                            columnsForDataField[targetIndex].dataField
                        if (moveToName !== columnName) {
                            console.error('Value Item is not exist')
                            return
                        }
                        const visibleRow = dataGridInstance.getVisibleRows()
                        if (visibleRow.length === 0) {
                            console.error('Rows are not exist')
                            return
                        }
                        const visibleRowIndex = visibleRow[0].loadIndex
                        console.log(visibleRowIndex)
                        const targetElement = dataGridInstance.getCellElement(
                            0,
                            moveToDataField
                        )
                        if (targetElement) {
                            dataGridInstance.focus(targetElement)
                            targetElement.classList.add('target-element')
                            targetElement.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                            })
                            targetElement.addEventListener(
                                'animationend',
                                () => {
                                    targetElement.classList.remove(
                                        'target-element'
                                    )
                                },
                                { once: true }
                            )
                        } else {
                            console.error('Cell element is undefined')
                        }
                    } else {
                        console.error('Column not found')
                    }
                }
            setIsLoadingPanel(false)
        }
        highlightColumnByName(
            `part_id-${currentSel.part_id},char_id-${currentSel.char_id}`
        )
    }, [currentSel])

    const warningMsgPage = useMemo(() => {
        setIsLoadingPanel(false)
        return (
            <PageWarningMessage
                message={dqTranslateMsg(
                    'Page_ValueList_' + 'DataIsNotSelected'
                )}
            />
        )
    }, [])

    useEffect(() => {
        if (datagridRef.current !== null) {
            vlHelper.handleLoadState(datagridRef, loadState)
        }
    }, [datagridRef.current])

    console.error('value list render')
    return (
        // 'headerPanel'
        <div className='dx-theme-background-color'>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />

            {addedDbIdDataSource != null ? (
                <DataGrid
                    ref={datagridRef}
                    dataSource={addedDbIdDataSource}
                    className='value-list-grid-container'
                    style={{ maxWidth: '100%' }}
                    allowColumnReordering={true}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    noDataText={dqTranslateMsg('Page_ValueList_' + 'NoData')}
                    allowColumnResizing={true}
                    paging={{ enabled: false }}
                    columnResizingMode={'widget'}
                    // columnMinWidth={200}
                    columnWidth={60}
                    columnAutoWidth={false}
                    hoverStateEnabled={true}
                    cacheEnabled={true}
                    renderAsync={false}
                    // deferredRendering={true}
                    repaintChangesOnly={true}
                    onExporting={(e) =>
                        vlHelper.onExporting(e, memoizedValuesArranged)
                    }
                    onRowUpdating={handleRowUpdating}
                    onRowUpdated={handleRowUpdated}
                    onRowRemoving={handleRowRemoving}
                    onRowRemoved={handleRowRemoved}
                    onSaved={handleRowSaved}
                    onContextMenuPreparing={handleAddMenuItems}
                    onCellPrepared={vlHelper.handleCellPrepared}
                    onEditorPreparing={(e) =>
                        vlHelper.onEditorPreparing(e, othersPage)
                    }
                    onOptionChanged={(e) =>
                        vlHelper.handleOptionChanged(
                            e,
                            selectedChar,
                            pageKey,
                            datagridRef
                        )
                    }
                    onInitialized={handleInit}
                    keyExpr='no'
                    width='100%' // Ensure the grid takes up the full width of its container
                >
                    {hasWriteAuth ? (
                        <Editing
                            mode='batch'
                            useIcons={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            selectTextOnEditStart={false}
                            startEditAction={'dblClick'}
                        />
                    ) : null}
                    <Scrolling
                        rowRenderingMode='virtual'
                        columnRenderingMode='virtual'
                        preloadEnabled={false}
                        renderAsync={false}
                        mode={'virtual'}
                        useNative={false}
                        scrollByContent={true}
                        scrollByThumb={true}
                    />
                    <Column
                        dataField={'no'}
                        caption={dqTranslateMsg('Page_ValueList_' + 'ID')}
                        minWidth={50}
                        width={'auto'}
                        alignment='center'
                        fixed={true}
                    />
                    {dbFilterInfo.data.others.arrangement !== 'None' ? (
                        <Column
                            dataField={'arrangement'}
                            minWidth={100}
                            width={'auto'}
                            // cssClass='arrange-max-width'
                            caption={dqTranslateMsg(
                                'Page_ValueList_' + 'Arrangement'
                            )}
                            alignment='center'
                            fixed={true}
                        />
                    ) : null}
                    {columns}
                    <Toolbar>
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'save',
                                text: dqTranslateMsg(
                                    'Page_ValueList_' + 'SaveLayout'
                                ),
                                onClick: () =>
                                    vlHelper.handleSaveState(
                                        datagridRef,
                                        saveState
                                    ),
                            }}
                            location='before'
                        />
                        <Item
                            widget='dxButton'
                            options={{
                                icon: 'clear',
                                text: dqTranslateMsg(
                                    'Page_ValueList_' + 'ClearLayout'
                                ),
                                onClick: () =>
                                    vlHelper.handleClearState(
                                        datagridRef,
                                        clearState
                                    ),
                            }}
                            location='before'
                        />
                        <Item
                            name='saveButton'
                            location='after'
                        />
                        <Item
                            name='revertButton'
                            location='after'
                        />
                        <Item
                            name='searchPanel'
                            location='after'
                        />
                        <Item
                            name='columnChooserButton'
                            locateInMenu='never'
                            location='after'
                        />
                        <Item
                            name='exportButton'
                            location='after'
                        />
                    </Toolbar>
                    <SearchPanel
                        visible={true}
                        width={140}
                        placeholder={dqTranslateMsg(
                            'Page_ValueList_' + 'SearchPlaceHoler'
                        )}
                    />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        width={300}
                        height={500}
                        title={dqTranslateMsg(
                            'Page_ValueList_' + 'ColumnChooserTitle'
                        )}
                        emptyPanelText={dqTranslateMsg(
                            'Page_ValueList_' + 'ColumnChooserEmptyPanelText'
                        )}
                    >
                        <Position
                            my='center'
                            at='center'
                            of={window}
                        />
                        <ColumnChooserSearch
                            enabled={true}
                            editorOptions={{
                                placeholder: dqTranslateMsg(
                                    'Page_ValueList_' +
                                        'ColumnChooserPlaceHoler'
                                ),
                            }}
                        />
                        <ColumnChooserSelection
                            allowSelectAll={false}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <Export
                        enabled={true}
                        formats={['xlsx']}
                        texts={{
                            exportAll: dqTranslateMsg(
                                'Page_ValueList_' + 'ExportToExcel'
                            ),
                        }}
                    />
                </DataGrid>
            ) : (
                warningMsgPage
            )}
        </div>
    )
}
