import Button from 'devextreme-react/button'
import {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    DataGrid,
    Editing,
    Form,
    GroupPanel,
    Item,
    Popup,
    Position,
    SearchPanel,
    Selection,
    Sorting,
    StateStoring,
    Toolbar,
    LoadPanel,
} from 'devextreme-react/data-grid'
import { useCallback, useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import produce from 'immer'
import * as dbCharApi from 'src/api/char'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as dbValueApi from 'src/api/value'
import { useAuth } from 'src/contexts/auth'
import { defCharField } from 'src/default/field/char'
import * as dtoUtils from 'src/dto/DTOImpl'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { makeArrangedData } from 'src/pages/components/dq-arrange/DQArrange'

import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import {
    setAllDataInfo,
    setCharInfoAll,
    setCharInfo,
} from 'src/store/charts-raw-data'
import { setCurrentSel, setLoadedAll } from 'src/store/common'
import { connect } from 'react-redux'
import './DatabaseCharForm.scss'
import TextTitle from 'src/functions/TextTitle'
import { useApi } from 'src/contexts/api'
import {
    compareAlphaNumAsc,
    compareAlphaNumDesc,
    compareNumAsc,
    compareNumDesc,
    comparisonAlphanumericAsc,
    comparisonAlphanumericDesc,
    comparisonNumericAsc,
    comparisonNumericDesc,
    comparisonStringAsc,
    comparisonStringDesc,
} from 'src/functions/SortComparison'
import { CheckBox, RadioGroup } from 'devextreme-react'
import { sortTypeDefine } from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { CustomColumnChooser } from 'src/pages/components/dq-wrapper-ui-devexpress/CustomColumnChooser'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'
import CommonCalcUtils from 'src/functions/CommonCalcUtils'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

const mapDispatchToProps = {
    dispatchSetAllDataInfo: setAllDataInfo,
    dispatchSetCharInfoAll: setCharInfo,
    dispatchLoadAll: setLoadedAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(DatabaseCharForm)

function getSortArrayAscendingByPartCharId(chars) {
    const sortedArray = [...chars].sort((a, b) => {
        if (a.part_id !== b.part_id) {
            return a.part_id - b.part_id
        } else {
            return a.char_id - b.char_id
        }
    })

    return sortedArray
}

function DatabaseCharForm({
    common,
    chartRawData,
    dispatchSetAllDataInfo,
    dispatchSetCharInfoAll,
    dispatchLoadAll,
}) {
    // const { partListAll, charListAll, setPartListAll, setCharListAll } = props

    // const [charListAll, setCharListAll] = useState([])
    const { spring } = useApi()
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState([])
    const [chooserVisible, setChooserVisible] = useState(false)
    const [collapsedRows, setCollapsedRows] = useState([])
    const [expandAll, setExpandAll] = useState(true)

    const charGridRef = useRef(null)
    const { saveState, loadState, clearState } = useCustomStateStore({
        stateKey: 'database-datagrid-char',
        excludedFields: [],
    }) // Pass your custom state key here
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useAuth()
    const charInfoAll = chartRawData.charInfoAll
    const chars = chartRawData.charInfo
    const pages = common.pages
    const othersPage = common.pages.others
    const curPage = common.pages.database
    const configPage = common.pages.configuration
    const selectedPartInfo = chartRawData.partInfo
    // const databaseFilterMode = getCurrentFilterMode(pages)
    const dbFilterInfo = DBUtils.getCurrentFilterData(pages, othersPage)
    const selectionFilter = ['ID', '>', 0] // 기본적으로 모든 항목 선택
    const hasWriteAuth = common.pages.others.permissions.includes('write')
    const hasReadAuth = common.pages.others.permissions.includes('read')
    const hasDemoAuth = common.pages.others.permissions.includes('demo')

    const isCommonEmpty = Object.keys(othersPage.useFieldList).length === 0
    const isLsUseFieldListEmpty =
        Object.keys(othersPage.useFieldList).length === 0
    let useFieldList = {}
    if (isCommonEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else if (isLsUseFieldListEmpty === false) {
        useFieldList = othersPage.useFieldList
    } else {
        useFieldList = {
            part: [],
            char: [],
            value: [],
        }
    }

    const defaultOpenColumns = ['p1002', 'c2001', 'c2002']

    const part_name = [
        {
            configuration: true,
            value: 'p1002',
            text: 'P1002',
            visible: true,
            dataType: 'string',
            groupIndex: 0,
        },
    ]
    const dbCharGridField = [
        { part: useFieldList.part, char: useFieldList.char },
    ]

    const columns = dbCharGridField.map((columnContent) => {
        const partColumn = columnContent.part.map((part, index) => {
            let captionCondition = ''
            if (configPage.commonSettings.displayFieldNameWithNumber) {
                captionCondition += `[ ${part.text} ] `
            }
            captionCondition += dqTranslateMsg(
                'Db_field_' + 'Part_' + part.text
            )
            return (
                <Column
                    key={`char-column-part-${index}`}
                    dataField={part.value}
                    caption={captionCondition}
                    dataType={part.dataType}
                    visible={defaultOpenColumns.includes(part.value)}
                    minWidth={100}
                    alignment={'center'}
                    {...(part.groupIndex !== undefined
                        ? { groupIndex: part.groupIndex }
                        : {})}
                />
            )
        })

        const charColumn = columnContent.char.map((char, index) => {
            let captionCondition = ''
            if (configPage.commonSettings.displayFieldNameWithNumber) {
                captionCondition += `[ ${char.text} ] `
            }
            captionCondition += dqTranslateMsg(
                'Db_field_' + 'Characteristics_' + char.text
            )
            return (
                <Column
                    key={`char-column-char-${index}`}
                    dataField={char.value}
                    caption={captionCondition}
                    dataType={char.dataType}
                    visible={defaultOpenColumns.includes(char.value)}
                    minWidth={100}
                    alignment={'center'}
                    {...(char.groupIndex !== undefined
                        ? { groupIndex: char.groupIndex }
                        : {})}
                />
            )
        })

        return [...partColumn, ...charColumn]
    })

    const makeCharGridDataSource = (selectedPart, selectedCharListAll) => {
        try {
            if (selectedCharListAll.length > 0) {
                const gridDataSource = selectedCharListAll.map(
                    (char, index) => {
                        const targetPart = selectedPart.find(
                            (part) => part.part_id === char.part_id
                        )
                        return {
                            ID: index + 1,
                            // p1002: targetPart.p1002,
                            ...targetPart,
                            ...char,
                        }
                    }
                )

                return gridDataSource
            }
            return []
        } catch (e) {
            console.log('database' + e)
            return []
        }
    }

    const makeCharGridDataDefaultRowKeys = (dataSource) => {
        const selRowKeys = dataSource.map((data) => data.ID)
        return selRowKeys
    }

    const handleLoadButtonClicked = useCallback(async () => {
        const selectedRow =
            await charGridRef.current.instance.getSelectedRowsData()

        if (selectedRow.length > 0) {
            setIsLoadingPanel(true)
            const selCharList = selectedRow.map((target) =>
                chartRawData.charInfoAll.find(
                    (item) =>
                        item.part_id === target.part_id &&
                        item.char_id === target.char_id
                )
            )

            // const { filters } = curPage
            // const filterMode = curPage.page.filterMode
            // const filtersAuth = { ...filters, filterMode }

            const ids = selCharList.reduce((acc, { part_id, char_id }) => {
                const existingEntry = acc.find(
                    (entry) => entry.part_id === part_id
                )

                if (existingEntry) {
                    existingEntry.char_ids.push(char_id)
                } else {
                    acc.push({ part_id, char_ids: [char_id] })
                }

                return acc
            }, [])

            spring
                .getValueInfo(user, ids, dbFilterInfo)
                .then((result) => {
                    console.log('getMultiValueInfo')

                    if (!ApiStatus.isSuccess(result.status)) {
                        alert(getErrCommMsg(result.status))
                    }

                    const data = result.data

                    if (data.length !== 0) {
                        const dataForCheck = data.flat()
                        const isValid =
                            DBUtils.checkValueValidation(dataForCheck)

                        if (isValid) {
                            const filterData = dbFilterInfo.data
                            const valueDTO =
                                dtoUtils.makeValueInfoToDTOFormat(data)
                            const arrangeOpt = filterData.others.arrangement
                            const latestOpt =
                                filterData.others.ignoreDuplicatedValue
                            const sortOpt = filterData.common.dateTimeSort

                            const selectedCharInfo = []
                            selectedRow.forEach((selChar) => {
                                // selectedCharInfo.push(chartRawData.charInfoAll.find((char) => char.part_id === selChar.part_id && char.char_id === selChar.char_id))
                                const foundChar = chartRawData.charInfoAll.find(
                                    (char) =>
                                        char.part_id === selChar.part_id &&
                                        char.char_id === selChar.char_id
                                )
                                console.log('foundChar:', foundChar) // 찾은 객체 출력
                                if (foundChar) {
                                    selectedCharInfo.push(foundChar)
                                }
                            })

                            const arrangedData = makeArrangedData(
                                arrangeOpt,
                                latestOpt,
                                sortOpt,
                                selectedPartInfo,
                                selectedCharInfo,
                                valueDTO
                            )

                            const next = produce(chartRawData, (draft) => {
                                draft.charInfo = selectedCharInfo
                                draft.valueInfo = arrangedData
                            })

                            // const charInfo =
                            //   getSortArrayAscendingByPartCharId(selectedCharInfo)
                            const charInfo = selectedCharInfo

                            dispatchSetAllDataInfo(next)
                            dispatch(setCurrentSel(charInfo[0]))
                            dispatchLoadAll({
                                overview: undefined,
                                main: undefined,
                                valueChart: undefined,
                                histogramChart: undefined,
                                positionalTolerancesChart: undefined,
                                boxPlotChart: undefined,
                                linearRegressionChart: undefined,
                                correlationChart: undefined,
                                qualityControlChart: undefined,
                                capabilitySummaryChart: undefined,
                                probabilityPlotChart: undefined,
                                paretoChart: undefined,
                                valueList: undefined,
                                valueSummaryList: undefined,
                                summaryList: undefined,
                                reportPrint: undefined,
                                decisionTreeChart: undefined,
                            })
                            setIsLoadingPanel(false)
                            navigate('/overview')
                        } else {
                            setIsLoadingPanel(false)
                            alert(
                                dqTranslateMsg(
                                    'Page_Database_' +
                                        'ErrorMsgNotMatchValueField'
                                )
                            )
                        }
                    } else {
                        setIsLoadingPanel(false)
                        alert(
                            dqTranslateMsg(
                                'Page_Database_' + 'ErrorMsgEmptyValueData'
                            )
                        )
                    }
                })
                .catch((error) => {
                    setIsLoadingPanel(false)
                    alert(error)
                })
        } else {
            alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgEmptyChar'))
        }
    })

    const handleRowUpdated = (e) => {
        console.log('updated')

        const tgCharData = { ...e.data }
        delete tgCharData.ID
        delete tgCharData.p1002

        // null 값을 가지고 있는
        const charData = {}
        const dt_field = []
        for (const key in tgCharData) {
            if (tgCharData[key] === null || tgCharData[key] === '') {
                dt_field.push(key)
            } else {
                charData[key] = tgCharData[key]
            }
        }

        const updatedData = {
            ...charData,
            dt_field,
        }
        // console.log(updatedData)
        dbCharApi.setCharInfo(user, updatedData)

        const selAllIndex = charInfoAll.findIndex(
            (item) =>
                item.part_id === charData.part_id &&
                item.char_id === charData.char_id
        )

        // If the item is found, update its p1001 value to 2
        let nextInfoAll
        if (selAllIndex !== -1) {
            nextInfoAll = produce(charInfoAll, (draft) => {
                const target = draft[selAllIndex]
                if (target) {
                    Object.keys(target).forEach((key) => {
                        if (charData.hasOwnProperty(key)) {
                            target[key] = charData[key]
                        } else {
                            target[key] = null
                        }
                    })
                }
            })
        }

        const selInfoIndex = chars.findIndex(
            (item) =>
                item.part_id === charData.part_id &&
                item.char_id === charData.char_id
        )

        let nextInfo
        if (selInfoIndex !== -1) {
            nextInfo = produce(chars, (draft) => {
                const target = draft[selInfoIndex]
                if (target) {
                    Object.keys(target).forEach((key) => {
                        if (charData.hasOwnProperty(key)) {
                            target[key] = charData[key]
                        } else {
                            target[key] = null
                        }
                    })
                }
            })
        }

        if (selAllIndex !== -1 || selInfoIndex !== -1) {
            batch(() => {
                if (selAllIndex !== -1) {
                    dispatch(setCharInfoAll(nextInfoAll))
                }

                if (selInfoIndex !== -1) {
                    dispatch(setCharInfo(nextInfo))
                }
            })
        }
    }
    const handleRowInserted = (e) => {
        console.log('inserted')
    }
    const handleRowRemoved = (e) => {
        console.log('removed')
        const d = e.data
        dbCharApi.deleteChar(user, d.part_id, d.char_id)
    }

    const handleSortTypeClicked = (e) => {
        console.log(e)
        const next = produce(curPage, (draft) => {
            draft.page.charSortType = e.value
        })

        dispatch(ps.database.setPageSettingsToRedux(next))
    }

    const sortByLocation = (rowData, dataField) => {
        console.log(rowData[dataField])
        return rowData[dataField]
    }

    const handleCustomSort = (e) => {
        if (e && e.rowType === 'header') {
            e.cellElement.style.textAlign = 'center'
            e.cellElement.addEventListener('click', function (args) {
                const sortOrder = e.column.sortOrder
                const dataField = e.column.dataField

                const setSortOptions = (order, sortingMethod) => {
                    e.component.columnOption(e.column.index, 'sortOrder', order)
                    if (e.column.dataType === 'string') {
                        e.component.columnOption(
                            e.column.index,
                            'calculateSortValue',
                            (rowData) => sortByLocation(rowData, dataField)
                        )
                        e.component.columnOption(
                            e.column.index,
                            'sortingMethod',
                            sortingMethod
                        )
                    } else {
                        e.component.columnOption(
                            e.column.index,
                            'calculateSortValue',
                            null
                        )
                        e.component.columnOption(
                            e.column.index,
                            'sortingMethod',
                            sortingMethod
                        )
                    }
                }

                if (!e.column.type && sortOrder === undefined) {
                    console.log('Added ascending sort')
                    setSortOptions(
                        'asc',
                        e.column.dataType === 'string'
                            ? compareAlphaNumAsc
                            : compareNumAsc
                    )
                } else if (!e.column.type && sortOrder === 'asc') {
                    console.log('Added descending sort')
                    setSortOptions(
                        'desc',
                        e.column.dataType === 'string'
                            ? compareAlphaNumDesc
                            : compareNumDesc
                    )
                } else {
                    console.log('Cleared sort')
                    setSortOptions(undefined, null)
                }

                console.log(`e.column.index: ${e.column.index}`)
                args.preventDefault()
                args.stopPropagation()
            })
        }
    }
    useEffect(() => {
        console.log('effect!!!')
        const charListAll = chartRawData.charInfoAll
        if (charListAll.length > 0) {
            const dataSource = makeCharGridDataSource(
                chartRawData.partInfo,
                charListAll
            )

            // const defaultSelectedRowKeys = makeCharGridDataDefaultRowKeys(dataSource)
            batch(() => {
                setDataSource(dataSource)
                // setDefaultSelectedRowKeys(defaultSelectedRowKeys)
            })
        }

        if (charGridRef.current) {
            charGridRef.current.instance.selectAll()
        }
    }, [chartRawData.charInfoAll])

    useEffect(() => {
        // if
        batch(() => {
            setDataSource([])
            // setDefaultSelectedRowKeys([])
        })
    }, [curPage])

    const handleColumnVisibilityChange = useCallback((e) => {
        if (
            e.component &&
            e.fullName.startsWith('columns[') &&
            e.fullName.endsWith('].visible')
        ) {
            const columnIndex = parseInt(e.fullName.match(/\d+/)[0], 10)
            const isVisible = e.value
            const gridInstance = e.component

            if (isVisible) {
                const visibleColumns = gridInstance.getVisibleColumns()
                const lastIndex = visibleColumns.length - 1
                gridInstance.columnOption(
                    columnIndex,
                    'visibleIndex',
                    lastIndex
                )
            }
        }
    }, [])

    const handleSaveState = () => {
        if (charGridRef.current) {
            const state = charGridRef.current.instance.state()
            saveState(state)
        }
    }

    const handleLoadState = () => {
        if (charGridRef.current) {
            const state = loadState()
            if (state) {
                charGridRef.current.instance.state(state)
            }
        }
    }

    const handleClearState = () => {
        clearState()
        if (charGridRef.current) {
            const emptyState = {
                columns: [],
                sorting: [],
                grouping: [],
                selectionFilter: [],
                pageIndex: 0,
                pageSize: 10,
            }
            charGridRef.current.instance.state(emptyState)
        }
    }
    const handleKeyDown = (e) => {
        if (charGridRef) {
            // Example shortcut: Shift+L to perform a specific action
            if (e.event.shiftKey && e.event.key === 'L') {
                // e.preventDefault() // Prevent the default action if any
                handleLoadButtonClicked() // Your specific action function
            }
            if (e.event.key === 'Escape') {
                charGridRef.current.instance.hideColumnChooser()
            }
        }
    }

    const handleExpandAllClicked = (value) => {
        setExpandAll(value)
        if (value === true) {
            charGridRef.current.instance.expandAll()
            setCollapsedRows([]) // 모든 행이 확장되었으므로 빈 배열로 초기화
        } else {
            const allRowKeys = charGridRef.current.instance
                .getVisibleRows()
                .filter((row) => row.rowType === 'group') // 그룹 행만 선택
                .map((row) => JSON.stringify(row.key)) // 각 그룹 키를 직렬화하여 고유 키 생성

            allRowKeys.forEach((key) => {
                const keyArray = JSON.parse(key) // 문자열을 배열로 변환
                charGridRef.current.instance.collapseRow(keyArray)
            })

            setCollapsedRows(allRowKeys) // 직렬화된 모든 그룹 키를 collapsedRows에 저장
        }
    }

    useEffect(() => {
        handleLoadState()
    }, [])

    return (
        // <div className="dx-theme-background-color">
        <div style={{ width: '100%', height: '100%' }}>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />
            <div className='settings-container'>
                <TextTitle
                    text={dqTranslateMsg('Page_Database_' + 'CharList')}
                    width='50%'
                    fontSize={'16px'}
                    textAlign={'left'}
                />
                {/* <RadioGroup items={sortTypeDefine()} layout="horizontal" width={400} value={curPage.page.charSortType} valueExpr="value" displayExpr="text" onValueChanged={handleSortTypeClicked} /> */}
                <div className='char-layout-button-container'>
                    <Button
                        id='layoutButton'
                        style={{ width: '100%' }}
                        icon='save'
                        text={dqTranslateMsg('Page_Database_' + 'SaveLayout')}
                        onClick={handleSaveState}
                    />
                </div>
                <div className='char-layout-button-container'>
                    <Button
                        id='layoutButton'
                        style={{ width: '100%' }}
                        icon='clear'
                        text={dqTranslateMsg('Page_Database_' + 'ClearLayout')}
                        onClick={handleClearState}
                    />
                </div>
                <div className='char-load-button-container'>
                    <Button
                        id='charLoadButton'
                        icon='download'
                        onClick={handleLoadButtonClicked}
                    />
                </div>
            </div>
            <div
                id='charDiv'
                className='database-char-grid-container'
            >
                <DataGrid
                    ref={charGridRef}
                    className='database-char-grid-content'
                    id='charDataGrid'
                    keyExpr='ID'
                    dataSource={dataSource}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode='widget'
                    // defaultSelectionFilter={selectionFilter}
                    // selectedRowKeys={defaultSelectedRowKeys}
                    scrolling={{
                        columnRenderingMode: 'standard',
                        rowRenderingMode: 'standard',
                        mode: 'standard',
                        preloadEnabled: false,
                        renderAsync: false,
                        showScrollbar: 'onHover',
                        useNative: false,
                        scrollByContent: true,
                        scrollByThumb: true,
                    }}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    filterRow={{ visible: false }}
                    noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onRowExpanded={(e) => {
                        setCollapsedRows((prevRows) => {
                            // e.key 배열을 문자열로 직렬화하여 고유 키로 사용
                            const targetKey = JSON.stringify(e.key)
                            return prevRows.filter((row) => row !== targetKey)
                        })
                    }}
                    onRowCollapsed={(e) => {
                        setCollapsedRows((prevRows) => {
                            const targetKey = JSON.stringify(e.key)
                            if (!prevRows.includes(targetKey)) {
                                return [...prevRows, targetKey]
                            }
                            return prevRows
                        })
                    }}
                    onKeyDown={handleKeyDown}
                    onRowUpdated={handleRowUpdated}
                    onRowInserted={handleRowInserted}
                    onRowRemoved={handleRowRemoved}
                    // onSelectionChanged={onSelectionChanged}
                    onCellPrepared={handleCustomSort}
                    onOptionChanged={handleColumnVisibilityChange}
                    onEditingStart={(e) => {
                        console.log('Editing started Char for: ', e.data)
                    }}
                    onEditorPreparing={(e) => {
                        // 특정 필드의 값이 변경될 때
                        // allowance지우면 lsl 자동으로 지워짐 ,usl이랑동일
                        // lsl을 입력하면 allowance가 입력됨 ,usl동일
                        // lsl보다 usl이 크면안된다 - 미만이어야된다. 알림띄워야된다 반대도마찬가지
                        // nominal 지우면 allowance들 다 지워지고
                        // nominal입력하면 allowance자동입력
                        if (
                            e.parentType === 'dataRow' &&
                            e.dataField === 'c2112'
                        ) {
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                const currentRowData = { ...e.row.data }

                                if (!currentRowData.c2101) {
                                    alert(
                                        getPageMsg(
                                            msgType.Database,
                                            'NominalUndefined'
                                        )
                                    )

                                    currentRowData.c2112 = null
                                    currentRowData.c2113 = null
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2112',
                                        currentRowData.c2112
                                    )
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2113',
                                        currentRowData.c2113
                                    )
                                    return
                                }

                                // 팝업 스크롤 컨테이너 가져오기
                                if (typeof currentRowData.c2101 === 'number') {
                                    if (args.value === null) {
                                        currentRowData.c2112 = args.value
                                        currentRowData.c2110 = null
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2112',
                                            currentRowData.c2112
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2110',
                                            currentRowData.c2110
                                        )
                                    } else {
                                        const c2101Value = currentRowData.c2101
                                        currentRowData.c2112 = args.value
                                        // currentRowData.c2110 = c2101Value + args.value
                                        currentRowData.c2110 =
                                            CommonCalcUtils.calculateSum(
                                                c2101Value,
                                                args.value
                                            )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2112',
                                            currentRowData.c2112
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2110',
                                            currentRowData.c2110
                                        )
                                    }
                                }
                            }
                        } else if (
                            e.parentType === 'dataRow' &&
                            e.dataField === 'c2113'
                        ) {
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                const currentRowData = { ...e.row.data }

                                if (!currentRowData.c2101) {
                                    alert(
                                        getPageMsg(
                                            msgType.Database,
                                            'NominalUndefined'
                                        )
                                    )
                                    currentRowData.c2112 = null
                                    currentRowData.c2113 = null
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2112',
                                        currentRowData.c2112
                                    )
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2113',
                                        currentRowData.c2113
                                    )
                                    return
                                }
                                // 팝업 스크롤 컨테이너 가져오기
                                if (typeof currentRowData.c2101 === 'number') {
                                    if (args.value === null) {
                                        currentRowData.c2113 = args.value
                                        currentRowData.c2111 = null
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2113',
                                            currentRowData.c2113
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2111',
                                            currentRowData.c2111
                                        )
                                    } else {
                                        const c2101Value = currentRowData.c2101
                                        currentRowData.c2113 = args.value
                                        // currentRowData.c2111 = c2101Value + args.value
                                        currentRowData.c2111 =
                                            CommonCalcUtils.calculateSum(
                                                c2101Value,
                                                args.value
                                            )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2113',
                                            currentRowData.c2113
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2111',
                                            currentRowData.c2111
                                        )
                                    }
                                }
                            }
                        } else if (
                            e.parentType === 'dataRow' &&
                            e.dataField === 'c2110'
                        ) {
                            // lsl
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                const currentRowData = { ...e.row.data }

                                if (
                                    typeof args.value === 'number' &&
                                    typeof currentRowData.c2111 === 'number'
                                ) {
                                    if (args.value >= currentRowData.c2111) {
                                        alert(
                                            getPageMsg(
                                                msgType.Database,
                                                'LslBiggerThanUsl'
                                            )
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2110',
                                            currentRowData.c2110
                                        )
                                        return
                                    }
                                }
                                if (typeof currentRowData.c2101 === 'number') {
                                    if (args.value === null) {
                                        currentRowData.c2110 = args.value
                                        currentRowData.c2112 = null
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2110',
                                            currentRowData.c2110
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2112',
                                            currentRowData.c2112
                                        )
                                    } else {
                                        const c2101Value = currentRowData.c2101
                                        currentRowData.c2110 = args.value
                                        // currentRowData.c2112 = args.value - c2101Value
                                        currentRowData.c2112 =
                                            CommonCalcUtils.calculateDifference(
                                                args.value,
                                                c2101Value
                                            )

                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2110',
                                            currentRowData.c2110
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2112',
                                            currentRowData.c2112
                                        )
                                    }
                                }
                            }
                        } else if (
                            e.parentType === 'dataRow' &&
                            e.dataField === 'c2111'
                        ) {
                            // usl
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                const currentRowData = { ...e.row.data }

                                if (
                                    typeof currentRowData.c2110 === 'number' &&
                                    typeof args.value === 'number'
                                ) {
                                    if (args.value <= currentRowData.c2110) {
                                        alert(
                                            getPageMsg(
                                                msgType.Database,
                                                'UslLesserThanLsl'
                                            )
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2111',
                                            currentRowData.c2111
                                        )
                                        return
                                    }
                                }

                                // 팝업 스크롤 컨테이너 가져오기
                                if (typeof currentRowData.c2101 === 'number') {
                                    //nominal
                                    if (args.value === null) {
                                        currentRowData.c2111 = args.value
                                        currentRowData.c2113 = null
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2111',
                                            currentRowData.c2111
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2113',
                                            currentRowData.c2113
                                        )
                                    } else {
                                        const c2101Value = currentRowData.c2101
                                        currentRowData.c2111 = args.value
                                        currentRowData.c2113 =
                                            CommonCalcUtils.calculateDifference(
                                                args.value,
                                                c2101Value
                                            )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2111',
                                            currentRowData.c2111
                                        )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2113',
                                            currentRowData.c2113
                                        )
                                    }
                                }
                            }
                        } else if (
                            e.parentType === 'dataRow' &&
                            e.dataField === 'c2101'
                        ) {
                            // nominal
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                const currentRowData = { ...e.row.data }

                                //nominal
                                if (args.value === null) {
                                    alert(
                                        getPageMsg(
                                            msgType.Database,
                                            'NominalUndefined'
                                        )
                                    )

                                    currentRowData.c2101 = args.value
                                    currentRowData.c2112 = null
                                    currentRowData.c2113 = null
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2101',
                                        currentRowData.c2101
                                    )
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2112',
                                        currentRowData.c2112
                                    )
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2113',
                                        currentRowData.c2113
                                    )
                                } else {
                                    // const c2101Value = currentRowData.c2101
                                    currentRowData.c2101 = args.value

                                    if (
                                        typeof currentRowData.c2110 === 'number'
                                    ) {
                                        const lslValue = currentRowData.c2110
                                        // currentRowData.c2112 = lslValue - args.value
                                        currentRowData.c2112 =
                                            CommonCalcUtils.calculateDifference(
                                                lslValue,
                                                args.value
                                            )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2112',
                                            currentRowData.c2112
                                        )
                                    }

                                    if (
                                        typeof currentRowData.c2111 === 'number'
                                    ) {
                                        const uslValue = currentRowData.c2111
                                        // currentRowData.c2113 = uslValue - args.value
                                        currentRowData.c2113 =
                                            CommonCalcUtils.calculateDifference(
                                                uslValue,
                                                args.value
                                            )
                                        gridInstance.cellValue(
                                            rowIndex,
                                            'c2113',
                                            currentRowData.c2113
                                        )
                                    }
                                    gridInstance.cellValue(
                                        rowIndex,
                                        'c2101',
                                        currentRowData.c2101
                                    )
                                }
                            }
                        } else if (e.parentType === 'dataRow') {
                            e.editorOptions.onValueChanged = function (args) {
                                const gridInstance = e.component
                                const rowIndex = e.row.rowIndex
                                if (e.dataType === 'number') {
                                    // 숫자형 데이터로 변환하여 저장
                                    gridInstance.cellValue(
                                        rowIndex,
                                        e.dataField,
                                        Number(args.value)
                                    )
                                } else {
                                    // 문자열로 저장
                                    gridInstance.cellValue(
                                        rowIndex,
                                        e.dataField,
                                        String(args.value)
                                    )
                                }
                            }
                        } else {
                        }
                    }}
                    onContentReady={(e) => {
                        collapsedRows.forEach((collapsedKey) => {
                            const keyArray = JSON.parse(collapsedKey) // 복원
                            e.component.collapseRow(keyArray)
                        })
                    }}
                >
                    {/* <LoadPanel enabled /> */}
                    <Selection
                        mode='multiple'
                        deferred={true}
                        allowSelectAll={true}
                        showCheckBoxesMode={'always'}
                    />
                    <Sorting mode='single' />
                    <Column
                        type='selection'
                        fixed={true}
                        showInColumnChooser={false}
                        visible={true}
                        fixedPosition='left'
                        width={80}
                    />
                    <Column
                        type='buttons'
                        fixed={true}
                        showInColumnChooser={false}
                        visible={true}
                        fixedPosition='right'
                        buttons={['edit']}
                        width={50}
                    />
                    <Editing
                        mode='popup'
                        useIcons={true}
                        allowUpdating={true}
                        allowDeleting={true}
                    >
                        <Popup
                            width={'80%'}
                            height={'auto'}
                            maxWidth={'80%'}
                            maxHeight={'80%'}
                            hideOnOutsideClick={true}
                            showCloseButton={true}
                        />
                        <Form
                            width={'100%'}
                            height={'100%'}
                            colCount={4}
                            labelMode='outside'
                            labelLocation='top'
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {useFieldList.char.length > 0
                                ? useFieldList.char.map((item, index) => {
                                      if (item.displayType === 'plain') {
                                          if (item.dataType === 'number') {
                                              return (
                                                  <Item
                                                      key={`database-char-editing-item-${index}`}
                                                      editorType={'dxNumberBox'}
                                                      dataField={item.value}
                                                      colSpan={1}
                                                      editorOptions={{
                                                          disabled:
                                                              item.configuration ===
                                                              false,
                                                          stylingMode:
                                                              'outlined', // 선택 사항: 스타일링 모드
                                                          width: '100%', // 부모 컨테이너 기준 너비 50%
                                                          invalidValueMessage:
                                                              '123',
                                                      }}
                                                  />
                                              )
                                          } else {
                                              return (
                                                  <Item
                                                      key={`database-char-editing-item-${index}`}
                                                      editorType={'dxTextBox'}
                                                      dataField={item.value}
                                                      colSpan={1}
                                                      editorOptions={{
                                                          disabled:
                                                              item.configuration ===
                                                              false,
                                                          stylingMode:
                                                              'outlined', // 선택 사항: 스타일링 모드
                                                          width: '100%', // 부모 컨테이너 기준 너비 50%
                                                      }}
                                                  />
                                              )
                                          }
                                      } else {
                                          return (
                                              <Item
                                                  key={`database-char-editing-item-${index}`}
                                                  dataField={item.value}
                                                  editorType='dxSelectBox'
                                                  colSpan={1}
                                                  style={{
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      textOverflow: 'ellipsis',
                                                  }}
                                                  editorOptions={{
                                                      dataSource:
                                                          item.listValue,
                                                      valueExpr: 'value',
                                                      displayExpr: 'text',
                                                      disabled:
                                                          item.configuration ===
                                                          false,
                                                  }}
                                              />
                                          )
                                      }
                                  })
                                : null}
                            {/* </Item> */}
                        </Form>
                    </Editing>
                    {/* ) : null} */}
                    {columns}
                    <Toolbar>
                        <Item
                            key={'database-char-toolbar-group-panel'}
                            name='groupPanel'
                            location='before'
                        />
                        <Item
                            key={'database-char-toolbar-search-panel'}
                            name='searchPanel'
                            location='after'
                        />
                        <Item
                            key={'database-char-toolbar-button'}
                            location='after'
                        >
                            <Button
                                icon={'remove'}
                                width={'auto'}
                                text={dqTranslateMsg(
                                    'Page_Database_' + 'DeleteSelected'
                                )}
                                focusStateEnabled={false}
                                onClick={async () => {
                                    const dgInstance =
                                        charGridRef.current.instance
                                    const selectedRow =
                                        await dgInstance.getSelectedRowsData()

                                    let deleteMessage = `${dqTranslateMsg(
                                        'Page_Database_ConfirmMessage'
                                    )}\n\n[Delete Items]\n`
                                    deleteMessage += selectedRow
                                        .map(
                                            (row) =>
                                                `${row.c2001} / ${row.c2002}`
                                        )
                                        .join('\n')
                                    let result = confirm(deleteMessage)

                                    if (result) {
                                        if (selectedRow.length === 0) {
                                            alert(
                                                dqTranslateMsg(
                                                    'Page_Database_' +
                                                        'ErrorMsgEmptyPart'
                                                )
                                            )
                                            return
                                        }

                                        // check this would return promise

                                        const selectedCharInfo =
                                            selectedRow.map((target) =>
                                                chartRawData.charInfoAll.find(
                                                    (item) =>
                                                        item.part_id ===
                                                            target.part_id &&
                                                        item.char_id ===
                                                            target.char_id
                                                )
                                            )

                                        console.log(selectedCharInfo)

                                        if (selectedCharInfo.length > 0) {
                                            const deletePromises =
                                                selectedCharInfo.map((char) =>
                                                    dbCharApi.deleteChar(
                                                        user,
                                                        char.part_id,
                                                        char.char_id
                                                    )
                                                )

                                            await Promise.all(deletePromises)

                                            batch(() => {
                                                const removeIds =
                                                    selectedCharInfo.map(
                                                        (item) => ({
                                                            part_id:
                                                                item.part_id,
                                                            char_id:
                                                                item.char_id,
                                                        })
                                                    )
                                                const currentData =
                                                    dgInstance.option(
                                                        'dataSource'
                                                    )

                                                const removedData =
                                                    currentData.filter(
                                                        (item1) =>
                                                            !removeIds.some(
                                                                (item2) =>
                                                                    item2.part_id ===
                                                                        item1.part_id &&
                                                                    item2.char_id ===
                                                                        item1.char_id
                                                            )
                                                    )
                                                dgInstance.option(
                                                    'dataSource',
                                                    removedData
                                                )
                                                setDataSource(removedData)

                                                dispatchSetCharInfoAll(
                                                    removedData
                                                )
                                                console.log('removed')
                                            })
                                        }
                                    }
                                }}
                            />
                        </Item>
                        <Item location='after'>
                            <Button
                                icon={
                                    expandAll === true
                                        ? 'chevrondown'
                                        : 'chevronright'
                                }
                                onClick={() =>
                                    handleExpandAllClicked(
                                        expandAll === true ? false : true
                                    )
                                }
                            />
                        </Item>
                        <Item
                            key={'database-char-toolbar-column-chooser'}
                            name='columnChooserButton'
                            locateInMenu='never'
                            location='after'
                        />
                    </Toolbar>
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        width={300}
                        height={500}
                        title={dqTranslateMsg(
                            'Page_Database_' + 'CharColumnChooserTitle'
                        )}
                        emptyPanelText={dqTranslateMsg(
                            'Page_Database_' + 'ColumnChooserEmptyPanelText'
                        )}
                    >
                        <Position
                            my='center'
                            at='center'
                            of={document.getElementById('charDiv')}
                        />
                        <ColumnChooserSearch
                            enabled={true}
                            editorOptions={{
                                placeholder: dqTranslateMsg(
                                    'Page_Database_' + 'ColumnChooserPlaceHoler'
                                ),
                            }}
                        />

                        <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <SearchPanel
                        visible={true}
                        width={140}
                        placeholder={dqTranslateMsg(
                            'Page_Database_' + 'SearchPlaceHoler'
                        )}
                    />
                    <GroupPanel
                        visible={true}
                        emptyPanelText={dqTranslateMsg(
                            'Page_Database_' + 'EmptyPanelText'
                        )}
                    />
                </DataGrid>
            </div>
        </div>
    )
}
