import PageContainer from 'src/functions/PageContainer'
import ReportPrintMultiFunctionForm from './components/main-view/ReportPrintMultiFunctionForm'
import './report-print-multi-function.scss'

function ReportPrintMultiFunction(pageKey) {
    return (
        <PageContainer
            pageKey={pageKey}
            ChartFormComponent={ReportPrintMultiFunctionForm}
        />
    )
}

export default ReportPrintMultiFunction
