import React from 'react'
import Form, { GroupItem, SimpleItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import SelectBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItemNew'

const arrangementDefine = () => [
    { value: 'None', text: dqTranslateMsg('Page_Database_' + 'None') },
    {
        value: 'DateTime',
        text: dqTranslateMsg('Page_Database_' + 'DateTime'),
    },
    {
        value: 'PartIdent',
        text: dqTranslateMsg('Page_Database_' + 'PartIdent'),
    },
    { value: 'Order', text: dqTranslateMsg('Page_Database_' + 'Order') },
]

const sortDefine = () => [
    { value: 'None', text: dqTranslateMsg('Page_Database_' + 'None') },
    {
        value: 'Ascending',
        text: dqTranslateMsg('Page_Database_' + 'Ascending'),
    },
    {
        value: 'Descending',
        text: dqTranslateMsg('Page_Database_' + 'Descending'),
    },
]

const CommonOption = ({ currentFilterMode }) => {
    const dispatch = useDispatch()
    const pageKey = 'database'
    const pageState = useSelector((state) => state.common.pages.database)

    const onSelectBoxArrangementValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const next = produce(pageState, (draft) => {
            draft.filters[currentFilterMode].others[dataField] = value
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
    const onSelectBoxSortValueChanged = (e, dataField) => {
        const value = e.itemData.value
        const next = produce(pageState, (draft) => {
            draft.filters[currentFilterMode].common[dataField] = value
        })

        dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`common-option-group-item`}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'CommonOption')}
            >
                {/* Arrangement SelectBox */}
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <SelectBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg('SidePanel_' + 'Arrangement')}
                            labelLocation='top'
                            name='arrangement'
                            dataSource={arrangementDefine()}
                            valueExpr='value'
                            displayExpr='text'
                            value={
                                arrangementDefine().find(
                                    (item) =>
                                        item.value ===
                                        pageState.filters[currentFilterMode]
                                            .others.arrangement
                                )?.value
                            }
                            disabled={false}
                            onItemClick={(e) =>
                                onSelectBoxArrangementValueChanged(
                                    e,
                                    'arrangement'
                                )
                            }
                        />
                    )}
                />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <SelectBoxSimpleItemNew
                            colSpan={1}
                            label={dqTranslateMsg(
                                'SidePanel_' + 'DateTimeSort'
                            )}
                            labelLocation='top'
                            name='dateTimeSort'
                            dataSource={sortDefine()}
                            valueExpr='value'
                            displayExpr='text'
                            value={
                                sortDefine().find(
                                    (item) =>
                                        item.value ===
                                        pageState.filters[currentFilterMode]
                                            .common.dateTimeSort
                                )?.value
                            }
                            disabled={false}
                            onItemClick={(e) =>
                                onSelectBoxSortValueChanged(e, 'dateTimeSort')
                            }
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}

export default CommonOption
