// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-form .submit-button {
  margin-top: 10px;
}
.reset-password-form .login-link {
  color: #cddc39;
  font-size: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/reset-password-form/ResetPasswordForm.scss","webpack://./src/themes/generated/variables.base.scss"],"names":[],"mappings":"AAGE;EACE,gBAAA;AAFJ;AAKE;EACE,cCRU;EDSV,eAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import \"../../themes/generated/variables.base.scss\";\r\n\r\n.reset-password-form {\r\n  .submit-button {\r\n    margin-top: 10px;\r\n  }\r\n\r\n  .login-link {\r\n    color: $base-accent;\r\n    font-size: 16px;\r\n    text-align: center;\r\n  }\r\n}\r\n","$base-accent: #cddc39;\r\n$base-text-color: #ffffff;\r\n$base-bg: #363640;\r\n$base-border-color: #515159;\r\n$base-border-radius: 2px;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
