import React, { useState } from 'react'
import { Label, SimpleItem } from 'devextreme-react/form'
import { List, Sortable } from 'devextreme-react'
import { Popup } from 'devextreme-react'
import { Position } from 'devextreme-react/popup'
import { useEffect } from 'react'
const PopupSimpleItem = ({
    visible,
    onHiding,
    title,
    width,
    height,
    contentRender,
    children,
}) => {
    return (
        <Popup
            visible={visible}
            onHiding={onHiding}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
            showTitle={true}
            title={title}
            container='.dx-viewport'
            // contentRender={contentRender} // contentRender를 함수로 직접 전달
            width={width}
            height={height}
        >
            <Position
                my='center'
                at='center'
                of={window}
            />
            {children}
        </Popup>
    )
}

export default PopupSimpleItem
