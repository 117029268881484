import { Font, PDFViewer } from '@react-pdf/renderer'
import ReactECharts from 'echarts-for-react'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { useSelector } from 'react-redux'

import produce from 'immer'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getSummary } from 'src/api/summary-list'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useTheme } from 'src/contexts/theme'
import CCUtils from 'src/functions/CommonCalcUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { makeChartOption } from 'src/functions/HistogramChartUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as VCUtils from 'src/functions/ValueChartUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'
import { getBoxPlotChartOption } from 'src/pages/chart-box-plot/components/main-view/chart-option/BoxPlotChartOption'
import * as bpImpl from 'src/pages/chart-box-plot/components/main-view/chart-option/BoxPlotChartOptionImpl'
import { makeCapabilityDataset } from 'src/pages/chart-capability-summary/components/main-view/CapabilitySummaryChartForm'
import * as csImpl from 'src/pages/chart-capability-summary/components/main-view/chart-option/CapabilitySummaryChartOption'
import * as csImpl2 from 'src/pages/chart-capability-summary/components/main-view/chart-option/CapabilitySummaryChartOptionImpl'
import {
    distributionName,
    makeHistogramOption,
    makeSettings,
    makeUpdateOption,
} from 'src/pages/chart-histogram/components/main-view/HistogramChartForm'
import * as histImpl from 'src/pages/chart-histogram/components/main-view/chart-option/HistogramChartOptionImpl'
import { makeBoxPlotChartOption } from 'src/pages/chart-pareto/components/main-view/ParetoChartForm'
import * as pco from 'src/pages/chart-pareto/components/main-view/chart-option/ParetoChartOption'
import * as probImpl from 'src/pages/chart-probability-plot/components/main-view//chart-option/ProbabilityPlotChartOptionImpl'
import {
    makeProbSettings,
    makeProbabilityPlotOption,
} from 'src/pages/chart-probability-plot/components/main-view/ProbabilityPlotChartForm'
import { makeProbChartOption } from 'src/pages/chart-probability-plot/components/main-view/chart-option/ProbabilityPlotChartOption'
import { getMergedChartOptions } from 'src/pages/chart-quality-control/components/main-view/chart-option/QualityControlMergedChartOption'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import {
    makeOutOfTolerance,
    makeResult,
} from 'src/pages/list-summary/components/main-view/SummaryListFormImpl'
import { useLanguage } from 'src/contexts/languages'
import PdfContent from './ReportPDFContent'
import { Sparkline } from 'devextreme-react'
import { filterElementFieldList } from '../../side-panel/components/define/DefineTagListIndividual'
import { contentFields, getReportFieldValue } from './ReportFieldComponent'

Font.register({
    family: 'MPLUS1p-Regular',
    src: '/fonts/MPLUS1p-Regular.ttf',
})
Font.register({
    family: 'ArialUnicode',
    src: '/fonts/ARIALUNI.ttf',
})
Font.register({
    family: 'D2Coding',
    src: '/fonts/D2Coding.ttf',
})

Font.registerHyphenationCallback((word) => {
    return [word]
})

// Get List Data
const getListData = async (type, probPage, configPage, valueArray) => {
    const value = valueArray
    const targetPart = value.partRaw
    const targetChar = value.charRaw
    const targetValue = value.valueRaw

    switch (type) {
        case 'summary-list-collective':
        case 'summary-list':
        case 'brief-info':
            const {
                lsl,
                usl,
                nominal,
                lwl,
                uwl,
                lpl,
                upl,
                lrl,
                url,
                lacl,
                uacl,
                gap,
                warnOffset,
            } = PCUtils.getAllLimits(targetChar, configPage)
            const limits = {
                lsl,
                usl,
                nominal,
                lwl,
                uwl,
                lpl,
                upl,
                lrl,
                url,
                lacl,
                uacl,
                gap,
                warnOffset,
            }
            const filteredValue = outlierFilterValues(
                limits,
                targetChar,
                targetValue,
                configPage
            )

            const result = await getSummary(
                targetChar,
                filteredValue,
                probPage,
                configPage
            )
            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
                return null
            } else {
                return {
                    data: result.data,
                    lsl,
                    usl,
                    part: targetPart,
                    char: targetChar,
                    median: PCUtils.getMedian(filteredValue),
                    limits,
                }
            }
        default:
            console.error('Not Valid List Type')
            return null
    }
}

// Chart generator
const generateChartOptions = async (
    type,
    valueArray,
    curPage,
    valuePage,
    histPage,
    boxPlotPage,
    capabilityPage,
    paretoPage,
    probPage,
    qccPage,
    configPage,
    othersPage,
    pageKey
) => {
    const value = valueArray
    const targetChar = value.charRaw
    const targetValue = value.valueRaw

    switch (type) {
        case 'value-chart':
            const pcSet = VCUtils.makePCData(
                configPage,
                targetChar,
                targetValue
            )
            if (pcSet === null) {
                return null
            }
            const chartSet = VCUtils.makeChartData(
                valuePage,
                configPage,
                pcSet,
                targetChar,
                pcSet.valuesReference
            )
            if (chartSet === null) {
                return null
            }

            let valueChartOption = VCUtils.makeChartOption(
                true,
                valuePage,
                configPage,
                othersPage,
                pcSet,
                chartSet,
                targetChar,
                pcSet.valuesReference,
                'light'
            )
            if (valueChartOption === null) {
                return null
            }

            return valueChartOption

        case 'histogram-chart':
            const getHistogramChartOption = async () => {
                let fdData = configPage.findDistribution
                // Fetch all chart options concurrently

                if (targetValue.length < 5) {
                    return null
                }

                const { settings } = makeSettings(
                    histPage,
                    configPage,
                    targetChar,
                    targetValue
                )

                const responseDataTemp = await makeHistogramOption(
                    settings,
                    configPage,
                    targetChar,
                    settings.valuesReference,
                    fdData
                )
                const responseData = responseDataTemp.data

                const settingsValue = { ...settings, ...histPage }
                const configSettings = configPage

                const options = makeChartOption(
                    true,
                    histPage,
                    configPage,
                    targetChar,
                    targetValue
                )
                const optionBaseData = makeUpdateOption(
                    responseData,
                    settingsValue
                )
                const bellCurveData = optionBaseData.curve_x_values.map(
                    (x, index) => [x, optionBaseData.curve_y_values[index]]
                )

                const next = produce(options, (draft) => {
                    draft.animationDuration = 0
                    draft.series = []
                    draft.series.push({
                        type: 'bar',
                        name: 'count',
                        xAxisIndex: 0,
                        yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                        // data: optionBaseData.bin_density_list,
                        data: optionBaseData.bin_count_list,
                        itemStyle: {
                            color: settingsValue.barChart.histogramBar.color,
                        },
                        barWidth: '99%',
                    })
                    draft.series.push({
                        type: 'line',
                        name: 'curve',
                        xAxisIndex: 1,
                        yAxisIndex: 1, // Ensure it's linked to the second (right) Y-axis
                        data: bellCurveData,
                        // data: optionBaseData.curve_y_values,
                        symbol: 'none',
                        itemStyle: {
                            color: settingsValue.barChart.bellCurve.color,
                        },
                        lineStyle: {
                            type: settingsValue.lineChart.chartLines.type,
                            width: settingsValue.lineChart.chartLines.width,
                        },
                    })
                    draft.series.push({
                        type: 'line',
                        name: 'Lines',
                        xAxisIndex: 1,
                        markLine: {
                            animation: false,
                            symbol: 'none',
                            precision: 10,
                            data: histImpl.convertToMarkLine(
                                responseData.dist,
                                settingsValue,
                                configSettings,
                                optionBaseData
                            ),
                            emphasis: {
                                disabled: true,
                            },
                        },
                    })

                    draft.xAxis[0].data = optionBaseData.bin_width_center_list
                    draft.xAxis[0].axisLabel.formatter = (value, index) =>
                        histImpl.makeXAxisLabelFormat(value, configSettings)

                    draft.xAxis[1].min = optionBaseData.bin_width_center_list[0]
                    draft.xAxis[1].max =
                        optionBaseData.bin_width_center_list[
                            optionBaseData.bin_width_center_list.length - 1
                        ]

                    draft.yAxis[0].name = 'count'
                    draft.yAxis[0].position = 'left'

                    draft.yAxis[1].name = 'curve'
                    draft.yAxis[1].position = 'right'
                })
                console.log('next : ')
                console.log(next)
                return next
            }

            const option = await getHistogramChartOption()
            return option
        case 'pareto-chart':
            const { displayData, filteredValue } = makeBoxPlotChartOption(
                targetChar,
                value,
                paretoPage,
                configPage,
                othersPage
            )
            const paretoOption = pco.getParetoChartOption(
                true,
                paretoPage,
                configPage,
                othersPage,
                targetChar,
                displayData,
                filteredValue,
                false
            )
            return paretoOption
        case 'probability-plot-chart':
            const getProbabilityChartOption = async () => {
                if (targetValue.length < 5) {
                    return null
                }
                const { settings } = makeProbSettings(
                    probPage,
                    configPage,
                    targetChar,
                    targetValue
                )

                const responseDataTemp = await makeProbabilityPlotOption(
                    settings,
                    configPage,
                    targetChar,
                    settings.valuesReference
                )

                const responseData = responseDataTemp.data
                const settingsValue = { ...settings, ...curPage }
                const configSettings = configPage

                const valueIntegerLineX = CCUtils.convertExponentialArrayToInt(
                    responseData.line_x_values
                )
                const valueIntegerLineY = CCUtils.convertExponentialArrayToInt(
                    responseData.line_y_values
                )
                const valueIntegerPointX = CCUtils.convertExponentialArrayToInt(
                    responseData.point_x_values
                )
                const valueIntegerPointY = CCUtils.convertExponentialArrayToInt(
                    responseData.point_y_values
                )

                const valueIntegerX0_135 = CCUtils.convertExponentialValueToInt(
                    responseData.x0_135
                )
                const valueIntegerX99_869 =
                    CCUtils.convertExponentialValueToInt(responseData.x99_865)

                const lineMin = Math.min(...valueIntegerLineX)
                const lineMax = Math.max(...valueIntegerLineX)
                const pointMin = Math.min(...valueIntegerPointX)
                const pointMax = Math.max(...valueIntegerPointX)
                const dataMin = lineMin < pointMin ? lineMin : pointMin
                const dataMax = pointMax < lineMax ? pointMax : lineMax

                const minValues = [dataMin, settingsValue.lsl].filter(
                    (value) => value !== null
                )
                const maxValues = [dataMax, settingsValue.usl].filter(
                    (value) => value !== null
                )

                const min = Math.min(...minValues, valueIntegerX0_135)
                const max = Math.max(...maxValues, valueIntegerX99_869)
                const xAxisLeft = min - (max - min) * 0.1
                const xAxisRight = max + (max - min) * 0.1
                const yAxisBottom = dataMin - (dataMax - dataMin) * 0.1
                const yAxisTop = dataMax + (dataMax - dataMin) * 0.1

                // const probOption = chartRef.current.getEchartsInstance().getOption()
                const probOption = makeProbChartOption(
                    pageKey,
                    probPage,
                    configSettings,
                    targetChar,
                    targetValue,
                    false
                )

                const next = produce(probOption, (draft) => {
                    draft.animationDuration = 0
                    draft.xAxis[0].axisLabel.fontSize = 10
                    draft.yAxis.axisLabel.fontSize = 10
                    draft.series = []
                    draft.series.push({
                        type: 'line',
                        name: 'line',
                        symbol: 'none',
                        // data: responseData.line_x_values.map((x, index) => [x, responseData.line_y_values[index]]),
                        data: valueIntegerLineX.map((x, index) => [
                            x,
                            valueIntegerLineY[index],
                        ]),
                        animation: false,
                        lineStyle: {
                            type: settingsValue.lineChart.chartLines.type,
                            width: settingsValue.lineChart.chartLines.width,
                            color: settingsValue.lineChart.chartLines.color,
                        },
                    })
                    draft.series.push({
                        type: 'scatter',
                        name: dqTranslateMsg(
                            'Page_ProbabilityPlotChart_' + 'ValueInfo'
                        ),
                        xAxisIndex: 0,
                        yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                        // data: responseData.point_x_values.map((x, index) => [x, CCUtils.convertExponentialValueToInt(responseData.point_y_values[index])]),
                        data: valueIntegerPointX.map((x, index) => [
                            x,
                            valueIntegerPointY[index],
                        ]),
                        symbol: (value, params) =>
                            probImpl.makeSymbol(value, params, settingsValue),
                        symbolSize: (value, params) =>
                            probImpl.makeSymbolSize(
                                value,
                                params,
                                settingsValue
                            ),
                        animation: false,
                        itemStyle: {
                            color: (params) =>
                                probImpl.makeSymbolColor(params, settingsValue),
                        },
                    })
                    draft.series.push({
                        type: 'line',
                        name: dqTranslateMsg(
                            'Page_ProbabilityPlotChart_' + 'Limits'
                        ),
                        animation: false,
                        yAxisIndex: 0,
                        markLine: {
                            animation: false,
                            symbol: 'none',
                            precision: 10,
                            // precision: settingsValue.lineChart.markLines.precision,
                            data: probImpl.convertToMarkLine(
                                responseData,
                                settingsValue,
                                configSettings
                            ),
                            emphasis: {
                                disabled: true,
                            },
                        },
                    })

                    draft.title.text = `${targetChar.c2002 ?? 'empty'}P:${
                        targetChar.part_id ?? '?'
                    }/C:${targetChar.char_id ?? '?'}`
                    draft.title.text +=
                        ' : ' + distributionName(responseData.dist)
                    draft.title.subtext =
                        'R² = ' +
                        DNUtils.calcValueDisplay(
                            responseData.r2,
                            configSettings
                        )

                    draft.xAxis[0].min = xAxisLeft
                    draft.xAxis[0].max = xAxisRight
                    draft.xAxis[0].axisLabel.formatter = function (value) {
                        const axisMax = xAxisRight
                        const axisMin = xAxisLeft

                        // 최대값과 최소값은 레이블을 표시하지 않음
                        if (value === axisMin || value === axisMax) {
                            return ''
                        }

                        return value // 그 외의 값은 그대로 표시
                    }

                    draft.yAxis.min =
                        settings.page.yAxisType === 'Theoretical-Value'
                            ? yAxisBottom
                            : 0
                    draft.yAxis.max =
                        settings.page.yAxisType === 'Theoretical-Value'
                            ? yAxisTop
                            : 100
                    draft.yAxis.axisLabel.formatter = function (value) {
                        if (settings.page.yAxisType === 'Theoretical-Value') {
                            const axisMax = yAxisTop
                            const axisMin = yAxisBottom

                            if (value === axisMin || value === axisMax) {
                                return ''
                            }

                            return value
                        } else {
                            return value
                        }
                    }
                })

                return next
            }

            const probOption = await getProbabilityChartOption()
            return probOption

        case 'capability-summary-chart':
            const displayType = capabilityPage.page.displayType
            const filteredValueCapa = makeCapabilityDataset(
                targetChar,
                targetValue,
                configPage
            )

            if (filteredValueCapa.length < 2) {
                const tagCpCpkArray = {
                    charName: targetChar.c2002,
                    cp: null,
                    cpk: null,
                    part_id: value.part_id,
                    char_id: value.char_id,
                }
                tableDataArray.push(tableRow)
                return
            }

            const result = await getSummary(
                targetChar,
                filteredValueCapa,
                capabilityPage,
                configPage
            )

            if (!ApiStatus.isSuccess(result.status)) {
                alert(getErrCommMsg(result.status))
                return
            }

            const cpCpkArray = csImpl2.makeCapabilityIndexDataOne(
                targetChar,
                value,
                result.data
            )
            const charNameArray = cpCpkArray.charName

            const cpArray =
                cpCpkArray.cp !== null && cpCpkArray.cp !== undefined
                    ? cpCpkArray.cp
                    : null

            const cpkArray =
                cpCpkArray.cpk !== null && cpCpkArray.cpk !== undefined
                    ? cpCpkArray.cpk
                    : null

            const seriesArray = csImpl2.makeSeriesData(
                capabilityPage,
                displayType,
                [cpArray],
                [cpkArray]
            )
            const capabilityOption = csImpl.getCapabilitySummaryChartOption2(
                capabilityPage,
                configPage,
                [targetChar],
                [value],
                [charNameArray],
                seriesArray,
                false
            )
            return capabilityOption
        case 'qcc-chart':
            const qccChartOption = getMergedChartOptions(
                true,
                qccPage,
                configPage,
                targetChar,
                targetValue,
                false
            )
            return qccChartOption

        case 'boxplot-chart':
            const { names, decimalPlacesArray, boxPlotData, boxPlotRefData } =
                bpImpl.makeBoxPlotDataSet(
                    boxPlotPage,
                    [targetChar],
                    [value],
                    configPage
                )
            const chartOptions = getBoxPlotChartOption(
                boxPlotPage,
                configPage,
                [targetChar],
                [value],
                names,
                decimalPlacesArray,
                boxPlotData,
                boxPlotRefData,
                false
            )

            return chartOptions
        default:
            return {}
    }
}

const generateChartImage = async (chartOptions) => {
    return new Promise((resolve) => {
        const chartRef = React.createRef()

        // Chart component to render
        const chart = (
            <ReactECharts
                key={`chart-${Math.random()}`}
                ref={chartRef}
                option={chartOptions}
                style={{ width: 600, height: 400 }}
                notMerge={false}
                theme={'light'}
                onChartReady={(chartInstance) => {
                    const dataURL = chartInstance.getConnectedDataURL({
                        type: 'png',
                        pixelRatio: 2,
                        backgroundColor: '#fff',
                        // useDirtyRect: false,
                    })
                    resolve(dataURL)
                }}
                opts={{ renderer: 'canvas' }}
            />
        )

        // Render chart into a virtual DOM (not shown in UI)
        const container = document.createElement('div')

        // Use React 18's createRoot API
        const root = ReactDOM.createRoot(container)
        root.render(chart)
    })
}

// Main component
const ReportPrintPage = ({ pageKey }) => {
    const { getTheme } = useTheme()
    const theme = getTheme()
    const { language, switchLanguage } = useLanguage()
    const common = useSelector((state) => state.common)
    const chartRawData = useSelector((state) => state.chartRawData)
    const curPage = common.pages[pageKey]
    const valuePage = common.pages.valueChart
    const histPage = common.pages.histogramChart
    const boxPlotPage = common.pages.boxPlotChart
    const paretoPage = common.pages.paretoChart
    const capabilityPage = common.pages.capabilitySummaryChart
    const probPage = common.pages.probabilityPlotChart
    const qccPage = common.pages.qualityControlChart
    const slPage = common.pages.summaryList

    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentSel = common.current_sel
    const selectedChar = common.loaded_all[pageKey]

    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(
        charArray,
        values
    )
    const valuesArranged = CPUtils.removeInvalidInValues(
        selectedValueWithDummy,
        curPage.page.showDeletedValue
    )

    const [isLoadingPanel, setIsLoadingPanel] = useState(false)
    const [logoImageSrc, setLogoImageSource] = useState(null)

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selTemplateNo, setSelTemplateNo] = useState(
        curPage.page.selectedTemplateNo
    )
    // const selTemplateNo = curPage.page.selectedTemplateNo
    const [chartImages, setChartImages] = useState({
        type: null,
        content: [],
    })
    const [totalPageIndex, setTotalPageIndex] = useState(0)

    useEffect(() => {
        if (othersPage.reportTemplates.length > 0) {
            setSelectedTemplate(
                othersPage.reportTemplates[curPage.page.selectedTemplateNo]
            )
            setSelTemplateNo(curPage.page.selectedTemplateNo)
            setChartImages({
                type: null,
                content: [],
            })
        } else {
            setSelectedTemplate(null)
            setSelTemplateNo(0)
            setChartImages({
                type: null,
                content: [],
            })
        }
    }, [curPage.page.selectedTemplateNo, othersPage.reportTemplates])

    useEffect(() => {
        const preparePdfData = async () => {
            setIsLoadingPanel(true)
            const images = {
                type: null,
                content: [],
            }
            /**
             * collective
             * type: 'collective'
             * content : [
             * 	{part_id : null, char_id: null, type : 'title-desc', value : imgData}
             *  {part_id : null, char_id: null, type : 'summary-list-collective', value : data}
             * ]
             * individual
             * type : 'individual'
             * content : [
             * 	{part_id : 25, char_id: 1, type : 'title-desc', value : string}
             *  {part_id : 25, char_id: 2, type : 'value-chart', value : chartImg png}
             *  {part_id : 26, char_id: 1, type : 'histogram-chart', value : chartImg png}
             * ]
             *
             */
            let pageIndex = 0

            const processContent = async (
                content,
                valueArray,
                part_id,
                char_id,
                pageIndex
            ) => {
                images.type = 'individual'
                const c_content = content.content
                const c_value = content.value
                const options = {
                    c_content,
                    c_value,
                    images,
                    valueArray,
                    part_id,
                    char_id,
                    pageIndex,
                }

                if (c_value.includes('chart')) {
                    return handleChartContent(options)
                } else if (
                    c_value.includes('list') ||
                    c_value.includes('info')
                ) {
                    return handleListOrInfoContent(options)
                } else if (c_value.includes('title-desc')) {
                    return handleTitleDescContent(options)
                } else if (filterElementFieldList().includes(c_value)) {
                    return handleFieldContent(options)
                } else {
                    console.error('Not Valid Element')
                }
            }

            const processCollectiveContent = async (
                content,
                valuesArranged,
                valuesArrangedLength,
                pageIndex
            ) => {
                images.type = 'collective'
                const c_content = content.content
                const c_value = content.value
                const options = {
                    c_content,
                    c_value,
                    valuesArranged,
                    images,
                }

                if (c_value.includes('list')) {
                    return handleListCollectiveContent(options)
                } else if (c_value.includes('title-desc')) {
                    return handleTitleDescCollectiveContent(options)
                } else if (filterElementFieldList().includes(c_value)) {
                    return handleFieldContent(options)
                } else {
                    console.error('Not Valid Element')
                }
            }

            const handleFieldContent = ({
                c_content,
                c_value,
                images,
                valueArray,
                part_id,
                char_id,
                pageIndex,
            }) => {
                const { partRaw, charRaw } = valueArray
                const contentData = {
                    part_id,
                    char_id,
                    type: c_value,
                    value: getReportFieldValue(c_value, partRaw, charRaw),
                }

                const tgIndex = images.content.findIndex(
                    (el) =>
                        el.part_id === part_id &&
                        el.char_id === char_id &&
                        el.type === c_value
                )
                if (tgIndex === -1) {
                    images.content.push(contentData)
                } else {
                    images.content[tgIndex] = contentData
                }
            }

            const handleChartContent = async ({
                c_content,
                c_value,
                images,
                valueArray,
                part_id,
                char_id,
                pageIndex,
            }) => {
                const options = await generateChartOptions(
                    c_value,
                    valueArray,
                    curPage,
                    valuePage,
                    histPage,
                    boxPlotPage,
                    capabilityPage,
                    paretoPage,
                    probPage,
                    qccPage,
                    configPage,
                    othersPage,
                    pageKey
                )
                const chartImage = await generateChartImage(options)

                const contentData = {
                    part_id,
                    char_id,
                    type: c_value,
                    value: chartImage,
                }

                const tgIndex = images.content.findIndex(
                    (el) =>
                        el.part_id === part_id &&
                        el.char_id === char_id &&
                        el.type === c_value
                )
                if (tgIndex === -1) {
                    images.content.push(contentData)
                } else {
                    images.content[tgIndex] = contentData
                }
            }

            const handleListOrInfoContent = async ({
                c_content,
                c_value,
                images,
                valueArray,
                part_id,
                char_id,
                pageIndex,
            }) => {
                for (const valueArray of valuesArranged) {
                    const { data, lsl, usl, part, char, limits } =
                        await getListData(
                            c_value,
                            slPage,
                            configPage,
                            valueArray
                        )

                    // const chartImage = await renderTrendChart(valueArray.valueRaw)
                    const options = await generateChartOptions(
                        'value-chart',
                        valueArray,
                        curPage,
                        valuePage,
                        histPage,
                        boxPlotPage,
                        capabilityPage,
                        paretoPage,
                        probPage,
                        qccPage,
                        configPage,
                        othersPage,
                        pageKey
                    )
                    const chartImage = await generateChartImage(options)

                    const contentData = {
                        part_id,
                        char_id,
                        type: c_value,
                        value: {
                            ...data,
                            ...part,
                            ...char,
                            ...limits,
                            result: makeResult(data.cp, data.cpk, slPage),
                            outOfTol: makeOutOfTolerance(
                                valueArray.valueRaw.filter(
                                    (data) => data.v0002 === 0
                                ),
                                lsl,
                                usl
                            ),
                            trend: chartImage,
                        },
                    }

                    const tgIndex = images.content.findIndex(
                        (el) =>
                            el.part_id === part_id &&
                            el.char_id === char_id &&
                            el.type === c_value
                    )
                    if (tgIndex === -1) {
                        images.content.push(contentData)
                    } else {
                        images.content[tgIndex] = contentData
                    }
                }
            }

            const handleListCollectiveContent = async ({
                c_content,
                c_value,
                valuesArranged,
                images,
            }) => {
                for (const valueArray of valuesArranged) {
                    const part_id = valueArray.part_id
                    const char_id = valueArray.char_id

                    const { data, lsl, usl, part, char, limits } =
                        await getListData(
                            c_value,
                            slPage,
                            configPage,
                            valueArray
                        )

                    // const chartImage = await renderTrendChart(valueArray.valueRaw)
                    const options = await generateChartOptions(
                        'value-chart',
                        valueArray,
                        curPage,
                        valuePage,
                        histPage,
                        boxPlotPage,
                        capabilityPage,
                        paretoPage,
                        probPage,
                        qccPage,
                        configPage,
                        othersPage,
                        pageKey
                    )
                    const chartImage = await generateChartImage(options)

                    const contentData = {
                        part_id,
                        char_id,
                        type: c_value,
                        value: {
                            ...data,
                            ...part,
                            ...char,
                            ...limits,
                            result: makeResult(data.cp, data.cpk, slPage),
                            outOfTol: makeOutOfTolerance(
                                valueArray.valueRaw.filter(
                                    (data) => data.v0002 === 0
                                ),
                                lsl,
                                usl
                            ),
                            trend: chartImage,
                        },
                    }

                    const tgIndex = images.content.findIndex(
                        (el) =>
                            el.part_id === part_id &&
                            el.char_id === char_id &&
                            el.type === c_value
                    )
                    if (tgIndex === -1) {
                        images.content.push(contentData)
                    } else {
                        images.content[tgIndex] = contentData
                    }
                }
            }

            const handleTitleDescContent = ({
                c_content,
                c_value,
                images,
                valueArray,
                part_id,
                char_id,
                pageIndex,
            }) => {
                const formatDate = (date) => {
                    const options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }

                    let countryCode = ''
                    switch (language) {
                        case 'en':
                            countryCode = 'en-US'
                            break
                        case 'ja':
                            countryCode = 'ja-JP' // 일본어에 맞는 지역 코드 추가
                            break
                        case 'kr':
                            countryCode = 'ko-KR' // 한국어에 맞는 지역 코드 추가
                            break
                        default:
                            countryCode = 'en-US'
                            break
                    }

                    const formatted = new Intl.DateTimeFormat(
                        countryCode,
                        options
                    ).format(date)

                    return formatted
                }

                const contentData = {
                    part_id,
                    char_id,
                    type: c_value,
                    value: {
                        logo: othersPage.reportLogo,
                        title: c_content,
                        date: formatDate(new Date()),
                        pageIndex: pageIndex,
                    },
                }

                const tgIndex = images.content.findIndex(
                    (item) =>
                        item.part_id === part_id &&
                        item.char_id === char_id &&
                        item.type === c_value
                )

                if (tgIndex === -1) {
                    images.content.push(contentData)
                } else {
                    images.content[tgIndex] = contentData
                }
            }
            const handleTitleDescCollectiveContent = ({
                c_content,
                c_value,
                valuesArranged,
                images,
            }) => {
                const formatDate = (date) => {
                    const options = {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    }

                    let countryCode = ''
                    switch (language) {
                        case 'en':
                            countryCode = 'en-US'
                            break
                        case 'ja':
                            countryCode = 'ja-JP' // 일본어에 맞는 지역 코드 추가
                            break
                        case 'kr':
                            countryCode = 'ko-KR' // 한국어에 맞는 지역 코드 추가
                            break
                        default:
                            countryCode = 'en-US'
                            break
                    }

                    const formatted = new Intl.DateTimeFormat(
                        countryCode,
                        options
                    ).format(date)

                    return formatted
                }

                const contentData = {
                    type: c_value,
                    value: {
                        logo: othersPage.reportLogo,
                        title: c_content,
                        date: formatDate(new Date()),
                        pageIndex: pageIndex,
                    },
                }

                const tgIndex = images.content.findIndex(
                    (el) => el.type === c_value
                )
                if (tgIndex === -1) {
                    images.content.push(contentData)
                } else {
                    images.content[tgIndex] = contentData
                }
            }

            if (selectedTemplate.type === 'individual') {
                for (const valueArray of valuesArranged) {
                    for (const element of selectedTemplate.elements) {
                        for (const content of element.columnContent) {
                            const part_id = valueArray.part_id
                            const char_id = valueArray.char_id
                            await processContent(
                                content,
                                valueArray,
                                part_id,
                                char_id,
                                pageIndex + 1
                            )
                        }
                    }
                    pageIndex++
                }
            } else {
                for (const element of selectedTemplate.elements) {
                    for (const content of element.columnContent) {
                        await processCollectiveContent(
                            content,
                            valuesArranged,
                            valuesArranged.length,
                            pageIndex + 1
                        )
                    }
                }
            }

            setTotalPageIndex(pageIndex)
            setChartImages(images)

            if (othersPage.reportTemplates.length > 0) {
                setSelectedTemplate(
                    othersPage.reportTemplates[curPage.page.selectedTemplateNo]
                )
            } else {
                console.log('template is not exist')
            }

            setIsLoadingPanel(false)
        }

        if (selectedTemplate) {
            preparePdfData()
        }
    }, [
        selectedTemplate,
        configPage,
        // curPage.page.selectedTemplateNo,
        othersPage.reportTemplates,
        othersPage.reportLogo,
        selectedChar,
    ])

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Common_' + 'LoadPanelLoading')}
            />

            {selectedTemplate && chartImages && (
                <PDFViewer
                    width='100%'
                    height='100%'
                >
                    <PdfContent
                        valuesArranged={valuesArranged}
                        // template={templateData}
                        template={selectedTemplate}
                        chartImages={chartImages}
                        totalPageIndex={totalPageIndex}
                        slPage={slPage}
                        configPage={configPage}
                        othersPage={othersPage}
                        language={language}
                        theme={theme}
                    />
                </PDFViewer>
            )}
        </div>
    )
}

export default ReportPrintPage
