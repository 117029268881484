import Form, {
    ButtonItem,
    ButtonOptions,
    Item,
    Label,
    RequiredRule,
} from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import { useCallback, useEffect, useRef, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as dtoUtils from 'src/dto/DTOImpl'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import * as rd from 'src/store/common'
import { useAuth } from '../../contexts/auth'
import './LoginForm.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { loginTable } from 'src/functions/table/LoginMessageTable'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { getAdminCompany } from 'src/api/admin/AdminCompanyApi'
import {
    adminSettingsPrm,
    defaultDBConfig,
    defaultLanguage,
    defaultPermissions,
    defaultRibbon,
} from 'src/pages/admin/components/settings/AdminSettings'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { useApi } from 'src/contexts/api'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import {
    configurationSettingsPrm,
    defaultCatalogs,
    defaultEncoding,
    defaultUseFieldList,
} from 'src/pages/config/components/settings/ConfigSettings'
import { getAdminGroup } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { groupTbDescription } from 'src/functions/constants/groupDBFieldDescription'
import { convertUserRoleToString } from 'src/functions/constants/authLevelConstants'
import { saveUserToLS } from 'src/pages/components/dq-settings/dq-local-storage/DQLocalStorageImpl'

//2024-12-06 로고 이미지 추가 HODO
import white_logo_img from 'src/assets/indeqs-white-theme.svg' //
import black_logo_img from 'src/assets/indeqs-dark-theme.svg' //
import { reportPrintMultiFunctionSettingsPrm } from 'src/pages/report-print-multi-function/components/settings/ReportPrintMultiFunctionSettings'

export default function LoginForm() {
    const { spring } = useApi()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setTheme, getTheme, getCurrentTheme } = useTheme()
    const { signIn, setUser } = useAuth()
    const { language, switchLanguage, updateLanguage } = useLanguage()
    const [loading, setLoading] = useState(false)
    const formData = useRef({ id: '', password: '' })
    const formRef = useRef(null)

    //2024-12-06 로고 이미지 추가 HODO
    const [logoImg, setlogoImg] = useState('')

    // 테마에 따라 로고 설정
    useEffect(() => {
        const currentTheme = getTheme()
        const ccc = getCurrentTheme()
        const isDarkTheme = ccc?.includes('dark')
        const logoPath = isDarkTheme ? black_logo_img : white_logo_img
        setlogoImg(logoPath)
    }, [getTheme])

    const emailEditorOptions = {
        stylingMode: 'filled',
        placeholder: getCommonMsg(msgType.Login, loginTable.M0002),
        mode: 'id',
        showClearButton: true,
    }
    const passwordEditorOptions = {
        stylingMode: 'text',
        placeholder: getCommonMsg(msgType.Login, loginTable.M0003),
        mode: 'password',
    }
    const rememberMeEditorOptions = {
        text: 'Remember me',
        elementAttr: { class: 'form-text' },
    }

    const onSubmit = useCallback(
        async (e) => {
            e.preventDefault()

            const { id, password } = formData.current
            setLoading(true)
            const userInfo = await signIn(id, password)
            if (!userInfo) {
                setLoading(false)
                return
            }

            // const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)
            const uiDTO = userInfo
            // JSON 파싱을 위한 함수
            const parseJSONOrDefault = (data, defaultVal) =>
                data ? JSON.parse(data) : defaultVal

            // 기본 설정값
            // const licenseSet = parseJSONOrDefault(uiDTO.license, [])
            // const companySet = parseJSONOrDefault(uiDTO.user_info, { company: null })
            // const configSet = parseJSONOrDefault(uiDTO.web_config, baseInit)
            const overviewSet = parseJSONOrDefault(
                uiDTO.chart_config_1,
                ps.overview.settings
            )
            const monitoringSet = parseJSONOrDefault(
                uiDTO.chart_config_2,
                ps.monitoring.settings
            )
            const databaseSet = parseJSONOrDefault(
                uiDTO.chart_config_3,
                ps.database.settings
            )
            const valueChartSet = parseJSONOrDefault(
                uiDTO.chart_config_4,
                ps.valueChart.settings
            )
            const histogramChartSet = parseJSONOrDefault(
                uiDTO.chart_config_5,
                ps.histogramChart.settings
            )
            const positionalTolerancesChartSet = parseJSONOrDefault(
                uiDTO.chart_config_6,
                ps.positionalTolerancesChart.settings
            )
            const boxPlotChartSet = parseJSONOrDefault(
                uiDTO.chart_config_7,
                ps.boxPlotChart.settings
            )
            const linearRegressionChartSet = parseJSONOrDefault(
                uiDTO.chart_config_8,
                ps.linearRegressionChart.settings
            )
            const correlationChartSet = parseJSONOrDefault(
                uiDTO.chart_config_9,
                ps.correlationChart.settings
            )
            const qualityControlChartSet = parseJSONOrDefault(
                uiDTO.chart_config_10,
                ps.qualityControlChart.settings
            )
            const capabilitySummaryChartSet = parseJSONOrDefault(
                uiDTO.chart_config_11,
                ps.capabilitySummaryChart.settings
            )
            const paretoChartSet = parseJSONOrDefault(
                uiDTO.chart_config_12,
                ps.paretoChart.settings
            )
            const probabilityPlotChartSet = parseJSONOrDefault(
                uiDTO.chart_config_13,
                ps.probabilityPlotChart.settings
            )
            const decisionTreeChartSet = parseJSONOrDefault(
                uiDTO.chart_config_14,
                ps.decisionTreeChart.settings
            )
            const valueListSet = parseJSONOrDefault(
                uiDTO.chart_config_15,
                ps.valueList.settings
            )
            const summaryListSet = parseJSONOrDefault(
                uiDTO.chart_config_16,
                ps.summaryList.settings
            )
            const valueSummaryListSet = parseJSONOrDefault(
                uiDTO.chart_config_17,
                ps.valueSummaryList.settings
            )
            const scrollBoardSet = parseJSONOrDefault(
                uiDTO.chart_config_18,
                ps.scrollBoard.settings
            )
            let configurationSet = parseJSONOrDefault(
                uiDTO.chart_config_19,
                ps.configuration.settings
            )
            const adminSettingsSet = parseJSONOrDefault(
                uiDTO.chart_config_20,
                ps.adminSettings.settings
            )
            const reportPrintMultiFunctionSet = parseJSONOrDefault(
                uiDTO.chart_config_21,
                ps.reportPrintMultiFunction.settings
            )
            const savedQuickFilterList = parseJSONOrDefault(
                uiDTO.chart_config_29,
                []
            )
            const savedAdvancedFilterList = parseJSONOrDefault(
                uiDTO.chart_config_30,
                []
            )

            const langType = uiDTO.language || 'en'

            const company = uiDTO.user_company
            const plant = uiDTO.user_plant
            const group = uiDTO?.user_group

            const targetPlantInfo = await getAdminCompany(
                spring,
                makeGetAdminCompanyPrms(company, plant)
            )
            let adminCompanyDTO = null
            if (!targetPlantInfo) {
                alert('Plant info is not exist')
                setLoading(false)
                return
            } else {
                adminCompanyDTO =
                    dtoUtils.makeAdminCompanyToDTOFormat(targetPlantInfo)
            }

            const targetGroupInfo = await getAdminGroup(
                spring,
                makeGetAdminGroupPrms(company, plant, group)
            )
            let adminGroupDTO = null
            if (!targetGroupInfo) {
                alert('Group info is not exist')
                setLoading(false)
                return
            } else {
                adminGroupDTO =
                    dtoUtils.makeAdminGroupToDTOFormat(targetGroupInfo)
            }

            const languageDataStr =
                adminCompanyDTO[companyPlantTbDescription.language]
            let languageData = null
            if (languageDataStr === '' || languageDataStr === null) {
                console.error('plant language data is empty')
                languageData = defaultLanguage
            } else {
                languageData = JSON.parse(languageDataStr)
            }

            let useFieldsData = null
            const useFieldsDataStr =
                adminCompanyDTO[companyPlantTbDescription.useFields]
            if (useFieldsDataStr === '' || useFieldsDataStr === null) {
                console.error('plant language data is empty')
                useFieldsData = defaultUseFieldList
            } else {
                useFieldsData = JSON.parse(useFieldsDataStr)
            }

            let catalogsData = null
            const catalogsDataStr =
                adminCompanyDTO[companyPlantTbDescription.catalogs]
            if (catalogsDataStr === '' || catalogsDataStr === null) {
                console.error('plant language data is empty')
                catalogsData = defaultCatalogs
            } else {
                catalogsData = JSON.parse(catalogsDataStr)
            }

            let reportTemplatesData = null
            const reportTemplatesDataStr =
                adminCompanyDTO[companyPlantTbDescription.reportTemplates]
            if (
                reportTemplatesDataStr === '' ||
                reportTemplatesDataStr === null
            ) {
                console.error('plant report Template data is empty')
                reportTemplatesData = []
            } else {
                reportTemplatesData = JSON.parse(reportTemplatesDataStr)
            }

            let reportLogoData = null
            const reportLogoDataStr =
                adminCompanyDTO[companyPlantTbDescription.reportLogo]
            if (reportLogoDataStr === '' || reportLogoDataStr === null) {
                console.error('plant report Logo data is empty')
                reportLogoData = null
            } else {
                reportLogoData = reportLogoDataStr
            }

            // const targetGroupInfo = await getAdminGroup(spring, makeGetAdminGroupPrms(company, plant, group))
            // const adminGroupDTO = dtoUtils.makeAdminGroupToDTOFormat(targetGroupInfo)

            let ribbonData = null
            const ribbonDataStr = adminGroupDTO[groupTbDescription.ribbon]
            if (ribbonDataStr === '' || ribbonDataStr === null) {
                console.warn(
                    `${company} / ${plant} / ${group}  find distribution data is empty`
                )
                ribbonData = defaultRibbon.default
            } else {
                ribbonData = JSON.parse(ribbonDataStr)
            }

            let permissionsData = null
            const permissionsDataStr =
                adminGroupDTO[groupTbDescription.permissions]
            if (permissionsDataStr === '' || permissionsDataStr === null) {
                console.warn(
                    `${company} / ${plant} / ${group}  find distribution data is empty`
                )
                permissionsData = defaultPermissions(company, plant, group)
            } else {
                permissionsData = JSON.parse(permissionsDataStr)
            }

            let dbConfigData = null
            const dbConfigDataStr = adminGroupDTO[groupTbDescription.dbConfig]
            if (dbConfigDataStr === '' || dbConfigDataStr === null) {
                console.warn(
                    `${company} / ${plant} / ${group}  find distribution data is empty`
                )
                dbConfigData = defaultDBConfig
            } else {
                dbConfigData = JSON.parse(dbConfigDataStr)
            }

            let encodingData = null
            const enCodingDataStr = adminGroupDTO[groupTbDescription.encoding]
            if (enCodingDataStr === '' || enCodingDataStr === null) {
                console.warn(
                    `${company} / ${plant} / ${group}  find distribution data is empty`
                )
                encodingData = defaultEncoding
            } else {
                encodingData = JSON.parse(enCodingDataStr)
            }

            batch(async () => {
                dispatch(rd.setMode('sign-in'))
                dispatch(ps.overview.setPageSettingsToRedux(overviewSet))
                dispatch(ps.monitoring.setPageSettingsToRedux(monitoringSet))
                dispatch(ps.database.setPageSettingsToRedux(databaseSet))
                // dispatch(ps.database.setPageSavedFilterListToRedux(databaseSavedFilterList))
                dispatch(ps.valueChart.setPageSettingsToRedux(valueChartSet))
                dispatch(
                    ps.histogramChart.setPageSettingsToRedux(histogramChartSet)
                )
                dispatch(
                    ps.positionalTolerancesChart.setPageSettingsToRedux(
                        positionalTolerancesChartSet
                    )
                )
                dispatch(
                    ps.boxPlotChart.setPageSettingsToRedux(boxPlotChartSet)
                )
                dispatch(
                    ps.linearRegressionChart.setPageSettingsToRedux(
                        linearRegressionChartSet
                    )
                )
                dispatch(
                    ps.correlationChart.setPageSettingsToRedux(
                        correlationChartSet
                    )
                )
                dispatch(
                    ps.qualityControlChart.setPageSettingsToRedux(
                        qualityControlChartSet
                    )
                )
                dispatch(
                    ps.capabilitySummaryChart.setPageSettingsToRedux(
                        capabilitySummaryChartSet
                    )
                )
                dispatch(ps.paretoChart.setPageSettingsToRedux(paretoChartSet))
                dispatch(
                    ps.probabilityPlotChart.setPageSettingsToRedux(
                        probabilityPlotChartSet
                    )
                )
                dispatch(
                    ps.decisionTreeChart.setPageSettingsToRedux(
                        decisionTreeChartSet
                    )
                )
                dispatch(ps.valueList.setPageSettingsToRedux(valueListSet))
                dispatch(ps.summaryList.setPageSettingsToRedux(summaryListSet))
                dispatch(
                    ps.valueSummaryList.setPageSettingsToRedux(
                        valueSummaryListSet
                    )
                )
                dispatch(ps.scrollBoard.setPageSettingsToRedux(scrollBoardSet))
                dispatch(
                    ps.configuration.setPageSettingsToRedux(configurationSet)
                )
                dispatch(
                    ps.adminSettings.setPageSettingsToRedux(adminSettingsSet)
                )
                dispatch(
                    ps.reportPrintMultiFunction.setPageSettingsToRedux(
                        reportPrintMultiFunctionSet
                    )
                )
                dispatch(rd.setEncoding(encodingData))
                dispatch(rd.setUseFieldList(useFieldsData))
                dispatch(rd.setCatalogs(catalogsData))
                dispatch(rd.setReportTemplates(reportTemplatesData))
                dispatch(rd.setReportLogoData(reportLogoData))
                dispatch(rd.setDBConfig(dbConfigData))
                dispatch(rd.setRibbon(ribbonData))
                dispatch(rd.setPermissions(permissionsData))
                dispatch(rd.setLanguageData(languageData))
                dispatch(
                    rd.setDatabaseSavedQuickFilterList(savedQuickFilterList)
                )
                dispatch(
                    rd.setDatabaseSavedAdvancedFilterList(
                        savedAdvancedFilterList
                    )
                )
                dispatch(rd.setLanguage(langType))
                const loadedTheme = configurationSet.theme
                setTheme(loadedTheme)

                updateLanguage(languageData)
                switchLanguage(configurationSet.language)

                const updatedUserInfo = {
                    ...userInfo,
                    user_role: convertUserRoleToString(userInfo.user_role),
                }
                setUser(updatedUserInfo)
                saveUserToLS({
                    user_id: userInfo.user_id,
                    user_company: userInfo.user_company,
                    user_plant: userInfo.user_plant,
                    user_group: userInfo.user_group,
                })
                setLoading(false)
            })
        },
        [signIn]
    )

    // const onCreateAccountClick = useCallback(() => {
    //   navigate('/create-account')
    // }, [navigate])

    const handleDevPageButtonClick = useCallback(
        async (e) => {
            dispatch(rd.setMode('development-functions'))
            const { id, password } = formData.current
            const userInfo = await signIn(id, password)
            navigate('/development-functions')
        },
        [navigate]
    )

    const [inputSequence, setInputSequence] = useState('')
    const [devButtonVisible, setDevButtonVisible] = useState(false)
    const handleKeyDown = useCallback((event) => {
        // Update input sequence with the latest key
        setInputSequence((prevSequence) => {
            const newSequence = prevSequence + event.key
            if (newSequence.includes('10293')) {
                setDevButtonVisible(true)
                return '' // Reset input sequence
            }
            // Keep only the last 6 characters to match the specific sequence
            return newSequence.slice(-6)
        })
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        // Cleanup event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown])

    return (
        <form
            className={'login-form'}
            onSubmit={onSubmit}
        >
            {/* <form className={'login-form'}> */}

            {/* 2024-12-06 hodo 로고 추가 */}
            <div className='logo-container'>
                <img
                    //로그인 색상 때문에 화이트 고정
                    src={logoImg}
                    alt='Company Logo'
                    className='login-logo'
                    style={{
                        display: 'block',
                        margin: '0 auto 20px',
                        width: '150px',
                        height: 'auto',
                    }}
                />
            </div>
            <Form
                ref={formRef}
                formData={formData.current}
                disabled={loading}
            >
                <Item
                    dataField={'id'}
                    editorType={'dxTextBox'}
                    editorOptions={emailEditorOptions}
                >
                    <Label visible={false} />
                </Item>
                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordEditorOptions}
                >
                    <RequiredRule message='Password is required' />
                    <Label visible={false} />
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        name='sign-in'
                        width={'100%'}
                        type={'default'}
                        // onClick={() => handleDevPageButtonClick('sign-in')}
                        useSubmitBehavior={true}
                    >
                        <span className='dx-button-text'>
                            {loading ? (
                                <LoadIndicator
                                    width={'24px'}
                                    height={'24px'}
                                    visible={true}
                                />
                            ) : (
                                getCommonMsg(msgType.Login, loginTable.M0001)
                            )}
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <ButtonItem>
                    <ButtonOptions
                        name='development-function'
                        width={'100%'}
                        type={'success'}
                        onClick={() =>
                            handleDevPageButtonClick('development-function')
                        }
                        visible={devButtonVisible}
                        // useSubmitBehavior={true}
                    >
                        <span className='dx-button-text'>
                            Development Settings
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                {/* <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            text={'Create an account'}
            width={'100%'}
            onClick={onCreateAccountClick}
          />
        </ButtonItem> */}
            </Form>
        </form>
    )
}
