import ReactECharts from 'echarts-for-react'
import { useCallback, useRef, useState } from 'react'
import { useTheme } from 'src/contexts/theme.js'
import { useLanguage } from 'src/contexts/languages.js'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import { setValueInfo } from 'src/store/charts-raw-data'
import * as dbValueApi from 'src/api/value'
import { CheckBox, List, Popup, SelectBox, TagBox, TextBox } from 'devextreme-react'
import { Position, ToolbarItem } from 'devextreme-react/popup'
import { batch, useDispatch } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { useAuth } from 'src/contexts/auth'

const getViewPortTheme = (pageKey, theme) => (theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light')

function ScrollBoardValueChartManual(props) {
    const { chartOptions, mode, char, value, sbPageSettings, configSettings, othersPage, stepReference, setStepReference } = props
    const { user } = useAuth()
    const { getTheme } = useTheme()
    const { language } = useLanguage()
    const chartRef = useRef(null)
    const eventContentRef = useRef(null)
    const theme = getTheme()
    const pageKey = 'scrollBoard'
    const dispatch = useDispatch()

    const [openEditEventPopup, setOpenEditEventPopup] = useState({
        show: false,
        value: null,
        part_id: null,
        char_id: null,
        value_id: null,
    })
    const [tags, setTags] = useState([])

    const width = `${100}%`
    const height = `${100}%`

    const onDataClicked = (params) => {
        if (params.componentType === 'series' && params.componentSubType === 'line') {
            const valueData = chartOptions[0].refValues[params.dataIndex]

            if (valueData) {
                const { part_id, char_id, value_id, v0005 } = valueData
                const itemsArray = v0005 ? v0005.split(',') : []

                setOpenEditEventPopup({ show: true, part_id, char_id, value_id })
                setTags(itemsArray)
            }
        }
    }

    const hideInfo = () => {
        setOpenEditEventPopup({ show: false, part_id: null, char_id: null, value_id: null })
    }

    const onPopupSaveButtonClicked = () => {
        const { part_id, char_id, value_id } = openEditEventPopup

        const formatEventDBField = tags.join(',')

        dbValueApi.setValueInfo(user, {
            part_id,
            char_id,
            value_id,
            v0005: formatEventDBField,
        })

        const targetIndex = stepReference.steps[stepReference.curStep].data.value[0].valueRaw.findIndex((item) => item.part_id === part_id && item.char_id === char_id && item.value_id === value_id)
        const targetData = stepReference.steps[stepReference.curStep].data.value[0].valueRaw[targetIndex]
        targetData.v0005 = formatEventDBField
        const next = produce(stepReference, (draft) => {
            draft.steps[draft.curStep].data.value[0].valueRaw[targetIndex] = targetData
        })

        const nextSettings = produce(sbPageSettings, (draft) => {
            draft.page.updateEventFlag = !draft.page.updateEventFlag
        })

        batch(() => {
            setStepReference(next)
            dispatch(ps.scrollBoard.setPageSettingsToRedux(nextSettings))
            setOpenEditEventPopup({ show: false, part_id: null, char_id: null, value_id: null })
        })
    }

    const onPopupRemoveButtonClicked = () => {
        const confirmFieldData = '999'
        const filteredTags = tags.filter((item) => item === confirmFieldData) // Keep only the item with 'no' 999
        setTags(filteredTags) // Update TagBox with the filtered values
        console.log('Removed all except 999:', filteredTags)
    }

    const onPopupCancelButtonClicked = () => {
        setOpenEditEventPopup({ show: false, part_id: null, char_id: null, value_id: null }) // Close the popup
    }

    const makeChart = (options) => {
        return options.map((option, index) => {
            if (option.option !== null) {
                return (
                    <div
                        key={`chart-${index}`}
                        style={{
                            width,
                            height,
                            padding: '5px',
                        }}
                    >
                        <ReactECharts
                            ref={chartRef}
                            style={{ width: '100%', height: '100%' }}
                            notMerge={true}
                            option={option.option}
                            onEvents={{ click: onDataClicked }}
                            theme={getViewPortTheme(pageKey, theme)}
                        />
                    </div>
                )
            } else {
                return (
                    <PageWarningMessage
                        fontSize={20}
                        message={`Value is not exist. Check Outlier Configuration Settings`}
                    />
                )
            }
        })
    }

    const getCheckBoxStateValueEvent = () => {
        if (tags.includes('999')) {
            return true
        }
        return false
    }

    const handleTagBoxChange = (e) => {
        setTags(e.value)
    }
    const handleTextBoxKeyPress = (e) => {
        if (e.event.key === 'Enter') {
            // Add new tag only if it doesn't already exist
            const value = e.component.option('value')
            if (!tags.includes(value)) {
                setTags([...tags, value]) // Add new tag to the list
            }
            e.component.reset() // Clear the TextBox input
        }
    }

    const handleSelectBoxItemClicked = (e) => {
        if (e.itemData) {
            // Add new tag only if it doesn't already exist
            const value = e.itemData.no
            if (!tags.includes(value)) {
                setTags([...tags, value]) // Add new tag to the list
            }
            e.component.reset() // Clear the TextBox input
        }
    }

    const renderTag = (e) => {
        const targetCatalog = othersPage.catalogs.v0005.find((item) => item.no === e)

        let content
        if (targetCatalog) {
            content = targetCatalog.desc
        } else {
            content = e
        }

        return (
            <div class='dx-tag-content'>
                {content}
                <div class='dx-tag-remove-button'></div>
            </div>
        )
    }

    if (chartOptions.length > 0) {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', height: '100%' }}>
                {makeChart(chartOptions)}
                <Popup
                    width={'20%'}
                    height={'auto'}
                    visible={openEditEventPopup.show}
                    onHiding={hideInfo}
                    hideOnOutsideClick={true}
                    dragEnabled={true}
                    showTitle={true}
                    showCloseButton={true}
                    // title="Event Configuration"
                    title={getPageMsg(msgType.ScrollBoard, 'EventConfiguration')}
                >
                    <Position
                        my='center'
                        at='center'
                        of={window}
                        collision='fit'
                    />
                    {/* <Position at="bottom" my="center" of={window}  /> */}
                    <ToolbarItem
                        widget='dxButton'
                        toolbar='bottom'
                        location='before'
                        options={{
                            icon: 'save',
                            stylingMode: 'contained',
                            text: getPageMsg(msgType.ScrollBoard, 'Save'),
                            onClick: onPopupSaveButtonClicked,
                            width: '100px',
                        }}
                    />
                    <ToolbarItem
                        widget='dxButton'
                        toolbar='bottom'
                        location='before'
                        options={{
                            icon: 'close',
                            stylingMode: 'contained',
                            text: getPageMsg(msgType.ScrollBoard, 'Cancel'),
                            onClick: onPopupCancelButtonClicked,
                            width: '100px',
                        }}
                    />
                    <ToolbarItem
                        widget='dxButton'
                        toolbar='bottom'
                        location='after'
                        options={{
                            icon: 'clearformat',
                            stylingMode: 'contained',
                            text: getPageMsg(msgType.ScrollBoard, 'Remove'),
                            onClick: onPopupRemoveButtonClicked,
                            width: '100px',
                        }}
                    />
                    <div style={{ padding: '10px' }}>
                        <CheckBox
                            value={getCheckBoxStateValueEvent()}
                            text={getPageMsg(msgType.ScrollBoard, 'ConfirmedValue')}
                            onValueChanged={(e) => {
                                if (e.event === undefined) return
                                const checkState = e.value

                                const confirmFieldData = '999'
                                if (checkState) {
                                    if (!tags.includes(confirmFieldData)) {
                                        setTags([...tags, confirmFieldData]) // Add new tag to the list
                                    }
                                } else {
                                    if (tags.includes(confirmFieldData)) {
                                        setTags(tags.filter((tag) => tag !== confirmFieldData)) // Remove the tag from the list
                                    }
                                }
                            }}
                        />
                    </div>
                    <div style={{ padding: '10px' }}>
                        <SelectBox
                            dataSource={[...othersPage.catalogs.v0005]}
                            keyExpr={'no'}
                            displayExpr={'desc'}
                            placeholder={getPageMsg(msgType.ScrollBoard, 'SelectFromCatalogEvent')}
                            onItemClick={handleSelectBoxItemClicked} // Handle Enter key press
                        />
                    </div>
                    <div style={{ padding: '10px' }}>
                        <TagBox
                            items={tags}
                            value={tags}
                            tagRender={renderTag}
                            onValueChanged={handleTagBoxChange}
                            placeholder={getPageMsg(msgType.ScrollBoard, 'YourTagsWillAppearHere')}
                            showDropDownButton={false}
                            searchEnabled={false}
                            showSelectionControls={true} // Display checkboxes if you want to allow tag deselection
                            multiline={true} // Allow the tags to wrap to the next line if needed
                        />
                    </div>
                </Popup>
            </div>
        )
    } else {
        return null
    }
}

export default ScrollBoardValueChartManual
