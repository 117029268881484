import Form, { GroupItem, Tab, TabbedItem } from 'devextreme-react/form'
import produce from 'immer'
import { useTheme } from 'src/contexts/theme'
import HeaderIcon from 'src/functions/HeaderIcon'
import IL from 'src/functions/IconLoader'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DataGridSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DataGridSimpleItem'

const tabIconPath = (data, type, menuIconType, theme, title) => {
    const mode = HeaderIcon.getIconMenuPath(menuIconType)
    const themeType = theme

    let path = ''
    switch (type) {
        case 'Configuration':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.CONFIGURATION)
            break
        case 'BarChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.HISTOGRAM_CHART)
            break
        case 'Diagram':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.SUMMARY_LIST)
            break
        case 'TreeMap':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_LIST)
            break
        case 'ValueChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_CHART)
            break
        case 'QccChart':
            path = IL.getNavImgPath(
                mode,
                themeType,
                IL.NAV.QUALITY_CONTROL_CHART
            )
            break
        default:
            break
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}
        >
            {path !== '' && (
                <img
                    src={path}
                    style={{
                        width: '15px',
                        height: '15px',
                        transform:
                            type === 'BarChart'
                                ? 'rotate(270deg) scaleY(-1)'
                                : 'none',
                    }}
                />
            )}
            <span style={{ width: '100%', height: '50%' }}>
                {dqTranslateMsg('SidePanel_' + title)}
            </span>
        </div>
    )
}

function makeConvertGridDataFromDTO(data) {
    // Initialize an empty result array
    const resultArray = []

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            resultArray.push({ field_id: key, value: data[key] })
        }
    }

    return resultArray
}

const QuickOption = ({
    dispatch,
    currentFilter,
    setCurrentFilter,
    isVisibleFilterPopup,
    setIsVisibleFilterPopup,
    pageKey,
    pageState,
    configState,
    othersPage,
    partRefPopup,
    charRefPopup,
    valueRefPopup,
    theme,
}) => {
    if (currentFilter === null) {
        return null
    }

    const handleRowSave = (e, ref, type) => {
        const conditionRows = ref.current.instance.getVisibleRows()
        // const filter = { ...currentFilter.data }
        const filter = {}

        conditionRows.forEach((item) => {
            filter[item.data.field_id] = item.data.value
        })

        const next = produce(currentFilter, (draft) => {
            draft.data[type] = filter
        })
        setCurrentFilter(next)
    }

    const onSaveAllButtonClick = () => {
        console.log('Save All')
        const targetIndex = othersPage.databaseSavedQuickFilterList.findIndex(
            (item) => item.filterName === currentFilter.filterName
        )

        if (targetIndex !== -1) {
            const next = produce(pageState, (draft) => {
                draft.filters.quick.savedFilters[targetIndex] = currentFilter
            })
            // setCurrentFilter(next)
            setIsVisibleFilterPopup(false)
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
        }
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-option-group-item`}
                style={{ padding: '3px' }}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'DBFieldFilter')}
            >
                <TabbedItem
                    colCount={2}
                    colSpan={2}
                    style={{ padding: '0px' }}
                >
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Part'
                            )
                        }
                    >
                        <DataGridSimpleItem
                            ref={partRefPopup}
                            colSpan={2}
                            type='part'
                            label={dqTranslateMsg('SidePanel_' + 'Part')}
                            dataSource={makeConvertGridDataFromDTO(
                                currentFilter.data?.part || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) =>
                                handleRowSave(e, partRefPopup, 'part')
                            }
                        />
                    </Tab>
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Characteristics'
                            )
                        }
                    >
                        <DataGridSimpleItem
                            ref={charRefPopup}
                            colSpan={2}
                            type='char'
                            label={dqTranslateMsg(
                                'SidePanel_' + 'Characteristics'
                            )}
                            dataSource={makeConvertGridDataFromDTO(
                                currentFilter.data?.char || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) =>
                                handleRowSave(e, charRefPopup, 'char')
                            }
                        />
                    </Tab>
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Value'
                            )
                        }
                    >
                        <DataGridSimpleItem
                            ref={valueRefPopup}
                            colSpan={2}
                            type='value'
                            label={dqTranslateMsg('SidePanel_' + 'Value')}
                            dataSource={makeConvertGridDataFromDTO(
                                currentFilter.data?.value || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) =>
                                handleRowSave(e, valueRefPopup, 'value')
                            }
                        />
                    </Tab>
                    {/* <Tab tabRender={(data) => tabIconPath(data, 'QccChart', configSettings.menuIconType, theme, 'QccChart')}>{qChartItems(pageState, configState)}</Tab> */}
                    {/* <Tab tabRender={(data) => tabIconPath(data, 'TreeMap', configSettings.menuIconType, theme, 'TreeMap')}>{tMapItems}</Tab> */}
                </TabbedItem>

                {/* {ButtonSimpleItem({
        colSpan: 2,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'SaveAll'),
        icon: 'check',
        width: '100%',
        height: '100%',
        onClick: onSaveAllButtonClick,
      })} */}
            </GroupItem>
        </Form>
    )
}

export default QuickOption
