import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useRef } from 'react'
import { useTheme } from 'src/contexts/theme'
import * as VCUtils from 'src/functions/ValueChartUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import styled from 'styled-components'

// Ensure the ReactECharts component takes full width and height of its container.
const StyledReactECharts = styled(ReactECharts)`
    width: 100%;
    height: 100%;
`

export function DecisionTreeValueChart({
    pageKey,
    pageSettings,
    othersPage,
    configSettings,
    language,
    targetData,
}) {
    const { getTheme } = useTheme()
    const theme = getTheme()
    const echartsRef = useRef(null)

    const pcSet = VCUtils.makePCData(
        configSettings,
        targetData.charRaw,
        targetData.valueRaw
    )
    const chartSet = VCUtils.makeChartData(
        pageSettings,
        configSettings,
        pcSet,
        targetData.charRaw,
        targetData.valueRaw
    )

    // Generate chart options using utility functions.
    const option = VCUtils.makeChartOption(
        false,
        pageSettings,
        configSettings,
        othersPage,
        pcSet,
        chartSet,
        targetData.charRaw, // Ensure that charRaw is the intended property. If it's a typo, correct as necessary.
        targetData.valueRaw,
        theme
    )

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <ReactECharts
                ref={echartsRef}
                // style={{ width: '750px', height: '450px' }}
                style={{ width: 1150, height: 750 }}
                notMerge={true} // Prevents merging with previous options.
                option={option} // Chart configuration options.
                theme={
                    getTheme().includes('dark') ||
                    getTheme().includes('contrast')
                        ? 'dark'
                        : 'light'
                } // Dynamic theme based on current theme context.
                opts={{
                    // width: 'auto',
                    // height: 'auto',
                    renderer: 'svg', // Use SVG for better quality at the expense of performance.
                    locale: ETCUtils.checkLangForECharts(language), // Ensure language is correctly parsed and passed.
                }}
                // onEvents={{ finished: resizeChart }}
            />
        </div>
    )
}
