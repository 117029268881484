import JwtUtils from 'src/functions/JwtUtils'

class ApiClient {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    // POST 요청을 처리하는 메소드
    async post(endpoint, data) {
        const accessToken = JwtUtils.loadToken() // 인증 토큰 로드
        const url = `${this.baseUrl}${endpoint}`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${accessToken}`, // 인증 토큰 포함
            },
            body: data && JSON.stringify(data), // 데이터를 JSON으로 인코딩
        }

        const response = await fetch(url, options)
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`)
        }
        return await response.json() // 응답을 JSON 형태로 파싱
    }

    async postMultiPart(endpoint, data) {
        const accessToken = JwtUtils.loadToken() // 인증 토큰 로드
        const url = `${this.baseUrl}${endpoint}`
        const options = {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                Authorization: `${accessToken}`, // 인증 토큰 포함
            },
            body: data && data, // 데이터를 JSON으로 인코딩
        }

        const response = await fetch(url, options)
        if (!response.ok) {
            throw new Error(`HTTP error, status = ${response.status}`)
        }
        return await response.json() // 응답을 JSON 형태로 파싱
    }
}

export default ApiClient
