import { Button } from 'devextreme-react'
import FilterBuilder, {
    CustomOperation,
    FilterOperationDescriptions,
} from 'devextreme-react/filter-builder'
import { SimpleItem } from 'devextreme-react/form'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'
import { useCallback } from 'react'
import { EditorComponent } from './EditorComponent'

const AdvancedFilterItem = ({
    colSpan,
    fields,
    value,
    maxGroupLevel,
    onValueChanged,
    onContentReady,
}) => {
    const filterOperationDescriptions = {
        contains: dqTranslateMsg('SidePanel_' + 'Contains'), // 포함
        equal: dqTranslateMsg('SidePanel_' + 'Equal'), // 같음
        // lessThanOrEqual: dqTranslateMsg('SidePanel_' + 'LessThanOrEqual'), // 작거나 같음
        // greaterThanOrEqual: dqTranslateMsg('SidePanel_' + 'GreaterThanOrEqual'), // 크거나 같음
        // anyof: dqTranslateMsg('SidePanel_' + 'AnyOf'), // 여러 값 중 하나
        // noneof: dqTranslateMsg('SidePanel_' + 'NoneOf'), // 여러 값 중 어떤 것도 아님
        // notEqual: dqTranslateMsg('SidePanel_' + 'NotEqual'), // 같지 않음
    }

    const calculateFilterExpression = (filterValue, field) => {
        return (
            filterValue &&
            filterValue.length &&
            Array.prototype.concat
                .apply(
                    [],
                    filterValue.map((i) => [[field.dataField, '=', i], 'or'])
                )
                .slice(0, -1)
        )
    }
    return (
        <SimpleItem
            colSpan={colSpan}
            render={() => (
                <FilterBuilder
                    fields={fields}
                    value={value}
                    maxGroupLevel={maxGroupLevel}
                    groupOperations={['and', 'or']}
                    filterOperationDescriptions={filterOperationDescriptions}
                    onValueChanged={onValueChanged}
                    width={'100%'}
                    onContentReady={onContentReady}
                >
                    <CustomOperation
                        name='anyof'
                        caption={dqTranslateMsg('SidePanel_' + 'AnyOf')}
                        icon='check'
                        editorComponent={EditorComponent}
                        calculateFilterExpression={calculateFilterExpression}
                    />
                </FilterBuilder>
            )}
        />
    )
}

export default AdvancedFilterItem
