import { Button } from 'devextreme-react'
import { useEffect, useState } from 'react'
import SidePanelForm from 'src/pages/components/side-panel-form/SidePanelForm'
import './PageContainer.scss'

const PageContainer = ({ pageKey, ChartFormComponent }) => {
    const [showSidePanel, setShowSidePanel] = useState(true)
    const [buttonTop, setButtonTop] = useState('95%')
    const [dragging, setDragging] = useState(false)
    // const [selectedTabIndex, setSelectedTabIndex] = useState(0)
    const [disableSidePanel, setDisableSidePanel] = useState(false)
    const [hover, setHover] = useState(false)

    // const [partWidth, setPartWidth] = useState(50) // DatabasePartForm의 초기 너비 (%)
    const [pageWidth, setPageWidth] = useState(80)
    const dividerWidth = 5 // divider의 너비

    useEffect(() => {
        setPageWidth(pageKey === 'scrollBoard' ? 70 : 80) // %
    }, [pageKey])

    const handleMouseDown = (e) => {
        e.preventDefault()
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = (e) => {
        const newPartWidth = (e.clientX / window.innerWidth) * 100
        setPageWidth(
            Math.min(
                Math.max(newPartWidth, pageKey === 'scrollBoard' ? 30 : 20),
                pageKey === 'scrollBoard' ? 70 : 80
            )
        ) // 최소 20%, 최대 80%로 제한
    }

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }

    const handleSidePanelFunctionBtnMouseDown = (e) => {
        setDragging(true)
    }

    const handleSidePanelFunctionBtnMouseMove = (e) => {
        if (dragging) {
            const containerRect = e.currentTarget.getBoundingClientRect()
            const newTop = e.clientY - containerRect.top
            const newTopVh = (newTop / window.innerHeight) * 100

            if (newTopVh >= 5 && newTopVh <= 90) {
                setButtonTop(`${newTopVh}vh`)
            }
        }
    }

    const handleSidePanelFunctionBtnMouseUp = () => {
        setDragging(false)
    }

    const handleSidePanelFunctionBtnMouseEnter = () => {
        setHover(true)
    }

    const handleSidePanelFunctionBtnMouseLeave = () => {
        if (!dragging) {
            setHover(false)
        }
    }
    return (
        <div
            className='page-container'
            style={{ position: 'relative', display: 'flex' }}
            onMouseMove={handleSidePanelFunctionBtnMouseMove}
            onMouseUp={handleSidePanelFunctionBtnMouseUp}
        >
            <div
                className='chart-container'
                style={{
                    flexGrow: 1,
                    // transition: 'width 0.3s',
                    // width: showSidePanel ? (pageKey === 'scrollBoard' ? '75%' : '80%') : '100%',
                    width: showSidePanel
                        ? `calc(${pageWidth}% - ${dividerWidth / 2}px)`
                        : '100%',
                }}
            >
                <ChartFormComponent
                    pageKey={pageKey}
                    setDisableSidePanel={setDisableSidePanel}
                />
            </div>
            <div
                className='divider'
                style={{ width: `${dividerWidth}px` }} // divider의 실제 너비 설정
                onMouseDown={handleMouseDown}
            ></div>
            {/* {showSidePanel && ( */}
            <div
                className='side-panel-container'
                style={{
                    width: showSidePanel
                        ? `calc(${100 - pageWidth}% - ${dividerWidth / 2}px)`
                        : '0%',
                }}
            >
                <SidePanelForm
                    pageKey={pageKey}
                    disable={disableSidePanel}
                    setSidePanelState={setDisableSidePanel}
                    // selectedTabIndex={selectedTabIndex}
                    // setSelectedTabIndex={setSelectedTabIndex}
                />
            </div>
            {/* )} */}
            <div
                onMouseDown={handleSidePanelFunctionBtnMouseDown}
                onMouseEnter={handleSidePanelFunctionBtnMouseEnter}
                onMouseLeave={handleSidePanelFunctionBtnMouseLeave}
                style={{
                    position: 'absolute',
                    top: buttonTop,
                    right: '10px',
                    zIndex: 1000,
                    transform: 'translateY(-50%)',
                    cursor: 'grab',
                }}
            >
                {hover && <Button icon={'expand'} />}
                <Button
                    onClick={() => setShowSidePanel(!showSidePanel)}
                    icon={
                        showSidePanel
                            ? 'chevrondoubleright'
                            : 'chevrondoubleleft'
                    }
                />
            </div>
        </div>
    )
}

export default PageContainer
