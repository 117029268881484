import React, { useEffect, useState } from 'react'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import SettingsRectElementsLayout from './SettingsRectElementsLayout'
import SettingsTemplateSave from './SettingsTemplateSave'
import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { sampleStyles } from '../define/SampleData'
import SettingsSelectedTemplateSave from './SettingsSelectedTemplateSave'
import SettingsSelectTemplate from './SettingsSelectTemplate'
import SettingsReportLayout from './SettingsReportLayout'
import SettingsReportType from './SettingsReportType'

const SettingsSelectedTemplatePopup = (props) => {
    const {
        isOpenSelectedTemplate,
        setIsOpenSelectedTemplate,
        selectedTemplate,
        setSelectedTemplate,
        selectedTemplateNo,
        templates,
        dispatch,
        pageKey,
        pageState,
        othersPage,
        mode,
    } = props

    const styles = sampleStyles

    const closePopup = () => {
        setIsOpenSelectedTemplate(false)
        setSelectedTemplate(null)
    }

    // PDF Content
    const PdfPortraitLayoutContent = ({ template }) => {
        const elements = template.elements
        const elementRowHeightUnit = 2 // 2% 최소

        return (
            <Document pageMode={'fullScreen'}>
                <Page
                    size={template.page.size}
                    style={styles.page}
                    orientation={template.page.orientation}
                >
                    {elements.map((element) => {
                        const columnContent = element.columnContent
                        // const rowCount = elements.length
                        const columnHeight =
                            elementRowHeightUnit * element.heightUnit
                        const columnCount = element.columnCount

                        return (
                            <View
                                key={element.index}
                                style={{
                                    flexDirection: 'row',
                                    // marginBottom: 20,
                                    width: '100%',
                                    height: `${columnHeight}%`,
                                }}
                            >
                                {columnContent
                                    ? columnContent.map((content, idx) => (
                                          <View
                                              key={element.index + idx}
                                              style={{
                                                  ...styles.section,
                                                  width: `${
                                                      100 / columnCount
                                                  }%`,
                                                  // width: `auto`,
                                              }}
                                          >
                                              <View style={styles.info}>
                                                  <Text style={styles.leftText}>
                                                      {content.value}
                                                  </Text>
                                              </View>
                                          </View>
                                      ))
                                    : null}
                            </View>
                        )
                    })}
                </Page>
            </Document>
        )
    }

    const PdfLandscapeLayoutContent = ({ template }) => {
        if (!template) {
            return null
        }
        const elements = template.elements
        const elementRowHeightUnit = 2 // 2% 최소

        return (
            <Document pageMode={'fullScreen'}>
                <Page
                    size={template.page.size}
                    style={styles.page}
                    orientation={template.page.orientation}
                >
                    {elements.map((element) => {
                        const columnContent = element.columnContent
                        // const rowCount = elements.length
                        const columnHeight =
                            elementRowHeightUnit * element.heightUnit
                        const columnCount = element.columnCount

                        return (
                            <View
                                key={element.index}
                                style={{
                                    flexDirection: 'row',
                                    // marginBottom: 20,
                                    width: '100%',
                                    height: `${columnHeight}%`,
                                }}
                            >
                                {columnContent
                                    ? columnContent.map((content, idx) => (
                                          <View
                                              key={element.index + idx}
                                              style={{
                                                  ...styles.section,
                                                  width: `${
                                                      100 / columnCount
                                                  }%`,
                                                  // width: `auto`,
                                              }}
                                          >
                                              <View style={styles.info}>
                                                  <Text style={styles.leftText}>
                                                      {content.value}
                                                  </Text>
                                              </View>
                                          </View>
                                      ))
                                    : null}
                            </View>
                        )
                    })}
                </Page>
            </Document>
        )
    }

    const renderFilterContent = () => {
        if (!selectedTemplate) {
            return null
        }

        return (
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    gap: '10px',
                }}
            >
                <div style={{ width: '50%', height: '100%' }}>
                    <SettingsReportType
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        disabled={true}
                    />

                    <SettingsReportLayout
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                    />
                    <SettingsRectElementsLayout
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        setIsOpenSelectedTemplate={setIsOpenSelectedTemplate}
                    />
                    <SettingsSelectedTemplateSave
                        dispatch={dispatch}
                        pageKey={pageKey}
                        pageState={pageState}
                        othersPage={othersPage}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        setIsOpenCreateTemplate={setIsOpenSelectedTemplate}
                    />
                </div>

                <div
                    style={{
                        width: '50%',
                        height: '100%',
                        border: '1px dotted gray',
                        padding: '10px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center', // 세로 중앙 정렬
                    }}
                >
                    <div
                        style={{
                            marginBottom: '5px',
                            width: '100%',
                            height: '100%',
                            fontWeight: 'bold',
                        }}
                    >
                        <PDFViewer
                            showToolbar={false}
                            width='100%'
                            height='100%'
                        >
                            {selectedTemplate ? (
                                selectedTemplate.page.orientation ===
                                'portrait' ? (
                                    <PdfPortraitLayoutContent
                                        template={selectedTemplate}
                                        // theme={theme}
                                    />
                                ) : (
                                    <PdfLandscapeLayoutContent
                                        template={selectedTemplate}
                                        // theme={theme}
                                    />
                                )
                            ) : null}
                        </PDFViewer>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <PopupSimpleItem
                visible={isOpenSelectedTemplate}
                onHiding={closePopup}
                title='Selected Filter'
                width='60%'
                height='80%'
                // contentRender={renderFilterContent}
                // contentRender={() => <div>234</div>}
            >
                {renderFilterContent()}
            </PopupSimpleItem>
        </>
    )
}

export default SettingsSelectedTemplatePopup
