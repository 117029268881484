import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CatalogUtils from './CatalogUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as VCArima from 'src/functions/ValueChartArima'

export function toolTipBalloonCallback(args, settings, configSettings, values, char, othersPage) {
    const checkArimaData = (args) => {
        // const arimaName = [dqTranslateMsg('Page_ValueChart_' + 'LowPred'), dqTranslateMsg('Page_ValueChart_' + 'UpperPred'), dqTranslateMsg('Page_ValueChart_' + 'Pred')]
        const arimaName = ['low_pred', 'pred', 'upper_pred']

        let isMatch = false
        args.forEach((element) => {
            if (arimaName.includes(element.seriesId)) {
                isMatch = true
            } else {
                isMatch = false
            }
        })

        return isMatch
    }

    let displayStr = ''

    if (checkArimaData(args)) {
        const preStr = dqTranslateMsg('Page_ValueChart_' + 'Prediction')
        let predictionContent = `<b>${preStr}</b><br>`
        args.forEach((element) => {
            if (element.seriesName) {
                predictionContent += `${element.seriesName}<br>`
            }
            if (element.data) {
                const numberItemName = dqTranslateMsg('Page_ValueChart_' + 'Index')
                predictionContent += `${numberItemName} : ${element.data[0]} <br>`
                const itemName = dqTranslateMsg('Db_field_Value_' + 'V0001')
                predictionContent += `${itemName} : ${DNUtils.calcValueDisplay(element.data[1], configSettings)} <br>`
            }
        })

        displayStr += `<table style="border-collapse: collapse; width: 100%;"><tr>`
        displayStr += `<td style="vertical-align: top; padding-right: 20px;">${predictionContent}</td>`
    } else {
        let predictionContent = ''
        args.forEach((element) => {
            if (element.seriesName) {
                predictionContent += `<b>${element.seriesName}</b><br>`
            }
            if (element.data) {
                const numberItemName = dqTranslateMsg('Page_ValueChart_' + 'Index')
                predictionContent += `${numberItemName} : ${element.data[0]} <br>`
            }
        })

        // Tooltip Info Items 추가 (predictionContent에 포함)
        settings.page.toolTipInfoItems.forEach((item) => {
            const dataField = item
            const valueAtIndex = values[args[0].data[0] - 1][dataField]
            const itemName = dqTranslateMsg('Db_field_Value_' + dataField.charAt(0).toUpperCase() + dataField.slice(1))

            if (valueAtIndex) {
                if (dataField === 'v0004') {
                    const dateTimeFormat = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeTooltipFormat, valueAtIndex)
                    predictionContent += `${itemName} : ${dateTimeFormat} <br>`
                } else if (dataField === 'v0001') {
                    predictionContent += `${itemName} : ${DNUtils.valueDisplay(valueAtIndex, char, configSettings)} <br>`
                } else if (dataField === 'v0005') {
                    const evtConverted = CatalogUtils.convertByCatalog(dataField, valueAtIndex, othersPage.catalogs)
                    predictionContent += `${itemName} : ${evtConverted} <br>`
                } else {
                    if (CatalogUtils.isCatalogField(dataField)) {
                        const evtConverted = CatalogUtils.convertByCatalog(dataField, valueAtIndex, othersPage.catalogs)
                        predictionContent += `${itemName} : ${evtConverted} <br>`
                    } else {
                        predictionContent += `${itemName} : ${valueAtIndex} <br>`
                    }
                }
            } else {
                predictionContent += `${itemName} :  <br>`
            }
        })
        displayStr += `<table style="border-collapse: collapse; width: 100%;"><tr>`
        displayStr += `<td style="vertical-align: top; padding-right: 20px;">${predictionContent}</td>`
    }

    if (settings.page.specificationLimitEnable === true || settings.page.warningLimitEnable === true || settings.page.averageLimitEnable === true || settings.page.nominalLimitEnable === true) {
        const limitsItemName = dqTranslateMsg('Page_ValueChart_' + 'Limits')
        let limitsContent = `<b>${limitsItemName}</b><br>`
        if (settings.page.specificationLimitEnable === true) {
            limitsContent += typeof settings.usl === 'number' ? `USL : ${DNUtils.axisValueDisplay(settings.usl, char, configSettings)} <br>` : ''
            limitsContent += typeof settings.lsl === 'number' ? `LSL : ${DNUtils.axisValueDisplay(settings.lsl, char, configSettings)} <br>` : ''
        }

        if (settings.page.warningLimitEnable === true) {
            limitsContent += typeof settings.uwl === 'number' ? `UWL : ${DNUtils.calcValueDisplay(settings.uwl, configSettings)} <br>` : ''
            limitsContent += typeof settings.lwl === 'number' ? `LWL : ${DNUtils.calcValueDisplay(settings.lwl, configSettings)} <br>` : ''
        }

        if (settings.page.averageLimitEnable === true) {
            const trans = dqTranslateMsg('Page_ValueChart_' + 'Average')
            limitsContent += typeof settings.average === 'number' ? `${trans} : ${DNUtils.calcValueDisplay(settings.average, configSettings)} <br>` : ''
        }

        if (settings.page.nominalLimitEnable === true) {
            const trans = dqTranslateMsg('Page_ValueChart_' + 'Nominal')
            limitsContent += typeof settings.nominal === 'number' ? `${trans} : ${settings.nominal} <br>` : ''
        }

        if (settings.page.plausibilityLimitEnable === true) {
            limitsContent += typeof settings.upl === 'number' ? `UPL: ${settings.upl} <br>` : ''
            limitsContent += typeof settings.lpl === 'number' ? `LPL: ${settings.lpl} <br>` : ''
        }

        if (settings.page.scrapLimitEnable === true) {
            limitsContent += typeof settings.url === 'number' ? `URL : ${settings.url} <br>` : ''
            limitsContent += typeof settings.lrl === 'number' ? `LRL : ${settings.lrl} <br>` : ''
        }

        if (settings.page.acceptanceLimitEnable === true) {
            limitsContent += typeof settings.uacl === 'number' ? `UAcL : ${settings.uacl} <br>` : ''
            limitsContent += typeof settings.lacl === 'number' ? `LAcL : ${settings.lacl} <br>` : ''
        }

        displayStr += `<td style="vertical-align: top;">${limitsContent}</td>`
        displayStr += `</tr></table>`
    }

    return displayStr
}

export function makeXAxisLabelFormat(value, index, settings, catalogs, refValues) {
    const xAxisLabelItems = settings.page.xAxisLabelItems
    const valueArray = refValues

    if (xAxisLabelItems.length === 0) {
        return value
    }

    if (value < 1 || value > valueArray.length) {
        return ''
    }

    let xLabel = ''
    xAxisLabelItems.forEach((item) => {
        if (!ETCUtils.isPositiveInteger(value)) {
            return
        }

        const itemValue = valueArray[value - 1][item]

        if (xLabel === '') {
            if (itemValue !== null && itemValue !== undefined) {
                if (item === 'v0004') {
                    xLabel = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)
                } else {
                    xLabel = CatalogUtils.convertByCatalog(item, itemValue, catalogs)
                }
            } else {
                xLabel = 'null'
            }
        } else {
            xLabel =
                item === 'v0004'
                    ? `${xLabel}/${CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)}`
                    : `${xLabel}/${CatalogUtils.convertByCatalog(item, itemValue, catalogs)}`
        }
    })

    return xLabel
}
