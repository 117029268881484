import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'

// Sample Data
export const templateData = {
    name: 'template-name-1',
    page: {
        size: 'A4',
        orientation: 'portrait',
        wrap: false,
        debug: false,
        dpi: 72,
    },
    briefInfoDef: ['dist', 'cp', 'cpk', 'report'],
    partInfoDef: ['p1001'],
    charInfoDef: ['c2001'],
    elements: [
        {
            index: 1,
            heightUnit: 3,
            columnCount: 1,
            columnContent: [
                { value: 'title-desc', content: 'Process Capability Report' },
            ],
        },
        {
            index: 7,
            heightUnit: 3,
            columnCount: 3,
            columnContent: [
                { value: 'p1001', content: null },
                { value: 'p1002', content: null },
                { value: 'c2001', content: null },
                { value: 'c2002', content: null },
            ],
        },
        {
            index: 2,
            heightUnit: 12,
            columnCount: 2,
            columnContent: [
                { value: 'histogram-chart', content: null },
                { value: 'qcc-chart', content: null },
            ],
        },
        {
            index: 3,
            heightUnit: 10,
            columnCount: 2,
            columnContent: [
                { value: 'value-chart', content: null },
                { value: 'probability-plot-chart', content: null },
            ],
        },
        {
            index: 5,
            heightUnit: 7,
            columnCount: 1,
            columnContent: [{ value: 'summary-list', content: null }],
        },
        {
            index: 6,
            heightUnit: 6,
            columnCount: 1,
            columnContent: [{ value: 'brief-info', content: null }],
        },
    ],
}

export const sampleStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
    },
    section: {
        // margin: 10,
        padding: 5,
        // flexGrow: 1,
    },
    chart: {
        borderWidth: 0.5, // 전체 리스트에 보더 추가
        borderColor: '#999', // 보더 색상
    },
    list: {
        flexDirection: 'row',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
        borderWidth: 0.5, // 전체 리스트에 보더 추가
        borderColor: '#999', // 보더 색상
    },
    listIcon: {
        fontFamily: 'D2Coding',
        width: '100%',
        // height: '100%',
        // display: 'flex',
        fontSize: 12, // 숫자로 수정
        textAlign: 'center', // 가로 가운데 정렬
        justifyContent: 'center', // 세로 가운데 정렬
        alignItems: 'center', // 세로 가운데 정렬
        // flexGrow: 1, // 자식 요소들이 flexbox로 크기 조정
        borderWidth: 0.5, // 각 텍스트 항목에 보더 추가
        borderColor: '#999', // 보더 색상
        padding: 15, // padding은 숫자로 설정
    },
    info: {
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
        borderWidth: 0.5, // 전체 리스트에 보더 추가
        borderColor: '#999', // 보더 색상
    },
    titleText: {
        fontFamily: 'D2Coding',
        fontSize: 25, // 기본 폰트 크기
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
    centeredText: {
        fontFamily: 'D2Coding',
        width: '100%',
        height: '100%',
        // display: 'flex',
        fontSize: 12, // 숫자로 수정
        textAlign: 'center', // 가로 가운데 정렬
        justifyContent: 'center', // 세로 가운데 정렬
        alignItems: 'center', // 세로 가운데 정렬
        // flexGrow: 1, // 자식 요소들이 flexbox로 크기 조정
        borderWidth: 0.5, // 각 텍스트 항목에 보더 추가
        borderColor: '#999', // 보더 색상
        padding: 2, // padding은 숫자로 설정
    },
    leftText: {
        fontFamily: 'D2Coding',
        width: '100%',
        height: '100%',
        fontSize: 12, // 기본 폰트 크기
        textAlign: 'left',
        verticalAlign: 'middle',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5, // 각 텍스트 항목에 보더 추가
        borderColor: '#999', // 보더 색상
        flexGrow: 1,
        padding: 2, // padding은 숫자로 설정
    },
})
