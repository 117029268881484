import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import RadioGroupSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItemNew'
import produce from 'immer'

export const reportType = () => [
    {
        value: 'individual',
        text: getCommonMsg(msgType.SidePanel, 'Individual'),
    },
    {
        value: 'collective',
        text: getCommonMsg(msgType.SidePanel, 'Collective'),
    },
]

export default function SettingsReportType(props) {
    const {
        dispatch,
        pageKey,
        pageState,
        othersPage,
        selectedTemplate,
        setSelectedTemplate,
        disabled,
    } = props
    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`settings-report-type-group-item`}
                colCount={6}
                caption={getCommonMsg(msgType.SidePanel, 'ReportType')}
            >
                <SimpleItem
                    colSpan={6}
                    render={() => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                gap: '5px',
                            }}
                        >
                            <RadioGroupSimpleItemNew
                                ref={null}
                                colSpan={6}
                                label={''}
                                labelLocation={'top'}
                                name={'reportType'}
                                items={reportType()}
                                layout={'horizontal'}
                                disabled={disabled}
                                valueExpr={'value'}
                                displayExpr={'text'}
                                value={selectedTemplate.type}
                                onValueChanged={(e) => {
                                    const next = produce(
                                        selectedTemplate,
                                        (draft) => {
                                            draft.type = e.value
                                        }
                                    )
                                    setSelectedTemplate(next)
                                }}
                            />
                        </div>
                    )}
                />
            </GroupItem>
        </Form>
    )
}
