import React from 'react'
import { Label } from 'devextreme-react/form'
import { List, Sortable } from 'devextreme-react'
import { ItemDragging } from 'devextreme-react/list'

const ListSimpleItemNew = ({
    colSpan,
    label,
    labelLocation,
    dataSource,
    itemRender,
    allowItemDeleting = false,
    itemDeleteMode = 'static',
    showSelectionControls = false,
    selectionMode = 'none',
    noDataText = 'No data available',
    onItemClick = null,
    onItemDeleted = null,
    onItemDeleting = null,
    onItemRendered = null,
    onSelectionChanged = null,
    onReorder = null,
    selectedItemKeys = [],
    selectByClick = false,
    onOptionChanged = null,
    keyExpr = 'id',
    displayExpr = 'name',
    backgroundCondition = true,
}) => {
    const listStyle = {
        border: backgroundCondition ? 'none' : '2px dashed orange',
        width: '100%',
    }

    return (
        <>
            {/* Label이 존재하면 렌더링 */}
            {label && (
                <Label
                    text={label}
                    location={labelLocation}
                />
            )}
            <List
                style={listStyle}
                dataSource={dataSource}
                itemRender={itemRender}
                allowItemDeleting={allowItemDeleting}
                itemDeleteMode={itemDeleteMode}
                selectionMode={selectionMode}
                noDataText={noDataText}
                onItemClick={onItemClick}
                onItemDeleted={onItemDeleted}
                onItemDeleting={onItemDeleting}
                onItemRendered={onItemRendered}
                onSelectionChanged={onSelectionChanged}
                showSelectionControls={showSelectionControls}
                selectedItemKeys={selectedItemKeys}
                selectByClick={selectByClick}
                keyExpr={keyExpr}
                displayExpr={displayExpr}
                onOptionChanged={onOptionChanged}
            >
                {onReorder !== null ? (
                    <ItemDragging
                        allowReordering={true}
                        group='tasks'
                        data='plannedTasks'
                        onReorder={onReorder}
                    ></ItemDragging>
                ) : null}
            </List>
        </>
    )
}

export default ListSimpleItemNew
