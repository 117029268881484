import { getDBFieldMsg } from 'src/functions/CommonMassageUtils'
import { fieldType } from 'src/functions/table/DBFieldTypeTable'

export const reportElementTagListForCollective = () => [
    { type: 'text', value: 'title-desc', display: 'Title' },
    {
        type: 'list',
        value: 'summary-list-collective',
        display: 'Summary List Collective',
    },
]

export const filterCollectiveTags = (type) => {
    switch (type) {
        case 'all':
            return reportElementTagListForCollective()
        case 'text':
        case 'list':
        case 'field':
            return reportElementTagListForCollective().filter(
                (element) => element.type === type
            )
        default:
            console.error('not match element type')
            break
    }
}

export const getAllElementCollectiveType = () => {
    return [
        ...new Set(reportElementTagListForCollective().map((el) => el.type)),
    ]
}

export const getAllElementCollectiveValue = () => {
    return [
        ...new Set(reportElementTagListForCollective().map((el) => el.value)),
    ]
}
