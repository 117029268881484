import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'

export const getPageStyleByLang = (lang) => {
    switch (lang) {
        case 'en':
            return indStyles.pageEn
        case 'ja':
            return indStyles.pageJa
        case 'kr':
            return indStyles.pageKr
        default:
            return null
    }
}
// Individual Report Style
export const indStyles = StyleSheet.create({
    pageEn: {
        flexDirection: 'column',
        // fontFamily: 'Helvetica',
        fontFamily: 'ArialUnicode',
        backgroundColor: '#FFF',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
    },
    pageJa: {
        flexDirection: 'column',
        // fontFamily: 'MPLUS1p-Regular',
        fontFamily: 'ArialUnicode',
        backgroundColor: '#FFF',
        paddingLeft: 10,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
    },
    pageKr: {
        flexDirection: 'column',
        // fontFamily: 'D2Coding',
        fontFamily: 'ArialUnicode',
        backgroundColor: '#FFF',
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
    },
    section: {
        // margin: 10,
        padding: 2,
        // flexGrow: 1,
    },
    title: {
        width: '100%',
        height: '100%',
        justifyContent: 'center', // 세로 가운데 정렬
        alignItems: 'center', // 가로 가운데 정렬
        flexDirection: 'row', // 항목들을 가로로 배치
        borderWidth: 0.5, // 전체 보더 추가
        borderColor: '#999', // 보더 색상
    },
    dateView: {
        width: '20%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
    },
    titleView: {
        width: '70%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
    },
    pageView: {
        width: '10%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
    },
    logoView: {
        width: '20%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 3,
        borderWidth: 0.5,
        borderColor: '#999',
    },
    date: {
        width: '20%', // 날짜를 위해 적당한 넓이 설정
        height: '100%',
        fontSize: 12, // 기본 폰트 크기
        textAlign: 'center',
        lineHeight: '100%', // 세로 중앙 정렬
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5, // 보더 추가
        borderColor: '#999', // 보더 색상
    },
    pageNumber: {
        width: '10%', // 페이지 번호를 위해 적당한 넓이 설정
        height: '100%',
        fontSize: 12, // 기본 폰트 크기
        textAlign: 'center',
        lineHeight: '100%', // 세로 중앙 정렬
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5, // 보더 추가
        borderColor: '#999', // 보더 색상
    },
    trendView: {
        width: '200%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
        textOverflow: 'clip',
        whiteSpace: 'nowrap', // 추가: 텍스트 줄바꿈 방지
    },
    trendHeaderView: {
        width: '200%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
        textOverflow: 'clip',
        whiteSpace: 'nowrap',
    },
    textView: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
        textOverflow: 'clip',
        whiteSpace: 'nowrap', // 추가: 텍스트 줄바꿈 방지
    },
    textHeaderView: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: '#999',
        textOverflow: 'clip',
        whiteSpace: 'nowrap',
    },
    chart: {
        // borderWidth: 0.5, // 전체 리스트에 보더 추가
        // borderColor: '#999', // 보더 색상
    },
    list: {
        width: '100%',
        // height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
        // borderWidth: 0.5, // 전체 리스트에 보더 추가
        // borderColor: '#999', // 보더 색상
    },
    listHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
    },
    listValue: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
    },
    listIcon: {
        width: 'auto',
        maxWidth: '50px',
        height: 'auto',
        maxHeight: '50px',
        justifyContent: 'center', // 세로 가운데 정렬
        alignItems: 'center', // 세로 가운데 정렬
        padding: 5, // padding은 숫자로 설정
    },
    info: {
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-around', // 요소 간격을 균등하게 배치
        alignItems: 'center', // 세로 가운데 정렬
    },
    titleText: {
        width: '100%',
        fontSize: 24,
        textAlign: 'center',
        textOverflow: 'clip',
        maxLines: 1,
        padding: 2,
    },
    centeredText: {
        fontSize: 10,
        textAlign: 'center',
        padding: 2,
        maxLines: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    leftText: {
        fontSize: 10,
        textAlign: 'left',
        padding: 2,
        maxLines: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
})
