import React from 'react'
import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CheckBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItemNew'

const AdvancedETCOption = ({ currentFilter, setCurrentFilter }) => {
    if (currentFilter === null) {
        return null
    }

    const onCheckBoxIgnoreDuplicatedValueValueChanged = (e, dataField) => {
        if (e.event === undefined) return
        const value = e.value
        const next = produce(currentFilter, (draft) => {
            draft.others[dataField] = value
        })
        setCurrentFilter(next)
        // dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`etc-option-group-item`}
                colCount={4}
                caption={dqTranslateMsg('SidePanel_' + 'Etcetera')}
            >
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <CheckBoxSimpleItemNew
                            // colSpan={1}
                            // label={dqTranslateMsg(
                            //     'SidePanel_' + 'IgnoreDuplicatedValue'
                            // )}
                            // labelLocation='right'
                            name='ignoreDuplicatedValue'
                            value={currentFilter.others.ignoreDuplicatedValue}
                            disabled={false}
                            onValueChanged={(e) =>
                                onCheckBoxIgnoreDuplicatedValueValueChanged(
                                    e,
                                    'ignoreDuplicatedValue'
                                )
                            }
                        />
                    )}
                >
                    <Label
                        text={dqTranslateMsg(
                            'SidePanel_' + 'IgnoreDuplicatedValue'
                        )}
                        location={'right'}
                        alignment={'center'}
                        showColon={false}
                    />
                </SimpleItem>
            </GroupItem>
        </Form>
    )
}

export default AdvancedETCOption
