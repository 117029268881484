import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Image,
    Font,
    PDFViewer,
    Svg,
} from '@react-pdf/renderer'
import { indStyles } from '../define/IndividualReportStyle'
import { getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import IconLoader from 'src/functions/IconLoader'

export const contentBriefInfo = (briefInfoItems, data, slPage, configPage) => {
    const renderContent = briefInfoItems.map((item) => {
        let titleText = ''
        let valueText = ''

        switch (item) {
            case 'dist':
                titleText = getPageMsg(
                    msgType.ReportPrintMultiFunction,
                    'Distribution'
                )
                valueText = data.dist
                break
            case 'cp':
                titleText = getPageMsg(
                    msgType.ReportPrintMultiFunction,
                    'PotentialPerformanceIndex'
                )
                valueText = data.cp
                break
            case 'cpk':
                titleText = getPageMsg(
                    msgType.ReportPrintMultiFunction,
                    'CriticalPerformanceIndex'
                )
                valueText = data.cpk
                break

            case 'report':
                titleText = getPageMsg(
                    msgType.ReportPrintMultiFunction,
                    'Report'
                )
                valueText = `${
                    data.cp > 1.33 ? 'Cp is Met' : 'Cp is Not Met'
                }, ${data.cpk > 1.33 ? 'Cpk is Met' : 'Cpk is Not Met'}`
                break
            default:
                break
        }

        return (
            <View style={indStyles.info}>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.leftText}
                    >
                        {titleText}
                    </Text>
                </View>
                <View style={indStyles.textView}>
                    <Text
                        wrap={false}
                        style={indStyles.centeredText}
                    >
                        {valueText}
                    </Text>
                </View>
            </View>
        )
    })

    return renderContent
}
