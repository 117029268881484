import React, { useRef, useState } from 'react'
import Form, { GroupItem, Tab, TabbedItem } from 'devextreme-react/form'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DataGridSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/DataGridSimpleItem'
import { useTheme } from 'src/contexts/theme'
import HeaderIcon from 'src/functions/HeaderIcon'
import IL from 'src/functions/IconLoader'

const tabIconPath = (data, type, menuIconType, theme, title) => {
    const mode = HeaderIcon.getIconMenuPath(menuIconType)
    const themeType = theme

    let path = ''
    switch (type) {
        case 'Configuration':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.CONFIGURATION)
            break
        case 'BarChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.HISTOGRAM_CHART)
            break
        case 'Diagram':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.SUMMARY_LIST)
            break
        case 'TreeMap':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_LIST)
            break
        case 'ValueChart':
            path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_CHART)
            break
        case 'QccChart':
            path = IL.getNavImgPath(
                mode,
                themeType,
                IL.NAV.QUALITY_CONTROL_CHART
            )
            break
        default:
            break
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
            }}
        >
            {path !== '' && (
                <img
                    src={path}
                    style={{
                        width: '15px',
                        height: '15px',
                        transform:
                            type === 'BarChart'
                                ? 'rotate(270deg) scaleY(-1)'
                                : 'none',
                    }}
                />
            )}
            <span style={{ width: '100%', height: '50%' }}>
                {dqTranslateMsg('SidePanel_' + title)}
            </span>
        </div>
    )
}

function makeConvertGridDataFromDTO(data) {
    // Initialize an empty result array
    const resultArray = []

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            resultArray.push({ field_id: key, value: data[key] })
        }
    }

    return resultArray
}

const DBFieldOption = ({
    pageKey,
    pageState,
    configState,
    othersPage,
    partRef,
    charRef,
    valueRef,
    theme,
}) => {
    const dispatch = useDispatch()
    // const { getTheme } = useTheme()
    // const theme = getTheme()

    const handleRowSave = (e, ref, type) => {
        const conditionRows = ref.current.instance.getVisibleRows()
        const filter = {}

        conditionRows.forEach((item) => {
            filter[item.data.field_id] = item.data.value
        })

        const next = produce(pageState, (draft) => {
            draft.filters.quick.data[type] = filter
        })
        dispatch(ps.database.setPageSettingsToRedux(next))
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`db-field-option-group-item`}
                style={{ padding: '3px' }}
                colCount={2}
                caption={dqTranslateMsg('SidePanel_' + 'DBFieldFilter')}
            >
                <TabbedItem
                    colCount={2}
                    colSpan={2}
                    style={{ padding: '0px' }}
                >
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Part'
                            )
                        }
                    >
                        <DataGridSimpleItem
                            ref={partRef}
                            colSpan={2}
                            type='part'
                            dataSource={makeConvertGridDataFromDTO(
                                pageState.filters.quick?.data?.part || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) => handleRowSave(e, partRef, 'part')}
                        />
                    </Tab>
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Characteristics'
                            )
                        }
                    >
                        {/* Characteristics DataGrid */}
                        <DataGridSimpleItem
                            ref={charRef}
                            colSpan={2}
                            type='char'
                            dataSource={makeConvertGridDataFromDTO(
                                pageState.filters.quick?.data?.char || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) => handleRowSave(e, charRef, 'char')}
                        />
                    </Tab>
                    <Tab
                        tabRender={(data) =>
                            tabIconPath(
                                data,
                                '',
                                configState.menuIconType,
                                theme,
                                'Value'
                            )
                        }
                    >
                        <DataGridSimpleItem
                            ref={valueRef}
                            colSpan={2}
                            type='value'
                            dataSource={makeConvertGridDataFromDTO(
                                pageState.filters.quick?.data?.value || {}
                            )}
                            configState={configState}
                            othersPage={othersPage}
                            onSave={(e) => handleRowSave(e, valueRef, 'value')}
                        />
                    </Tab>
                </TabbedItem>
            </GroupItem>
        </Form>
    )
}

export default DBFieldOption
