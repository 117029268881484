import { Form, ScrollView } from 'devextreme-react'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { useLanguage } from 'src/contexts/languages'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './SidePanelTemplate.scss'
import DBFieldAdvancedRealTimeFilter from 'src/pages/database/components/side-panel/components/settings/DBFieldAdvancedRealTimeFilter'
import CommonOption from 'src/pages/database/components/side-panel/components/settings/CommonOption'
import DateTimeOption from 'src/pages/database/components/side-panel/components/settings/DateTimeOption'
import LastNFilterOption from 'src/pages/database/components/side-panel/components/settings/LastNFilterOption'
import ETCOption from 'src/pages/database/components/side-panel/components/settings/ETCOption'

export default function SidePanelTemplate({
    pageKey,
    formComponents,
    saveCallback,
    resetCallback,
    extraButtons,
    toastRef,
}) {
    const dispatch = useDispatch()
    const { user } = useAuth()
    const { language } = useLanguage()
    const parentRef = useRef(null)

    const [scrollPosition, setScrollPosition] = useState(0)
    const common = useSelector((state) => state.common)
    const curPage = common.pages[pageKey]
    const othersPage = common.pages.others
    const configPage = common.pages.configuration
    const currentFilterMode = curPage.page.filterMode

    return (
        <div
            ref={parentRef}
            style={{ padding: '5px', width: '100%', height: '100%' }}
        >
            <DQToast ref={toastRef} />
            <div
                style={{
                    width: '100%',
                    height: '4%',
                }}
            >
                <DQSaveReset
                    saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
                    resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
                    saveCallback={saveCallback}
                    resetCallback={resetCallback}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    height: '4%',
                    padding: '5px 0px',
                }}
            >
                {extraButtons}
            </div>
            <div style={{ width: '100%', height: '92%' }}>
                <ScrollView
                    className='side-panel-tab-container'
                    style={{
                        padding: 0,
                        width: '100%',
                        padding: '0px 5px 0px 5px',
                    }}
                    showScrollbar={'onHover'}
                    useNative={false}
                >
                    {formComponents}
                </ScrollView>
            </div>
        </div>
    )
}
