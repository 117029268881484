import React, { useEffect } from 'react'
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'
import ButtonSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItemNew'
import TextBoxSimpleItemNew from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItemNew'
import { useState } from 'react'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { useApi } from 'src/contexts/api'
import { companyPlantTbDescription } from 'src/functions/constants/companyPlantDBfieldDescription'
import { useAuth } from 'src/contexts/auth'
import * as rd from 'src/store/common'
import { updateAdminCompany } from 'src/api/admin/AdminCompanyApi'
import { makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'

export default function SettingsSelectedTemplateSave(props) {
    const {
        dispatch,
        pageKey,
        pageState,
        othersPage,
        selectedTemplate,
        setSelectedTemplate,
        setIsOpenCreateTemplate,
    } = props
    const { spring } = useApi()
    const { user } = useAuth()

    const handleSaveClicked = (e) => {
        const filterList = othersPage.reportTemplates.filter(
            (template) => template.name !== selectedTemplate.name
        )
        filterList.push(selectedTemplate)

        const nextOthers = produce(othersPage, (draft) => {
            // reportTemplates 배열을 filterList로 업데이트
            draft.reportTemplates = filterList
        })
        dispatch(rd.setReportTemplates(nextOthers.reportTemplates))

        const company = user.user_company
        const plant = user.user_plant
        const dataJsonStr = JSON.stringify(nextOthers.reportTemplates)

        const updateData = {
            [companyPlantTbDescription.reportTemplates]: dataJsonStr,
        }

        updateAdminCompany(
            spring,
            makeGetAdminCompanyPrms(company, plant, updateData)
        )
        setIsOpenCreateTemplate(false)
    }

    const handleCloseClicked = (e) => {
        setIsOpenCreateTemplate(false)
    }

    return (
        <Form
            colCount={1}
            labelMode='outside'
        >
            <GroupItem
                key={`settings-template-save-group-item`}
                colCount={2}
                caption={''}
            >
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            type='normal'
                            text={getCommonMsg(msgType.SidePanel, 'Save')}
                            icon='check'
                            onClick={handleSaveClicked}
                        />
                    )}
                />
                <SimpleItem
                    colSpan={1}
                    render={() => (
                        <ButtonSimpleItemNew
                            type='normal'
                            text={getCommonMsg(msgType.SidePanel, 'Close')}
                            icon='close'
                            onClick={handleCloseClicked}
                        />
                    )}
                />
            </GroupItem>
        </Form>
    )
}
