import { Fragment, useEffect, useRef, useState } from 'react'
import { useTheme } from 'src/contexts/theme'
import './ReportPrintForm.scss'
// import * as vcImpl from 'src/components/'
import { Button } from 'devextreme-react/button'
import { Column, DataGrid } from 'devextreme-react/data-grid'
import html2canvas from 'html2canvas'
import produce from 'immer'
import { jsPDF } from 'jspdf'
import { batch, connect } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getHistogram } from 'src/api/histogram'
import { getProbability } from 'src/api/probability-plot'
import { getSummary } from 'src/api/summary-list'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import CCUtils from 'src/functions/CommonCalcUtils'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { makeChartOption as makeHistChartOption } from 'src/functions/HistogramChartUtils'
import IconLoader from 'src/functions/IconLoader'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import * as VCUtils from 'src/functions/ValueChartUtils'
import ApacheEChartHistogramComponent from 'src/pages/chart-histogram/components/chart/ApacheEChartHistogramComponent'
import * as histImpl from 'src/pages/chart-histogram/components/main-view/chart-option/HistogramChartOptionImpl'
import ApacheEChartPTComponent from 'src/pages/chart-probability-plot/components/chart/ApacheEChartPTComponent'
import * as probImpl from 'src/pages/chart-probability-plot/components/main-view//chart-option/ProbabilityPlotChartOptionImpl'
import { makeProbChartOption } from 'src/pages/chart-probability-plot/components/main-view/chart-option/ProbabilityPlotChartOption'
import ApacheEChartValueChartComponent from 'src/pages/chart-value/components/arima/ApacheEChartValueChartComponent'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as slImpl from './datagrid/ReportPrintSummaryListImpl'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const mapStateToProps = (state) => ({
    common: state.common,
    chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ReportPrintForm)

function makeHistSettings(curPage, configSettings, targetChar, targetValue) {
    const {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    }
    const filteredValue = outlierFilterValues(
        limits,
        targetChar,
        targetValue,
        configSettings
    )
    const { lcl, ucl, average, minValue, maxValue, v0001s } =
        PCUtils.getCalculatedPrms(filteredValue, configSettings)
    const x0_135 = lcl
    const x99_865 = ucl
    const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
    const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
    const min = Math.min(...minValues)
    const max = Math.max(...maxValues)
    const xAxisLeft = min - (max - min) * 0.1
    const xAxisRight = max + (max - min) * 0.1

    const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

    const settings = {
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        char: targetChar,
        valuesReference: filteredValue,
        x0_135,
        x99_865,
        nominal,
        // warnOffset,
        xAxisLeft,
        xAxisRight,
        decimalPlaces,
        srcData: v0001s,
        ...configSettings,
    }

    return { settings }
}

const makeHistogramOption = async (
    settings,
    configSettings,
    targetChar,
    targetValue,
    fdConfig
) => {
    try {
        const result = await getHistogram(
            targetChar,
            targetValue,
            settings,
            configSettings,
            fdConfig
        )
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data
        return data
    } catch (error) {
        console.error('Error in makeHistogramOption:', error)
    }

    return []
}

function makeProbSettings(curPage, configSettings, targetChar, targetValue) {
    const {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    } = PCUtils.getAllLimits(targetChar, configSettings)
    const limits = {
        lsl,
        usl,
        nominal,
        lwl,
        uwl,
        lpl,
        upl,
        lrl,
        url,
        lacl,
        uacl,
        gap,
        warnOffset,
    }
    const filteredValue = outlierFilterValues(
        limits,
        targetChar,
        targetValue,
        configSettings
    )
    const { lcl, ucl, average, minValue, maxValue, v0001s } =
        PCUtils.getCalculatedPrms(filteredValue, configSettings)

    // const srcData = targetValue.map((value) => value.v0001)
    const srcData = v0001s

    const x99_865 = ucl
    const x0_135 = lcl

    const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
    const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
    const min = Math.min(...minValues)
    const max = Math.max(...maxValues)
    const xAxisLeft = min - (max - min) * 0.1
    const xAxisRight = max + (max - min) * 0.1
    const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

    const settings = {
        ...curPage,
        lsl,
        usl,
        lwl,
        uwl,
        upl,
        lpl,
        url,
        lrl,
        uacl,
        lacl,
        x0_135,
        x99_865,
        nominal,
        char: targetChar,
        valuesReference: filteredValue,
        nominal,
        // warnOffset,
        xAxisLeft,
        xAxisRight,
        decimalPlaces,
        srcData,
        ...configSettings,
    }

    // const configSettings = configPage

    return { targetChar, targetValue, settings, configSettings }
}

const makeProbabilityPlotOption = async (
    settings,
    configSettings,
    targetChar,
    targetValue
) => {
    try {
        const result = await getProbability(
            targetChar,
            targetValue,
            settings,
            configSettings
        )
        if (!ApiStatus.isSuccess(result.status)) {
            alert(getErrCommMsg(result.status))
        }

        const data = result.data
        return data
    } catch (error) {
        console.error('Error in makeHistogramOption:', error)
    }

    return []
}

function makeUpdateOption(data, settings) {
    console.log('data.bin_count_list')
    console.log(data.bin_count_list)
    const bin_count_list = [...data.bin_count_list]

    const bin_density_list = [...data.bin_density_list]
    const bin_width_start_list = [...data.bin_width_start_list]
    const bin_width_center_list = [...data.bin_width_center_list]
    const bin_width_end_list = [...data.bin_width_end_list]
    settings.xAxisLeft = Math.min(settings.xAxisLeft, data.x0_135)
    settings.xAxisRight = Math.max(settings.xAxisRight, data.x99_865)

    const addStartMinArray = []
    const addStartCenterArray = []
    const addStartMaxArray = []
    const addZeroCountMinArray = []
    let dec = bin_width_end_list[0] - bin_width_start_list[0]
    // for (let i = bin_width_start_list[0]; i >= settings.xAxisLeft; i = i - dec) {
    //   addStartMinArray.push(i)
    //   addStartCenterArray.push(i + dec / 2)
    //   addStartMaxArray.push(i + dec)
    //   addZeroCountMinArray.push(0)
    // }
    let ii = bin_width_start_list[0]
    do {
        ii = ii - dec
        addStartMinArray.push(ii)
        addStartCenterArray.push(ii + dec / 2)
        addStartMaxArray.push(ii + dec)
        addZeroCountMinArray.push(0)
    } while (ii >= settings.xAxisLeft - dec)

    addStartMinArray.sort((a, b) => a - b)
    addStartCenterArray.sort((a, b) => a - b)
    addStartMaxArray.sort((a, b) => a - b)

    const addEndMaxArray = []
    const addEndCenterArray = []
    const addEndMinArray = []
    const addZeroCountMaxArray = []
    dec = bin_width_end_list[0] - bin_width_start_list[0]
    // for (let i = bin_width_end_list[bin_width_end_list.length - 1]; i <= settings.xAxisRight; i = i + dec) {
    //   addEndMinArray.push(i - dec)
    //   addEndCenterArray.push(i - dec / 2)
    //   addEndMaxArray.push(i)
    //   addZeroCountMaxArray.push(0)
    // }
    let i = bin_width_end_list[bin_width_end_list.length - 1]
    do {
        i = i + dec
        addEndMinArray.push(i - dec)
        addEndCenterArray.push(i - dec / 2)
        addEndMaxArray.push(i)
        addZeroCountMaxArray.push(0)
    } while (i <= settings.xAxisRight + dec)

    addEndMaxArray.sort((a, b) => a - b)
    addEndCenterArray.sort((a, b) => a - b)
    addEndMinArray.sort((a, b) => a - b)

    bin_count_list.unshift(...addZeroCountMinArray)
    bin_count_list.push(...addZeroCountMaxArray)

    bin_density_list.unshift(...addZeroCountMinArray)
    bin_density_list.push(...addZeroCountMaxArray)

    bin_width_start_list.unshift(...addStartMinArray)
    bin_width_start_list.push(...addEndMinArray)

    bin_width_center_list.unshift(...addStartCenterArray)
    bin_width_center_list.push(...addEndCenterArray)

    bin_width_end_list.unshift(...addStartMaxArray)
    bin_width_end_list.push(...addEndMaxArray)

    return {
        bin_count_list: bin_count_list,
        bin_density_list: bin_density_list,
        bin_width_start_list: bin_width_start_list,
        bin_width_center_list: bin_width_center_list,
        bin_width_end_list: bin_width_end_list,
        curve_x_values: data.curve_x_values,
        curve_y_values: data.curve_y_values,
        xBar: data.xBar,
        x99_865: data.x99_865,
        x50: data.x50,
        x0_135: data.x0_135,
    }
}
const tableRow = (
    part,
    char,
    values,
    index,
    info,
    settings,
    configSettings
) => {
    const lsl = char.c2110 ?? 0
    const usl = char.c2111 ?? 0
    const valueArray = values.valueRaw
        .filter((data) => data.v0002 === 0)
        .map((item) => item.v0001)
    const min = info ? info.minimum : null
    const max = info ? info.maximum : null
    const partName = part.p1002
    const charDesc = slImpl.makeCharDescription(char)
    const median = slImpl.calculateMedian(valueArray)
    const average = info.mean //calculateAverage(valueArray)
    const stdDev = info ? info.stdev : null
    const cp = info ? info.cp : null
    const cpk = info ? info.cpk : null
    const result = slImpl.makeResult(cp, cpk, settings)
    const outOfTol = slImpl.makeOutOfTolerance(
        values.valueRaw.filter((data) => data.v0002 === 0),
        lsl,
        usl
    )
    const dist = info ? info.dist : 'null'

    // extra data
    const decimalPlaces = DNUtils.getValueDecimalPlaces(char, configSettings)

    const convertedValue = values.valueRaw
        .filter((data) => data.v0002 === 0)
        .map((value, index) => ({
            index: index + 1,
            v0001: value.v0001,
        }))
    const trendData = slImpl.makeTrend(convertedValue)

    return {
        no: index + 1,
        part_name: partName,
        desc: charDesc,
        average: average,
        median: median,
        stdDev: stdDev,
        min: min,
        max: max,
        cp: cp,
        cpk: cpk,
        result: result,
        out_of_tol: outOfTol,
        trend: trendData,
        // extra data
        decimalPlaces: decimalPlaces,
        part_id: char.part_id,
        char_id: char.char_id,
        dist: dist,
    }
}

function distributionName(dist) {
    const defineUseDistribution = [
        { value: 0, type: 'Auto-calculation' },
        { value: 1, type: 'Normal-distribution' },
        { value: 2, type: 'Log-normal-distribution' },
        { value: 21, type: 'Folded-normal-distribution' },
        { value: 30, type: 'Weibull-distribution' },
    ]

    const targetDistribution = defineUseDistribution.find(
        (item) => item.value === dist
    )

    return targetDistribution.type
}

function ReportPrintForm({ pageKey, common, chartRawData }) {
    const { getTheme } = useTheme()
    const theme = getTheme()
    const [isLoadingPanel, setIsLoadingPanel] = useState(false)

    const printRef = useRef(null)
    // For initialRendering
    const isInitialRender = useRef(true)

    // const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])
    // const { charArray, curPage, configPage, currentSel } = useMemo(() => CPUtils.loadRefOtherData(common, chars, pageKey), [common])

    const othersPage = common.pages.others
    const valuePage = common.pages.valueChart
    const histPage = common.pages.histogramChart
    const probPage = common.pages.probabilityPlotChart
    const summaryListPage = common.pages.summaryList
    const configPage = common.pages.configuration

    const currentSel = common.current_sel
    const selectedChar = common.loaded_all[pageKey]

    // const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    // const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    // const numCharts = chars.length // Specify the number of charts you want to display
    const reportPageRefs = useRef(null)
    const summaryListRefs = useRef(null)
    const valueChartRefs = useRef(null)
    const histChartRefs = useRef(null)
    const probChartRefs = useRef(null)

    const [calcDataTheoValue, setCalcDataTheoValue] = useState([])
    const [calcDataTheoQualtile, setCalcDataTheoQualtile] = useState([])
    const [probChartOptions, setProbChartOptions] = useState([])
    const [calcValueData, setCalcValueData] = useState([])
    const [valueChartOptions, setValueChartOptions] = useState([])
    const [calcHistData, setCalcHistData] = useState([])
    const [histChartOptions, setHistChartOptions] = useState([])
    const [dataSourceState, setDataSourceState] = useState([])
    const [arimaShowFlag, setArimaShowFlag] = useState(
        valuePage.page.arima.show
    )

    useEffect(() => {
        const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
        const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
        const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(
            charArray,
            values
        )
        const valuesArranged = CPUtils.removeDummyInValues(
            selectedValueWithDummy
        )
        const numCharts = chars.length // Specify the number of charts you want to display

        reportPageRefs.current = new Array(numCharts)
            .fill(null)
            .map(() => ({ element: null, chartElement: null, id: null }))
        summaryListRefs.current = new Array(numCharts)
            .fill(null)
            .map(() => ({ element: null, chartElement: null, id: null }))
        valueChartRefs.current = new Array(numCharts)
            .fill(null)
            .map(() => ({ element: null, chartElement: null, id: null }))
        histChartRefs.current = new Array(numCharts)
            .fill(null)
            .map(() => ({ element: null, chartElement: null, id: null }))
        probChartRefs.current = new Array(numCharts)
            .fill(null)
            .map(() => ({ element: null, chartElement: null, id: null }))
        let valueChartData = []
        let valueChartOptions = []
        let arimaShowFlag = false
        let histogramChartData = []
        let histChartOptions = []
        let probChartData = []
        let probChartOptions = []
        let tableDataArray = []

        let targetSetCalcData
        const processValueChartOptions = async () => {
            if (valuesArranged.length > 0) {
                await Promise.all(
                    valuesArranged.map(async (value) => {
                        const targetPart = value.partRaw
                        const targetChar = value.charRaw
                        const targetValue = value.valueRaw

                        let responseData = null
                        if (
                            arimaShowFlag === false &&
                            valuePage.page.arima.show === true
                        ) {
                            const { sampleSize, forecastSize } =
                                VCUtils.adjustedForecastSampleSize(
                                    valuePage.page.arima.forecastSize,
                                    valuePage.page.arima.sampleSize,
                                    targetValue.length
                                )
                            const entireSrc = targetValue.map(
                                (value) => value.v0001
                            )
                            const sampleSrc = entireSrc.slice(-sampleSize)
                            responseData = await makeArimaOption(
                                valuePage,
                                configPage,
                                forecastSize,
                                sampleSrc
                            )
                            valueChartData.push({
                                info: {
                                    part_id: targetChar.part_id,
                                    char_id: targetChar.char_id,
                                    partReference: targetPart,
                                    charReference: targetChar,
                                },
                                targetPart,
                                targetChar,
                                targetValue,
                                settings: valuePage,
                                configSettings: configPage,
                                responseData,
                                error: { name: null, extra: null },
                            })
                        } else {
                            if (valuePage.page.arima.show === true) {
                                const isAdded = calcValueData.some(
                                    (data) =>
                                        data.info.part_id === value.part_id &&
                                        data.info.char_id === value.char_id
                                )
                                if (!isAdded) {
                                    const { sampleSize, forecastSize } =
                                        VCUtils.adjustedForecastSampleSize(
                                            valuePage.page.arima.forecastSize,
                                            valuePage.page.arima.sampleSize,
                                            targetValue.length
                                        )
                                    const entireSrc = targetValue.map(
                                        (value) => value.v0001
                                    )
                                    const sampleSrc = entireSrc.slice(
                                        -sampleSize
                                    )
                                    responseData = await makeArimaOption(
                                        valuePage,
                                        configPage,
                                        forecastSize,
                                        sampleSrc
                                    )
                                    valueChartData.push({
                                        info: {
                                            part_id: targetChar.part_id,
                                            char_id: targetChar.char_id,
                                            partReference: targetPart,
                                            charReference: targetChar,
                                        },
                                        targetPart,
                                        targetChar,
                                        targetValue,
                                        settings: valuePage,
                                        configSettings: configPage,
                                        responseData,
                                        error: { name: null, extra: null },
                                    })
                                } else {
                                    const tgData = calcValueData.find(
                                        (data) =>
                                            data.info.part_id ===
                                                value.part_id &&
                                            data.info.char_id === value.char_id
                                    )
                                    valueChartData.push(tgData)
                                }
                            } else {
                                valueChartData.push({
                                    info: {
                                        part_id: targetChar.part_id,
                                        char_id: targetChar.char_id,
                                        partReference: targetPart,
                                        charReference: targetChar,
                                    },
                                    targetPart,
                                    targetChar,
                                    targetValue,
                                    settings: valuePage,
                                    configSettings: configPage,
                                    responseData: null,
                                    error: { name: null, extra: null },
                                })
                            }
                        }
                    })
                )

                valueChartData.sort((a, b) => {
                    if (a.info.part_id !== b.info.part_id) {
                        return a.info.part_id - b.info.part_id
                    } else {
                        return a.info.char_id - b.info.char_id
                    }
                })

                valueChartOptions = valueChartData.map((data) => {
                    const targetPart = data.targetPart
                    const targetChar = data.targetChar
                    const targetValue = data.targetValue
                    const settings = data.settings
                    // const configSettings = data.configSettings
                    const responseData = data.responseData

                    if (data.error.name !== null) {
                        return {
                            info: {
                                part_id: targetChar.part_id,
                                char_id: targetChar.char_id,
                                partReference: targetPart,
                                charReference: targetChar,
                            },
                            targetPart,
                            targetChar,
                            targetValue,
                            option: null,
                            error: {
                                name: data.error.name,
                                extra: data.error.extra,
                            },
                        }
                    }

                    const settingsValue = { ...settings, ...valuePage }
                    const configSettings = configPage

                    const pcSet = VCUtils.makePCData(
                        configSettings,
                        targetChar,
                        targetValue
                    )
                    const chartSet = VCUtils.makeChartData(
                        settingsValue,
                        configSettings,
                        pcSet,
                        targetChar,
                        targetValue
                    )
                    let valueChartOption = VCUtils.makeChartOption(
                        true,
                        settingsValue,
                        configSettings,
                        othersPage,
                        pcSet,
                        chartSet,
                        targetChar,
                        targetValue,
                        getTheme()
                    )

                    if (valuePage.page.arima.show === true) {
                        const expectFieldType = [
                            'low_pred',
                            'pred',
                            'upper_pred',
                        ]
                        const forecastFormat = { expectation: [] }
                        const minMaxValues = []

                        expectFieldType.forEach((field) => {
                            forecastFormat.expectation.push({
                                name: field,
                                data: responseData[field],
                            })
                            minMaxValues.push(...responseData[field])
                        })

                        const min = Math.min(...minMaxValues)
                        const max = Math.max(...minMaxValues)
                        const addDataLength =
                            forecastFormat.expectation[0].data.length

                        const chartXAxis = valueChartOption.xAxis
                        let chartXAxisLength =
                            chartXAxis.data.length + addDataLength
                        const chartOneSeries = valueChartOption.series.find(
                            (series) =>
                                series.name ===
                                dqTranslateMsg('Page_ValueChart_' + 'ValueInfo')
                        )
                        let charDataLength = chartOneSeries.data.length

                        const xAxisData = []
                        for (let i = 1; i <= chartXAxisLength; i++) {
                            xAxisData.push([i])
                        }

                        const next = produce(valueChartOption, (draft) => {
                            forecastFormat.expectation.forEach((item) => {
                                let index = charDataLength + 1
                                draft.series.push({
                                    type: 'line',
                                    id: item.name,
                                    name: arimaNameTranslate(item.name),
                                    data: item.data.map((data) => [
                                        index++,
                                        data,
                                    ]),
                                    itemStyle: {
                                        color:
                                            item.name === 'low_pred' ||
                                            item.name === 'upper_pred'
                                                ? valuePage.lineChart
                                                      .arimaChartLines.outer
                                                      .color
                                                : valuePage.lineChart
                                                      .arimaChartLines.inner
                                                      .color,
                                    },
                                    lineStyle: {
                                        color:
                                            item.name === 'low_pred' ||
                                            item.name === 'upper_pred'
                                                ? valuePage.lineChart
                                                      .arimaChartLines.outer
                                                      .color
                                                : valuePage.lineChart
                                                      .arimaChartLines.inner
                                                      .color,
                                    },
                                })
                                draft.xAxis.data = xAxisData
                                draft.xAxis.min = 0
                                draft.xAxis.max = xAxisData.length

                                draft.yAxis.min =
                                    valueChartOption.yAxis.min < min
                                        ? draft.yAxis.min
                                        : min
                                draft.yAxis.max =
                                    valueChartOption.yAxis.max > max
                                        ? draft.yAxis.max
                                        : max
                            })
                        })

                        return {
                            info: {
                                part_id: targetChar.part_id,
                                char_id: targetChar.char_id,
                                partReference: targetPart,
                                charReference: targetChar,
                            },
                            targetPart,
                            targetChar,
                            targetValue,
                            option: next,
                            error: { name: null, extra: null },
                        }
                    } else {
                        return {
                            info: {
                                part_id: targetChar.part_id,
                                char_id: targetChar.char_id,
                                partReference: targetPart,
                                charReference: targetChar,
                            },
                            targetPart,
                            targetChar,
                            targetValue,
                            option: valueChartOption,
                            error: { name: null, extra: null },
                        }
                    }
                })
            } else {
            }
        }
        const processHistChartOptions = async () => {
            let fdData = configPage.findDistribution
            if (valuesArranged.length > 0) {
                await Promise.all(
                    valuesArranged.map(async (value) => {
                        const targetChar = value.charRaw
                        const targetValue = value.valueRaw

                        if (targetValue.length < 5) {
                            histogramChartData.push({
                                info: {
                                    part_id: targetChar.part_id,
                                    char_id: targetChar.char_id,
                                },
                                targetChar,
                                targetValue,
                                settings: null,
                                responseData: null,
                                error: {
                                    name: 'DataIsNotEnough',
                                    extra: targetValue.length,
                                },
                            })
                            return
                        }

                        const { settings } = makeHistSettings(
                            histPage,
                            configPage,
                            targetChar,
                            targetValue
                        )
                        const isAdded = calcHistData.some(
                            (data) =>
                                data.info.part_id === value.part_id &&
                                data.info.char_id === value.char_id
                        )

                        if (!isAdded) {
                            const responseData = await makeHistogramOption(
                                settings,
                                configPage,
                                targetChar,
                                settings.valuesReference,
                                fdData
                            )
                            if (responseData.hasOwnProperty('curve_x_values')) {
                                histogramChartData.push({
                                    info: {
                                        part_id: targetChar.part_id,
                                        char_id: targetChar.char_id,
                                    },
                                    targetChar,
                                    targetValue,
                                    settings,
                                    responseData,
                                    error: { name: null, extra: null },
                                })
                            } else {
                                histogramChartData.push({
                                    info: {
                                        part_id: targetChar.part_id,
                                        char_id: targetChar.char_id,
                                    },
                                    targetChar,
                                    targetValue,
                                    settings: null,
                                    responseData: null,
                                    error: { name: 'Deviation', extra: 0 },
                                })
                            }
                        } else {
                            const tgData = calcHistData.find(
                                (data) =>
                                    data.info.part_id === value.part_id &&
                                    data.info.char_id === value.char_id
                            )
                            histogramChartData.push(tgData)
                        }
                    })
                )

                histogramChartData.sort((a, b) => {
                    if (a.info.part_id !== b.info.part_id) {
                        return a.info.part_id - b.info.part_id
                    } else {
                        return a.info.char_id - b.info.char_id
                    }
                })

                histChartOptions = histogramChartData.map((data) => {
                    const targetChar = data.targetChar
                    const targetValue = data.targetValue
                    const settings = data.settings
                    // const configSettings = data.configSettings
                    const responseData = data.responseData

                    if (data.error.name !== null) {
                        return {
                            info: {
                                part_id: targetChar.part_id,
                                char_id: targetChar.char_id,
                                charReference: targetChar,
                            },
                            option: null,
                            error: {
                                name: data.error.name,
                                extra: data.error.extra,
                            },
                        }
                    }
                    const settingsValue = { ...settings, ...histPage }
                    const configSettings = configPage

                    const options = makeHistChartOption(
                        true,
                        histPage,
                        configPage,
                        targetChar,
                        targetValue
                    )
                    const optionBaseData = makeUpdateOption(
                        responseData,
                        settingsValue
                    )
                    const bellCurveData = optionBaseData.curve_x_values.map(
                        (x, index) => [x, optionBaseData.curve_y_values[index]]
                    )

                    const next = produce(options, (draft) => {
                        draft.series = []
                        draft.series.push({
                            type: 'bar',
                            name: 'count',
                            // xAxisIndex: 0,
                            yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                            // data: optionBaseData.bin_density_list,
                            data: optionBaseData.bin_count_list,
                            itemStyle: {
                                color: settingsValue.barChart.histogramBar
                                    .color,
                            },
                            barWidth: '99%',
                        })
                        draft.series.push({
                            type: 'line',
                            name: 'curve',
                            xAxisIndex: 1,
                            yAxisIndex: 1, // Ensure it's linked to the second (right) Y-axis
                            data: bellCurveData,
                            // data: optionBaseData.curve_y_values,
                            symbol: 'none',
                            itemStyle: {
                                color: settingsValue.barChart.bellCurve.color,
                            },
                            lineStyle: {
                                type: settingsValue.lineChart.chartLines.type,
                                width: settingsValue.lineChart.chartLines.width,
                            },
                        })
                        draft.series.push({
                            type: 'line',
                            name: 'Lines',
                            xAxisIndex: 1,
                            markLine: {
                                animation: false,
                                symbol: 'none',
                                precision: 10,
                                data: histImpl.convertToMarkLine(
                                    responseData.dist,
                                    settingsValue,
                                    configSettings,
                                    optionBaseData,
                                    pageKey
                                ),
                                emphasis: {
                                    disabled: true,
                                },
                            },
                        })

                        draft.xAxis[0].data =
                            optionBaseData.bin_width_center_list
                        draft.xAxis[0].axisLabel.formatter = (value, index) =>
                            histImpl.makeXAxisLabelFormat(value, configSettings)
                        // draft.xAxis[0].min = optionBaseData.bin_width_center_list[0]
                        // draft.xAxis[0].max = optionBaseData.bin_width_center_list[optionBaseData.bin_width_center_list.length - 1]

                        draft.xAxis[1].min =
                            optionBaseData.bin_width_center_list[0]
                        draft.xAxis[1].max =
                            optionBaseData.bin_width_center_list[
                                optionBaseData.bin_width_center_list.length - 1
                            ]

                        // draft.yAxis[0].data = optionBaseData.bin_count_list
                        // draft.yAxis[0].min = Math.min(...optionBaseData.bin_count_list)
                        // draft.yAxis[0].max = Math.max(...optionBaseData.bin_count_list)
                        draft.yAxis[0].name = 'count'
                        draft.yAxis[0].position = 'left'

                        // draft.yAxis[1].data = optionBaseData.curve_y_values
                        // draft.yAxis[1].min = Math.min(...optionBaseData.curve_y_values)
                        // draft.yAxis[1].max = Math.max(...optionBaseData.curve_y_values)

                        draft.yAxis[1].name = 'curve'
                        draft.yAxis[1].position = 'right'

                        draft.tooltip.formatter = function (args) {
                            return histImpl.toolTipBalloonCallback(
                                args,
                                targetChar,
                                settingsValue,
                                configSettings,
                                responseData.dist,
                                optionBaseData
                            )
                        }
                        draft.tooltip.textStyle.fontSize = 10

                        // draft.title.text = `${targetChar.c2002 ?? 'empty'} P${targetChar.part_id ?? '?'}/C${targetChar.char_id ?? '?'}` + ' : ' + distributionName(responseData.dist)
                        // draft.title.textStyle.fontSize = (settingsValue.page.fontSize / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 4
                    })
                    return {
                        info: {
                            part_id: targetChar.part_id,
                            char_id: targetChar.char_id,
                            charReference: targetChar,
                        },
                        option: next,
                        error: { name: null, extra: null },
                    }
                })

                // batch(() => {
                //   setCalcHistData(histogramData)
                //   setHistChartOptions(histChartOptions)
                // })
            } else {
                // batch(() => {
                //   setCalcHistData([])
                //   setHistChartOptions([])
                // })
            }
        }
        const processProbChartOptions = async () => {
            // Fetch all chart options concurrently
            let probPlotData = []
            let targetCalcData = calcDataTheoValue
            targetSetCalcData = setCalcDataTheoValue

            if (probPage.page.yAxisType === 'Theoretical-Value') {
                targetCalcData = calcDataTheoValue
                targetSetCalcData = setCalcDataTheoValue
            } else {
                targetCalcData = calcDataTheoQualtile
                targetSetCalcData = setCalcDataTheoQualtile
            }

            if (valuesArranged.length > 0) {
                await Promise.all(
                    valuesArranged.map(async (value) => {
                        const targetChar = value.charRaw
                        const targetValue = value.valueRaw

                        if (targetValue.length < 5) {
                            probChartData.push({
                                info: {
                                    part_id: targetChar.part_id,
                                    char_id: targetChar.char_id,
                                },
                                targetChar,
                                targetValue,
                                settings: null,
                                configSettings: null,
                                responseData: null,
                                error: {
                                    name: 'DataIsNotEnough',
                                    extra: targetValue.length,
                                },
                            })
                            return
                        }

                        const { settings } = makeProbSettings(
                            probPage,
                            configPage,
                            targetChar,
                            targetValue
                        )
                        const isAdded = targetCalcData.some(
                            (data) =>
                                data.info.part_id === value.part_id &&
                                data.info.char_id === value.char_id
                        )

                        if (!isAdded) {
                            const responseData =
                                await makeProbabilityPlotOption(
                                    settings,
                                    configPage,
                                    targetChar,
                                    targetValue
                                )
                            if (responseData.hasOwnProperty('line_x_values')) {
                                probChartData.push({
                                    info: {
                                        part_id: targetChar.part_id,
                                        char_id: targetChar.char_id,
                                        charReference: targetChar,
                                    },
                                    targetChar,
                                    targetValue,
                                    settings,
                                    //configSettings: configPage,
                                    responseData,
                                    error: { name: null, extra: null },
                                })
                            } else {
                                probChartData.push({
                                    info: {
                                        part_id: targetChar.part_id,
                                        char_id: targetChar.char_id,
                                        charReference: targetChar,
                                    },
                                    targetChar,
                                    targetValue,
                                    settings: null,
                                    //configSettings: null,
                                    responseData: null,
                                    error: { name: 'Deviation', extra: 0 },
                                })
                            }
                        } else {
                            const tgData = targetCalcData.find(
                                (data) =>
                                    data.info.part_id === value.part_id &&
                                    data.info.char_id === value.char_id
                            )
                            probChartData.push(tgData)
                        }
                    })
                )

                probChartData.sort((a, b) => {
                    if (a.info.part_id !== b.info.part_id) {
                        return a.info.part_id - b.info.part_id
                    } else {
                        return a.info.char_id - b.info.char_id
                    }
                })

                probChartOptions = probChartData.map((data) => {
                    const targetChar = data.targetChar
                    const targetValue = data.targetValue
                    const settings = data.settings
                    // const configSettings = data.configSettings
                    const responseData = data.responseData

                    if (data.error.name !== null) {
                        return {
                            info: {
                                part_id: targetChar.part_id,
                                char_id: targetChar.char_id,
                                charReference: targetChar,
                            },
                            option: null,
                            error: {
                                name: data.error.name,
                                extra: data.error.extra,
                            },
                        }
                    }

                    const settingsValue = { ...settings, ...probPage }
                    const configSettings = configPage

                    const valueIntegerLineX =
                        CCUtils.convertExponentialArrayToInt(
                            responseData.line_x_values
                        )
                    const valueIntegerLineY =
                        CCUtils.convertExponentialArrayToInt(
                            responseData.line_y_values
                        )
                    const valueIntegerPointX =
                        CCUtils.convertExponentialArrayToInt(
                            responseData.point_x_values
                        )
                    const valueIntegerPointY =
                        CCUtils.convertExponentialArrayToInt(
                            responseData.point_y_values
                        )

                    const valueIntegerX0_135 =
                        CCUtils.convertExponentialValueToInt(
                            responseData.x0_135
                        )
                    const valueIntegerX99_869 =
                        CCUtils.convertExponentialValueToInt(
                            responseData.x99_865
                        )

                    const lineMin = Math.min(...valueIntegerLineX)
                    const lineMax = Math.max(...valueIntegerLineX)
                    const pointMin = Math.min(...valueIntegerPointX)
                    const pointMax = Math.max(...valueIntegerPointX)
                    const dataMin = lineMin < pointMin ? lineMin : pointMin
                    const dataMax = pointMax < lineMax ? pointMax : lineMax

                    const minValues = [
                        dataMin,
                        settingsValue.lsl,
                        settingsValue.x0_135,
                        settingsValue.x0_135,
                    ].filter((value) => value !== null)
                    const maxValues = [
                        dataMax,
                        settingsValue.usl,
                        settingsValue.x99_865,
                        settingsValue.x99_865,
                    ].filter((value) => value !== null)
                    const min = Math.min(...minValues, valueIntegerX0_135)
                    const max = Math.max(...maxValues, valueIntegerX99_869)
                    const xAxisLeft = min - (max - min) * 0.1
                    const xAxisRight = max + (max - min) * 0.1
                    const yAxisBottom = dataMin - (dataMax - dataMin) * 0.1
                    const yAxisTop = dataMax + (dataMax - dataMin) * 0.1

                    // const probOption = chartRef.current.getEchartsInstance().getOption()
                    const probOption = makeProbChartOption(
                        pageKey,
                        probPage,
                        configSettings,
                        targetChar,
                        targetValue,
                        true
                    )

                    const next = produce(probOption, (draft) => {
                        if (pageKey !== 'reportPrint') {
                            draft.xAxis[0].axisLabel.fontSize =
                                ((settingsValue.page.fontSize - 2) /
                                    (settingsValue.page.layout.row +
                                        settingsValue.page.layout.column)) *
                                3
                            draft.yAxis.axisLabel.fontSize =
                                ((settingsValue.page.fontSize - 2) /
                                    (settingsValue.page.layout.row +
                                        settingsValue.page.layout.column)) *
                                3
                        } else {
                            draft.xAxis[0].axisLabel.fontSize = 10
                            draft.yAxis.axisLabel.fontSize = 10
                        }
                        draft.series = []
                        draft.series.push({
                            type: 'line',
                            name: 'line',
                            symbol: 'none',
                            // data: responseData.line_x_values.map((x, index) => [x, responseData.line_y_values[index]]),
                            data: valueIntegerLineX.map((x, index) => [
                                x,
                                valueIntegerLineY[index],
                            ]),
                            animation: true,
                            lineStyle: {
                                type: settingsValue.lineChart.chartLines.type,
                                width: settingsValue.lineChart.chartLines.width,
                                color: settingsValue.lineChart.chartLines.color,
                            },
                            animation: true,
                        })
                        draft.series.push({
                            type: 'scatter',
                            name: dqTranslateMsg(
                                'Page_ProbabilityPlotChart_' + 'ValueInfo'
                            ),
                            xAxisIndex: 0,
                            yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                            // data: responseData.point_x_values.map((x, index) => [x, CCUtils.convertExponentialValueToInt(responseData.point_y_values[index])]),
                            data: valueIntegerPointX.map((x, index) => [
                                x,
                                valueIntegerPointY[index],
                            ]),
                            symbol: (value, params) =>
                                probImpl.makeSymbol(
                                    value,
                                    params,
                                    settingsValue
                                ),
                            symbolSize: (value, params) =>
                                probImpl.makeSymbolSize(
                                    value,
                                    params,
                                    settingsValue
                                ),
                            animation: true,
                            itemStyle: {
                                color: (params) =>
                                    probImpl.makeSymbolColor(
                                        params,
                                        settingsValue
                                    ),
                            },
                        })
                        draft.series.push({
                            type: 'line',
                            name: dqTranslateMsg(
                                'Page_ProbabilityPlotChart_' + 'Limits'
                            ),
                            yAxisIndex: 0,
                            markLine: {
                                animation: false,
                                symbol: 'none',
                                precision: 10,
                                // precision: settingsValue.lineChart.markLines.precision,
                                data: probImpl.convertToMarkLine(
                                    responseData,
                                    settingsValue,
                                    configSettings
                                ),
                                emphasis: {
                                    disabled: true,
                                },
                            },
                        })

                        if (pageKey !== 'reportPrint') {
                            draft.title.text = `${
                                targetChar.c2002 ?? 'empty'
                            }P:${targetChar.part_id ?? '?'}/C:${
                                targetChar.char_id ?? '?'
                            }`
                            draft.title.text +=
                                ' : ' + distributionName(responseData.dist)
                            draft.title.subtext =
                                'R² = ' +
                                DNUtils.calcValueDisplay(
                                    responseData.r2,
                                    configSettings
                                )
                        }

                        draft.xAxis[0].min = xAxisLeft
                        draft.xAxis[0].max = xAxisRight
                        // draft.xAxis[0].axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
                        // draft.yAxis.axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
                        draft.xAxis[0].axisLabel.formatter = function (value) {
                            // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
                            // return DNUtils.calcValueDisplay(values, configSettings)
                            const axisMax = xAxisRight
                            const axisMin = xAxisLeft

                            // 최대값과 최소값은 레이블을 표시하지 않음
                            if (value === axisMin || value === axisMax) {
                                return ''
                            }

                            return value // 그 외의 값은 그대로 표시
                        }

                        draft.yAxis.min =
                            settings.page.yAxisType === 'Theoretical-Value'
                                ? yAxisBottom
                                : 0
                        draft.yAxis.max =
                            settings.page.yAxisType === 'Theoretical-Value'
                                ? yAxisTop
                                : 100
                        draft.yAxis.axisLabel.formatter = function (value) {
                            if (
                                settings.page.yAxisType === 'Theoretical-Value'
                            ) {
                                const axisMax = yAxisTop
                                const axisMin = yAxisBottom

                                if (value === axisMin || value === axisMax) {
                                    return ''
                                }

                                return value
                            } else {
                                return value
                            }
                        }
                    })

                    return {
                        info: {
                            part_id: targetChar.part_id,
                            char_id: targetChar.char_id,
                            charReference: targetChar,
                        },
                        option: next,
                        error: {
                            name: 'DataIsNotEnough',
                            extra: targetValue.length,
                        },
                    }
                })

                // batch(() => {
                //   targetSetCalcData(probPlotData)
                //   setProbChartOptions(probPlotChartOptions)
                // })
            } else {
                // batch(() => {
                //   targetSetCalcData([])
                //   setProbChartOptions([])
                // })
            }
        }

        const processSummaryData = async (
            valueArray,
            targetChars,
            settings,
            configSettings
        ) => {
            try {
                await Promise.all(
                    valueArray.map(async (valueObj, index) => {
                        const targetPart = valueObj.partRaw
                        const targetChar = valueObj.charRaw
                        const targetValue = valueObj.valueRaw

                        if (targetValue.length < 2) {
                            tableDataArray.push(
                                tableRow(
                                    targetPart,
                                    targetChar,
                                    valueObj,
                                    index,
                                    null,
                                    settings
                                )
                            )
                            return
                        }

                        const result = await getSummary(
                            targetChar,
                            valueObj.valueRaw,
                            settings,
                            configSettings
                        )

                        if (!ApiStatus.isSuccess(result.status)) {
                            alert(getErrCommMsg(result.status))
                        }
                        tableDataArray.push(
                            tableRow(
                                targetPart,
                                targetChar,
                                valueObj,
                                0,
                                result.data,
                                settings,
                                configSettings
                            )
                        )
                    })
                )
            } catch (error) {
                console.error('Error processing summary data:', error)
            }
        }

        const processChartOptions = async () => {
            setIsLoadingPanel(true)
            try {
                await processValueChartOptions()
                await processHistChartOptions()
                await processProbChartOptions()
                await processSummaryData(
                    valuesArranged,
                    chars,
                    summaryListPage,
                    configPage
                )

                batch(() => {
                    setArimaShowFlag(arimaShowFlag)
                    setCalcValueData(valueChartData)
                    setValueChartOptions(valueChartOptions)
                    setCalcHistData(histogramChartData)
                    setHistChartOptions(histChartOptions)
                    targetSetCalcData(probChartData)
                    setProbChartOptions(probChartOptions)
                    setDataSourceState(tableDataArray)
                })
            } catch (error) {
                console.error('Error fetching chart options:', error)
            } finally {
                setIsLoadingPanel(false)
            }
        }
        processChartOptions()
    }, [
        selectedChar,
        valuePage,
        histPage,
        probPage,
        summaryListPage,
        configPage,
    ])

    const generateCanvas = (i, doc, { resolve }) => {
        let imageDiv = document.getElementById(`div${i}`)

        const scale = 2 // 유지 - 캡처할 이미지의 해상도를 높임
        html2canvas(imageDiv, { scale: scale, useCORS: true })
            .then((canvas) => {
                const imgHeight = 297 // A4 페이지의 높이에 맞춤, 210mm로 조정
                const imgWidth = (imgHeight * canvas.width) / canvas.height // 가로 세로 비율을 유지하면서 너비 계산
                const position = 0 // 페이지 상단에 이미지를 위치

                try {
                    doc.addImage(
                        canvas.toDataURL('image/jpeg', 0.8), // 이미지 포맷을 JPEG로 변경하고 품질을 0.8로 설정
                        'JPEG', // 이미지 포맷을 PNG에서 JPEG로 변경하여 파일 크기 감소
                        10, // 좌측 여백 조정
                        position, // 상단 여백 설정
                        imgWidth,
                        imgHeight
                    )
                    doc.addPage() // 다음 이미지를 위한 페이지 추가
                } catch (error) {
                    console.error('Error adding image to PDF:', error)
                }

                resolve() // 프로미스 완료 처리
            })
            .catch((error) => {
                console.error('Error capturing canvas:', error)
                resolve() // 에러가 발생해도 프로미스를 완료 처리하여 전체 프로세스 중단 방지
            })
    }

    const saveBodyContent = async () => {
        try {
            const numberOfInnerDivs = document.querySelectorAll(
                '.report-print-form-print-area-content-page'
            )
            const deferreds = []
            const doc = new jsPDF({ orientation: 'portrait' })

            for (let i = 0; i < numberOfInnerDivs.length; i++) {
                const deferred = new Promise((resolve) => {
                    generateCanvas(i, doc, { resolve })
                })
                deferreds.push(deferred)
            }

            Promise.all(deferreds).then(() => {
                doc.save('report-print.pdf')
            })
        } catch (error) {
            console.error('Error converting div to image:', error)
        }
    }

    function renderResult(cellData, settings) {
        const iconType = settings.theme.iconType.result

        if (cellData.data.result === 1) {
            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find(
                            (type) => type.path === 'good'
                        ).id
                    )}`}
                    width='20'
                    height='20'
                />
            ) // NG
        } else if (cellData.data.result === 0) {
            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find((type) => type.path === 'bad')
                            .id
                    )}`}
                    width='20'
                    height='20'
                />
            ) // NG
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                    {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                </>
            )
        }
    }

    function renderAverage(cellData, settings, configSettings) {
        const decimalPlaces = cellData.data.decimalPlaces
        // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.average), decimalPlaces), isExponential)}</div>
        return (
            <div>
                {DNUtils.calcValueDisplay(
                    Number(cellData.data.average),
                    configSettings
                )}
            </div>
        )
    }
    function renderMedian(cellData, settings, configSettings) {
        // const decimalPlaces = cellData.data.decimalPlaces
        // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.median), decimalPlaces), isExponential)}</div>
        return (
            <div>
                {DNUtils.calcValueDisplay(
                    Number(cellData.data.median),
                    configSettings
                )}
            </div>
        )
    }

    function renderStandardDeviation(cellData, settings, configSettings) {
        // const decimalPlaces = cellData.data.decimalPlaces
        // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.stdDev), decimalPlaces), isExponential)}</div>
        return (
            <div>
                {DNUtils.calcValueDisplay(
                    Number(cellData.data.stdDev),
                    configSettings
                )}
            </div>
        )
    }
    function renderMinimum(cellData, settings, configSettings) {
        // const decimalPlaces = cellData.data.decimalPlaces
        // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.min), decimalPlaces), isExponential)}</div>
        return (
            <div>
                {DNUtils.calcValueDisplay(
                    Number(cellData.data.min),
                    configSettings
                )}
            </div>
        )
    }

    function renderMaximum(cellData, settings, configSettings) {
        // const decimalPlaces = cellData.data.decimalPlaces
        // return <div>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.max), decimalPlaces), isExponential)}</div>
        return (
            <div>
                {DNUtils.calcValueDisplay(
                    Number(cellData.data.max),
                    configSettings
                )}
            </div>
        )
    }

    function renderCp(cellData, settings, configSettings) {
        const decimalPlaces = cellData.data.decimalPlaces
        if (cellData.data.cp !== null) {
            if (cellData.data.cp <= settings.page.cpGood) {
                return (
                    <div className='summary-list-cp-cpk-container'>
                        {/* <div style={{ color: 'red' }}>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.cp), decimalPlaces), isExponential)}</div> */}
                        <div style={{ color: 'red' }}>
                            {DNUtils.calcValueDisplay(
                                Number(cellData.data.cp),
                                configSettings
                            )}
                        </div>
                    </div>
                )
            }

            return (
                <div>
                    {DNUtils.calcValueDisplay(
                        Number(cellData.data.cp),
                        configSettings
                    )}
                </div>
            )
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                    {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                </>
            )
        }
    }

    function checkCp(cp, settings, configSettings) {
        const iconType = 'arrow'

        if (cp !== null) {
            if (cp <= settings.page.cpGood) {
                return (
                    <img
                        src={`${IconLoader.getImgPath(
                            configPage.menuIconType, // mode
                            IconLoader.TYPES_DEF.find(
                                (type) => type.path === iconType
                            ).id,
                            getTheme().includes('dark') ? 1 : 0,
                            IconLoader.STATE_DEF.find(
                                (type) => type.path === 'bad'
                            ).id
                        )}`}
                        width='20'
                        height='20'
                    />
                ) // NG
            }

            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find(
                            (type) => type.path === 'good'
                        ).id
                    )}`}
                    width='20'
                    height='20'
                />
            ) // OK
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                </>
            )
        }
    }

    function renderCpk(cellData, settings, configSettings) {
        const decimalPlaces = cellData.data.decimalPlaces

        if (cellData.data.cpk !== null) {
            if (cellData.data.cpk <= settings.page.cpkGood) {
                return (
                    <div className='summary-list-cp-cpk-container'>
                        {/* <div style={{ color: 'red' }}>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.cpk), decimalPlaces), isExponential)}</div> */}
                        <div style={{ color: 'red' }}>
                            {DNUtils.calcValueDisplay(
                                Number(cellData.data.cpk),
                                configSettings
                            )}
                        </div>
                    </div>
                )
            }

            return (
                <div className='summary-list-cp-cpk-container'>
                    {/* <div style={{ fontSize: '12px' }}>{DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(cellData.data.cpk), decimalPlaces), isExponential)}</div> */}
                    <div style={{ fontSize: '12px' }}>
                        {DNUtils.calcValueDisplay(
                            Number(cellData.data.cpk),
                            configSettings
                        )}
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                    {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                </>
            )
        }
    }

    function checkCpk(cpk, settings, configSettings) {
        const iconType = 'arrow'

        if (cpk !== null) {
            if (cpk <= settings.page.cpkGood) {
                return (
                    <img
                        src={`${IconLoader.getImgPath(
                            configPage.menuIconType, // mode
                            IconLoader.TYPES_DEF.find(
                                (type) => type.path === iconType
                            ).id,
                            getTheme().includes('dark') ? 1 : 0,
                            IconLoader.STATE_DEF.find(
                                (type) => type.path === 'bad'
                            ).id
                        )}`}
                        width='20'
                        height='20'
                    />
                ) // NG
            }

            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find(
                            (type) => type.path === 'good'
                        ).id
                    )}`}
                    width='20'
                    height='20'
                />
            ) // OK
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                </>
            )
        }
    }
    function renderOutOfTol(cellData, settings) {
        const iconType = settings.theme.iconType.outOfTolerances

        if (cellData.data.out_of_tol === 1) {
            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find(
                            (type) => type.path === 'good'
                        ).id
                    )}`}
                    width='20'
                    height='20'
                />
            ) // OK
        } else if (cellData.data.out_of_tol === 0) {
            return (
                <img
                    src={`${IconLoader.getImgPath(
                        configPage.menuIconType, // mode
                        IconLoader.TYPES_DEF.find(
                            (type) => type.path === iconType
                        ).id,
                        getTheme().includes('dark') ? 1 : 0,
                        IconLoader.STATE_DEF.find((type) => type.path === 'bad')
                            .id
                    )}`}
                    width='20'
                    height='20'
                />
            )
        } else {
            return (
                <>
                    <img
                        src={'/images/color/warning-exclamation.png'}
                        width='20'
                        height='20'
                    />
                    {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
                </>
            )
        }
    }

    function onRowPrepared(e) {
        e.rowElement.style.height = '12px'
        e.rowElement.style.fontSize = '10px'
        e.rowElement.style.verticalAlign = 'middle'
    }

    return (
        <div className={'dx-theme-background-color'}>
            <DQLoadPanel
                open={isLoadingPanel}
                message={dqTranslateMsg('Page_ReportPrint_' + 'Processing')}
            />
            <div className='report-print-form-control-container'>
                <Button
                    className='report-print-form-control-save-button'
                    icon='save'
                    text={'Sava PDF'}
                    width={'100%'}
                    height={'100%'}
                    onClick={saveBodyContent}
                />
                {/* <Button className="report-print-form-control-print-button" icon="print" text={'Print Report'} width={'50%'} height={'100%'} onClick={printBodyContent} /> */}
            </div>
            {valueChartOptions.length > 0 ? (
                <div className='report-print-form-content'>
                    <div className='report-print-form-content-print'>
                        <div className='report-print-form-empty-area-content'></div>
                        <div
                            ref={printRef}
                            className='report-print-form-print-area-content'
                        >
                            {valueChartOptions.map((el, index) => {
                                const info = {
                                    part_id: el.info.part_id,
                                    char_id: el.info.char_id,
                                }
                                const targetValueChart = valueChartOptions.find(
                                    (option) =>
                                        option.info.part_id === info.part_id &&
                                        option.info.char_id === info.char_id
                                )
                                const targetHistogramChart =
                                    histChartOptions.find(
                                        (option) =>
                                            option.info.part_id ===
                                                info.part_id &&
                                            option.info.char_id === info.char_id
                                    )
                                const targetProbPlotChart =
                                    probChartOptions.find(
                                        (option) =>
                                            option.info.part_id ===
                                                info.part_id &&
                                            option.info.char_id === info.char_id
                                    )
                                const targetSummaryList = dataSourceState.find(
                                    (option) =>
                                        option.part_id === info.part_id &&
                                        option.char_id === info.char_id
                                )

                                // const target = [valueChartOptions[index], histChartOptions[index], probChartOptions[index], dataSourceState[index], info]
                                const target = [
                                    targetValueChart,
                                    targetHistogramChart,
                                    targetProbPlotChart,
                                    targetSummaryList,
                                    info,
                                ]
                                return (
                                    <div
                                        key={index}
                                        id={`div${index}`}
                                        ref={(e) => {
                                            reportPageRefs.current[
                                                index
                                            ].element = e
                                            reportPageRefs.current[
                                                index
                                            ].id = `${target[4].part_id}_${target[4].char_id}`
                                        }}
                                        className='report-print-form-print-area-content-page'
                                    >
                                        <div className='rpf-title'>
                                            <div className='rpf-title-icon'>
                                                {/* <img src={'/images/color/corporate_black.svg'} style={{ width: '100%', height: '100%', padding: '5px' }} /> */}
                                                <img
                                                    src={
                                                        '/images/color/corporate.png'
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        padding: '5px',
                                                    }}
                                                />
                                            </div>
                                            <div className='rpf-title-text-container'>
                                                <p className='rpf-title-text-content'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'ProcessCapabilityReport'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='rpf-description'>
                                            <div className='rpf-description-part-info-a'>
                                                <div className='rpf-description-part-number-info'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'PartNumber'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-part-number-info-data'>
                                                    {target[0].targetPart.p1001}
                                                </div>
                                            </div>
                                            <div className='rpf-description-part-info-b'>
                                                <div className='rpf-description-part-desc-info'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'PartDescription'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-part-desc-info-data'>
                                                    {target[0].targetPart.p1002}
                                                </div>
                                            </div>
                                            <div className='rpf-description-char-info'>
                                                <div className='rpf-description-char-info-a'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'CharacteristicsName'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-char-info-a-data'>
                                                    {target[0].targetChar.c2002}
                                                </div>
                                            </div>
                                            <div className='rpf-description-char2-info'>
                                                <div className='rpf-description-char-info-b'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Nominal'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-char-info-b-data'>
                                                    {target[0].targetChar.c2101}
                                                </div>
                                                <div className='rpf-description-char-info-c'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'USL'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-char-info-c-data'>
                                                    {target[0].targetChar.c2111}
                                                </div>
                                                <div className='rpf-description-char-info-d'>
                                                    {dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'LSL'
                                                    ) + ' : '}
                                                </div>
                                                <div className='rpf-description-char-info-d-data'>
                                                    {target[0].targetChar.c2110}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='rpf-main-chart'
                                            style={{ padding: '5px' }}
                                        >
                                            <ApacheEChartValueChartComponent
                                                option={target[0].option}
                                                theme={getTheme()}
                                                pageKey={pageKey}
                                            />
                                        </div>
                                        <div className='rpf-sub-chart'>
                                            <div className='rpf-sub-chart-left'>
                                                <Fragment>
                                                    {target[1].option ? (
                                                        <ApacheEChartHistogramComponent
                                                            option={
                                                                target[1].option
                                                            }
                                                            theme={getTheme()}
                                                            pageKey={pageKey}
                                                        />
                                                    ) : (
                                                        <PageWarningMessage
                                                            fontSize={18}
                                                            message={
                                                                `${
                                                                    target[1]
                                                                        .info
                                                                        .charReference
                                                                        .c2002 ??
                                                                    'empty'
                                                                } P${
                                                                    target[1]
                                                                        .info
                                                                        .part_id ??
                                                                    '?'
                                                                }/C${
                                                                    target[1]
                                                                        .info
                                                                        .char_id ??
                                                                    '?'
                                                                }` +
                                                                ' - ' +
                                                                dqTranslateMsg(
                                                                    'Page_HistogramChart_' +
                                                                        target[1]
                                                                            .error
                                                                            .name
                                                                ) +
                                                                ` (${target[1].error.extra})`
                                                            }
                                                        />
                                                    )}
                                                </Fragment>
                                            </div>
                                            <div className='rpf-sub-chart-right'>
                                                {target[2].option ? (
                                                    <ApacheEChartPTComponent
                                                        option={
                                                            target[2].option
                                                        }
                                                        theme={getTheme()}
                                                        pageKey={pageKey}
                                                    />
                                                ) : (
                                                    <PageWarningMessage
                                                        fontSize={18}
                                                        message={
                                                            `${
                                                                target[2].info
                                                                    .charReference
                                                                    .c2002 ??
                                                                'empty'
                                                            } P${
                                                                target[2].info
                                                                    .part_id ??
                                                                '?'
                                                            }/C${
                                                                target[1].info
                                                                    .char_id ??
                                                                '?'
                                                            }` +
                                                            ' - ' +
                                                            dqTranslateMsg(
                                                                'Page_HistogramChart_' +
                                                                    target[2]
                                                                        .error
                                                                        .name
                                                            ) +
                                                            ` (${target[2].error.extra})`
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className='rpf-main-list'>
                                            <DataGrid
                                                // ref={summaryListGrid}
                                                className='rpf-main-list'
                                                id='report-print-summary-list-datagrid'
                                                ref={(e) => {
                                                    summaryListRefs.current[
                                                        index
                                                    ].chartElement = e
                                                }}
                                                key={index}
                                                // dataSource={[dataSourceState[index]]}
                                                dataSource={[target[3]]}
                                                // dataSource={target[3].tableDataArray}
                                                allowColumnReordering={true}
                                                allowColumnResizing={true}
                                                columnResizingMode={'widget'}
                                                filterRow={{ visible: false }}
                                                noDataText={dqTranslateMsg(
                                                    'Page_ReportPrint_' +
                                                        'NoData'
                                                )}
                                                paging={{ enabled: false }}
                                                showColumnLines={true}
                                                showRowLines={true}
                                                showBorders={true}
                                                rowAlternationEnabled={false}
                                                hoverStateEnabled={true}
                                                onRowPrepared={onRowPrepared}
                                            >
                                                <Column
                                                    dataField='desc'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Description'
                                                    )}
                                                    width={'15%'}
                                                />
                                                <Column
                                                    dataField='average'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Average'
                                                    )}
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderAverage(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='median'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Median'
                                                    )}
                                                    width={'10%'}
                                                    visible={false}
                                                    cellRender={(cellData) =>
                                                        renderMedian(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='stdDev'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'StandardDeviation'
                                                    )}
                                                    alignment='center'
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderStandardDeviation(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='min'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Minimum'
                                                    )}
                                                    alignment='center'
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderMinimum(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='max'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Maximum'
                                                    )}
                                                    alignment='center'
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderMaximum(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='cp'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Cp'
                                                    )}
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderCp(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='cpk'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Cpk'
                                                    )}
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderCpk(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='result'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'Result'
                                                    )}
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderResult(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                                <Column
                                                    dataField='out_of_tol'
                                                    alignment='center'
                                                    caption={dqTranslateMsg(
                                                        'Page_ReportPrint_' +
                                                            'OutOfTol'
                                                    )}
                                                    width={'10%'}
                                                    cellRender={(cellData) =>
                                                        renderOutOfTol(
                                                            cellData,
                                                            summaryListPage,
                                                            configPage
                                                        )
                                                    }
                                                />
                                            </DataGrid>
                                        </div>
                                        <div className='rpf-etc-info'>
                                            {/* {target[3] ? ( */}
                                            <Fragment>
                                                <div className='rpf-capability-report-distribution'>
                                                    <div className='rpf-capability-report-distribution-desc'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'Distribution'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-distribution-value'>
                                                        {target[3]
                                                            ? distributionName(
                                                                  target[3].dist
                                                              )
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className='rpf-capability-report-cp'>
                                                    <div className='rpf-capability-report-cp-desc'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'PotentialPerformanceIndex'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-cp-desc-char'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'Cp'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-cp-value'>
                                                        {/* {target[3] !== null ? DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(Number(target[3].cp), target[3].decimalPlaces), isExponential) : ''} */}
                                                        {target[3]
                                                            ? DNUtils.calcValueDisplay(
                                                                  Number(
                                                                      target[3]
                                                                          .cp
                                                                  ),
                                                                  configPage
                                                              )
                                                            : ''}
                                                    </div>
                                                    <div className='rpf-capability-report-cp-img'>
                                                        {target[3]
                                                            ? checkCp(
                                                                  target[3].cp,
                                                                  summaryListPage,
                                                                  configPage
                                                              )
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className='rpf-capability-report-cpk'>
                                                    <div className='rpf-capability-report-cpk-desc'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'CriticalPerformanceIndex'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-cpk-desc-char'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'Cpk'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-cpk-value'>
                                                        {target[3]
                                                            ? DNUtils.calcValueDisplay(
                                                                  Number(
                                                                      target[3]
                                                                          .cpk
                                                                  ),
                                                                  configPage
                                                              )
                                                            : ''}
                                                    </div>
                                                    <div className='rpf-capability-report-cpk-img'>
                                                        {target[3]
                                                            ? checkCpk(
                                                                  target[3].cpk,
                                                                  summaryListPage,
                                                                  configPage
                                                              )
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className='rpf-capability-report-info'>
                                                    <div className='rpf-capability-report-info-desc'>
                                                        {dqTranslateMsg(
                                                            'Page_ReportPrint_' +
                                                                'Report'
                                                        )}
                                                    </div>
                                                    <div className='rpf-capability-report-info-desc-char'>
                                                        <div className='rpf-capability-report-info-cp-desc-char'>
                                                            {dqTranslateMsg(
                                                                'Page_ReportPrint_' +
                                                                    'Cp'
                                                            )}
                                                        </div>
                                                        <div className='rpf-capability-report-info-cpk-desc-char'>
                                                            {dqTranslateMsg(
                                                                'Page_ReportPrint_' +
                                                                    'Cpk'
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='rpf-capability-report-info-result'>
                                                        <div className='rpf-capability-report-info-result-cp-value'>
                                                            {target[3]
                                                                ? target[3].cp >
                                                                  1.33
                                                                    ? 'is Met'
                                                                    : 'is Not Met'
                                                                : ''}
                                                        </div>
                                                        <div className='rpf-capability-report-info-result-cpk-value'>
                                                            {target[3]
                                                                ? target[3]
                                                                      .cpk >
                                                                  1.33
                                                                    ? 'is Met'
                                                                    : 'is Not Met'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            {/* ) : null} */}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='report-print-form-empty-area-content'></div>
                    </div>
                </div>
            ) : (
                <PageWarningMessage
                    message={dqTranslateMsg(
                        'Page_ReportPrint_' + 'DataIsNotSelected'
                    )}
                />
            )}
        </div>
    )
}
