// {
//     name: "3333",
//     hint: "",
//     type: "collective",
//     page: {
//       size: "A4",
//       orientation: "portrait",
//       wrap: false,
//       debug: false,
//       dpi: 72,
//     },
//     elements: [
//       {
//         index: 0,
//         heightUnit: 5,
//         columnCount: 1,
//         columnContent: [
//           {
//             type: "text",
//             value: "title-desc",
//             display: "Title",
//           },
//         ],
//       },
//       {
//         index: 1,
//         heightUnit: 10,
//         columnCount: 1,
//         columnContent: [
//           {
//             value: "summary-list-collective",
//             content: [
//               "cp",
//               "p1002",
//               "c2001",
//               "average",
//               "median",
//               "max",
//               "nominal",
//               "usl",
//               "lsl",
//               "upAlw",
//               "loAlw",
//               "cpk",
//               "result",
//               "outOfTol",
//             ],
//           },
//         ],
//       },
//       {
//         index: 2,
//         heightUnit: 10,
//         columnCount: 1,
//         columnContent: [
//           {
//             value: "summary-list-collective",
//             content: [
//               "result",
//             ],
//           },
//         ],
//       },
//     ],
//   }

import { defineResultItems } from '../define/DefineEllementInfo'
import {
    getAllElementCollectiveValue,
    reportElementTagListForCollective,
} from '../define/DefineTagListCollective'
import { getAllElementCollectiveTagsValue } from '../define/DefineTagListCollectiveSummaryList'
import {
    getAllElementIndividualType,
    getAllElementIndividualValue,
    reportElementTagListForIndividual,
} from '../define/DefineTagListIndividual'

export const validateTemplates = (templates, oriTemplates) => {
    return templates.every((template) => {
        if (
            !template.name ||
            !template.type ||
            !template.page ||
            !template.elements
        ) {
            return false
        }

        const isSameTemplateName = oriTemplates.some(
            (ori) => ori.name === template.name
        )
        if (isSameTemplateName) {
            return false
        }

        return validateTemplate(template)
    })
}

const checkIndividualTemplateElements = (elements) => {
    return elements.every((el) => {
        if (!el.heightUnit || !el.columnContent) {
            return false
        }

        return el.columnContent.every((item) => {
            if (!item.value) {
                return false
            }

            const itemValue = getAllElementIndividualValue()
            if (!itemValue.includes(item.value)) {
                return false
            }

            if (Array.isArray(item.content)) {
                return item.content.every((itemInner) => {
                    const itemTagsContent = reportElementTagListForIndividual()
                    const itemResultContent = defineResultItems
                    if (
                        !itemTagsContent.includes(itemInner) ||
                        !itemResultContent.includes(itemInner)
                    ) {
                        return false
                    }

                    return true
                })
            }

            return true
        })
    })
}
const checkCollectiveTemplateElements = (elements) => {
    return elements.every((el) => {
        if (!el.heightUnit || !el.columnContent) {
            return false
        }

        return el.columnContent.every((item) => {
            if (!item.value) {
                return false
            }

            const itemValue = getAllElementCollectiveValue()
            if (!itemValue.includes(item.value)) {
                return false
            }

            if (Array.isArray(item.content)) {
                return item.content.every((itemInner) => {
                    const itemTagsContent = getAllElementCollectiveTagsValue()
                    if (!itemTagsContent.includes(itemInner)) {
                        return false
                    }

                    return true
                })
            }

            return true
        })
    })
}

const validateTemplate = (template) => {
    // check page info valid

    const templateType = template.type

    return templateType === 'individual'
        ? checkIndividualTemplateElements(template.elements)
        : checkCollectiveTemplateElements(template.elements)

    // check element info valid
}
