import { getDBFieldMsg, getPageMsg } from 'src/functions/CommonMassageUtils'
import { msgType } from 'src/functions/table/MessageTypeTable'

export const reportTagsCollectiveSummaryList = () => [
    {
        type: 'item',
        value: 'p1002',
        display: getPageMsg(
            msgType.ReportPrintMultiFunction,
            'PartDescription'
        ),
    },
    {
        type: 'item',
        value: 'c2001',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Description'),
    },
    {
        type: 'item',
        value: 'average',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Average'),
    },
    {
        type: 'item',
        value: 'median',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Median'),
    },
    {
        type: 'item',
        value: 'stdDev',
        display: getPageMsg(
            msgType.ReportPrintMultiFunction,
            'StandardDeviation'
        ),
    },
    {
        type: 'item',
        value: 'min',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Minimum'),
    },
    {
        type: 'item',
        value: 'max',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Maximum'),
    },
    {
        type: 'item',
        value: 'nominal',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Nominal'),
    },
    {
        type: 'item',
        value: 'usl',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'USL'),
    },
    {
        type: 'item',
        value: 'lsl',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'LSL'),
    },
    {
        type: 'item',
        value: 'upAlw',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'UpperAllowance'),
    },
    {
        type: 'item',
        value: 'loAlw',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'LowerAllowance'),
    },
    {
        type: 'item',
        value: 'cp',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Cp'),
    },
    {
        type: 'item',
        value: 'cpk',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Cpk'),
    },
    {
        type: 'item',
        value: 'result',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Result'),
    },
    {
        type: 'item',
        value: 'outOfTol',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'OutOfTol'),
    },
    {
        type: 'item',
        value: 'trend',
        display: getPageMsg(msgType.ReportPrintMultiFunction, 'Trend'),
    },
]

export const filterCollectiveTags = (type) => {
    switch (type) {
        case 'all':
            return reportElementTagListForCollective()
        case 'text':
        case 'list':
        case 'field':
            return reportElementTagListForCollective().filter(
                (element) => element.type === type
            )
        default:
            console.error('not match element type')
            break
    }
}

export const getAllElementCollectiveTagsValue = () => {
    return [...new Set(reportTagsCollectiveSummaryList().map((el) => el.value))]
}
